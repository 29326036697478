import React, { Component } from 'react';
import Modals from '../../../components/modal';
import Empty_container from '../../../components/empty_container';
import ReactToPrint, { PrintContextConsumer } from 'react-to-print';
import { isHasPermission } from '../../../utils/helpres';
import axios from 'axios';
import Tooltip from '@mui/material/Tooltip';

class Document extends Component {
  constructor(props, context) {
    super(props, context);
    this.state = {
      setIsOpen: false,
      setIsOpenDeleteModal: false,
      file: null,
      errorSizeFile: false,
      setIsOpenUploadFile: false,
      fileUrlWithToken: '',
      fileUrl: '',
      fileName: '',
    };
    this.handleChangeFile = this.handleChangeFile.bind(this);
    this.openModal = this.openModal.bind(this);
    this.openModalUploadFile = this.openModalUploadFile.bind(this);
    this.openDeleteModal = this.openDeleteModal.bind(this);
  }

  handleChangeFiles = (event, name) => {
    const files = event.target.files[0];
    const filesize = (event.target.files[0].size / 1024).toFixed(4);
    if (filesize > 10000) {
      this.setState({
        errorSizeFile: true,
      });
    } else {
      this.setState({
        [name]: files,
        errorSizeFile: false,
      });
    }
  };

  openDeleteModal() {
    this.setState({
      setIsOpenDeleteModal: !this.state.setIsOpenDeleteModal,
    });
  }

  openModal() {
    this.setState({
      setIsOpen: !this.state.setIsOpen,
    });
  }

  openModalUploadFile() {
    this.setState({
      setIsOpenUploadFile: !this.state.setIsOpenUploadFile,
    });
    this.handleChangeFile();
  }

  handleChangeFile() {
    this.setState({
      file: null,
      errorSizeFile: false,
    });
  }

  componentWillReceiveProps(nextProps, nextContext) {
    const { addFiles } = nextProps;
    if (addFiles) {
      this.openModalUploadFile();
    }
  }

  getItemDocument(id, fileURL) {
    const { files } = this.props;
    const file = files.find((element) => element.id === parseInt(id));
    this.setState({
      id: file.id,
      fileUrlWithToken: fileURL,
      fileUrl: file.url,
      fileName: file.label,
    });
  }

  handleClick(fileUploader) {
    this.refs[fileUploader].click();
  }

  render() {
    const { files, user, patientName, consultationId } = this.props;
    const {
      setIsOpen,
      setIsOpenDeleteModal,
      file,
      fileName,
      setIsOpenUploadFile,
      id,
      errorSizeFile,
      fileUrlWithToken,
    } = this.state;
    if (files && files.length === 0)
      return (
        <>
          <Empty_container
            user={user}
            permission="ADD_DOCUMENT"
            title="Pas de documents !"
            body="Dès qu'une consultation est créée, vous pourrez y ajouter des documents."
            btn_name="Ajouter un document"
            onClick={this.openModalUploadFile}
            url=""
          />
          <Modals
            setIsOpen={setIsOpenUploadFile}
            openModal={this.openModalUploadFile}
            data={
              <>
                <div
                  className="modal-header"
                  style={{
                    borderBottom: '1px solid #dee2e6',
                    padding: '1.5rem 0rem',
                    margin: '0px 20px',
                    alignItems: 'baseline',
                    marginLeft: '3%',
                    marginTop: '-30px',
                    width: '100%',
                  }}
                >
                  <span>Téléverser un document</span>
                  <button
                    type="button"
                    className="close"
                    data-dismiss="modal"
                    aria-label="Close"
                    onClick={() => this.openModalUploadFile()}
                  >
                    <span
                      aria-hidden="true"
                      style={{ marginRight: '30px', color: '#1A1C1D' }}
                    >
                      &times;
                    </span>
                  </button>
                </div>

                <hr style={{ width: '95%' }} />
                <div
                  className="modal-body"
                  style={{
                    width: file ? '520px' : '400px',
                    height: file ? '400px' : '200px',
                  }}
                >
                  {file ? (
                    file.type === 'application/pdf' ? (
                      <iframe
                        title="iframe"
                        src={URL.createObjectURL(file)}
                        style={{ height: 400, marginTop: '2%', width: '100%' }}
                      ></iframe>
                    ) : (
                      <img
                        style={{ height: '100%' }}
                        className="doc_review_img"
                        src={URL.createObjectURL(file)}
                        alt=""
                        width="100%"
                        height="auto"
                      />
                    )
                  ) : (
                    <>
                      <input
                        onChange={(e) => this.handleChangeFiles(e, 'file')}
                        type="file"
                        accept=".png, .jpg,.pdf,.jpeg"
                        ref="fileUploader"
                        name="file"
                        hidden
                      />
                      <button
                        onClick={() => this.handleClick('fileUploader')}
                        style={{ color: '#ffffff' }}
                        type="button"
                        className="upload_file"
                      >
                        <i className="fa fa-upload" aria-hidden="true"></i> Téléverser un
                        document
                      </button>
                    </>
                  )}
                  {errorSizeFile ? (
                    <div
                      style={{ height: '0', width: '73%' }}
                      className="drop_zone__line"
                    >
                      <span style={{ color: '#f83003' }}>
                        Le fichier est plus volumineux que la taille maximale autorisée
                        (10 Mo)
                      </span>
                    </div>
                  ) : (
                    ''
                  )}
                </div>
                <hr style={{ width: '95%' }} />
                <div
                  className="modal-footer create_prise_en_charge_1_footer"
                  style={{
                    borderBottom: '1px solid #dee2e6',
                    padding: '1.5rem 0rem',
                    margin: '0px 20px',
                  }}
                >
                  {file ? (
                    <>
                      <button
                        style={{ backgroundColor: '#f6f6f6' }}
                        type="button"
                        className="btn btn-partage"
                        data-dismiss="modal"
                        onClick={() => this.handleChangeFile()}
                      >
                        Annuler
                      </button>
                      <button
                        onClick={() =>
                          this.props.uploadFIleConsultation({
                            id: consultationId,
                            file: file,
                          })
                        }
                        style={{ color: '#2f2f2f' }}
                        type="button"
                        className="btn btn-imprimer-seance"
                      >
                        Enregistrer
                      </button>
                    </>
                  ) : (
                    ''
                  )}
                </div>
              </>
            }
          />
        </>
      );
    else
      return (
        <div className="add_bilan_container">
          <div className="add_document_container_header">
            <div className="left">
              <span>Documents</span>
            </div>
            {isHasPermission(user.login_access.permissions, 'ADD_DOCUMENT') ? (
              <div onClick={() => this.openModalUploadFile()} className="right">
                <svg
                  width="12"
                  height="11"
                  viewBox="0 0 12 11"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M11.2381 6.23828H6.73805V10.7383H5.26149V6.23828H0.76149V4.76172H5.26149V0.261719H6.73805V4.76172H11.2381V6.23828Z"
                    fill="#5446DB"
                  />
                </svg>

                <span>Ajouter Document</span>
              </div>
            ) : (
              ''
            )}
          </div>
          <div className="add_document_container_body">
            {files && files.length > 0
              ? files.map((file) => {
                  return (
                    <div key={file.id} className="add_document_content_preview">
                      <div className="add_document_content_preview_item">
                        <div
                          style={{ width: '80%' }}
                          className="bilan_preview_sub_item"
                          id="patient_consulation_timing"
                        >
                          <svg
                            width="38"
                            height="38"
                            viewBox="0 0 38 38"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <rect
                              x="0.5"
                              y="0.5"
                              width="37"
                              height="37"
                              rx="9.5"
                              fill="white"
                              stroke="#ECEEF5"
                            />
                            <path
                              d="M19.7383 16.75H23.8867L19.7383 12.6367V16.75ZM21.9883 20.5117V19H16.0117V20.5117H21.9883ZM21.9883 23.5V21.9883H16.0117V23.5H21.9883ZM20.5117 11.5117L25.0117 16.0117V25.0117C25.0117 25.4102 24.8594 25.7617 24.5547 26.0664C24.25 26.3477 23.8984 26.4883 23.5 26.4883H14.5C14.1016 26.4883 13.75 26.3477 13.4453 26.0664C13.1406 25.7617 12.9883 25.4102 12.9883 25.0117L13.0234 12.9883C13.0234 12.5898 13.1641 12.25 13.4453 11.9688C13.75 11.6641 14.1016 11.5117 14.5 11.5117H20.5117Z"
                              fill="#8083A3"
                            />
                          </svg>
                          <div className="prise_en_charge_text">
                            <span id="prise_en_charge_text_title">
                              {file.label.substring(0, 25)}...
                            </span>
                            <span id="prise_en_charge_text_desc">{patientName}</span>
                          </div>
                        </div>
                        {isHasPermission(
                          user.login_access.permissions,
                          'VIEW_DOCUMENTS',
                        ) ? (
                          <Tooltip title="Voir document">
                            <div
                              onClick={() => {
                                axios(`${process.env.REACT_APP_PUBLIC_URL}${file.url}`, {
                                  method: 'GET',
                                  headers: {
                                    Authorization: `JWT ${window.localStorage.getItem(
                                      'token',
                                    )}`,
                                  },
                                  responseType: 'blob', //Force to receive data in a Blob Format
                                })
                                  .then((response) => {
                                    let url;
                                    if (file.label.split('.')[1] === 'pdf') {
                                      const fileP = new Blob([response.data], {
                                        type: 'application/pdf',
                                      });
                                      url = URL.createObjectURL(fileP);
                                    } else {
                                      url = URL.createObjectURL(
                                        new Blob([response.data]),
                                      );
                                    }
                                    this.getItemDocument(file.id, url);
                                    this.openModal();
                                  })
                                  .catch((error) => {
                                    console.log(error);
                                  });
                              }}
                              style={{ marginRight: '1%' }}
                              className="more_icon_container"
                            >
                              <i
                                style={{
                                  padding: '0',
                                  border: '',
                                  color: '',
                                  borderRadius: '',
                                  backgroundColor: 'white',
                                }}
                                className="fas fa-eye"
                                aria-hidden="true"
                              ></i>
                            </div>
                          </Tooltip>
                        ) : (
                          ''
                        )}
                        {isHasPermission(
                          user.login_access.permissions,
                          'DELETE_DOCUMENT',
                        ) ? (
                          <Tooltip title="Supprimer document">
                            <div
                              onClick={() => {
                                this.getItemDocument(file.id);
                                this.openDeleteModal();
                              }}
                              className="more_icon_container"
                            >
                              <i
                                style={{
                                  padding: '0',
                                  border: '',
                                  color: '',
                                  borderRadius: '',
                                  backgroundColor: 'white',
                                }}
                                className="fas fa-trash"
                                aria-hidden="true"
                              ></i>
                            </div>
                          </Tooltip>
                        ) : (
                          ''
                        )}
                      </div>
                    </div>
                  );
                })
              : ''}
          </div>
          <Modals
            setIsOpen={setIsOpenDeleteModal}
            openModal={this.openDeleteModal}
            data={
              <>
                <div className="modal-header" style={{ marginTop: '-9px' }}>
                  <button
                    type="button"
                    className="close"
                    data-dismiss="modal"
                    aria-label="Close"
                    onClick={() => this.openDeleteModal()}
                  >
                    <span
                      aria-hidden="true"
                      style={{ marginRight: '13px', color: '#1A1C1D' }}
                    >
                      &times;
                    </span>
                  </button>
                </div>
                <div className="modal-body">
                  <h2>Suppression!</h2>
                  <span>Etes-vous sûr de vouloir supprimer ce document ?</span>
                </div>
                <div className="modal-footer">
                  <button
                    onClick={() => this.openDeleteModal()}
                    type="button"
                    className="modal_close_btn"
                    // data-dismiss="modal"
                    style={{ backgroundColor: '#f6f6f6' }}
                  >
                    Non
                  </button>
                  <button
                    onClick={() => {
                      this.props.deleteFile(id, consultationId);
                    }}
                    type="button"
                    className="modal_disconnect_btn"
                  >
                    Oui
                  </button>
                </div>
              </>
            }
          />
          <Modals
            setIsOpen={setIsOpen}
            openModal={this.openModal}
            data={
              <>
                <div
                  className="modal-header"
                  style={{
                    borderBottom: '1px solid #dee2e6',
                    padding: '1.5rem 0rem',
                    margin: '0px 20px',
                    alignItems: 'baseline',
                    marginLeft: '3%',
                    marginTop: '-30px',
                    width: '100%',
                  }}
                >
                  <span>{fileName}</span>
                  <button
                    type="button"
                    className="close"
                    data-dismiss="modal"
                    aria-label="Close"
                    onClick={() => this.openModal()}
                  >
                    <span
                      aria-hidden="true"
                      style={{ marginRight: '30px', color: '#1A1C1D' }}
                    >
                      &times;
                    </span>
                  </button>
                </div>
                <hr style={{ width: '85%' }} />
                <div
                  style={{
                    height: '470px',
                    width: '660px',
                  }}
                  ref={(el) => (this.componentRef = el)}
                  className="modal-body"
                >
                  {fileName.split('.')[1] === 'pdf' ? (
                    <iframe
                      title="iframe"
                      src={fileUrlWithToken}
                      style={{ height: 400, marginTop: '2%', width: '100%' }}
                    ></iframe>
                  ) : (
                    <img
                      style={{ height: '100%' }}
                      className="doc_review_img"
                      src={fileUrlWithToken}
                      alt=""
                      width="100%"
                      height="100%"
                    />
                  )}
                </div>
                <hr style={{ width: '95%' }} />
                <div
                  className="modal-footer create_prise_en_charge_1_footer"
                  style={{
                    borderBottom: '1px solid #dee2e6',
                    padding: '1.5rem 0rem',
                    margin: '0px 20px',
                  }}
                >
                  <button
                    style={{ backgroundColor: '#f6f6f6' }}
                    type="button"
                    className="btn btn-partage"
                    data-dismiss="modal"
                    onClick={() => this.openModal()}
                  >
                    Annuler
                  </button>
                  {fileName.split('.')[1] === 'pdf' ? (
                    ''
                  ) : (
                    <ReactToPrint content={() => this.componentRef}>
                      <PrintContextConsumer>
                        {({ handlePrint }) => (
                          <button
                            style={{ color: '#ffffff' }}
                            type="button"
                            className="btn btn-imprimer-seance"
                            onClick={handlePrint}
                          >
                            Imprimer
                          </button>
                        )}
                      </PrintContextConsumer>
                    </ReactToPrint>
                  )}
                </div>
              </>
            }
          />
          <Modals
            setIsOpen={setIsOpenUploadFile}
            openModal={this.openModalUploadFile}
            data={
              <>
                <div
                  className="modal-header"
                  style={{
                    borderBottom: '1px solid #dee2e6',
                    padding: '1.5rem 0rem',
                    margin: '0px 20px',
                    alignItems: 'baseline',
                    marginLeft: '3%',
                    marginTop: '-30px',
                    width: '100%',
                  }}
                >
                  <span>Téléverser un document</span>
                  <button
                    type="button"
                    className="close"
                    data-dismiss="modal"
                    aria-label="Close"
                    onClick={() => this.openModalUploadFile()}
                  >
                    <span
                      aria-hidden="true"
                      style={{ marginRight: '30px', color: '#1A1C1D' }}
                    >
                      &times;
                    </span>
                  </button>
                </div>
                <hr style={{ width: '95%' }} />
                <div
                  className="modal-body"
                  style={{
                    width: file ? '520px' : '400px',
                    height: file ? '400px' : '200px',
                  }}
                >
                  {file ? (
                    file.type === 'application/pdf' ? (
                      <iframe
                        title="iframe"
                        src={URL.createObjectURL(file)}
                        style={{ height: 400, marginTop: '2%', width: '100%' }}
                      ></iframe>
                    ) : (
                      <img
                        style={{ height: '100%' }}
                        className="doc_review_img"
                        src={URL.createObjectURL(file)}
                        alt=""
                        width="100%"
                        height="100%"
                      />
                    )
                  ) : (
                    <>
                      <input
                        onChange={(e) => this.handleChangeFiles(e, 'file')}
                        type="file"
                        ref="fileUploader"
                        accept=".png, .jpg,.pdf,.jpeg,.docx"
                        name="file"
                        hidden
                      />
                      <button
                        onClick={() => this.handleClick('fileUploader')}
                        style={{ color: '#ffffff' }}
                        type="button"
                        className="upload_file"
                      >
                        <i className="fa fa-upload" aria-hidden="true"></i> Téléverser un
                        document
                      </button>
                      {errorSizeFile ? (
                        <div
                          style={{ height: '0', width: '73%' }}
                          className="drop_zone__line"
                        >
                          <span style={{ color: '#f83003' }}>
                            Le fichier est plus volumineux que la taille maximale
                            autorisée
                          </span>
                        </div>
                      ) : (
                        ''
                      )}
                    </>
                  )}
                </div>
                <hr style={{ width: '95%' }} />
                <div
                  className="modal-footer create_prise_en_charge_1_footer"
                  style={{
                    borderBottom: '1px solid #dee2e6',
                    padding: '1.5rem 0rem',
                    margin: '0px 20px',
                  }}
                >
                  {file ? (
                    <>
                      <button
                        style={{ backgroundColor: '#f6f6f6' }}
                        type="button"
                        className="btn btn-partage"
                        data-dismiss="modal"
                        onClick={() => this.handleChangeFile()}
                      >
                        Annuler
                      </button>
                      <button
                        onClick={() =>
                          this.props.uploadFIleConsultation({
                            id: consultationId,
                            file: file,
                          })
                        }
                        style={{ color: '#ffffff' }}
                        type="button"
                        className="btn btn-imprimer-seance"
                      >
                        Enregistrer
                      </button>
                    </>
                  ) : (
                    ''
                  )}
                </div>
              </>
            }
          />
        </div>
      );
  }
}

export default Document;
//deleteFile
