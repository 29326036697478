import React, { useState, useEffect, Fragment } from 'react';
import {
  Page,
  Text,
  View,
  Document,
  StyleSheet,
  PDFViewer,
  Image,
} from '@react-pdf/renderer';
import moment from 'moment';
import IconKine from '../../assets/img/logo-kine.png';
import { formatDate } from '../../utils/helpres';
import { FORMAT_DATE_SERVER } from '../../utils/constants';
// Create styles
const newstyles = StyleSheet.create({
  page: {
    display: 'inline-grid',
    flexDirection: 'row',
    marginTop: 10,
  },
  PDFViewer: {
    width: '100%',
    height: '550px',
  },
  section: {
    marginLeft: 10,
    padding: 10,
  },
  sectionNameDoctor: {
    marginLeft: 10,
    marginRight: 90,
    padding: 10,
  },
  section2: {
    marginLeft: 10,
    padding: 10,
  },
  hr: {
    BorderTop: '1px bold red',
  },
  cabinet: {
    display: 'block',
    alignItems: 'center',
    justifyContent: 'center',
    marginBottom: 20,
  },
  sectionBill: {
    margin: 10,
    padding: 10,
    flexDirection: 'row',
  },
  detailBill: {
    margin: 10,
    padding: 10,
    flexDirection: 'row',
  },
  detailBillText: {
    color: '#070707',
    fontWeight: '1000',
    fontSize: 12,
    fontStyle: 'gras',
    //fontFamily: 'Georgia, serif',
    margin: 2,
  },
  detailBillText2: {
    color: '#070707',
    fontWeight: 'bolder',
    marginLeft: 10,
    padding: 10,
  },
  text: {
    color: '#5446db',
    fontWeight: 'bolder',
  },
  bill: {
    color: '#5446db',
    fontWeight: '500',
    marginLeft: '1%',
  },
  text1: {
    color: '#49494c',
    fontSize: 12,
    fontWeight: 'bolder',
    display: 'block',
    margin: 2,
    width: '150px',
  },
  images: {
    marginTop: 150,
    marginLeft: 20,
    // padding: 10,
    width: 50,
    height: 20,
  },
  sectionLogo: {
    padding: 10,
    justifyContent: 'center',
  },
});
function RDVPDF(props) {
  document.title = 'Rendez-vous';
  const rdv = props.location.state.rdv;
  const date = props.location.state.date;
  const user = props.location.state.office;
  const [i, setI] = useState(0);
  const [data, setData] = useState([]);
  const [data2, setData2] = useState([]);
  const [data1, setData1] = useState([]);
  const [data3, setData3] = useState([]);
  useEffect(() => {
    let i = 0;
    let rdvByDay = [];
    rdvByDay = rdv.filter((item) =>
      moment(item.start, FORMAT_DATE_SERVER).isSame(moment(date, FORMAT_DATE_SERVER)),
    );
    for (let i = 0; i < 10; i++) {
      rdvByDay.push({
        display_name: '',
        start: '',
        end: '',
      });
    }
    rdvByDay &&
      rdvByDay.length > 0 &&
      rdvByDay.map((rdv) => {
        setI(i + 1);
        i++;
        if (i <= 70)
          if (i <= 23)
            data.push({
              num: i,
              name: rdv.display_name ? rdv.display_name : '',
              seance: '',
              hour_start: rdv.start ? moment(rdv.start).format('HH:mm') : '',
              hour_end: rdv.end ? moment(rdv.end).format('HH:mm') : '',
              p: '',
              pc: '',
              pc_np: '',
              g: '',
            });
          else if (i <= 51 && i > 23)
            data1.push({
              num: i,
              name: rdv.display_name ? rdv.display_name : '',
              seance: '',
              hour_start: rdv.start ? moment(rdv.start).format('HH:mm') : '',
              hour_end: rdv.end ? moment(rdv.end).format('HH:mm') : '',
              p: '',
              pc: '',
              pc_np: '',
              g: '',
            });
          else if (i <= 70 && i > 51)
            data2.push({
              num: i,
              name: rdv.display_name ? rdv.display_name : '',
              seance: '',
              hour_start: rdv.start ? moment(rdv.start).format('HH:mm') : '',
              hour_end: rdv.end ? moment(rdv.end).format('HH:mm') : '',
              p: '',
              pc: '',
              pc_np: '',
              g: '',
            });
          else
            data3.push({
              num: i,
              name: rdv.display_name ? rdv.display_name : '',
              seance: '',
              hour_start: rdv.start ? moment(rdv.start).format('HH:mm') : '',
              hour_end: rdv.end ? moment(rdv.end).format('HH:mm') : '',
              p: '',
              pc: '',
              pc_np: '',
              g: '',
            });
      });
    setData(data);
    setData2(data2);
    setData1(data1);
    setData3(data3);
  }, [data, data1, data2, data3]);
  return (
    <div>
      <div
        onClick={() =>
          props.history.push({
            pathname: '/agenda',
            //state: { consultation_id: consultation.id },
          })
        }
        className="bilan_details_header"
      >
        <div className="back_icon">
          <img src={require('../../assets/img/arrow1.png')} alt="" />
        </div>
        <span>Retour</span>
      </div>
      {data.length > 0 ? (
        <Fragment>
          <PDFViewer style={newstyles.PDFViewer}>
            <Document orientation="landscape">
              <Page>
                <View style={newstyles.cabinet}>
                  <View style={newstyles.sectionLogo}>
                    <Image
                      style={{
                        width: 60,
                        height: 60,
                      }}
                      src={IconKine}
                    />
                  </View>
                  <Text>Journé de {formatDate(date)}</Text>
                </View>
                <View
                  style={{
                    marginLeft: 30,
                    marginRight: 40,
                    marginTop: 5,
                    width: '100%',
                    flexDirection: 'row',
                  }}
                >
                  <View style={{ width: '100%' }}>
                    <View
                      style={{
                        width: 250,
                        // border: 1,
                        height: 28,
                        display: 'flex',
                        flexDirection: 'row',
                        // margin:5
                      }}
                    >
                      <View
                        style={{
                          width: 40,
                          border: 1,
                          height: 28,
                          alignItems: 'center',
                          justifyContent: 'center',
                          textAlign: 'left',
                        }}
                      >
                        <Text style={{ padding: 5, fontSize: 12 }}>N°</Text>
                      </View>
                      <View
                        style={{
                          width: 130,
                          border: 1,
                          height: 28,
                          alignItems: 'flex-start',
                          justifyContent: 'center',
                          textAlign: 'left',
                        }}
                      >
                        <Text style={{ padding: 5, fontSize: 12 }}>Patient</Text>
                      </View>
                      <View
                        style={{
                          width: 50,
                          border: 1,
                          height: 28,
                          alignItems: 'flex-start',
                          justifyContent: 'center',
                          textAlign: 'left',
                        }}
                      >
                        <Text style={{ padding: 5, fontSize: 12 }}>Séance</Text>
                      </View>
                      <View
                        style={{
                          width: 50,
                          border: 1,
                          height: 28,
                          alignItems: 'flex-start',
                          justifyContent: 'center',
                          textAlign: 'left',
                        }}
                      >
                        <Text style={{ padding: 5, fontSize: 12 }}>H E</Text>
                      </View>
                      <View
                        style={{
                          width: 50,
                          border: 1,
                          height: 28,
                          alignItems: 'flex-start',
                          justifyContent: 'center',
                          textAlign: 'left',
                        }}
                      >
                        <Text style={{ padding: 5, fontSize: 12 }}>H S</Text>
                      </View>
                      <View
                        style={{
                          width: 50,
                          border: 1,
                          height: 28,
                          alignItems: 'flex-start',
                          justifyContent: 'center',
                          textAlign: 'left',
                        }}
                      >
                        <Text style={{ padding: 5, fontSize: 12 }}>P</Text>
                      </View>
                      <View
                        style={{
                          width: 50,
                          border: 1,
                          height: 28,
                          alignItems: 'flex-start',
                          justifyContent: 'center',
                          textAlign: 'left',
                        }}
                      >
                        <Text style={{ padding: 5, fontSize: 12 }}>PC</Text>
                      </View>
                      <View
                        style={{
                          width: 50,
                          border: 1,
                          height: 28,
                          alignItems: 'flex-start',
                          justifyContent: 'center',
                          textAlign: 'left',
                        }}
                      >
                        <Text style={{ padding: 5, fontSize: 12 }}>PC np</Text>
                      </View>
                      <View
                        style={{
                          width: 50,
                          border: 1,
                          height: 28,
                          alignItems: 'flex-start',
                          justifyContent: 'center',
                          textAlign: 'left',
                        }}
                      >
                        <Text style={{ padding: 5, fontSize: 12 }}>G</Text>
                      </View>
                    </View>
                    {data.map((item) => {
                      return (
                        <View
                          style={{
                            width: 250,
                            // border: 1,
                            height: 28,
                            display: 'flex',
                            flexDirection: 'row',
                            // margin:5
                          }}
                        >
                          <View
                            style={{
                              width: 40,
                              border: 1,
                              height: 28,
                              alignItems: 'flex-start',
                              justifyContent: 'center',
                              textAlign: 'left',
                            }}
                          >
                            <Text style={{ padding: 5, fontSize: 12 }}>{item.num}</Text>
                          </View>
                          <View
                            style={{
                              width: 130,
                              border: 1,
                              height: 28,
                              alignItems: 'flex-start',
                              justifyContent: 'center',
                              textAlign: 'left',
                            }}
                          >
                            <Text style={{ padding: 5, fontSize: 12 }}>{item.name}</Text>
                          </View>
                          <View
                            style={{
                              width: 50,
                              border: 1,
                              height: 28,
                              alignItems: 'flex-start',
                              justifyContent: 'center',
                              textAlign: 'left',
                            }}
                          >
                            <Text style={{ padding: 5, fontSize: 12 }}>
                              {item.seance}
                            </Text>
                          </View>
                          <View
                            style={{
                              width: 50,
                              border: 1,
                              height: 28,
                              alignItems: 'flex-start',
                              justifyContent: 'center',
                              textAlign: 'left',
                            }}
                          >
                            <Text style={{ padding: 5, fontSize: 12 }}>
                              {item.hour_start}
                            </Text>
                          </View>
                          <View
                            style={{
                              width: 50,
                              border: 1,
                              height: 28,
                              alignItems: 'flex-start',
                              justifyContent: 'center',
                              textAlign: 'left',
                            }}
                          >
                            <Text style={{ padding: 5, fontSize: 12 }}>
                              {item.hour_end}
                            </Text>
                          </View>
                          <View
                            style={{
                              width: 50,
                              border: 1,
                              height: 28,
                              alignItems: 'flex-start',
                              justifyContent: 'center',
                              textAlign: 'left',
                            }}
                          >
                            <Text style={{ padding: 5, fontSize: 12 }}>{item.p}</Text>
                          </View>
                          <View
                            style={{
                              width: 50,
                              border: 1,
                              height: 28,
                              alignItems: 'flex-start',
                              justifyContent: 'center',
                              textAlign: 'left',
                            }}
                          >
                            <Text style={{ padding: 5, fontSize: 12 }}>{item.pc}</Text>
                          </View>
                          <View
                            style={{
                              width: 50,
                              border: 1,
                              height: 28,
                              alignItems: 'flex-start',
                              justifyContent: 'center',
                              textAlign: 'left',
                            }}
                          >
                            <Text style={{ padding: 5, fontSize: 12 }}>{item.pc_np}</Text>
                          </View>
                          <View
                            style={{
                              width: 50,
                              border: 1,
                              height: 28,
                              alignItems: 'flex-start',
                              justifyContent: 'center',
                              textAlign: 'left',
                            }}
                          >
                            <Text style={{ padding: 5, fontSize: 12 }}>{item.g}</Text>
                          </View>
                        </View>
                      );
                    })}
                    {data1.length === 0 && data.length <= 14 ? (
                      <View
                        style={{
                          marginLeft: 30,
                          marginRight: 40,
                          marginTop: 3,
                          width: '100%',
                          flexDirection: 'row',
                        }}
                      >
                        <View style={{ width: '50%' }}>
                          <View
                            style={{
                              width: 150,
                              marginTop: 30,
                              marginLeft: 30,
                              // border: 1,
                              height: 28,
                              display: 'flex',
                              flexDirection: 'row',
                              // margin:5
                            }}
                          >
                            <View
                              style={{
                                width: 100,
                                border: 1,
                                height: 28,
                                alignItems: 'flex-start',
                                justifyContent: 'center',
                                textAlign: 'left',
                              }}
                            >
                              <Text style={{ padding: 5, fontSize: 12 }}>
                                Total séances
                              </Text>
                            </View>
                            <View
                              style={{
                                width: 100,
                                border: 1,
                                height: 28,
                                alignItems: 'flex-start',
                                justifyContent: 'center',
                                textAlign: 'left',
                              }}
                            >
                              <Text style={{ padding: 5, fontSize: 12 }}></Text>
                            </View>
                          </View>
                          <View
                            style={{
                              width: 150,
                              marginLeft: 30,
                              // border: 1,
                              height: 28,
                              display: 'flex',
                              flexDirection: 'row',
                              // margin:5
                            }}
                          >
                            <View
                              style={{
                                width: 100,
                                border: 1,
                                height: 28,
                                alignItems: 'flex-start',
                                justifyContent: 'center',
                                textAlign: 'left',
                              }}
                            >
                              <Text style={{ padding: 5, fontSize: 12 }}>
                                Total recette
                              </Text>
                            </View>
                            <View
                              style={{
                                width: 100,
                                border: 1,
                                height: 28,
                                alignItems: 'flex-start',
                                justifyContent: 'center',
                                textAlign: 'left',
                              }}
                            >
                              <Text style={{ padding: 5, fontSize: 12 }}></Text>
                            </View>
                          </View>
                          <View
                            style={{
                              width: 150,
                              marginLeft: 30,
                              // border: 1,
                              height: 28,
                              display: 'flex',
                              flexDirection: 'row',
                              // margin:5
                            }}
                          >
                            <View
                              style={{
                                width: 100,
                                border: 1,
                                height: 28,
                                alignItems: 'flex-start',
                                justifyContent: 'center',
                                textAlign: 'left',
                              }}
                            >
                              <Text style={{ padding: 5, fontSize: 12 }}>Dépense</Text>
                            </View>
                            <View
                              style={{
                                width: 100,
                                border: 1,
                                height: 28,
                                alignItems: 'flex-start',
                                justifyContent: 'center',
                                textAlign: 'left',
                              }}
                            >
                              <Text style={{ padding: 5, fontSize: 12 }}></Text>
                            </View>
                          </View>
                          <View
                            style={{
                              width: 150,
                              marginLeft: 30,
                              // border: 1,
                              height: 28,
                              display: 'flex',
                              flexDirection: 'row',
                              // margin:5
                            }}
                          >
                            <View
                              style={{
                                width: 100,
                                border: 1,
                                height: 28,
                                alignItems: 'flex-start',
                                justifyContent: 'center',
                                textAlign: 'left',
                              }}
                            >
                              <Text style={{ padding: 5, fontSize: 12 }}>Total</Text>
                            </View>
                            <View
                              style={{
                                width: 100,
                                border: 1,
                                height: 28,
                                alignItems: 'flex-start',
                                justifyContent: 'center',
                                textAlign: 'left',
                              }}
                            >
                              <Text style={{ padding: 5, fontSize: 12 }}></Text>
                            </View>
                          </View>
                        </View>
                        <View style={{ width: '50%' }}>
                          <View
                            style={{
                              width: 150,
                              marginTop: 30,
                              // border: 1,
                              height: 28,
                              display: 'flex',
                              flexDirection: 'row',
                              // margin:5
                            }}
                          >
                            <View
                              style={{
                                width: 100,
                                border: 1,
                                height: 28,
                                alignItems: 'flex-start',
                                justifyContent: 'center',
                                textAlign: 'left',
                              }}
                            >
                              <Text style={{ padding: 5, fontSize: 12 }}>p=</Text>
                            </View>
                            <View
                              style={{
                                width: 100,
                                border: 1,
                                height: 28,
                                alignItems: 'flex-start',
                                justifyContent: 'center',
                                textAlign: 'left',
                              }}
                            >
                              <Text style={{ padding: 5, fontSize: 12 }}></Text>
                            </View>
                          </View>
                          <View
                            style={{
                              width: 150,
                              // border: 1,
                              height: 28,
                              display: 'flex',
                              flexDirection: 'row',
                              // margin:5
                            }}
                          >
                            <View
                              style={{
                                width: 100,
                                border: 1,
                                height: 28,
                                alignItems: 'flex-start',
                                justifyContent: 'center',
                                textAlign: 'left',
                              }}
                            >
                              <Text style={{ padding: 5, fontSize: 12 }}>np=</Text>
                            </View>
                            <View
                              style={{
                                width: 100,
                                border: 1,
                                height: 28,
                                alignItems: 'flex-start',
                                justifyContent: 'center',
                                textAlign: 'left',
                              }}
                            >
                              <Text style={{ padding: 5, fontSize: 12 }}></Text>
                            </View>
                          </View>
                          <View
                            style={{
                              width: 150,
                              // border: 1,
                              height: 28,
                              display: 'flex',
                              flexDirection: 'row',
                              // margin:5
                            }}
                          >
                            <View
                              style={{
                                width: 100,
                                border: 1,
                                height: 28,
                                alignItems: 'flex-start',
                                justifyContent: 'center',
                                textAlign: 'left',
                              }}
                            >
                              <Text style={{ padding: 5, fontSize: 12 }}>acompte=</Text>
                            </View>
                            <View
                              style={{
                                width: 100,
                                border: 1,
                                height: 28,
                                alignItems: 'flex-start',
                                justifyContent: 'center',
                                textAlign: 'left',
                              }}
                            >
                              <Text style={{ padding: 5, fontSize: 12 }}></Text>
                            </View>
                          </View>
                          <View
                            style={{
                              width: 150,
                              // border: 1,
                              height: 28,
                              display: 'flex',
                              flexDirection: 'row',
                              // margin:5
                            }}
                          >
                            <View
                              style={{
                                width: 100,
                                border: 1,
                                height: 28,
                                alignItems: 'flex-start',
                                justifyContent: 'center',
                                textAlign: 'left',
                              }}
                            >
                              <Text style={{ padding: 5, fontSize: 12 }}>avoir=</Text>
                            </View>
                            <View
                              style={{
                                width: 100,
                                border: 1,
                                height: 28,
                                alignItems: 'flex-start',
                                justifyContent: 'center',
                                textAlign: 'left',
                              }}
                            >
                              <Text style={{ padding: 5, fontSize: 12 }}></Text>
                            </View>
                          </View>
                          <View
                            style={{
                              width: 150,
                              // border: 1,
                              height: 28,
                              display: 'flex',
                              flexDirection: 'row',
                              // margin:5
                            }}
                          >
                            <View
                              style={{
                                width: 100,
                                border: 1,
                                height: 28,
                                alignItems: 'flex-start',
                                justifyContent: 'center',
                                textAlign: 'left',
                              }}
                            >
                              <Text style={{ padding: 5, fontSize: 12 }}>an=</Text>
                            </View>
                            <View
                              style={{
                                width: 100,
                                border: 1,
                                height: 28,
                                alignItems: 'flex-start',
                                justifyContent: 'center',
                                textAlign: 'left',
                              }}
                            >
                              <Text style={{ padding: 5, fontSize: 12 }}></Text>
                            </View>
                          </View>
                          <View
                            style={{
                              width: 150,
                              // border: 1,
                              height: 28,
                              display: 'flex',
                              flexDirection: 'row',
                              // margin:5
                            }}
                          >
                            <View
                              style={{
                                width: 100,
                                border: 1,
                                height: 28,
                                alignItems: 'flex-start',
                                justifyContent: 'center',
                                textAlign: 'left',
                              }}
                            >
                              <Text style={{ padding: 5, fontSize: 12 }}>sup=</Text>
                            </View>
                            <View
                              style={{
                                width: 100,
                                border: 1,
                                height: 28,
                                alignItems: 'flex-start',
                                justifyContent: 'center',
                                textAlign: 'left',
                              }}
                            >
                              <Text style={{ padding: 5, fontSize: 12 }}></Text>
                            </View>
                          </View>
                          <View
                            style={{
                              width: 150,
                              // border: 1,
                              height: 28,
                              display: 'flex',
                              flexDirection: 'row',
                              // margin:5
                            }}
                          >
                            <View
                              style={{
                                width: 100,
                                border: 1,
                                height: 28,
                                alignItems: 'flex-start',
                                justifyContent: 'center',
                                textAlign: 'left',
                              }}
                            >
                              <Text style={{ padding: 5, fontSize: 12 }}>ab=</Text>
                            </View>
                            <View
                              style={{
                                width: 100,
                                border: 1,
                                height: 28,
                                alignItems: 'flex-start',
                                justifyContent: 'center',
                                textAlign: 'left',
                              }}
                            >
                              <Text style={{ padding: 5, fontSize: 12 }}></Text>
                            </View>
                          </View>
                        </View>
                      </View>
                    ) : undefined}
                  </View>
                </View>
                <View
                  style={{
                    height: '40px',
                    position: 'absolute',
                    left: 0,
                    bottom: 0,
                    width: '100%',
                    color: 'white',
                    textAlign: 'center',
                  }}
                >
                  <View
                    style={{
                      borderBottomColor: 'black',
                      marginLeft: 50,
                      marginRight: 60,
                      //marginTop: 350,
                      borderBottomWidth: 1,
                    }}
                  />
                  <View>
                    <Text
                      style={{
                        top: 10,
                        color: '#070707',
                        fontWeight: '1000',
                        fontSize: 10,
                        fontStyle: 'gras',
                        //fontFamily: 'Georgia, serif',
                        margin: 3,
                      }}
                    >
                      {user && user.address_practitioner
                        ? `${user.address_practitioner.street} ${user.address_practitioner.city} ${user.address_practitioner.zip_code}`
                        : '-'}{' '}
                      {user && user.phone ? `Tél/Fax: +216 ${user.phone} ` : '-'}
                      {user && user.gsm ? `GSM: +216 ${user.gsm} ` : '-'}{' '}
                      {user && user.address_office
                        ? `Adresse: ${user.address_office.street}, ${user.address_office.zip_code} ${user.address_office.city} ${user.address_office.country}`
                        : '-'}
                    </Text>
                  </View>
                </View>
              </Page>
              {data1.length === 0 && data.length > 14 && data.length <= 23 ? (
                <Page>
                  <View
                    style={{
                      marginLeft: 30,
                      marginRight: 40,
                      marginTop: 3,
                      width: '100%',
                      flexDirection: 'row',
                    }}
                  >
                    <View style={{ width: '50%' }}>
                      <View
                        style={{
                          width: 150,
                          marginTop: 30,
                          marginLeft: 30,
                          // border: 1,
                          height: 28,
                          display: 'flex',
                          flexDirection: 'row',
                          // margin:5
                        }}
                      >
                        <View
                          style={{
                            width: 100,
                            border: 1,
                            height: 28,
                            alignItems: 'flex-start',
                            justifyContent: 'center',
                            textAlign: 'left',
                          }}
                        >
                          <Text style={{ padding: 5, fontSize: 12 }}>Total séances</Text>
                        </View>
                        <View
                          style={{
                            width: 100,
                            border: 1,
                            height: 28,
                            alignItems: 'flex-start',
                            justifyContent: 'center',
                            textAlign: 'left',
                          }}
                        >
                          <Text style={{ padding: 5, fontSize: 12 }}></Text>
                        </View>
                      </View>
                      <View
                        style={{
                          width: 150,
                          marginLeft: 30,
                          // border: 1,
                          height: 28,
                          display: 'flex',
                          flexDirection: 'row',
                          // margin:5
                        }}
                      >
                        <View
                          style={{
                            width: 100,
                            border: 1,
                            height: 28,
                            alignItems: 'flex-start',
                            justifyContent: 'center',
                            textAlign: 'left',
                          }}
                        >
                          <Text style={{ padding: 5, fontSize: 12 }}>Total recette</Text>
                        </View>
                        <View
                          style={{
                            width: 100,
                            border: 1,
                            height: 28,
                            alignItems: 'flex-start',
                            justifyContent: 'center',
                            textAlign: 'left',
                          }}
                        >
                          <Text style={{ padding: 5, fontSize: 12 }}></Text>
                        </View>
                      </View>
                      <View
                        style={{
                          width: 150,
                          marginLeft: 30,
                          // border: 1,
                          height: 28,
                          display: 'flex',
                          flexDirection: 'row',
                          // margin:5
                        }}
                      >
                        <View
                          style={{
                            width: 100,
                            border: 1,
                            height: 28,
                            alignItems: 'flex-start',
                            justifyContent: 'center',
                            textAlign: 'left',
                          }}
                        >
                          <Text style={{ padding: 5, fontSize: 12 }}>Dépense</Text>
                        </View>
                        <View
                          style={{
                            width: 100,
                            border: 1,
                            height: 28,
                            alignItems: 'flex-start',
                            justifyContent: 'center',
                            textAlign: 'left',
                          }}
                        >
                          <Text style={{ padding: 5, fontSize: 12 }}></Text>
                        </View>
                      </View>
                      <View
                        style={{
                          width: 150,
                          marginLeft: 30,
                          // border: 1,
                          height: 28,
                          display: 'flex',
                          flexDirection: 'row',
                          // margin:5
                        }}
                      >
                        <View
                          style={{
                            width: 100,
                            border: 1,
                            height: 28,
                            alignItems: 'flex-start',
                            justifyContent: 'center',
                            textAlign: 'left',
                          }}
                        >
                          <Text style={{ padding: 5, fontSize: 12 }}>Total</Text>
                        </View>
                        <View
                          style={{
                            width: 100,
                            border: 1,
                            height: 28,
                            alignItems: 'flex-start',
                            justifyContent: 'center',
                            textAlign: 'left',
                          }}
                        >
                          <Text style={{ padding: 5, fontSize: 12 }}></Text>
                        </View>
                      </View>
                    </View>
                    <View style={{ width: '50%' }}>
                      <View
                        style={{
                          width: 150,
                          marginTop: 30,
                          // border: 1,
                          height: 28,
                          display: 'flex',
                          flexDirection: 'row',
                          // margin:5
                        }}
                      >
                        <View
                          style={{
                            width: 100,
                            border: 1,
                            height: 28,
                            alignItems: 'flex-start',
                            justifyContent: 'center',
                            textAlign: 'left',
                          }}
                        >
                          <Text style={{ padding: 5, fontSize: 12 }}>p=</Text>
                        </View>
                        <View
                          style={{
                            width: 100,
                            border: 1,
                            height: 28,
                            alignItems: 'flex-start',
                            justifyContent: 'center',
                            textAlign: 'left',
                          }}
                        >
                          <Text style={{ padding: 5, fontSize: 12 }}></Text>
                        </View>
                      </View>
                      <View
                        style={{
                          width: 150,
                          // border: 1,
                          height: 28,
                          display: 'flex',
                          flexDirection: 'row',
                          // margin:5
                        }}
                      >
                        <View
                          style={{
                            width: 100,
                            border: 1,
                            height: 28,
                            alignItems: 'flex-start',
                            justifyContent: 'center',
                            textAlign: 'left',
                          }}
                        >
                          <Text style={{ padding: 5, fontSize: 12 }}>np=</Text>
                        </View>
                        <View
                          style={{
                            width: 100,
                            border: 1,
                            height: 28,
                            alignItems: 'flex-start',
                            justifyContent: 'center',
                            textAlign: 'left',
                          }}
                        >
                          <Text style={{ padding: 5, fontSize: 12 }}></Text>
                        </View>
                      </View>
                      <View
                        style={{
                          width: 150,
                          // border: 1,
                          height: 28,
                          display: 'flex',
                          flexDirection: 'row',
                          // margin:5
                        }}
                      >
                        <View
                          style={{
                            width: 100,
                            border: 1,
                            height: 28,
                            alignItems: 'flex-start',
                            justifyContent: 'center',
                            textAlign: 'left',
                          }}
                        >
                          <Text style={{ padding: 5, fontSize: 12 }}>acompte=</Text>
                        </View>
                        <View
                          style={{
                            width: 100,
                            border: 1,
                            height: 28,
                            alignItems: 'flex-start',
                            justifyContent: 'center',
                            textAlign: 'left',
                          }}
                        >
                          <Text style={{ padding: 5, fontSize: 12 }}></Text>
                        </View>
                      </View>
                      <View
                        style={{
                          width: 150,
                          // border: 1,
                          height: 28,
                          display: 'flex',
                          flexDirection: 'row',
                          // margin:5
                        }}
                      >
                        <View
                          style={{
                            width: 100,
                            border: 1,
                            height: 28,
                            alignItems: 'flex-start',
                            justifyContent: 'center',
                            textAlign: 'left',
                          }}
                        >
                          <Text style={{ padding: 5, fontSize: 12 }}>avoir=</Text>
                        </View>
                        <View
                          style={{
                            width: 100,
                            border: 1,
                            height: 28,
                            alignItems: 'flex-start',
                            justifyContent: 'center',
                            textAlign: 'left',
                          }}
                        >
                          <Text style={{ padding: 5, fontSize: 12 }}></Text>
                        </View>
                      </View>
                      <View
                        style={{
                          width: 150,
                          // border: 1,
                          height: 28,
                          display: 'flex',
                          flexDirection: 'row',
                          // margin:5
                        }}
                      >
                        <View
                          style={{
                            width: 100,
                            border: 1,
                            height: 28,
                            alignItems: 'flex-start',
                            justifyContent: 'center',
                            textAlign: 'left',
                          }}
                        >
                          <Text style={{ padding: 5, fontSize: 12 }}>an=</Text>
                        </View>
                        <View
                          style={{
                            width: 100,
                            border: 1,
                            height: 28,
                            alignItems: 'flex-start',
                            justifyContent: 'center',
                            textAlign: 'left',
                          }}
                        >
                          <Text style={{ padding: 5, fontSize: 12 }}></Text>
                        </View>
                      </View>
                      <View
                        style={{
                          width: 150,
                          // border: 1,
                          height: 28,
                          display: 'flex',
                          flexDirection: 'row',
                          // margin:5
                        }}
                      >
                        <View
                          style={{
                            width: 100,
                            border: 1,
                            height: 28,
                            alignItems: 'flex-start',
                            justifyContent: 'center',
                            textAlign: 'left',
                          }}
                        >
                          <Text style={{ padding: 5, fontSize: 12 }}>sup=</Text>
                        </View>
                        <View
                          style={{
                            width: 100,
                            border: 1,
                            height: 28,
                            alignItems: 'flex-start',
                            justifyContent: 'center',
                            textAlign: 'left',
                          }}
                        >
                          <Text style={{ padding: 5, fontSize: 12 }}></Text>
                        </View>
                      </View>
                      <View
                        style={{
                          width: 150,
                          // border: 1,
                          height: 28,
                          display: 'flex',
                          flexDirection: 'row',
                          // margin:5
                        }}
                      >
                        <View
                          style={{
                            width: 100,
                            border: 1,
                            height: 28,
                            alignItems: 'flex-start',
                            justifyContent: 'center',
                            textAlign: 'left',
                          }}
                        >
                          <Text style={{ padding: 5, fontSize: 12 }}>ab=</Text>
                        </View>
                        <View
                          style={{
                            width: 100,
                            border: 1,
                            height: 28,
                            alignItems: 'flex-start',
                            justifyContent: 'center',
                            textAlign: 'left',
                          }}
                        >
                          <Text style={{ padding: 5, fontSize: 12 }}></Text>
                        </View>
                      </View>
                    </View>
                  </View>
                  <View
                    style={{
                      height: '40px',
                      position: 'absolute',
                      left: 0,
                      bottom: 0,
                      width: '100%',
                      color: 'white',
                      textAlign: 'center',
                    }}
                  >
                    <View
                      style={{
                        borderBottomColor: 'black',
                        marginLeft: 50,
                        marginRight: 60,
                        //marginTop: 350,
                        borderBottomWidth: 1,
                      }}
                    />
                    <View>
                      <Text
                        style={{
                          top: 10,
                          color: '#070707',
                          fontWeight: '1000',
                          fontSize: 10,
                          fontStyle: 'gras',
                          //fontFamily: 'Georgia, serif',
                          margin: 3,
                        }}
                      >
                        {user && user.address_practitioner
                          ? `${user.address_practitioner.street} ${user.address_practitioner.city} ${user.address_practitioner.zip_code}`
                          : '-'}{' '}
                        {user && user.phone ? `Tél/Fax: +216 ${user.phone} ` : '-'}
                        {user && user.gsm ? `GSM: +216 ${user.gsm} ` : '-'}{' '}
                        {user && user.address_office
                          ? `Adresse: ${user.address_office.street}, ${user.address_office.zip_code} ${user.address_office.city} ${user.address_office.country}`
                          : '-'}
                      </Text>
                    </View>
                  </View>
                </Page>
              ) : undefined}
              {data1 && data1.length > 0 ? (
                <Page>
                  <View
                    style={{
                      marginLeft: 30,
                      marginRight: 40,
                      marginTop: 5,
                      width: '100%',
                      flexDirection: 'row',
                    }}
                  >
                    <View style={{ width: '100%' }}>
                      {data1.map((item) => {
                        return (
                          <View
                            style={{
                              width: 250,
                              // border: 1,
                              height: 28,
                              display: 'flex',
                              flexDirection: 'row',
                              // margin:5
                            }}
                          >
                            <View
                              style={{
                                width: 40,
                                border: 1,
                                height: 28,
                                alignItems: 'flex-start',
                                justifyContent: 'center',
                                textAlign: 'left',
                              }}
                            >
                              <Text style={{ padding: 5, fontSize: 12 }}>{item.num}</Text>
                            </View>
                            <View
                              style={{
                                width: 130,
                                border: 1,
                                height: 28,
                                alignItems: 'flex-start',
                                justifyContent: 'center',
                                textAlign: 'left',
                              }}
                            >
                              <Text style={{ padding: 5, fontSize: 12 }}>
                                {item.name}
                              </Text>
                            </View>
                            <View
                              style={{
                                width: 50,
                                border: 1,
                                height: 28,
                                alignItems: 'flex-start',
                                justifyContent: 'center',
                                textAlign: 'left',
                              }}
                            >
                              <Text style={{ padding: 5, fontSize: 12 }}>
                                {item.seance}
                              </Text>
                            </View>
                            <View
                              style={{
                                width: 50,
                                border: 1,
                                height: 28,
                                alignItems: 'flex-start',
                                justifyContent: 'center',
                                textAlign: 'left',
                              }}
                            >
                              <Text style={{ padding: 5, fontSize: 12 }}>
                                {item.hour_start}
                              </Text>
                            </View>
                            <View
                              style={{
                                width: 50,
                                border: 1,
                                height: 28,
                                alignItems: 'flex-start',
                                justifyContent: 'center',
                                textAlign: 'left',
                              }}
                            >
                              <Text style={{ padding: 5, fontSize: 12 }}>
                                {item.hour_end}
                              </Text>
                            </View>
                            <View
                              style={{
                                width: 50,
                                border: 1,
                                height: 28,
                                alignItems: 'flex-start',
                                justifyContent: 'center',
                                textAlign: 'left',
                              }}
                            >
                              <Text style={{ padding: 5, fontSize: 12 }}>{item.p}</Text>
                            </View>
                            <View
                              style={{
                                width: 50,
                                border: 1,
                                height: 28,
                                alignItems: 'flex-start',
                                justifyContent: 'center',
                                textAlign: 'left',
                              }}
                            >
                              <Text style={{ padding: 5, fontSize: 12 }}>{item.pc}</Text>
                            </View>
                            <View
                              style={{
                                width: 50,
                                border: 1,
                                height: 28,
                                alignItems: 'flex-start',
                                justifyContent: 'center',
                                textAlign: 'left',
                              }}
                            >
                              <Text style={{ padding: 5, fontSize: 12 }}>
                                {item.pc_np}
                              </Text>
                            </View>
                            <View
                              style={{
                                width: 50,
                                border: 1,
                                height: 28,
                                alignItems: 'flex-start',
                                justifyContent: 'center',
                                textAlign: 'left',
                              }}
                            >
                              <Text style={{ padding: 5, fontSize: 12 }}>{item.g}</Text>
                            </View>
                          </View>
                        );
                      })}
                      {data2.length === 0 && data1.length <= 14 ? (
                        <View
                          style={{
                            marginLeft: 30,
                            marginRight: 40,
                            marginTop: 3,
                            width: '100%',
                            flexDirection: 'row',
                          }}
                        >
                          <View style={{ width: '50%' }}>
                            <View
                              style={{
                                width: 150,
                                marginTop: 30,
                                marginLeft: 30,
                                // border: 1,
                                height: 28,
                                display: 'flex',
                                flexDirection: 'row',
                                // margin:5
                              }}
                            >
                              <View
                                style={{
                                  width: 100,
                                  border: 1,
                                  height: 28,
                                  alignItems: 'flex-start',
                                  justifyContent: 'center',
                                  textAlign: 'left',
                                }}
                              >
                                <Text style={{ padding: 5, fontSize: 12 }}>
                                  Total séances
                                </Text>
                              </View>
                              <View
                                style={{
                                  width: 100,
                                  border: 1,
                                  height: 28,
                                  alignItems: 'flex-start',
                                  justifyContent: 'center',
                                  textAlign: 'left',
                                }}
                              >
                                <Text style={{ padding: 5, fontSize: 12 }}></Text>
                              </View>
                            </View>
                            <View
                              style={{
                                width: 150,
                                marginLeft: 30,
                                // border: 1,
                                height: 28,
                                display: 'flex',
                                flexDirection: 'row',
                                // margin:5
                              }}
                            >
                              <View
                                style={{
                                  width: 100,
                                  border: 1,
                                  height: 28,
                                  alignItems: 'flex-start',
                                  justifyContent: 'center',
                                  textAlign: 'left',
                                }}
                              >
                                <Text style={{ padding: 5, fontSize: 12 }}>
                                  Total recette
                                </Text>
                              </View>
                              <View
                                style={{
                                  width: 100,
                                  border: 1,
                                  height: 28,
                                  alignItems: 'flex-start',
                                  justifyContent: 'center',
                                  textAlign: 'left',
                                }}
                              >
                                <Text style={{ padding: 5, fontSize: 12 }}></Text>
                              </View>
                            </View>
                            <View
                              style={{
                                width: 150,
                                marginLeft: 30,
                                // border: 1,
                                height: 28,
                                display: 'flex',
                                flexDirection: 'row',
                                // margin:5
                              }}
                            >
                              <View
                                style={{
                                  width: 100,
                                  border: 1,
                                  height: 28,
                                  alignItems: 'flex-start',
                                  justifyContent: 'center',
                                  textAlign: 'left',
                                }}
                              >
                                <Text style={{ padding: 5, fontSize: 12 }}>Dépense</Text>
                              </View>
                              <View
                                style={{
                                  width: 100,
                                  border: 1,
                                  height: 28,
                                  alignItems: 'flex-start',
                                  justifyContent: 'center',
                                  textAlign: 'left',
                                }}
                              >
                                <Text style={{ padding: 5, fontSize: 12 }}></Text>
                              </View>
                            </View>
                            <View
                              style={{
                                width: 150,
                                marginLeft: 30,
                                // border: 1,
                                height: 28,
                                display: 'flex',
                                flexDirection: 'row',
                                // margin:5
                              }}
                            >
                              <View
                                style={{
                                  width: 100,
                                  border: 1,
                                  height: 28,
                                  alignItems: 'flex-start',
                                  justifyContent: 'center',
                                  textAlign: 'left',
                                }}
                              >
                                <Text style={{ padding: 5, fontSize: 12 }}>Total</Text>
                              </View>
                              <View
                                style={{
                                  width: 100,
                                  border: 1,
                                  height: 28,
                                  alignItems: 'flex-start',
                                  justifyContent: 'center',
                                  textAlign: 'left',
                                }}
                              >
                                <Text style={{ padding: 5, fontSize: 12 }}></Text>
                              </View>
                            </View>
                          </View>
                          <View style={{ width: '50%' }}>
                            <View
                              style={{
                                width: 150,
                                marginTop: 30,
                                // border: 1,
                                height: 28,
                                display: 'flex',
                                flexDirection: 'row',
                                // margin:5
                              }}
                            >
                              <View
                                style={{
                                  width: 100,
                                  border: 1,
                                  height: 28,
                                  alignItems: 'flex-start',
                                  justifyContent: 'center',
                                  textAlign: 'left',
                                }}
                              >
                                <Text style={{ padding: 5, fontSize: 12 }}>p=</Text>
                              </View>
                              <View
                                style={{
                                  width: 100,
                                  border: 1,
                                  height: 28,
                                  alignItems: 'flex-start',
                                  justifyContent: 'center',
                                  textAlign: 'left',
                                }}
                              >
                                <Text style={{ padding: 5, fontSize: 12 }}></Text>
                              </View>
                            </View>
                            <View
                              style={{
                                width: 150,
                                // border: 1,
                                height: 28,
                                display: 'flex',
                                flexDirection: 'row',
                                // margin:5
                              }}
                            >
                              <View
                                style={{
                                  width: 100,
                                  border: 1,
                                  height: 28,
                                  alignItems: 'flex-start',
                                  justifyContent: 'center',
                                  textAlign: 'left',
                                }}
                              >
                                <Text style={{ padding: 5, fontSize: 12 }}>np=</Text>
                              </View>
                              <View
                                style={{
                                  width: 100,
                                  border: 1,
                                  height: 28,
                                  alignItems: 'flex-start',
                                  justifyContent: 'center',
                                  textAlign: 'left',
                                }}
                              >
                                <Text style={{ padding: 5, fontSize: 12 }}></Text>
                              </View>
                            </View>
                            <View
                              style={{
                                width: 150,
                                // border: 1,
                                height: 28,
                                display: 'flex',
                                flexDirection: 'row',
                                // margin:5
                              }}
                            >
                              <View
                                style={{
                                  width: 100,
                                  border: 1,
                                  height: 28,
                                  alignItems: 'flex-start',
                                  justifyContent: 'center',
                                  textAlign: 'left',
                                }}
                              >
                                <Text style={{ padding: 5, fontSize: 12 }}>acompte=</Text>
                              </View>
                              <View
                                style={{
                                  width: 100,
                                  border: 1,
                                  height: 28,
                                  alignItems: 'flex-start',
                                  justifyContent: 'center',
                                  textAlign: 'left',
                                }}
                              >
                                <Text style={{ padding: 5, fontSize: 12 }}></Text>
                              </View>
                            </View>
                            <View
                              style={{
                                width: 150,
                                // border: 1,
                                height: 28,
                                display: 'flex',
                                flexDirection: 'row',
                                // margin:5
                              }}
                            >
                              <View
                                style={{
                                  width: 100,
                                  border: 1,
                                  height: 28,
                                  alignItems: 'flex-start',
                                  justifyContent: 'center',
                                  textAlign: 'left',
                                }}
                              >
                                <Text style={{ padding: 5, fontSize: 12 }}>avoir=</Text>
                              </View>
                              <View
                                style={{
                                  width: 100,
                                  border: 1,
                                  height: 28,
                                  alignItems: 'flex-start',
                                  justifyContent: 'center',
                                  textAlign: 'left',
                                }}
                              >
                                <Text style={{ padding: 5, fontSize: 12 }}></Text>
                              </View>
                            </View>
                            <View
                              style={{
                                width: 150,
                                // border: 1,
                                height: 28,
                                display: 'flex',
                                flexDirection: 'row',
                                // margin:5
                              }}
                            >
                              <View
                                style={{
                                  width: 100,
                                  border: 1,
                                  height: 28,
                                  alignItems: 'flex-start',
                                  justifyContent: 'center',
                                  textAlign: 'left',
                                }}
                              >
                                <Text style={{ padding: 5, fontSize: 12 }}>an=</Text>
                              </View>
                              <View
                                style={{
                                  width: 100,
                                  border: 1,
                                  height: 28,
                                  alignItems: 'flex-start',
                                  justifyContent: 'center',
                                  textAlign: 'left',
                                }}
                              >
                                <Text style={{ padding: 5, fontSize: 12 }}></Text>
                              </View>
                            </View>
                            <View
                              style={{
                                width: 150,
                                // border: 1,
                                height: 28,
                                display: 'flex',
                                flexDirection: 'row',
                                // margin:5
                              }}
                            >
                              <View
                                style={{
                                  width: 100,
                                  border: 1,
                                  height: 28,
                                  alignItems: 'flex-start',
                                  justifyContent: 'center',
                                  textAlign: 'left',
                                }}
                              >
                                <Text style={{ padding: 5, fontSize: 12 }}>sup=</Text>
                              </View>
                              <View
                                style={{
                                  width: 100,
                                  border: 1,
                                  height: 28,
                                  alignItems: 'flex-start',
                                  justifyContent: 'center',
                                  textAlign: 'left',
                                }}
                              >
                                <Text style={{ padding: 5, fontSize: 12 }}></Text>
                              </View>
                            </View>
                            <View
                              style={{
                                width: 150,
                                // border: 1,
                                height: 28,
                                display: 'flex',
                                flexDirection: 'row',
                                // margin:5
                              }}
                            >
                              <View
                                style={{
                                  width: 100,
                                  border: 1,
                                  height: 28,
                                  alignItems: 'flex-start',
                                  justifyContent: 'center',
                                  textAlign: 'left',
                                }}
                              >
                                <Text style={{ padding: 5, fontSize: 12 }}>ab=</Text>
                              </View>
                              <View
                                style={{
                                  width: 100,
                                  border: 1,
                                  height: 28,
                                  alignItems: 'flex-start',
                                  justifyContent: 'center',
                                  textAlign: 'left',
                                }}
                              >
                                <Text style={{ padding: 5, fontSize: 12 }}></Text>
                              </View>
                            </View>
                          </View>
                        </View>
                      ) : undefined}
                    </View>
                  </View>
                  <View
                    style={{
                      height: '40px',
                      position: 'absolute',
                      left: 0,
                      bottom: 0,
                      width: '100%',
                      color: 'white',
                      textAlign: 'center',
                    }}
                  >
                    <View
                      style={{
                        borderBottomColor: 'black',
                        marginLeft: 50,
                        marginRight: 60,
                        //marginTop: 350,
                        borderBottomWidth: 1,
                      }}
                    />
                    <View>
                      <Text
                        style={{
                          top: 10,
                          color: '#070707',
                          fontWeight: '1000',
                          fontSize: 10,
                          fontStyle: 'gras',
                          //fontFamily: 'Georgia, serif',
                          margin: 3,
                        }}
                      >
                        {user && user.address_practitioner
                          ? `${user.address_practitioner.street} ${user.address_practitioner.city} ${user.address_practitioner.zip_code}`
                          : '-'}{' '}
                        {user && user.phone ? `Tél/Fax: +216 ${user.phone} ` : '-'}
                        {user && user.gsm ? `GSM: +216 ${user.gsm} ` : '-'}
                        {user && user.address_office
                          ? `Adresse: ${user.address_office.street}, ${user.address_office.zip_code} ${user.address_office.city} ${user.address_office.country}`
                          : '-'}
                      </Text>
                    </View>
                  </View>
                </Page>
              ) : undefined}
              {data2.length === 0 && data1.length > 14 && data1.length <= 23 ? (
                <Page>
                  <View
                    style={{
                      marginLeft: 30,
                      marginRight: 40,
                      marginTop: 3,
                      width: '100%',
                      flexDirection: 'row',
                    }}
                  >
                    <View style={{ width: '50%' }}>
                      <View
                        style={{
                          width: 150,
                          marginTop: 30,
                          marginLeft: 30,
                          // border: 1,
                          height: 28,
                          display: 'flex',
                          flexDirection: 'row',
                          // margin:5
                        }}
                      >
                        <View
                          style={{
                            width: 100,
                            border: 1,
                            height: 28,
                            alignItems: 'flex-start',
                            justifyContent: 'center',
                            textAlign: 'left',
                          }}
                        >
                          <Text style={{ padding: 5, fontSize: 12 }}>Total séances</Text>
                        </View>
                        <View
                          style={{
                            width: 100,
                            border: 1,
                            height: 28,
                            alignItems: 'flex-start',
                            justifyContent: 'center',
                            textAlign: 'left',
                          }}
                        >
                          <Text style={{ padding: 5, fontSize: 12 }}></Text>
                        </View>
                      </View>
                      <View
                        style={{
                          width: 150,
                          marginLeft: 30,
                          // border: 1,
                          height: 28,
                          display: 'flex',
                          flexDirection: 'row',
                          // margin:5
                        }}
                      >
                        <View
                          style={{
                            width: 100,
                            border: 1,
                            height: 28,
                            alignItems: 'flex-start',
                            justifyContent: 'center',
                            textAlign: 'left',
                          }}
                        >
                          <Text style={{ padding: 5, fontSize: 12 }}>Total recette</Text>
                        </View>
                        <View
                          style={{
                            width: 100,
                            border: 1,
                            height: 28,
                            alignItems: 'flex-start',
                            justifyContent: 'center',
                            textAlign: 'left',
                          }}
                        >
                          <Text style={{ padding: 5, fontSize: 12 }}></Text>
                        </View>
                      </View>
                      <View
                        style={{
                          width: 150,
                          marginLeft: 30,
                          // border: 1,
                          height: 28,
                          display: 'flex',
                          flexDirection: 'row',
                          // margin:5
                        }}
                      >
                        <View
                          style={{
                            width: 100,
                            border: 1,
                            height: 28,
                            alignItems: 'flex-start',
                            justifyContent: 'center',
                            textAlign: 'left',
                          }}
                        >
                          <Text style={{ padding: 5, fontSize: 12 }}>Dépense</Text>
                        </View>
                        <View
                          style={{
                            width: 100,
                            border: 1,
                            height: 28,
                            alignItems: 'flex-start',
                            justifyContent: 'center',
                            textAlign: 'left',
                          }}
                        >
                          <Text style={{ padding: 5, fontSize: 12 }}></Text>
                        </View>
                      </View>
                      <View
                        style={{
                          width: 150,
                          marginLeft: 30,
                          // border: 1,
                          height: 28,
                          display: 'flex',
                          flexDirection: 'row',
                          // margin:5
                        }}
                      >
                        <View
                          style={{
                            width: 100,
                            border: 1,
                            height: 28,
                            alignItems: 'flex-start',
                            justifyContent: 'center',
                            textAlign: 'left',
                          }}
                        >
                          <Text style={{ padding: 5, fontSize: 12 }}>Total</Text>
                        </View>
                        <View
                          style={{
                            width: 100,
                            border: 1,
                            height: 28,
                            alignItems: 'flex-start',
                            justifyContent: 'center',
                            textAlign: 'left',
                          }}
                        >
                          <Text style={{ padding: 5, fontSize: 12 }}></Text>
                        </View>
                      </View>
                    </View>
                    <View style={{ width: '50%' }}>
                      <View
                        style={{
                          width: 150,
                          marginTop: 30,
                          // border: 1,
                          height: 28,
                          display: 'flex',
                          flexDirection: 'row',
                          // margin:5
                        }}
                      >
                        <View
                          style={{
                            width: 100,
                            border: 1,
                            height: 28,
                            alignItems: 'flex-start',
                            justifyContent: 'center',
                            textAlign: 'left',
                          }}
                        >
                          <Text style={{ padding: 5, fontSize: 12 }}>p=</Text>
                        </View>
                        <View
                          style={{
                            width: 100,
                            border: 1,
                            height: 28,
                            alignItems: 'flex-start',
                            justifyContent: 'center',
                            textAlign: 'left',
                          }}
                        >
                          <Text style={{ padding: 5, fontSize: 12 }}></Text>
                        </View>
                      </View>
                      <View
                        style={{
                          width: 150,
                          // border: 1,
                          height: 28,
                          display: 'flex',
                          flexDirection: 'row',
                          // margin:5
                        }}
                      >
                        <View
                          style={{
                            width: 100,
                            border: 1,
                            height: 28,
                            alignItems: 'flex-start',
                            justifyContent: 'center',
                            textAlign: 'left',
                          }}
                        >
                          <Text style={{ padding: 5, fontSize: 12 }}>np=</Text>
                        </View>
                        <View
                          style={{
                            width: 100,
                            border: 1,
                            height: 28,
                            alignItems: 'flex-start',
                            justifyContent: 'center',
                            textAlign: 'left',
                          }}
                        >
                          <Text style={{ padding: 5, fontSize: 12 }}></Text>
                        </View>
                      </View>
                      <View
                        style={{
                          width: 150,
                          // border: 1,
                          height: 28,
                          display: 'flex',
                          flexDirection: 'row',
                          // margin:5
                        }}
                      >
                        <View
                          style={{
                            width: 100,
                            border: 1,
                            height: 28,
                            alignItems: 'flex-start',
                            justifyContent: 'center',
                            textAlign: 'left',
                          }}
                        >
                          <Text style={{ padding: 5, fontSize: 12 }}>acompte=</Text>
                        </View>
                        <View
                          style={{
                            width: 100,
                            border: 1,
                            height: 28,
                            alignItems: 'flex-start',
                            justifyContent: 'center',
                            textAlign: 'left',
                          }}
                        >
                          <Text style={{ padding: 5, fontSize: 12 }}></Text>
                        </View>
                      </View>
                      <View
                        style={{
                          width: 150,
                          // border: 1,
                          height: 28,
                          display: 'flex',
                          flexDirection: 'row',
                          // margin:5
                        }}
                      >
                        <View
                          style={{
                            width: 100,
                            border: 1,
                            height: 28,
                            alignItems: 'flex-start',
                            justifyContent: 'center',
                            textAlign: 'left',
                          }}
                        >
                          <Text style={{ padding: 5, fontSize: 12 }}>avoir=</Text>
                        </View>
                        <View
                          style={{
                            width: 100,
                            border: 1,
                            height: 28,
                            alignItems: 'flex-start',
                            justifyContent: 'center',
                            textAlign: 'left',
                          }}
                        >
                          <Text style={{ padding: 5, fontSize: 12 }}></Text>
                        </View>
                      </View>
                      <View
                        style={{
                          width: 150,
                          // border: 1,
                          height: 28,
                          display: 'flex',
                          flexDirection: 'row',
                          // margin:5
                        }}
                      >
                        <View
                          style={{
                            width: 100,
                            border: 1,
                            height: 28,
                            alignItems: 'flex-start',
                            justifyContent: 'center',
                            textAlign: 'left',
                          }}
                        >
                          <Text style={{ padding: 5, fontSize: 12 }}>an=</Text>
                        </View>
                        <View
                          style={{
                            width: 100,
                            border: 1,
                            height: 28,
                            alignItems: 'flex-start',
                            justifyContent: 'center',
                            textAlign: 'left',
                          }}
                        >
                          <Text style={{ padding: 5, fontSize: 12 }}></Text>
                        </View>
                      </View>
                      <View
                        style={{
                          width: 150,
                          // border: 1,
                          height: 28,
                          display: 'flex',
                          flexDirection: 'row',
                          // margin:5
                        }}
                      >
                        <View
                          style={{
                            width: 100,
                            border: 1,
                            height: 28,
                            alignItems: 'flex-start',
                            justifyContent: 'center',
                            textAlign: 'left',
                          }}
                        >
                          <Text style={{ padding: 5, fontSize: 12 }}>sup=</Text>
                        </View>
                        <View
                          style={{
                            width: 100,
                            border: 1,
                            height: 28,
                            alignItems: 'flex-start',
                            justifyContent: 'center',
                            textAlign: 'left',
                          }}
                        >
                          <Text style={{ padding: 5, fontSize: 12 }}></Text>
                        </View>
                      </View>
                      <View
                        style={{
                          width: 150,
                          // border: 1,
                          height: 28,
                          display: 'flex',
                          flexDirection: 'row',
                          // margin:5
                        }}
                      >
                        <View
                          style={{
                            width: 100,
                            border: 1,
                            height: 28,
                            alignItems: 'flex-start',
                            justifyContent: 'center',
                            textAlign: 'left',
                          }}
                        >
                          <Text style={{ padding: 5, fontSize: 12 }}>ab=</Text>
                        </View>
                        <View
                          style={{
                            width: 100,
                            border: 1,
                            height: 28,
                            alignItems: 'flex-start',
                            justifyContent: 'center',
                            textAlign: 'left',
                          }}
                        >
                          <Text style={{ padding: 5, fontSize: 12 }}></Text>
                        </View>
                      </View>
                    </View>
                  </View>
                  <View
                    style={{
                      height: '40px',
                      position: 'absolute',
                      left: 0,
                      bottom: 0,
                      width: '100%',
                      color: 'white',
                      textAlign: 'center',
                    }}
                  >
                    <View
                      style={{
                        borderBottomColor: 'black',
                        marginLeft: 50,
                        marginRight: 60,
                        //marginTop: 350,
                        borderBottomWidth: 1,
                      }}
                    />
                    <View>
                      <Text
                        style={{
                          top: 10,
                          color: '#070707',
                          fontWeight: '1000',
                          fontSize: 10,
                          fontStyle: 'gras',
                          //fontFamily: 'Georgia, serif',
                          margin: 3,
                        }}
                      >
                        {user && user.address_practitioner
                          ? `${user.address_practitioner.street} ${user.address_practitioner.city} ${user.address_practitioner.zip_code}`
                          : '-'}{' '}
                        {user && user.phone ? `Tél/Fax: +216 ${user.phone} ` : '-'}
                        {user && user.gsm ? `GSM: +216 ${user.gsm} ` : '-'}{' '}
                        {user && user.address_office
                          ? `Adresse: ${user.address_office.street}, ${user.address_office.zip_code} ${user.address_office.city} ${user.address_office.country}`
                          : '-'}
                      </Text>
                    </View>
                  </View>
                </Page>
              ) : undefined}
              {data2 && data2.length > 0 ? (
                <Page>
                  <View
                    style={{
                      marginLeft: 30,
                      marginRight: 40,
                      marginTop: 5,
                      width: '100%',
                      flexDirection: 'row',
                    }}
                  >
                    <View style={{ width: '100%' }}>
                      {data2.map((item) => {
                        return (
                          <View
                            style={{
                              width: 250,
                              // border: 1,
                              height: 28,
                              display: 'flex',
                              flexDirection: 'row',
                              // margin:5
                            }}
                          >
                            <View
                              style={{
                                width: 40,
                                border: 1,
                                height: 28,
                                alignItems: 'flex-start',
                                justifyContent: 'center',
                                textAlign: 'left',
                              }}
                            >
                              <Text style={{ padding: 5, fontSize: 12 }}>{item.num}</Text>
                            </View>
                            <View
                              style={{
                                width: 130,
                                border: 1,
                                height: 28,
                                alignItems: 'flex-start',
                                justifyContent: 'center',
                                textAlign: 'left',
                              }}
                            >
                              <Text style={{ padding: 5, fontSize: 12 }}>
                                {item.name}
                              </Text>
                            </View>
                            <View
                              style={{
                                width: 50,
                                border: 1,
                                height: 28,
                                alignItems: 'flex-start',
                                justifyContent: 'center',
                                textAlign: 'left',
                              }}
                            >
                              <Text style={{ padding: 5, fontSize: 12 }}>
                                {item.seance}
                              </Text>
                            </View>
                            <View
                              style={{
                                width: 50,
                                border: 1,
                                height: 28,
                                alignItems: 'flex-start',
                                justifyContent: 'center',
                                textAlign: 'left',
                              }}
                            >
                              <Text style={{ padding: 5, fontSize: 12 }}>
                                {item.hour_start}
                              </Text>
                            </View>
                            <View
                              style={{
                                width: 50,
                                border: 1,
                                height: 28,
                                alignItems: 'flex-start',
                                justifyContent: 'center',
                                textAlign: 'left',
                              }}
                            >
                              <Text style={{ padding: 5, fontSize: 12 }}>
                                {item.hour_end}
                              </Text>
                            </View>
                            <View
                              style={{
                                width: 50,
                                border: 1,
                                height: 28,
                                alignItems: 'flex-start',
                                justifyContent: 'center',
                                textAlign: 'left',
                              }}
                            >
                              <Text style={{ padding: 5, fontSize: 12 }}>{item.p}</Text>
                            </View>
                            <View
                              style={{
                                width: 50,
                                border: 1,
                                height: 28,
                                alignItems: 'flex-start',
                                justifyContent: 'center',
                                textAlign: 'left',
                              }}
                            >
                              <Text style={{ padding: 5, fontSize: 12 }}>{item.pc}</Text>
                            </View>
                            <View
                              style={{
                                width: 50,
                                border: 1,
                                height: 28,
                                alignItems: 'flex-start',
                                justifyContent: 'center',
                                textAlign: 'left',
                              }}
                            >
                              <Text style={{ padding: 5, fontSize: 12 }}>
                                {item.pc_np}
                              </Text>
                            </View>
                            <View
                              style={{
                                width: 50,
                                border: 1,
                                height: 28,
                                alignItems: 'flex-start',
                                justifyContent: 'center',
                                textAlign: 'left',
                              }}
                            >
                              <Text style={{ padding: 5, fontSize: 12 }}>{item.g}</Text>
                            </View>
                          </View>
                        );
                      })}
                      {data3.length === 0 && data2.length <= 14 ? (
                        <View
                          style={{
                            marginLeft: 30,
                            marginRight: 40,
                            marginTop: 3,
                            width: '100%',
                            flexDirection: 'row',
                          }}
                        >
                          <View style={{ width: '50%' }}>
                            <View
                              style={{
                                width: 150,
                                marginTop: 30,
                                marginLeft: 30,
                                // border: 1,
                                height: 28,
                                display: 'flex',
                                flexDirection: 'row',
                                // margin:5
                              }}
                            >
                              <View
                                style={{
                                  width: 100,
                                  border: 1,
                                  height: 28,
                                  alignItems: 'flex-start',
                                  justifyContent: 'center',
                                  textAlign: 'left',
                                }}
                              >
                                <Text style={{ padding: 5, fontSize: 12 }}>
                                  Total séances
                                </Text>
                              </View>
                              <View
                                style={{
                                  width: 100,
                                  border: 1,
                                  height: 28,
                                  alignItems: 'flex-start',
                                  justifyContent: 'center',
                                  textAlign: 'left',
                                }}
                              >
                                <Text style={{ padding: 5, fontSize: 12 }}></Text>
                              </View>
                            </View>
                            <View
                              style={{
                                width: 150,
                                marginLeft: 30,
                                // border: 1,
                                height: 28,
                                display: 'flex',
                                flexDirection: 'row',
                                // margin:5
                              }}
                            >
                              <View
                                style={{
                                  width: 100,
                                  border: 1,
                                  height: 28,
                                  alignItems: 'flex-start',
                                  justifyContent: 'center',
                                  textAlign: 'left',
                                }}
                              >
                                <Text style={{ padding: 5, fontSize: 12 }}>
                                  Total recette
                                </Text>
                              </View>
                              <View
                                style={{
                                  width: 100,
                                  border: 1,
                                  height: 28,
                                  alignItems: 'flex-start',
                                  justifyContent: 'center',
                                  textAlign: 'left',
                                }}
                              >
                                <Text style={{ padding: 5, fontSize: 12 }}></Text>
                              </View>
                            </View>
                            <View
                              style={{
                                width: 150,
                                marginLeft: 30,
                                // border: 1,
                                height: 28,
                                display: 'flex',
                                flexDirection: 'row',
                                // margin:5
                              }}
                            >
                              <View
                                style={{
                                  width: 100,
                                  border: 1,
                                  height: 28,
                                  alignItems: 'flex-start',
                                  justifyContent: 'center',
                                  textAlign: 'left',
                                }}
                              >
                                <Text style={{ padding: 5, fontSize: 12 }}>Dépense</Text>
                              </View>
                              <View
                                style={{
                                  width: 100,
                                  border: 1,
                                  height: 28,
                                  alignItems: 'flex-start',
                                  justifyContent: 'center',
                                  textAlign: 'left',
                                }}
                              >
                                <Text style={{ padding: 5, fontSize: 12 }}></Text>
                              </View>
                            </View>
                            <View
                              style={{
                                width: 150,
                                marginLeft: 30,
                                // border: 1,
                                height: 28,
                                display: 'flex',
                                flexDirection: 'row',
                                // margin:5
                              }}
                            >
                              <View
                                style={{
                                  width: 100,
                                  border: 1,
                                  height: 28,
                                  alignItems: 'flex-start',
                                  justifyContent: 'center',
                                  textAlign: 'left',
                                }}
                              >
                                <Text style={{ padding: 5, fontSize: 12 }}>Total</Text>
                              </View>
                              <View
                                style={{
                                  width: 100,
                                  border: 1,
                                  height: 28,
                                  alignItems: 'flex-start',
                                  justifyContent: 'center',
                                  textAlign: 'left',
                                }}
                              >
                                <Text style={{ padding: 5, fontSize: 12 }}></Text>
                              </View>
                            </View>
                          </View>
                          <View style={{ width: '50%' }}>
                            <View
                              style={{
                                width: 150,
                                marginTop: 30,
                                // border: 1,
                                height: 28,
                                display: 'flex',
                                flexDirection: 'row',
                                // margin:5
                              }}
                            >
                              <View
                                style={{
                                  width: 100,
                                  border: 1,
                                  height: 28,
                                  alignItems: 'flex-start',
                                  justifyContent: 'center',
                                  textAlign: 'left',
                                }}
                              >
                                <Text style={{ padding: 5, fontSize: 12 }}>p=</Text>
                              </View>
                              <View
                                style={{
                                  width: 100,
                                  border: 1,
                                  height: 28,
                                  alignItems: 'flex-start',
                                  justifyContent: 'center',
                                  textAlign: 'left',
                                }}
                              >
                                <Text style={{ padding: 5, fontSize: 12 }}></Text>
                              </View>
                            </View>
                            <View
                              style={{
                                width: 150,
                                // border: 1,
                                height: 28,
                                display: 'flex',
                                flexDirection: 'row',
                                // margin:5
                              }}
                            >
                              <View
                                style={{
                                  width: 100,
                                  border: 1,
                                  height: 28,
                                  alignItems: 'flex-start',
                                  justifyContent: 'center',
                                  textAlign: 'left',
                                }}
                              >
                                <Text style={{ padding: 5, fontSize: 12 }}>np=</Text>
                              </View>
                              <View
                                style={{
                                  width: 100,
                                  border: 1,
                                  height: 28,
                                  alignItems: 'flex-start',
                                  justifyContent: 'center',
                                  textAlign: 'left',
                                }}
                              >
                                <Text style={{ padding: 5, fontSize: 12 }}></Text>
                              </View>
                            </View>
                            <View
                              style={{
                                width: 150,
                                // border: 1,
                                height: 28,
                                display: 'flex',
                                flexDirection: 'row',
                                // margin:5
                              }}
                            >
                              <View
                                style={{
                                  width: 100,
                                  border: 1,
                                  height: 28,
                                  alignItems: 'flex-start',
                                  justifyContent: 'center',
                                  textAlign: 'left',
                                }}
                              >
                                <Text style={{ padding: 5, fontSize: 12 }}>acompte=</Text>
                              </View>
                              <View
                                style={{
                                  width: 100,
                                  border: 1,
                                  height: 28,
                                  alignItems: 'flex-start',
                                  justifyContent: 'center',
                                  textAlign: 'left',
                                }}
                              >
                                <Text style={{ padding: 5, fontSize: 12 }}></Text>
                              </View>
                            </View>
                            <View
                              style={{
                                width: 150,
                                // border: 1,
                                height: 28,
                                display: 'flex',
                                flexDirection: 'row',
                                // margin:5
                              }}
                            >
                              <View
                                style={{
                                  width: 100,
                                  border: 1,
                                  height: 28,
                                  alignItems: 'flex-start',
                                  justifyContent: 'center',
                                  textAlign: 'left',
                                }}
                              >
                                <Text style={{ padding: 5, fontSize: 12 }}>avoir=</Text>
                              </View>
                              <View
                                style={{
                                  width: 100,
                                  border: 1,
                                  height: 28,
                                  alignItems: 'flex-start',
                                  justifyContent: 'center',
                                  textAlign: 'left',
                                }}
                              >
                                <Text style={{ padding: 5, fontSize: 12 }}></Text>
                              </View>
                            </View>
                            <View
                              style={{
                                width: 150,
                                // border: 1,
                                height: 28,
                                display: 'flex',
                                flexDirection: 'row',
                                // margin:5
                              }}
                            >
                              <View
                                style={{
                                  width: 100,
                                  border: 1,
                                  height: 28,
                                  alignItems: 'flex-start',
                                  justifyContent: 'center',
                                  textAlign: 'left',
                                }}
                              >
                                <Text style={{ padding: 5, fontSize: 12 }}>an=</Text>
                              </View>
                              <View
                                style={{
                                  width: 100,
                                  border: 1,
                                  height: 28,
                                  alignItems: 'flex-start',
                                  justifyContent: 'center',
                                  textAlign: 'left',
                                }}
                              >
                                <Text style={{ padding: 5, fontSize: 12 }}></Text>
                              </View>
                            </View>
                            <View
                              style={{
                                width: 150,
                                // border: 1,
                                height: 28,
                                display: 'flex',
                                flexDirection: 'row',
                                // margin:5
                              }}
                            >
                              <View
                                style={{
                                  width: 100,
                                  border: 1,
                                  height: 28,
                                  alignItems: 'flex-start',
                                  justifyContent: 'center',
                                  textAlign: 'left',
                                }}
                              >
                                <Text style={{ padding: 5, fontSize: 12 }}>sup=</Text>
                              </View>
                              <View
                                style={{
                                  width: 100,
                                  border: 1,
                                  height: 28,
                                  alignItems: 'flex-start',
                                  justifyContent: 'center',
                                  textAlign: 'left',
                                }}
                              >
                                <Text style={{ padding: 5, fontSize: 12 }}></Text>
                              </View>
                            </View>
                            <View
                              style={{
                                width: 150,
                                // border: 1,
                                height: 28,
                                display: 'flex',
                                flexDirection: 'row',
                                // margin:5
                              }}
                            >
                              <View
                                style={{
                                  width: 100,
                                  border: 1,
                                  height: 28,
                                  alignItems: 'flex-start',
                                  justifyContent: 'center',
                                  textAlign: 'left',
                                }}
                              >
                                <Text style={{ padding: 5, fontSize: 12 }}>ab=</Text>
                              </View>
                              <View
                                style={{
                                  width: 100,
                                  border: 1,
                                  height: 28,
                                  alignItems: 'flex-start',
                                  justifyContent: 'center',
                                  textAlign: 'left',
                                }}
                              >
                                <Text style={{ padding: 5, fontSize: 12 }}></Text>
                              </View>
                            </View>
                          </View>
                        </View>
                      ) : undefined}
                    </View>
                  </View>
                  <View
                    style={{
                      height: '40px',
                      position: 'absolute',
                      left: 0,
                      bottom: 0,
                      width: '100%',
                      color: 'white',
                      textAlign: 'center',
                    }}
                  >
                    <View
                      style={{
                        borderBottomColor: 'black',
                        marginLeft: 50,
                        marginRight: 60,
                        //marginTop: 350,
                        borderBottomWidth: 1,
                      }}
                    />
                    <View>
                      <Text
                        style={{
                          top: 10,
                          color: '#070707',
                          fontWeight: '1000',
                          fontSize: 10,
                          fontStyle: 'gras',
                          //fontFamily: 'Georgia, serif',
                          margin: 3,
                        }}
                      >
                        {user && user.address_practitioner
                          ? `${user.address_practitioner.street} ${user.address_practitioner.city} ${user.address_practitioner.zip_code}`
                          : '-'}{' '}
                        {user && user.phone ? `Tél/Fax: +216 ${user.phone} ` : '-'}
                        {user && user.gsm ? `GSM: +216 ${user.gsm} ` : '-'}
                        {user && user.address_office
                          ? `Adresse: ${user.address_office.street}, ${user.address_office.zip_code} ${user.address_office.city} ${user.address_office.country}`
                          : '-'}
                      </Text>
                    </View>
                  </View>
                </Page>
              ) : undefined}
              {data3.length === 0 && data2.length > 14 && data2.length <= 23 ? (
                <Page>
                  <View
                    style={{
                      marginLeft: 30,
                      marginRight: 40,
                      marginTop: 3,
                      width: '100%',
                      flexDirection: 'row',
                    }}
                  >
                    <View style={{ width: '50%' }}>
                      <View
                        style={{
                          width: 150,
                          marginTop: 30,
                          marginLeft: 30,
                          // border: 1,
                          height: 28,
                          display: 'flex',
                          flexDirection: 'row',
                          // margin:5
                        }}
                      >
                        <View
                          style={{
                            width: 100,
                            border: 1,
                            height: 28,
                            alignItems: 'flex-start',
                            justifyContent: 'center',
                            textAlign: 'left',
                          }}
                        >
                          <Text style={{ padding: 5, fontSize: 12 }}>Total séances</Text>
                        </View>
                        <View
                          style={{
                            width: 100,
                            border: 1,
                            height: 28,
                            alignItems: 'flex-start',
                            justifyContent: 'center',
                            textAlign: 'left',
                          }}
                        >
                          <Text style={{ padding: 5, fontSize: 12 }}></Text>
                        </View>
                      </View>
                      <View
                        style={{
                          width: 150,
                          marginLeft: 30,
                          // border: 1,
                          height: 28,
                          display: 'flex',
                          flexDirection: 'row',
                          // margin:5
                        }}
                      >
                        <View
                          style={{
                            width: 100,
                            border: 1,
                            height: 28,
                            alignItems: 'flex-start',
                            justifyContent: 'center',
                            textAlign: 'left',
                          }}
                        >
                          <Text style={{ padding: 5, fontSize: 12 }}>Total recette</Text>
                        </View>
                        <View
                          style={{
                            width: 100,
                            border: 1,
                            height: 28,
                            alignItems: 'flex-start',
                            justifyContent: 'center',
                            textAlign: 'left',
                          }}
                        >
                          <Text style={{ padding: 5, fontSize: 12 }}></Text>
                        </View>
                      </View>
                      <View
                        style={{
                          width: 150,
                          marginLeft: 30,
                          // border: 1,
                          height: 28,
                          display: 'flex',
                          flexDirection: 'row',
                          // margin:5
                        }}
                      >
                        <View
                          style={{
                            width: 100,
                            border: 1,
                            height: 28,
                            alignItems: 'flex-start',
                            justifyContent: 'center',
                            textAlign: 'left',
                          }}
                        >
                          <Text style={{ padding: 5, fontSize: 12 }}>Dépense</Text>
                        </View>
                        <View
                          style={{
                            width: 100,
                            border: 1,
                            height: 28,
                            alignItems: 'flex-start',
                            justifyContent: 'center',
                            textAlign: 'left',
                          }}
                        >
                          <Text style={{ padding: 5, fontSize: 12 }}></Text>
                        </View>
                      </View>
                      <View
                        style={{
                          width: 150,
                          marginLeft: 30,
                          // border: 1,
                          height: 28,
                          display: 'flex',
                          flexDirection: 'row',
                          // margin:5
                        }}
                      >
                        <View
                          style={{
                            width: 100,
                            border: 1,
                            height: 28,
                            alignItems: 'flex-start',
                            justifyContent: 'center',
                            textAlign: 'left',
                          }}
                        >
                          <Text style={{ padding: 5, fontSize: 12 }}>Total</Text>
                        </View>
                        <View
                          style={{
                            width: 100,
                            border: 1,
                            height: 28,
                            alignItems: 'flex-start',
                            justifyContent: 'center',
                            textAlign: 'left',
                          }}
                        >
                          <Text style={{ padding: 5, fontSize: 12 }}></Text>
                        </View>
                      </View>
                    </View>
                    <View style={{ width: '50%' }}>
                      <View
                        style={{
                          width: 150,
                          marginTop: 30,
                          // border: 1,
                          height: 28,
                          display: 'flex',
                          flexDirection: 'row',
                          // margin:5
                        }}
                      >
                        <View
                          style={{
                            width: 100,
                            border: 1,
                            height: 28,
                            alignItems: 'flex-start',
                            justifyContent: 'center',
                            textAlign: 'left',
                          }}
                        >
                          <Text style={{ padding: 5, fontSize: 12 }}>p=</Text>
                        </View>
                        <View
                          style={{
                            width: 100,
                            border: 1,
                            height: 28,
                            alignItems: 'flex-start',
                            justifyContent: 'center',
                            textAlign: 'left',
                          }}
                        >
                          <Text style={{ padding: 5, fontSize: 12 }}></Text>
                        </View>
                      </View>
                      <View
                        style={{
                          width: 150,
                          // border: 1,
                          height: 28,
                          display: 'flex',
                          flexDirection: 'row',
                          // margin:5
                        }}
                      >
                        <View
                          style={{
                            width: 100,
                            border: 1,
                            height: 28,
                            alignItems: 'flex-start',
                            justifyContent: 'center',
                            textAlign: 'left',
                          }}
                        >
                          <Text style={{ padding: 5, fontSize: 12 }}>np=</Text>
                        </View>
                        <View
                          style={{
                            width: 100,
                            border: 1,
                            height: 28,
                            alignItems: 'flex-start',
                            justifyContent: 'center',
                            textAlign: 'left',
                          }}
                        >
                          <Text style={{ padding: 5, fontSize: 12 }}></Text>
                        </View>
                      </View>
                      <View
                        style={{
                          width: 150,
                          // border: 1,
                          height: 28,
                          display: 'flex',
                          flexDirection: 'row',
                          // margin:5
                        }}
                      >
                        <View
                          style={{
                            width: 100,
                            border: 1,
                            height: 28,
                            alignItems: 'flex-start',
                            justifyContent: 'center',
                            textAlign: 'left',
                          }}
                        >
                          <Text style={{ padding: 5, fontSize: 12 }}>acompte=</Text>
                        </View>
                        <View
                          style={{
                            width: 100,
                            border: 1,
                            height: 28,
                            alignItems: 'flex-start',
                            justifyContent: 'center',
                            textAlign: 'left',
                          }}
                        >
                          <Text style={{ padding: 5, fontSize: 12 }}></Text>
                        </View>
                      </View>
                      <View
                        style={{
                          width: 150,
                          // border: 1,
                          height: 28,
                          display: 'flex',
                          flexDirection: 'row',
                          // margin:5
                        }}
                      >
                        <View
                          style={{
                            width: 100,
                            border: 1,
                            height: 28,
                            alignItems: 'flex-start',
                            justifyContent: 'center',
                            textAlign: 'left',
                          }}
                        >
                          <Text style={{ padding: 5, fontSize: 12 }}>avoir=</Text>
                        </View>
                        <View
                          style={{
                            width: 100,
                            border: 1,
                            height: 28,
                            alignItems: 'flex-start',
                            justifyContent: 'center',
                            textAlign: 'left',
                          }}
                        >
                          <Text style={{ padding: 5, fontSize: 12 }}></Text>
                        </View>
                      </View>
                      <View
                        style={{
                          width: 150,
                          // border: 1,
                          height: 28,
                          display: 'flex',
                          flexDirection: 'row',
                          // margin:5
                        }}
                      >
                        <View
                          style={{
                            width: 100,
                            border: 1,
                            height: 28,
                            alignItems: 'flex-start',
                            justifyContent: 'center',
                            textAlign: 'left',
                          }}
                        >
                          <Text style={{ padding: 5, fontSize: 12 }}>an=</Text>
                        </View>
                        <View
                          style={{
                            width: 100,
                            border: 1,
                            height: 28,
                            alignItems: 'flex-start',
                            justifyContent: 'center',
                            textAlign: 'left',
                          }}
                        >
                          <Text style={{ padding: 5, fontSize: 12 }}></Text>
                        </View>
                      </View>
                      <View
                        style={{
                          width: 150,
                          // border: 1,
                          height: 28,
                          display: 'flex',
                          flexDirection: 'row',
                          // margin:5
                        }}
                      >
                        <View
                          style={{
                            width: 100,
                            border: 1,
                            height: 28,
                            alignItems: 'flex-start',
                            justifyContent: 'center',
                            textAlign: 'left',
                          }}
                        >
                          <Text style={{ padding: 5, fontSize: 12 }}>sup=</Text>
                        </View>
                        <View
                          style={{
                            width: 100,
                            border: 1,
                            height: 28,
                            alignItems: 'flex-start',
                            justifyContent: 'center',
                            textAlign: 'left',
                          }}
                        >
                          <Text style={{ padding: 5, fontSize: 12 }}></Text>
                        </View>
                      </View>
                      <View
                        style={{
                          width: 150,
                          // border: 1,
                          height: 28,
                          display: 'flex',
                          flexDirection: 'row',
                          // margin:5
                        }}
                      >
                        <View
                          style={{
                            width: 100,
                            border: 1,
                            height: 28,
                            alignItems: 'flex-start',
                            justifyContent: 'center',
                            textAlign: 'left',
                          }}
                        >
                          <Text style={{ padding: 5, fontSize: 12 }}>ab=</Text>
                        </View>
                        <View
                          style={{
                            width: 100,
                            border: 1,
                            height: 28,
                            alignItems: 'flex-start',
                            justifyContent: 'center',
                            textAlign: 'left',
                          }}
                        >
                          <Text style={{ padding: 5, fontSize: 12 }}></Text>
                        </View>
                      </View>
                    </View>
                  </View>
                  <View
                    style={{
                      height: '40px',
                      position: 'absolute',
                      left: 0,
                      bottom: 0,
                      width: '100%',
                      color: 'white',
                      textAlign: 'center',
                    }}
                  >
                    <View
                      style={{
                        borderBottomColor: 'black',
                        marginLeft: 50,
                        marginRight: 60,
                        //marginTop: 350,
                        borderBottomWidth: 1,
                      }}
                    />
                    <View>
                      <Text
                        style={{
                          top: 10,
                          color: '#070707',
                          fontWeight: '1000',
                          fontSize: 10,
                          fontStyle: 'gras',
                          //fontFamily: 'Georgia, serif',
                          margin: 3,
                        }}
                      >
                        {user && user.address_practitioner
                          ? `${user.address_practitioner.street} ${user.address_practitioner.city} ${user.address_practitioner.zip_code}`
                          : '-'}{' '}
                        {user && user.phone ? `Tél/Fax: +216 ${user.phone} ` : '-'}
                        {user && user.gsm ? `GSM: +216 ${user.gsm} ` : '-'}{' '}
                        {user && user.address_office
                          ? `Adresse: ${user.address_office.street}, ${user.address_office.zip_code} ${user.address_office.city} ${user.address_office.country}`
                          : '-'}
                      </Text>
                    </View>
                  </View>
                </Page>
              ) : undefined}
              {data3 && data3.length > 0 ? (
                <Page>
                  <View
                    style={{
                      marginLeft: 30,
                      marginRight: 40,
                      marginTop: 5,
                      width: '100%',
                      flexDirection: 'row',
                    }}
                  >
                    <View style={{ width: '100%' }}>
                      {data3.map((item) => {
                        return (
                          <View
                            style={{
                              width: 250,
                              // border: 1,
                              height: 28,
                              display: 'flex',
                              flexDirection: 'row',
                              // margin:5
                            }}
                          >
                            <View
                              style={{
                                width: 40,
                                border: 1,
                                height: 28,
                                alignItems: 'flex-start',
                                justifyContent: 'center',
                                textAlign: 'left',
                              }}
                            >
                              <Text style={{ padding: 5, fontSize: 12 }}>{item.num}</Text>
                            </View>
                            <View
                              style={{
                                width: 130,
                                border: 1,
                                height: 28,
                                alignItems: 'flex-start',
                                justifyContent: 'center',
                                textAlign: 'left',
                              }}
                            >
                              <Text style={{ padding: 5, fontSize: 12 }}>
                                {item.name}
                              </Text>
                            </View>
                            <View
                              style={{
                                width: 50,
                                border: 1,
                                height: 28,
                                alignItems: 'flex-start',
                                justifyContent: 'center',
                                textAlign: 'left',
                              }}
                            >
                              <Text style={{ padding: 5, fontSize: 12 }}>
                                {item.seance}
                              </Text>
                            </View>
                            <View
                              style={{
                                width: 50,
                                border: 1,
                                height: 28,
                                alignItems: 'flex-start',
                                justifyContent: 'center',
                                textAlign: 'left',
                              }}
                            >
                              <Text style={{ padding: 5, fontSize: 12 }}>
                                {item.hour_start}
                              </Text>
                            </View>
                            <View
                              style={{
                                width: 50,
                                border: 1,
                                height: 28,
                                alignItems: 'flex-start',
                                justifyContent: 'center',
                                textAlign: 'left',
                              }}
                            >
                              <Text style={{ padding: 5, fontSize: 12 }}>
                                {item.hour_end}
                              </Text>
                            </View>
                            <View
                              style={{
                                width: 50,
                                border: 1,
                                height: 28,
                                alignItems: 'flex-start',
                                justifyContent: 'center',
                                textAlign: 'left',
                              }}
                            >
                              <Text style={{ padding: 5, fontSize: 12 }}>{item.p}</Text>
                            </View>
                            <View
                              style={{
                                width: 50,
                                border: 1,
                                height: 28,
                                alignItems: 'flex-start',
                                justifyContent: 'center',
                                textAlign: 'left',
                              }}
                            >
                              <Text style={{ padding: 5, fontSize: 12 }}>{item.pc}</Text>
                            </View>
                            <View
                              style={{
                                width: 50,
                                border: 1,
                                height: 28,
                                alignItems: 'flex-start',
                                justifyContent: 'center',
                                textAlign: 'left',
                              }}
                            >
                              <Text style={{ padding: 5, fontSize: 12 }}>
                                {item.pc_np}
                              </Text>
                            </View>
                            <View
                              style={{
                                width: 50,
                                border: 1,
                                height: 28,
                                alignItems: 'flex-start',
                                justifyContent: 'center',
                                textAlign: 'left',
                              }}
                            >
                              <Text style={{ padding: 5, fontSize: 12 }}>{item.g}</Text>
                            </View>
                          </View>
                        );
                      })}
                      <View
                        style={{
                          marginLeft: 30,
                          marginRight: 40,
                          marginTop: 3,
                          width: '100%',
                          flexDirection: 'row',
                        }}
                      >
                        <View style={{ width: '50%' }}>
                          <View
                            style={{
                              width: 150,
                              marginTop: 30,
                              marginLeft: 30,
                              // border: 1,
                              height: 28,
                              display: 'flex',
                              flexDirection: 'row',
                              // margin:5
                            }}
                          >
                            <View
                              style={{
                                width: 100,
                                border: 1,
                                height: 28,
                                alignItems: 'flex-start',
                                justifyContent: 'center',
                                textAlign: 'left',
                              }}
                            >
                              <Text style={{ padding: 5, fontSize: 12 }}>
                                Total séances
                              </Text>
                            </View>
                            <View
                              style={{
                                width: 100,
                                border: 1,
                                height: 28,
                                alignItems: 'flex-start',
                                justifyContent: 'center',
                                textAlign: 'left',
                              }}
                            >
                              <Text style={{ padding: 5, fontSize: 12 }}></Text>
                            </View>
                          </View>
                          <View
                            style={{
                              width: 150,
                              marginLeft: 30,
                              // border: 1,
                              height: 28,
                              display: 'flex',
                              flexDirection: 'row',
                              // margin:5
                            }}
                          >
                            <View
                              style={{
                                width: 100,
                                border: 1,
                                height: 28,
                                alignItems: 'flex-start',
                                justifyContent: 'center',
                                textAlign: 'left',
                              }}
                            >
                              <Text style={{ padding: 5, fontSize: 12 }}>
                                Total recette
                              </Text>
                            </View>
                            <View
                              style={{
                                width: 100,
                                border: 1,
                                height: 28,
                                alignItems: 'flex-start',
                                justifyContent: 'center',
                                textAlign: 'left',
                              }}
                            >
                              <Text style={{ padding: 5, fontSize: 12 }}></Text>
                            </View>
                          </View>
                          <View
                            style={{
                              width: 150,
                              marginLeft: 30,
                              // border: 1,
                              height: 28,
                              display: 'flex',
                              flexDirection: 'row',
                              // margin:5
                            }}
                          >
                            <View
                              style={{
                                width: 100,
                                border: 1,
                                height: 28,
                                alignItems: 'flex-start',
                                justifyContent: 'center',
                                textAlign: 'left',
                              }}
                            >
                              <Text style={{ padding: 5, fontSize: 12 }}>Dépense</Text>
                            </View>
                            <View
                              style={{
                                width: 100,
                                border: 1,
                                height: 28,
                                alignItems: 'flex-start',
                                justifyContent: 'center',
                                textAlign: 'left',
                              }}
                            >
                              <Text style={{ padding: 5, fontSize: 12 }}></Text>
                            </View>
                          </View>
                          <View
                            style={{
                              width: 150,
                              marginLeft: 30,
                              // border: 1,
                              height: 28,
                              display: 'flex',
                              flexDirection: 'row',
                              // margin:5
                            }}
                          >
                            <View
                              style={{
                                width: 100,
                                border: 1,
                                height: 28,
                                alignItems: 'flex-start',
                                justifyContent: 'center',
                                textAlign: 'left',
                              }}
                            >
                              <Text style={{ padding: 5, fontSize: 12 }}>Total</Text>
                            </View>
                            <View
                              style={{
                                width: 100,
                                border: 1,
                                height: 28,
                                alignItems: 'flex-start',
                                justifyContent: 'center',
                                textAlign: 'left',
                              }}
                            >
                              <Text style={{ padding: 5, fontSize: 12 }}></Text>
                            </View>
                          </View>
                        </View>
                        <View style={{ width: '50%' }}>
                          <View
                            style={{
                              width: 150,
                              marginTop: 30,
                              // border: 1,
                              height: 28,
                              display: 'flex',
                              flexDirection: 'row',
                              // margin:5
                            }}
                          >
                            <View
                              style={{
                                width: 100,
                                border: 1,
                                height: 28,
                                alignItems: 'flex-start',
                                justifyContent: 'center',
                                textAlign: 'left',
                              }}
                            >
                              <Text style={{ padding: 5, fontSize: 12 }}>p=</Text>
                            </View>
                            <View
                              style={{
                                width: 100,
                                border: 1,
                                height: 28,
                                alignItems: 'flex-start',
                                justifyContent: 'center',
                                textAlign: 'left',
                              }}
                            >
                              <Text style={{ padding: 5, fontSize: 12 }}></Text>
                            </View>
                          </View>
                          <View
                            style={{
                              width: 150,
                              // border: 1,
                              height: 28,
                              display: 'flex',
                              flexDirection: 'row',
                              // margin:5
                            }}
                          >
                            <View
                              style={{
                                width: 100,
                                border: 1,
                                height: 28,
                                alignItems: 'flex-start',
                                justifyContent: 'center',
                                textAlign: 'left',
                              }}
                            >
                              <Text style={{ padding: 5, fontSize: 12 }}>np=</Text>
                            </View>
                            <View
                              style={{
                                width: 100,
                                border: 1,
                                height: 28,
                                alignItems: 'flex-start',
                                justifyContent: 'center',
                                textAlign: 'left',
                              }}
                            >
                              <Text style={{ padding: 5, fontSize: 12 }}></Text>
                            </View>
                          </View>
                          <View
                            style={{
                              width: 150,
                              // border: 1,
                              height: 28,
                              display: 'flex',
                              flexDirection: 'row',
                              // margin:5
                            }}
                          >
                            <View
                              style={{
                                width: 100,
                                border: 1,
                                height: 28,
                                alignItems: 'flex-start',
                                justifyContent: 'center',
                                textAlign: 'left',
                              }}
                            >
                              <Text style={{ padding: 5, fontSize: 12 }}>acompte=</Text>
                            </View>
                            <View
                              style={{
                                width: 100,
                                border: 1,
                                height: 28,
                                alignItems: 'flex-start',
                                justifyContent: 'center',
                                textAlign: 'left',
                              }}
                            >
                              <Text style={{ padding: 5, fontSize: 12 }}></Text>
                            </View>
                          </View>
                          <View
                            style={{
                              width: 150,
                              // border: 1,
                              height: 28,
                              display: 'flex',
                              flexDirection: 'row',
                              // margin:5
                            }}
                          >
                            <View
                              style={{
                                width: 100,
                                border: 1,
                                height: 28,
                                alignItems: 'flex-start',
                                justifyContent: 'center',
                                textAlign: 'left',
                              }}
                            >
                              <Text style={{ padding: 5, fontSize: 12 }}>avoir=</Text>
                            </View>
                            <View
                              style={{
                                width: 100,
                                border: 1,
                                height: 28,
                                alignItems: 'flex-start',
                                justifyContent: 'center',
                                textAlign: 'left',
                              }}
                            >
                              <Text style={{ padding: 5, fontSize: 12 }}></Text>
                            </View>
                          </View>
                          <View
                            style={{
                              width: 150,
                              // border: 1,
                              height: 28,
                              display: 'flex',
                              flexDirection: 'row',
                              // margin:5
                            }}
                          >
                            <View
                              style={{
                                width: 100,
                                border: 1,
                                height: 28,
                                alignItems: 'flex-start',
                                justifyContent: 'center',
                                textAlign: 'left',
                              }}
                            >
                              <Text style={{ padding: 5, fontSize: 12 }}>an=</Text>
                            </View>
                            <View
                              style={{
                                width: 100,
                                border: 1,
                                height: 28,
                                alignItems: 'flex-start',
                                justifyContent: 'center',
                                textAlign: 'left',
                              }}
                            >
                              <Text style={{ padding: 5, fontSize: 12 }}></Text>
                            </View>
                          </View>
                          <View
                            style={{
                              width: 150,
                              // border: 1,
                              height: 28,
                              display: 'flex',
                              flexDirection: 'row',
                              // margin:5
                            }}
                          >
                            <View
                              style={{
                                width: 100,
                                border: 1,
                                height: 28,
                                alignItems: 'flex-start',
                                justifyContent: 'center',
                                textAlign: 'left',
                              }}
                            >
                              <Text style={{ padding: 5, fontSize: 12 }}>sup=</Text>
                            </View>
                            <View
                              style={{
                                width: 100,
                                border: 1,
                                height: 28,
                                alignItems: 'flex-start',
                                justifyContent: 'center',
                                textAlign: 'left',
                              }}
                            >
                              <Text style={{ padding: 5, fontSize: 12 }}></Text>
                            </View>
                          </View>
                          <View
                            style={{
                              width: 150,
                              // border: 1,
                              height: 28,
                              display: 'flex',
                              flexDirection: 'row',
                              // margin:5
                            }}
                          >
                            <View
                              style={{
                                width: 100,
                                border: 1,
                                height: 28,
                                alignItems: 'flex-start',
                                justifyContent: 'center',
                                textAlign: 'left',
                              }}
                            >
                              <Text style={{ padding: 5, fontSize: 12 }}>ab=</Text>
                            </View>
                            <View
                              style={{
                                width: 100,
                                border: 1,
                                height: 28,
                                alignItems: 'flex-start',
                                justifyContent: 'center',
                                textAlign: 'left',
                              }}
                            >
                              <Text style={{ padding: 5, fontSize: 12 }}></Text>
                            </View>
                          </View>
                        </View>
                      </View>
                    </View>
                  </View>
                  <View
                    style={{
                      height: '40px',
                      position: 'absolute',
                      left: 0,
                      bottom: 0,
                      width: '100%',
                      color: 'white',
                      textAlign: 'center',
                    }}
                  >
                    <View
                      style={{
                        borderBottomColor: 'black',
                        marginLeft: 50,
                        marginRight: 60,
                        //marginTop: 350,
                        borderBottomWidth: 1,
                      }}
                    />
                    <View>
                      <Text
                        style={{
                          top: 10,
                          color: '#070707',
                          fontWeight: '1000',
                          fontSize: 10,
                          fontStyle: 'gras',
                          //fontFamily: 'Georgia, serif',
                          margin: 3,
                        }}
                      >
                        {user && user.address_practitioner
                          ? `${user.address_practitioner.street} ${user.address_practitioner.city} ${user.address_practitioner.zip_code}`
                          : '-'}{' '}
                        {user && user.phone ? `Tél/Fax: +216 ${user.phone} ` : '-'}
                        {user && user.gsm ? `GSM: +216 ${user.gsm} ` : '-'}
                        {user && user.address_office
                          ? `Adresse: ${user.address_office.street}, ${user.address_office.zip_code} ${user.address_office.city} ${user.address_office.country}`
                          : '-'}
                      </Text>
                    </View>
                  </View>
                </Page>
              ) : undefined}
            </Document>
          </PDFViewer>
        </Fragment>
      ) : undefined}
    </div>
  );
}

export default RDVPDF;
