import React, { Component } from 'react';
import Modals from '../../components/modal';
import axios from 'axios';
import ToastAction from '../../components/ToastAction';
import { toast } from 'react-toastify';

class UploadImgNoteBilan extends Component {
  constructor(props) {
    super(props);
    this.state = {
      setIsOpen: false,
      fileName: '',
    };
    this.openModal = this.openModal.bind(this);
  }

  handleClick(fileUploader) {
    this.refs[fileUploader].click();
  }
  openModal() {
    this.setState({
      setIsOpen: !this.state.setIsOpen,
    });
  }
  render() {
    const { setIsOpen, isLoading, file, fileName } = this.state;
    const { initialValues } = this.props;
    return (
      <>
        <div className="field_sub_section_container_item">
          <div className="description_container">
            <div className="description_input_main_container">
              <div className="description_item">
                {/* //ajouter doc */}
                <div className="attachement_icons">
                  <div className="attachement_icon_item">
                    <input
                      accept=".png, .jpg,.jpeg"
                      onChange={(event) => {
                        this.props.handleChangeFile(event);
                      }}
                      type="file"
                      ref={`fileUploaderConsignes`}
                      name={`file`}
                      hidden
                    />
                    <img
                      onClick={() => this.handleClick(`fileUploaderConsignes`)}
                      src={require('../../assets/img/link.png')}
                      width="16px"
                      height="auto"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="add_document_container_body">
          <div style={{ marginRight: '1%' }}>
            {initialValues.file && initialValues.file.name ? (
              <div
                style={{ display: 'flex' }}
                className="field_sub_section_container_item"
              >
                <span
                  onClick={() => {
                    this.setState({
                      file: initialValues.file ? initialValues.file : initialValues.file,
                    });
                    this.openModal();
                  }}
                  style={{
                    color: 'gray',
                    marginRight: '126px',
                    cursor: 'pointer',
                  }}
                >
                  {initialValues.file.name}
                </span>
                <span
                  style={{ cursor: 'pointer' }}
                  onClick={() => {
                    this.props.handleChangeDeleteFile();
                  }}
                >
                  X
                </span>
              </div>
            ) : initialValues.file ? (
              <div
                style={{ display: 'flex' }}
                className="field_sub_section_container_item"
              >
                <span
                  onClick={() => {
                    axios(`${process.env.REACT_APP_PUBLIC_URL}${initialValues.file}`, {
                      method: 'GET',
                      headers: {
                        Authorization: `JWT ${window.localStorage.getItem('token')}`,
                      },
                      responseType: 'blob', //Force to receive data in a Blob Format
                    })
                      .then((response) => {
                        const url = URL.createObjectURL(new Blob([response.data]));
                        this.setState({
                          file: url,
                          fileName: initialValues.file_name,
                        });
                        this.openModal();
                      })
                      .catch((_) => {
                        toast(
                          ({ closeToast }) => (
                            <ToastAction
                              type="error"
                              message="Impossible de récupérer la pièce jointe"
                            />
                          ),
                          { hideProgressBar: true },
                        );
                      });
                  }}
                  style={{
                    color: 'gray',
                    marginRight: '126px',
                    cursor: 'pointer',
                    width: '100%',
                  }}
                >
                  {initialValues.file_name}
                </span>

                {initialValues.file ? (
                  <span
                    style={{ cursor: 'pointer' }}
                    onClick={() => {
                      this.props.handleChangeDeleteFile();
                    }}
                  >
                    X
                  </span>
                ) : (
                  ''
                )}
              </div>
            ) : (
              ''
            )}
          </div>
        </div>
        <Modals
          setIsOpen={setIsOpen}
          openModal={this.openModal}
          data={
            <>
              <div
                className="modal-header"
                style={{
                  borderBottom: '1px solid #dee2e6',
                  padding: '1.5rem 0rem',
                  margin: '0px 20px',
                  alignItems: 'baseline',
                  marginLeft: '3%',
                  marginTop: '-30px',
                  width: '100%',
                }}
              >
                <span>{fileName}</span>
                <button
                  type="button"
                  className="close"
                  data-dismiss="modal"
                  aria-label="Close"
                  onClick={() => this.openModal()}
                >
                  <span
                    aria-hidden="true"
                    style={{ marginRight: '30px', color: '#1A1C1D' }}
                  >
                    &times;
                  </span>
                </button>
              </div>
              <hr style={{ width: '85%' }} />
              <div
                style={{
                  height: '470px',
                  width: '470px',
                }}
                ref={(el) => (this.componentRef = el)}
                className="modal-body"
              >
                {file && file.name ? (
                  <div className="miniature-container">
                    <img
                      style={{
                        height: '44%',
                        marginTop: '310px',
                        width: '100%',
                        borderRadius: '10px',
                      }}
                      src={URL.createObjectURL(file)}
                    />
                  </div>
                ) : (
                  <img
                    style={{ height: '100%', width: '100%', borderRadius: '10px' }}
                    src={file}
                  />
                )}
              </div>
              <hr style={{ width: '95%' }} />
              <div
                className="modal-footer create_prise_en_charge_1_footer"
                style={{
                  borderBottom: '1px solid #dee2e6',
                  padding: '1.5rem 0rem',
                  margin: '0px 20px',
                }}
              ></div>
            </>
          }
        />
      </>
    );
  }
}
export default UploadImgNoteBilan;
