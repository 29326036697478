import React, { Component } from 'react';
import './index.css';
import actions from '../../js/actions';
import { connect } from 'react-redux';
import CircularProgress from '@material-ui/core/CircularProgress';
import { isHasPermission, formatDate } from '../../utils/helpres';
import moment from 'moment';
import TextField from '@material-ui/core/TextField';
import DrawerRecetteDepense from '../compta/drawer-geet-recette-depense';
import actionsDepense from '../depense/store/actions';
import actionsRecette from '../recette/store/actions';
import actionsJs from '../../js/actions';
import { FORMAT_DATE_SERVER } from '../../utils/constants';

const mapStateToProps = (state) => ({
  paramsPayment: state.AppReducer.paramsPayment,
  user: state.AppReducer.user,
  statistical: state.AppReducer.statistical,
  isLoadingStatistical: state.AppReducer.isLoadingStatistical,
  paramsDepense: state.DepenseReducer.params,
  paramsRecette: state.RecetteReducer.params,
  office: state.AppReducer.office,
});

const mapDispatchToProps = (dispatch) => ({
  getStatistical: (params) => dispatch(actions.getStatistical(params)),
  changeActiveItemSideBarSettings: (item, data, create) =>
    dispatch(actions.changeActiveItemSideBarSettings(item, data, create)),
  changeActiveItemSideBarCompta: (data) =>
    dispatch(actions.changeActiveItemSideBarCompta(data)),
  changeSearchedPaymentStartDate: async (params) => {
    await dispatch(actions.changeSearchedPayment(params));
    dispatch(actions.getStatistical(params));
  },
  changeSearchedPaymentEndDate: async (params) => {
    await dispatch(actions.changeSearchedPayment(params));
    dispatch(actions.getStatistical(params));
  },
  changeDepenseSearchedStartDate: async (params) => {
    await dispatch(actionsDepense.changeSearchedStringDepenses(params));
    dispatch(actionsDepense.getAllDepenses(params));
  },
  changeRecetteSearchedEndDate: async (params) => {
    await dispatch(actionsRecette.changeSearchedStringRecettes(params));
    dispatch(actionsRecette.getAllRecettes(params));
  },
  getOfficeByIdDoctor: () => dispatch(actionsJs.getOfficeByIdDoctor()),
});

class Payment extends Component {
  constructor(props) {
    super(props);
    //props.getStatistical(props.paramsPayment);
    props.changeNamePage(
      props.type === 'autre' ? 'Compta en chiffres' : 'Module cnam en chiffres',
    );
    props.changeActiveItemSideBarSettings(111, false, true);
    props.changeActiveItemSideBarCompta(null);
    props.getOfficeByIdDoctor();
    this.state = {
      errorDate: false,
      activeDrawer: false,
    };
  }
  componentDidMount() {
    this.props.changeSearchedPaymentEndDate({
      ...this.props.paramsPayment,
      start_date: formatDate(moment(), FORMAT_DATE_SERVER),
      end_date: formatDate(moment(), FORMAT_DATE_SERVER),
      search: '',
      status: '',
    });
  }
  toggleDrawer = (anchor, open, id) => (event) => {
    if (
      event &&
      event.type === 'keydown' &&
      (event.key === 'Tab' || event.key === 'Shift')
    ) {
      return;
    }
    this.setState({
      left: open,
      anchor: 'left',
    });
  };
  render() {
    const {
      admin,
      paramsDepense,
      paramsRecette,
      statistical,
      paramsPayment,
      type,
      user,
      isLoadingStatistical,
      office,
    } = this.props;
    const { errorDate, activeDrawer } = this.state;
    return (
      <div style={{ overflow: 'auto', display: 'block' }} className="main_content">
        <DrawerRecetteDepense
          toggleDrawer={this.toggleDrawer}
          office={office}
          activeDrawer={activeDrawer}
          admin={admin}
          onCancel={() => this.setState({ activeDrawer: false })}
        />
        {admin ? (
          <div className="consultation_section_controls">
            <TextField
              id="date"
              error={errorDate}
              type="date"
              label="Depuis"
              defaultValue={
                paramsPayment.start_date
                  ? moment(paramsPayment.start_date).format('YYYY-MM-DD')
                  : moment().format('YYYY-MM-DD')
              }
              onChange={(event) => {
                if (
                  paramsPayment.endDate &&
                  moment(paramsPayment.end_date).isBefore(moment(event.target.value))
                ) {
                  this.setState({
                    errorDate: true,
                  });
                } else {
                  this.setState({
                    errorDate: false,
                  });
                  this.props.changeSearchedPaymentStartDate({
                    ...paramsPayment,
                    start_date: event.target.value ? formatDate(event.target.value) : '',
                  });
                }
              }}
              InputLabelProps={{
                shrink: true,
              }}
            />
            <TextField
              id="datetime-local"
              type="date"
              label="Jusqu'à "
              error={errorDate}
              defaultValue={
                paramsPayment.end_date
                  ? moment(paramsPayment.end_date).format('YYYY-MM-DD')
                  : moment().format('YYYY-MM-DD')
              }
              onChange={(e) => {
                if (
                  paramsPayment.start_date &&
                  moment(paramsPayment.start_date).isAfter(moment(e.target.value))
                ) {
                  this.setState({
                    errorDate: true,
                  });
                } else {
                  this.setState({
                    errorDate: false,
                  });
                  this.props.changeSearchedPaymentEndDate({
                    ...paramsPayment,
                    end_date: formatDate(e.target.value),
                  });
                }
              }}
              InputLabelProps={{
                shrink: true,
              }}
            />
          </div>
        ) : (
          ''
        )}
        {isLoadingStatistical ? (
          <CircularProgress disableShrink style={{ marginTop: '25%' }} />
        ) : (
          <div className="stats-container">
            <div className="stats-content">
              <div className="stats-item">
                <div className="container">
                  <div className="row">
                    <div className="patient-stats-cards-container">
                      <div className="patient-stats-cards-content">
                        {type === 'cnam' ? (
                          <div className="card-group">
                            {isHasPermission(
                              user.login_access.permissions,
                              'BILLS_ACCESS',
                            ) ? (
                              <>
                                <div className="card">
                                  <div className="patient-stats-card-header">
                                    <div className="patient-stats-card-title">
                                      <h4>Total payé des factures CNAM </h4>
                                    </div>
                                  </div>
                                  <div className="card-body">
                                    <span>{statistical.sumBillCnamPayed}</span>
                                  </div>
                                </div>
                                <div className="card">
                                  <div className="patient-stats-card-header">
                                    <div className="patient-stats-card-title">
                                      <h4>Total impayé des factures Cnam</h4>
                                    </div>
                                  </div>
                                  <div className="card-body">
                                    <span>{statistical.sumBillCnamNotPayed}</span>
                                  </div>
                                </div>{' '}
                              </>
                            ) : (
                              ''
                            )}
                            {isHasPermission(
                              user.login_access.permissions,
                              'BILLS_ACCESS',
                            ) ? (
                              <>
                                <div className="card">
                                  <div className="patient-stats-card-header">
                                    <div className="patient-stats-card-title">
                                      <h4>Total payé des factures ACT </h4>
                                    </div>
                                  </div>
                                  <div className="card-body">
                                    <span>{statistical.sumBillACTPayed}</span>
                                  </div>
                                </div>
                                <div className="card">
                                  <div className="patient-stats-card-header">
                                    <div className="patient-stats-card-title">
                                      <h4>Total impayé des factures ACT</h4>
                                    </div>
                                  </div>
                                  <div className="card-body">
                                    <span>{statistical.sumBillACTNotPayed}</span>
                                  </div>
                                </div>{' '}
                              </>
                            ) : (
                              ''
                            )}
                            {isHasPermission(
                              user.login_access.permissions,
                              'CNAM_SLIP_ACCESS',
                            ) ? (
                              <>
                                <div className="card">
                                  <div className="patient-stats-card-header">
                                    <div className="patient-stats-card-title">
                                      <h4>Total bordereaux Cnam</h4>
                                    </div>
                                  </div>
                                  <div className="card-body">
                                    <span>{statistical.sumBordereauCnam}</span>
                                  </div>
                                </div>
                                <div className="card">
                                  <div className="patient-stats-card-header">
                                    <div className="patient-stats-card-title">
                                      <h4>Total bordereaux Act</h4>
                                    </div>
                                  </div>
                                  <div className="card-body">
                                    <span>{statistical.sumBordereauAct}</span>
                                  </div>
                                </div>
                              </>
                            ) : (
                              ''
                            )}
                          </div>
                        ) : (
                          <div className="card-group">
                            {isHasPermission(
                              user.login_access.permissions,
                              'BILLS_ACCESS',
                            ) ? (
                              <>
                                <div className="card">
                                  <div className="patient-stats-card-header">
                                    <div className="patient-stats-card-title">
                                      <h4>Total payé des factures </h4>
                                    </div>
                                  </div>
                                  <div className="card-body">
                                    <span>{statistical.sumBillPayed}</span>
                                  </div>
                                </div>
                                <div className="card">
                                  <div className="patient-stats-card-header">
                                    <div className="patient-stats-card-title">
                                      <h4>Total impayé des factures</h4>
                                    </div>
                                  </div>
                                  <div className="card-body">
                                    <span>{statistical.sumBillNotPayed}</span>
                                  </div>
                                </div>{' '}
                              </>
                            ) : (
                              ''
                            )}
                            {isHasPermission(
                              user.login_access.permissions,
                              'RECEIPT_ACCESS',
                            ) ? (
                              <div className="card">
                                <div className="patient-stats-card-header">
                                  <div className="patient-stats-card-title">
                                    <h4>Total des reçus </h4>
                                  </div>
                                </div>
                                <div className="card-body">
                                  <span>{statistical.sumReceipt}</span>
                                </div>
                              </div>
                            ) : (
                              ''
                            )}
                            {isHasPermission(
                              user.login_access.permissions,
                              'CNAM_SLIP_ACCESS',
                            ) ? (
                              <div className="card">
                                <div className="patient-stats-card-header">
                                  <div className="patient-stats-card-title">
                                    <h4>Total bordereaux</h4>
                                  </div>
                                </div>
                                <div className="card-body">
                                  <span>{statistical.sumBordereauBill}</span>
                                </div>
                              </div>
                            ) : (
                              ''
                            )}
                            {isHasPermission(
                              user.login_access.permissions,
                              'BILLS_ACCESS',
                            ) ? (
                              <>
                                <div className="card">
                                  <div className="patient-stats-card-header">
                                    <div className="patient-stats-card-title">
                                      <h4>Total des recettes </h4>
                                    </div>
                                  </div>
                                  <div className="card-body">
                                    <span>
                                      {parseFloat(statistical.sumRecette).toFixed(2)}
                                    </span>
                                  </div>
                                </div>
                                <div className="card">
                                  <div className="patient-stats-card-header">
                                    <div className="patient-stats-card-title">
                                      <h4>Total des dépenses</h4>
                                    </div>
                                  </div>
                                  <div className="card-body">
                                    <span>
                                      {parseFloat(statistical.sumDepense).toFixed(2)}
                                    </span>
                                  </div>
                                </div>{' '}
                                <div className="card">
                                  <div className="patient-stats-card-header">
                                    <div
                                      style={{ display: 'flex' }}
                                      className="patient-stats-card-title"
                                    >
                                      <h4 style={{ width: '90%' }}>Total Net</h4>
                                      <div
                                        onClick={async () => {
                                          await this.props.changeRecetteSearchedEndDate({
                                            ...paramsRecette,
                                            start_date: formatDate(
                                              moment(),
                                              FORMAT_DATE_SERVER,
                                            ),
                                            end_date: formatDate(
                                              moment(),
                                              FORMAT_DATE_SERVER,
                                            ),
                                            search: '',
                                            status: '',
                                          });
                                          await this.props.changeDepenseSearchedStartDate(
                                            {
                                              ...paramsDepense,
                                              start_date: formatDate(
                                                moment(),
                                                FORMAT_DATE_SERVER,
                                              ),
                                              end_date: formatDate(
                                                moment(),
                                                FORMAT_DATE_SERVER,
                                              ),
                                              search: '',
                                              status: '',
                                            },
                                          );

                                          this.setState({ activeDrawer: true });
                                        }}
                                        className="more_icon"
                                        style={{ cursor: 'pointer' }}
                                      >
                                        <i
                                          className="fas fa-download"
                                          aria-hidden="true"
                                        ></i>
                                      </div>
                                    </div>
                                  </div>
                                  <div className="card-body">
                                    <span>
                                      {parseFloat(
                                        statistical.sumRecette - statistical.sumDepense,
                                      ).toFixed(2)}
                                    </span>
                                  </div>
                                </div>{' '}
                                <div className="card">
                                  <div className="patient-stats-card-header">
                                    <div className="patient-stats-card-title">
                                      <h4>Total des crédits</h4>
                                    </div>
                                  </div>
                                  <div className="card-body">
                                    <span>
                                      {parseFloat(statistical.sumCredit).toFixed(2)}
                                    </span>
                                  </div>
                                </div>{' '}
                              </>
                            ) : (
                              ''
                            )}
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    );
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(Payment);
