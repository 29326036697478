import React, { Component } from 'react';
import './list_bilan.css';
import moment from 'moment';
import { Link } from 'react-router-dom';
import { formatDate } from '../../../utils/helpres';
class List_bilan extends Component {
  componentDidMount() {
    this.props.changeSearchedStringBilan(
      {
        ...this.props.paramsBilan,
        limit: 10,
      },
      this.props.patientRecord.id,
    );
  }
  render() {
    const { bilanPatientRecord, paramsBilan, patientRecord } = this.props;
    return (
      <div className="container_changeable_content">
        <div className="patients_bilans_record">
          <div className="patients_bilans_record_header">
            <svg
              onClick={() => this.props.showPatient(patientRecord.patient.id)}
              style={{ cursor: 'pointer' }}
              width="46"
              height="46"
              viewBox="0 0 46 46"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <rect
                x="0.5"
                y="45.5"
                width="45"
                height="45"
                rx="9.5"
                transform="rotate(-90 0.5 45.5)"
                fill="white"
                stroke="#ECEEF5"
              />
              <path
                d="M20.0117 23L24.5117 18.5L25.5664 19.5547L22.1211 23L25.5664 26.4453L24.5117 27.5L20.0117 23Z"
                fill="#8083A3"
              />
            </svg>
            <div style={{ width: '50px', height: '50px' }} className="patient_avatar">
              <svg
                width="20"
                height="34"
                viewBox="0 0 33 34"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M5.73958 23.2812C9.42708 21.7812 12.9583 21.0312 16.3333 21.0312C19.7083 21.0312 23.2083 21.7812 26.8333 23.2812C30.5208 24.7188 32.3646 26.625 32.3646 29V33.0312H0.302084V29C0.302084 26.625 2.11458 24.7188 5.73958 23.2812ZM21.9583 14.6562C20.3958 16.2188 18.5208 17 16.3333 17C14.1458 17 12.2708 16.2188 10.7083 14.6562C9.14583 13.0938 8.36458 11.2188 8.36458 9.03125C8.36458 6.84375 9.14583 4.96875 10.7083 3.40625C12.2708 1.78125 14.1458 0.96875 16.3333 0.96875C18.5208 0.96875 20.3958 1.78125 21.9583 3.40625C23.5208 4.96875 24.3021 6.84375 24.3021 9.03125C24.3021 11.2188 23.5208 13.0938 21.9583 14.6562Z"
                  fill="#8083A3"
                />
              </svg>
            </div>
            <div className="patient_basic_info">
              <span id="patient_name">{`${patientRecord.patient.last_name} ${patientRecord.patient.first_name}`}</span>
              <span id="patient_city">
                {patientRecord.patient.address_patient
                  ? patientRecord.patient.address_patient.city
                  : '-'}
              </span>
            </div>
          </div>
          <div
            style={{ width: '36%', marginLeft: '20px' }}
            className="contact_patients_header"
          >
            <div className="search_contact_patients">
              <input
                style={{ borderBottom: '1px solid' }}
                onChange={(event) => {
                  this.props.changeSearchedStringBilan(
                    {
                      ...paramsBilan,
                      search: event.target.value ? event.target.value : '',
                    },
                    patientRecord.id,
                  );
                }}
                type="text"
                placeholder="Taper le nom d’un bilan"
              />
              <i style={{ color: 'black' }} className="fas fa-search"></i>
            </div>
          </div>
          {bilanPatientRecord &&
          bilanPatientRecord.data &&
          bilanPatientRecord.data.length > 0 ? (
            <div className="patients_bilans_record_body">
              <div className="patients_bilans_record_sub_header">
                <span className="patients_bilans_record_label">Bilans</span>
              </div>
              <div className="patients_bilans_record_container">
                {bilanPatientRecord.data.map((item) => {
                  return (
                    <Link
                      to={`/bilan/${item.bilan.label}/${item.consultation.id}/${item.id}`}
                    >
                      <div key={item.id} className="bilans_preview_item">
                        <div
                          className="bilans_preview_sub_item"
                          id="patient_bilans_record_timing"
                        >
                          <svg
                            width="38"
                            height="38"
                            viewBox="0 0 38 38"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <rect
                              x="0.5"
                              y="0.5"
                              width="37"
                              height="37"
                              rx="9.5"
                              fill="white"
                              stroke="#ECEEF5"
                            />
                            <path
                              d="M19.7383 16.75H23.8867L19.7383 12.6367V16.75ZM21.9883 20.5117V19H16.0117V20.5117H21.9883ZM21.9883 23.5V21.9883H16.0117V23.5H21.9883ZM20.5117 11.5117L25.0117 16.0117V25.0117C25.0117 25.4102 24.8594 25.7617 24.5547 26.0664C24.25 26.3477 23.8984 26.4883 23.5 26.4883H14.5C14.1016 26.4883 13.75 26.3477 13.4453 26.0664C13.1406 25.7617 12.9883 25.4102 12.9883 25.0117L13.0234 12.9883C13.0234 12.5898 13.1641 12.25 13.4453 11.9688C13.75 11.6641 14.1016 11.5117 14.5 11.5117H20.5117Z"
                              fill="#8083A3"
                            />
                          </svg>

                          <div
                            style={{
                              width: '300px',
                            }}
                            className="text_clock_cont"
                          >
                            <span id="start_time">{item.bilan.display_name}</span>
                            <span id="prise_en_charge_text_desc">
                              {item.path.frequence_item_3} le{' '}
                              {formatDate(item.path.b_1_2)}
                            </span>
                          </div>
                        </div>
                        <div className="recent_patient_icon">
                          <i className="fas fa-angle-right"></i>
                        </div>
                      </div>
                    </Link>
                  );
                })}
              </div>
            </div>
          ) : (
            <div className="container_changeable_content">
              <div className="patient_detailed_info_section">
                <div className="patient_detailed_info_section_body">
                  <div className="empty_consultation_result_container">
                    <div className="empty_consultation_result">
                      <img
                        src={require('../../../assets/img/undraw_design_team_af2y.svg')}
                      />
                      <span id="no_consultation">Pas de Bilans !</span>
                      <span id="no_consultation_desc">
                        Essayez de créer des nouveaux bilans. Si vous n'avez pas des
                        cures, commencez déja par en créer des nouvelles.
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    );
  }
}

export default List_bilan;
