import React, { Component } from 'react';
import CircularProgress from '@material-ui/core/CircularProgress';
import moment from 'moment';

class Archive extends Component {
  constructor(props) {
    super(props);
    props.changeNamePage('Registre d’activités');
    props.changeActiveItemSideBarSettings(111, false, true);
    document.title = 'Registre d’activités';
  }
  render() {
    const { isLoadingArchive, archives } = this.props;
    if (isLoadingArchive)
      return (
        <CircularProgress
          disableShrink
          style={{ marginLeft: '500px', marginTop: '25%' }}
        />
      );
    return (
      <div
        className="main_content"
        style={{
          overflow: 'hidden',
          width: '100%',
          padding: '5%',
          height: '100%',
        }}
      >
        <div
          className="general_info"
          style={{ display: 'contents', width: '87%', height: '100%' }}
        >
          <div className="contact_patients_body" style={{ height: '100%' }}>
            <div style={{ display: 'flex', gap: 10 }}>
              <svg
                onClick={async () => {
                  this.props.goBackItem('showArchive');
                }}
                width="46"
                height="46"
                viewBox="0 0 46 46"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                style={{ cursor: 'pointer' }}
              >
                <rect
                  x="0.5"
                  y="45.5"
                  width="45"
                  height="45"
                  rx="9.5"
                  transform="rotate(-90 0.5 45.5)"
                  fill="white"
                  stroke="#ECEEF5"
                ></rect>
                <path
                  d="M20.0117 23L24.5117 18.5L25.5664 19.5547L22.1211 23L25.5664 26.4453L24.5117 27.5L20.0117 23Z"
                  fill="#8083A3"
                ></path>
              </svg>
              <br />
              <span
                style={{ marginTop: 10 }}
                className="patients_consultations_record_label"
              >
                Registre d’activités
              </span>
            </div>
            <br />
            <div style={{ width: '90%' }} className="notification_info_form_item">
              <div style={{ width: '30%' }}>
                <div
                  style={{ width: '600px' }}
                  className="options_container_item option1"
                >
                  <div
                    className="seance_number"
                    style={{ fontWeight: 600, width: '80%' }}
                  >
                    Date{' '}
                  </div>
                </div>
              </div>
              <div style={{ width: '70%' }} className="seance_date">
                <div className="seance_date">
                  <div className="seance_number" style={{ fontWeight: 600 }}>
                    Content
                  </div>
                </div>
              </div>
            </div>
            {archives && archives.length > 0 ? (
              archives.map((item) => {
                return (
                  <div style={{ width: '90%' }} className="notification_info_form_item">
                    <div style={{ width: '30%' }}>
                      <div
                        style={{ width: '600px' }}
                        className="options_container_item option1"
                      >
                        <div className="seance_number" style={{ width: '80%' }}>
                          <span>{moment(item.date).format('DD/MM/YYYY HH:mm')}</span>
                        </div>
                      </div>
                    </div>
                    <div style={{ width: '70%' }} className="seance_date">
                      <div className="seance_date">
                        <span>{item.content}</span>
                      </div>
                    </div>
                  </div>
                );
              })
            ) : (
              <div className="consultation_detailed_info_section">
                <div className="empty_consultation_result_container">
                  <div className="empty_consultation_result">
                    <img
                      src={require('../../../assets/img/undraw_design_team_af2y.svg')}
                    />
                    <span id="no_consultation">Liste vide !</span>
                    <span id="no_consultation_desc">
                      L’historique des actions sera affiché au fur et à mesure des actions
                      faites sur l’application.
                    </span>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    );
  }
}

export default Archive;
