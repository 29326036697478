import moment from 'moment';
import { formatDate } from './helpres';

export const FORMAT_DATE = 'DD/MM/YYYY';

export const FORMAT_DATE_SERVER = 'YYYY-MM-DD';

export const CNAM = 'cnam';

export const UNIT_PRICE_CNAM = 11.5;

export const zerosNumBordereau = ['000', '00', '0', ''];

export const zerosCodeBureau = ['00', '0', ''];

export const zerosNumBill = ['0000', '000', '00', '0', ''];

export const zerosNbrBill = ['000000', '00000', '0000', '000', '00', '0', ''];

export const zerosTotalTTc = [
  '0000000000',
  '000000000',
  '00000000',
  '0000000',
  '000000',
  '00000',
  '0000',
  '000',
  '00',
  '0',
  '',
];

export const zeros = ['000000', '00000', '0000', '000', '00', '0', ''];

const cities = [
  { title: 'Carthage' },
  { title: 'La Medina' },
  { title: 'Bab Bhar' },
  { title: 'Bab Souika' },
  { title: 'Omrane' },
  { title: 'Omrane Superieur' },
  { title: 'Jerba mellita' },
  { title: 'Attahrir' },
  { title: 'El Menzah' },
  { title: 'Cite Alkhadhra' },
  { title: 'Bardo' },
  { title: 'Sejoumi' },
  { title: 'Azzouhour' },
  { title: 'Alhrairia' },
  { title: 'Sidi Hsine' },
  { title: 'Ouardia' },
  { title: 'Kabaria' },
  { title: 'Sidi Elbechir' },
  { title: 'Jebel Jelloud' },
  { title: 'La Goulette' },
  { title: 'Le Kram' },
  { title: 'La Marsa' },
  { title: 'Ariana Ville' },
  { title: 'Soukra' },
  { title: 'Raouede' },
  { title: 'Kalâat Andalous' },
  { title: 'Mnihla' },
  { title: 'Cite Attadhamon' },
  { title: 'Oued Ellil' },
  { title: 'Manouba' },
  { title: 'Tebourba' },
  { title: 'Battan' },
  { title: 'Jedaida' },
  { title: 'Mornaguia' },
  { title: 'Borj Amri' },
  { title: 'Douar Hicher' },
  { title: 'Ben Arous' },
  { title: 'Nouvelle Medina' },
  { title: 'Mourouj' },
  { title: 'Hammam Lif' },
  { title: 'Hammam Chatt' },
  { title: 'Boumhel Bassatine' },
  { title: 'Ezzahra' },
  { title: 'Rades' },
  { title: 'M hamdia' },
  { title: 'Fouchana' },
  { title: 'Mornag' },
  { title: 'Nabeul' },
  { title: 'Dar Chaabane Elfehri' },
  { title: 'Beni Khiar' },
  { title: 'Korba' },
  { title: 'Menzel Temime' },
  { title: 'Mida' },
  { title: 'Kelibia' },
  { title: 'Hammam Ghezaz' },
  { title: 'Haouaria' },
  { title: 'Takelsa' },
  { title: 'Slimane' },
  { title: 'Menzel Bouzelfa' },
  { title: 'Beni Khalled' },
  { title: 'Grombalia' },
  { title: 'Bouargoub' },
  { title: 'Hammamet' },
  { title: 'Bizerte Nord' },
  { title: 'Jarzouna' },
  { title: 'Bizerte Sud' },
  { title: 'Sejnane' },
  { title: 'Joumine' },
  { title: 'Mateur' },
  { title: 'Ghezala' },
  { title: 'Menzel Bourguiba' },
  { title: 'Tinja' },
  { title: 'Utique' },
  { title: 'Ghar El Melh' },
  { title: 'Menzel Jemil' },
  { title: 'El Alia' },
  { title: 'Ras Jebel' },
  { title: 'Zaghouan' },
  { title: 'Zeriba' },
  { title: 'Bir Mecharga' },
  { title: 'Fahs' },
  { title: 'Nadhour' },
  { title: 'Saouaf' },
  { title: 'Sousse Ville' },
  { title: 'Zaouia Ksiba Thrayat' },
  { title: 'Sousse Ryadh' },
  { title: 'Sousse Jawhara' },
  { title: 'Sousse Sidi Abdelhamid' },
  { title: 'Hammam sousse' },
  { title: 'Akouda' },
  { title: 'Kalaa Elkebira' },
  { title: 'Sidi Bouali' },
  { title: 'Hergla' },
  { title: 'Enfidha' },
  { title: 'Bouficha' },
  { title: 'Koundar' },
  { title: 'Sidi Elheni' },
  { title: 'Msaken' },
  { title: 'Kalaa Esseghira' },
  { title: 'Monastir' },
  { title: 'Ouerdanine' },
  { title: 'Sahline' },
  { title: 'Zeramdine' },
  { title: 'Beni Hassan' },
  { title: 'Jammel' },
  { title: 'Benbla' },
  { title: 'Moknine' },
  { title: 'Bekalta' },
  { title: 'Teboulba' },
  { title: 'Ksar Helal' },
  { title: 'Ksibet Medyouni' },
  { title: 'Sayada Lamta Bouhjar' },
  { title: 'Mahdia' },
  { title: 'Boumerdes' },
  { title: 'Ouled Chamekh' },
  { title: 'Chorbane' },
  { title: 'Hbira' },
  { title: 'Souassi' },
  { title: 'Eljem' },
  { title: 'Chebba' },
  { title: 'Malloulech' },
  { title: 'Sidi Alouane' },
  { title: 'Ksour Essef' },
  { title: 'Sfax Ville' },
  { title: 'Sfax Ouest' },
  { title: 'Sakiet Ezzit' },
  { title: 'Sakiet Eddaier' },
  { title: 'Sfax sud' },
  { title: 'Tina' },
  { title: 'Agareb' },
  { title: 'Jebeniana' },
  { title: 'El Amra' },
  { title: 'El Hencha' },
  { title: 'Menzel chaker' },
  { title: 'Ghraiba' },
  { title: 'Bir Ali Ben Khelifa' },
  { title: 'Sekhira' },
  { title: 'Mahres' },
  { title: 'Kerkennah' },
  { title: 'Beja nord' },
  { title: 'Beja sud' },
  { title: 'Amdoun' },
  { title: 'Nefza' },
  { title: 'Teboursouk' },
  { title: 'Tibar' },
  { title: 'Testour' },
  { title: 'Goubellat' },
  { title: 'Mejez El Bab' },
  { title: 'Jendouba' },
  { title: 'Jendouba Nord' },
  { title: 'Boussalem' },
  { title: 'Tabarka' },
  { title: 'Ain Drahem' },
  { title: 'Fernana' },
  { title: 'Ghardimaou' },
  { title: 'Oued Mliz' },
  { title: 'Balta Bouaouene' },
  { title: 'Kef Ouest' },
  { title: 'Kef Est' },
  { title: 'Nebeur' },
  { title: 'Sakiet Sidi Youssef' },
  { title: 'Tejerouine' },
  { title: 'Kalaat sinane' },
  { title: 'Kalaa El khasba' },
  { title: 'Jerissa' },
  { title: 'Gsour' },
  { title: 'Dahmani' },
  { title: 'Le Sers' },
  { title: 'Siliana nord' },
  { title: 'Siliana sud' },
  { title: 'Bouarada' },
  { title: 'Gaafour' },
  { title: 'El Aroussa' },
  { title: 'Le Krib' },
  { title: 'Bourouis' },
  { title: 'Makther' },
  { title: 'Rouhia' },
  { title: 'Kesra' },
  { title: 'Bargou' },
  { title: 'Kairouan Nord' },
  { title: 'Kairouan Sud' },
  { title: 'Chebika' },
  { title: 'Sebikha' },
  { title: 'Oueslatia' },
  { title: 'Haffouz' },
  { title: 'El Ala' },
  { title: 'Hajeb El Ayoun' },
  { title: 'Nasrallah' },
  { title: 'Cherarda' },
  { title: 'Bouhajla' },
  { title: 'Sidi Bouzid Ouest' },
  { title: 'Sidi Bouzid Est' },
  { title: 'Jelma' },
  { title: 'Sabbalet Ouled Askar' },
  { title: 'Bir Hfay' },
  { title: 'Sidi Ali Benoun' },
  { title: 'Menzel Bouzayane' },
  { title: 'Meknassi' },
  { title: 'Souk Jedid' },
  { title: 'Mezouna' },
  { title: 'Regueb' },
  { title: 'Ouled Haffouz' },
  { title: 'Kasserine Nord' },
  { title: 'Kasserine Sud' },
  { title: 'Hassi ferid' },
  { title: 'Sbitla' },
  { title: 'Sbiba' },
  { title: 'Jedliane' },
  { title: 'El Ayoun' },
  { title: 'Tela' },
  { title: 'Hidra' },
  { title: 'Foussana' },
  { title: 'Feriana' },
  { title: 'Mejel Bel Abbes' },
  { title: 'Gabes ville' },
  { title: 'Gabes ouest' },
  { title: 'Gabes sud' },
  { title: 'Ghannouch' },
  { title: 'Metouia' },
  { title: 'Menzel habib' },
  { title: 'Hamma' },
  { title: 'Matmata' },
  { title: 'Matmata nouvelle' },
  { title: 'Mareth' },
  { title: 'Mednine Nord' },
  { title: 'Mednine Sud' },
  { title: 'Beni khedach' },
  { title: 'Ben Guerdene' },
  { title: 'Zarzis' },
  { title: 'Jerba Houmet Souk' },
  { title: 'Jerba Midoun' },
  { title: 'Jerba Ajim' },
  { title: 'Sidi Makhlouf' },
  { title: 'Gafsa Nord' },
  { title: 'Sidi Aich' },
  { title: 'El Ksar' },
  { title: 'Gafsa Sud' },
  { title: 'Moulares' },
  { title: 'Redyef' },
  { title: 'Metlaoui' },
  { title: 'El Mdhilla' },
  { title: 'El Guettar' },
  { title: 'Belkhir' },
  { title: 'Sned' },
  { title: 'Tozeur' },
  { title: 'Degueche' },
  { title: 'Tameghza' },
  { title: 'Nefta' },
  { title: 'Hezoua' },
  { title: 'Tataouine Nord' },
  { title: 'Tataouine Sud' },
  { title: 'Smar' },
  { title: 'Bir Lahmer' },
  { title: 'Ghomrassen' },
  { title: 'Dhehiba' },
  { title: 'Remada' },
  { title: 'Kebili Sud' },
  { title: 'Kebili Nord' },
  { title: 'Souk El Ahad' },
  { title: 'Douz nord' },
  { title: 'Douz sud' },
  { title: 'El Faouar' },
];

export const BilanList = [
  {
    id: 1,
    name: 'Traumatologie / Rhumato',
  },
  {
    id: 2,
    name: 'Bilans généraux',
  },
  {
    id: 3,
    name: 'Gériatrie',
  },
  {
    id: 4,
    name: 'Pédiatrie',
  },
  {
    id: 5,
    name: 'Uro-Gynécologie / Périnée',
  },
  {
    id: 6,
    name: 'Respiratoire',
  },
  {
    id: 7,
    name: 'Neurologie centrale',
  },
  {
    id: 8,
    name: 'Neurologie périphérique',
  },
  {
    id: 9,
    name: 'Pour les McKenzistes',
  },
  {
    id: 10,
    name: 'Thérapie manuelle',
  },
  {
    id: 11,
    name: 'Sport',
  },
  {
    id: 12,
    name: 'Lymphoedème',
  },
  {
    id: 13,
    name: 'Maxillo-facial',
  },
  {
    id: 14,
    name: 'Vestibulaire',
  },
];

export const codeBureauCNAM1 = [
  {
    id: 1,
    title: 'CNAM Djerba H.Souk',
    code: 71,
  },
  {
    id: 2,
    title: 'CNAM Djerba Midoun',
    code: 71,
  },
  {
    id: 3,
    title: 'CNAM Ben Guerden',
    code: 74,
  },
  {
    id: 4,
    title: 'CNAM Tataouin',
    code: 73,
  },
  {
    id: 5,
    title: 'CNAM Zarzis',
    code: 71,
  },
  {
    id: 6,
    title: 'CNAM Medenine',
    code: 71,
  },
  {
    id: 7,
    title: 'CNAM Metlawi',
    code: 71,
  },
  {
    id: 8,
    title: 'CNAM Gafsa Chabeb',
    code: 71,
  },
  {
    id: 9,
    title: 'CNAM Gafsa',
    code: 71,
  },
  {
    id: 10,
    title: 'CNAM Tozeur',
    code: 71,
  },
  {
    id: 11,
    title: 'CNAM Gebelli',
    code: 71,
  },
  {
    id: 12,
    title: 'CNAM Tunisie 1',
    code: 71,
  },
  {
    id: 13,
    title: 'CNAM Tunisie 2',
    code: 71,
  },
  {
    id: 14,
    title: 'CNAM Tunisie 3',
    code: 71,
  },
  {
    id: 15,
    title: 'CNAM Tunisie 4',
    code: 71,
  },
  {
    id: 16,
    title: 'CNAM La Marsa',
    code: 71,
  },
  {
    id: 17,
    title: 'CNAM khair eddine bacha',
    code: 71,
  },
  {
    id: 18,
    title: 'CNAM Bardo',
    code: 71,
  },
  {
    id: 19,
    title: 'CNAM Sidi hssin',
    code: 71,
  },
  {
    id: 20,
    title: 'CNAM Ariana',
    code: 71,
  },
  {
    id: 21,
    title: 'CNAM El menzah',
    code: 71,
  },
  {
    id: 22,
    title: 'CNAM Cité ettadhamen',
    code: 71,
  },
  {
    id: 23,
    title: 'CNAM Manouba',
    code: 71,
  },
];

export const codeBureauCNAM = {
  71: 'CNAM Djerba H.Souk',
  77: 'CNAM Djerba Midoun',
  74: 'CNAM Ben Guerden',
  73: 'CNAM Tataouin',
  72: 'CNAM Zarzis',
  70: 'CNAM Medenine',
  59: 'CNAM Metlawi',
  69: 'CNAM Gafsa Chabeb',
  58: 'CNAM Gafsa',
  61: 'CNAM Tozeur',
  64: 'CNAM Gebelli',
  81: 'CNAM Tunis 1',
  80: 'CNAM Tunis 2',
  85: 'CNAM Tunis 3',
  83: 'CNAM Tunis 4',
  82: 'CNAM La Marsa',
  86: 'CNAM khaireddine',
  84: 'CNAM Bardo',
  11: 'CNAM Sidi Hssin',
  10: 'CNAM Ariana',
  88: 'CNAM El Manzah',
  87: 'CNAM Cité Ettadhamen',
  12: 'CNAM Manouba',
  27: 'CNAM El jadida',
  13: 'CNAM Ben arous',
  14: 'CNAM Hammam Lenf',
  15: 'CNAM El mourouj',
  22: 'CNAM Bizerte',
  23: 'CNAM Menzel Bourguiba',
  26: 'CNAM Ras jebel',
  19: 'CNAM Zaghouan',
  75: 'CNAM El fahs',
  16: 'CNAM Nabeul',
  17: 'CNAM Slimen',
  18: 'CNAM Menzel Temim',
  30: 'CNAM Hammamet',
  8: 'CNAM Klibia',
  25: 'CNAM Beja',
  20: 'CNAM Mjez el beb',
  28: 'CNAM Jendouba',
  29: 'CNAM Bou salem',
  31: 'CNAM El kef',
  9: 'CNAM Tajerouine',
  34: 'CNAM Siliana',
  46: 'CNAM Sousse',
  36: 'CNAM Cité Erriadh',
  47: 'CNAM Hammam sousse',
  48: "CNAM M'saken",
  37: 'CNAM El kairouan',
  39: 'CNAM Bou hajla',
  38: 'CNAM haffouz',
  49: 'CNAM Monastir',
  50: 'CNAM Ksar hellal',
  44: 'CNAM Jammel',
  52: 'CNAM Mahdia',
  63: 'CNAM La chebba',
  45: 'CNAM El jem',
  55: 'CNAM Sfax ville',
  53: 'CNAM Sfax nord',
  62: 'CNAM Sfax ouest',
  56: 'CNAM Sakiet ezzit',
  65: 'CNAM Sakiet eddaier',
  60: 'CNAM Gremda',
  51: 'CNAM Jebeniana',
  57: 'CNAM Kerkennah',
  54: 'CNAM Mahres',
  40: 'CNAM kasserine',
  41: 'CNAM Sbitla',
  43: 'CNAM Sidi bouzid',
  67: 'CNAM Gabes',
  76: 'CNAM Mareth',
  68: 'CNAM Gabes sud',
  66: 'CNAM Hemma',
};

export const types = [
  { title: 'Congé payé' },
  { title: 'Sans solde' },
  { title: 'Maternité' },
  { title: 'Garde d’enfant' },
  { title: 'Autre ' },
];

export const sortCities = cities.sort((a, b) =>
  a.title > b.title ? 1 : b.title > a.title ? -1 : 0,
);

export const country = [{ title: 'Tunisie' }];

export const typeContract = [
  { title: 'CDI' },
  { title: 'CDD' },
  { title: 'SIVP' },
  { title: 'Karama' },
  { title: 'Autre' },
];

export const fonction = [
  { title: 'Formations' },
  { title: 'Déplacements' },
  { title: 'Autre' },
];

export const Motifs = [{ title: 'Femme de ménage' }, { title: 'Stagiaire ' }];

export const ModePaiement = [
  { title: 'Espèce' },
  { title: 'Chèque' },
  { title: 'Virement' },
];

export const gouvernorat = [
  { title: 'Ariana ' },
  { title: 'Beja ' },
  { title: 'Ben Arous ' },
  { title: 'Benzart ' },
  { title: 'Gabes ' },
  { title: 'Gafsa ' },
  { title: 'Jendouba ' },
  { title: 'Kairoune ' },
  { title: 'Kasrine ' },
  { title: 'Kbeli ' },
  { title: 'Kef ' },
  { title: 'Mahdia ' },
  { title: 'Mannouba ' },
  { title: 'Medenine ' },
  { title: 'Monastir ' },
  { title: 'Nabeul ' },
  { title: 'Sfax ' },
  { title: 'Sidi-bouzid ' },
  { title: 'Siliana ' },
  { title: 'Sousse ' },
  { title: 'Tataoune ' },
  { title: 'Tozeur ' },
  { title: 'Tunis ' },
  { title: 'Zaghouane ' },
];
export const hoursAgenda = [
  {
    id: 1,
    from: '08:00',
    to: '08:30',
    S00: '00',
    S20: '20',
    S40: '40',
  },
  {
    id: 2,
    from: '08:30',
    to: '09:00',
    S00: '00',
    S20: '20',
    S40: '40',
  },
  {
    id: 3,
    from: '09:00',
    to: '09:30',
    S00: '00',
    S20: '20',
    S40: '40',
  },
  {
    id: 4,
    from: '09:30',
    to: '10:00',
    S00: '00',
    S20: '20',
    S40: '40',
  },
  {
    id: 5,
    from: '10:00',
    to: '10:30',
    S00: '00',
    S20: '20',
    S40: '40',
  },
  {
    id: 6,
    from: '10:30',
    to: '11:00',
    S00: '00',
    S20: '20',
    S40: '40',
  },
  {
    id: 7,
    from: '11:00',
    to: '11:30',
    S00: '00',
    S20: '20',
    S40: '40',
  },
  {
    id: 8,
    from: '11:30',
    to: '12:00',
    S00: '00',
    S20: '20',
    S40: '40',
  },
  {
    id: 9,
    from: '12:00',
    to: '12:30',
    S00: '00',
    S20: '20',
    S40: '40',
  },
  {
    id: 10,
    from: '12:30',
    to: '13:00',
    S00: '00',
    S20: '20',
    S40: '40',
  },
  {
    id: 11,
    from: '13:00',
    to: '13:30',
    S00: '00',
    S20: '20',
    S40: '40',
  },
  {
    id: 12,
    from: '13:30',
    to: '14:00',
    S00: '00',
    S20: '20',
    S40: '40',
  },

  {
    id: 13,
    from: '14:00',
    to: '14:30',
    S00: '00',
    S20: '20',
    S40: '40',
  },
  {
    id: 14,
    from: '14:30',
    to: '15:00',
    S00: '00',
    S20: '20',
    S40: '40',
  },
  {
    id: 15,
    from: '15:00',
    to: '15:30',
    S00: '00',
    S20: '20',
    S40: '40',
  },
  {
    id: 16,
    from: '15:30',
    to: '16:00',
    S00: '00',
    S20: '20',
    S40: '40',
  },
  {
    id: 17,
    from: '16:00',
    to: '16:30',
    S00: '00',
    S20: '20',
    S40: '40',
  },
  {
    id: 18,
    from: '16:30',
    to: '17:00',
    S00: '00',
    S20: '20',
    S40: '40',
  },
  {
    id: 19,
    from: '17:00',
    to: '17:30',
    S00: '00',
    S20: '20',
    S40: '40',
  },
  {
    id: 20,
    from: '17:30',
    to: '18:00',
    S00: '00',
    S20: '20',
    S40: '40',
  },
  {
    id: 21,
    from: '18:00',
    to: '18:30',
    S00: '00',
    S20: '20',
    S40: '40',
  },
  {
    id: 22,
    from: '18:30',
    to: '19:00',
    S00: '00',
    S20: '20',
    S40: '40',
  },
];

export const csv = (data) => {
  const listData = [];
  if (data && data.length > 0) {
    let total = 0;
    let i = 0;
    data.map((item) => {
      total += item.total_ttc;
      i++;
      listData.push({
        number: ` F° ${item.number}`,
        patient:
          item.patient_record && item.patient_record.patient
            ? `${item.patient_record.patient.first_name} ${item.patient_record.patient.last_name}`
            : '-',
        pec:
          item.prise_en_charge &&
          item.prise_en_charge.consultation &&
          item.prise_en_charge.consultation.patient_record &&
          item.prise_en_charge.consultation.patient_record.patient
            ? `${item.prise_en_charge.consultation.patient_record.patient.first_name} ${item.prise_en_charge.consultation.patient_record.patient.last_name}`
            : '-',
        start_date: formatDate(item.start_date),
        end_date: formatDate(item.end_date),
        created_at: formatDate(item.created_at),
        status: item.status === 1 ? 'Payé' : 'Non payé',
        total_ttc: parseFloat(item.total_ttc).toFixed(2),
      });
      if (i === data.length) {
        listData.push({
          total_ttc: parseFloat(total).toFixed(2),
        });
      }
    });
    return listData;
  }
  return listData;
};
