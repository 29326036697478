import React, { Component } from 'react';
import { SwipeableDrawer } from '@material-ui/core';
import moment from 'moment';
import { CircularProgress } from '@mui/material';
import { toast } from 'react-toastify';
import ToastAction from '../../components/ToastAction';
import { generateTimeSlots } from '../../utils/helpres';

class SwipeableDrawerCreate extends Component {
  render() {
    const {
      initialValues,
      params,
      hasError,
      isLoadingDuplicateRdv,
      employeeManagement,
      userList,
      absenceHours,
      switchDuplicateRDV,
      getAllRDV,
    } = this.props;
    const timeSlots = generateTimeSlots(30);

    return (
      <SwipeableDrawer
        style={{ width: '50%' }}
        anchor="right"
        open={switchDuplicateRDV}
        onClose={this.props.toggleDrawer('right', false)}
        onOpen={this.props.toggleDrawer('right', true)}
      >
        <div className="right_side_bar">
          <div className="right_side_bar_header">
            <span id="add_app_sidebar_title">Ajouter un nouveau rendez-vous</span>
            <svg
              onClick={() => {
                this.props.handleInitialStateRDV();
                this.props.handleSwitchList();
              }}
              style={{ cursor: 'pointer' }}
              id="close-right-sidebar"
              width="40"
              height="40"
              viewBox="0 0 40 40"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <rect
                x="0.5"
                y="0.5"
                width="39"
                height="39"
                rx="9.5"
                fill="white"
                stroke="#ECEEF5"
              />
              <path
                d="M25.2383 15.8164L21.0547 20L25.2383 24.1836L24.1836 25.2383L20 21.0547L15.8164 25.2383L14.7617 24.1836L18.9453 20L14.7617 15.8164L15.8164 14.7617L20 18.9453L24.1836 14.7617L25.2383 15.8164Z"
                fill="#8083A3"
              />
            </svg>
          </div>
          <div className="right_side_bar_body">
            <div className="right_sidebar_body" id="Motif">
              <div
                className={
                  hasError && !initialValues.doctor_id
                    ? 'right_sidebar_body_item type3'
                    : 'right_sidebar_body_item'
                }
              >
                <label>Collaborateur</label>
                <select
                  style={{ width: '100%' }}
                  value={initialValues.doctor_id}
                  onChange={this.props.handleChangeInput}
                  name="doctor_id"
                >
                  <option value="">Collaborateurs</option>
                  {employeeManagement.map((item) => {
                    return (
                      <option
                        key={item.id}
                        value={item.id}
                      >{`${item.first_name} ${item.last_name}`}</option>
                    );
                  })}
                </select>
              </div>
              <div className="right_sidebar_body_item">
                <label>Patient</label>
                <div className="input_and_icon">
                  <input
                    style={{ width: '100%' }}
                    value={initialValues.display_name_update}
                    name="date"
                    className="form-control"
                    disabled={true}
                    type="text"
                    placeholder="John Doe"
                  />
                </div>
              </div>
              <div className="right_sidebar_body_item">
                <label>Date</label>
                <div className="input_and_icon">
                  <input
                    style={{ width: '100%' }}
                    value={initialValues.date}
                    name="date"
                    onChange={(e) => {
                      const rdvs = getAllRDV.find(
                        (element) =>
                          element.patient_id === parseInt(initialValues.patient_id) &&
                          moment(element.start, 'YYYY-MM-DD').isSame(
                            moment(initialValues.date, 'YYYY-MM-DD'),
                          ),
                      );
                      if (rdvs) {
                        toast(
                          ({ closeToast }) => (
                            <ToastAction
                              type="warning"
                              message="Ce patient a déjà un RDV dans ce jour"
                            />
                          ),
                          { hideProgressBar: true },
                        );
                      }
                      this.props.handleChangeInput(e);
                    }}
                    className="form-control"
                    type="date"
                    placeholder="John Doe"
                  />
                </div>
              </div>
              <div
                style={{
                  display: 'flex',
                }}
              >
                <div
                  className="right_sidebar_body_item"
                  style={{ width: '-webkit-fill-available', marginRight: '1%' }}
                >
                  <span className="creneau_container_title">Heure début</span>
                  <div
                    className={
                      hasError &&
                      (!initialValues.startHours ||
                        (initialValues.startHours &&
                          initialValues.endHours &&
                          moment(
                            moment(
                              `${initialValues.date} ${initialValues.startHours}`,
                            ).format('YYYY-MM-DD HH:mm:ss'),
                          ).isSameOrAfter(
                            moment(
                              `${initialValues.date} ${initialValues.endHours}`,
                            ).format('YYYY-MM-DD HH:mm:ss'),
                          )))
                        ? 'creneau_container type3'
                        : 'creneau_container'
                    }
                    style={{ maxHeight: '300px', overflow: 'auto' }}
                  >
                    <div className="input_and_icon" style={{ width: '100%' }}>
                      <select
                        style={{ width: '100%' }}
                        value={initialValues.startHours}
                        name="startHours"
                        onChange={this.props.handleChangeInput}
                        className="form-control"
                      >
                        {timeSlots.map((time) => (
                          <option key={time} value={time}>
                            {time}
                          </option>
                        ))}
                      </select>
                    </div>
                  </div>
                </div>
                <div
                  className="right_sidebar_body_item"
                  style={{ width: '-webkit-fill-available', marginLeft: '1%' }}
                >
                  <span className="creneau_container_title">Heure fin</span>
                  <div
                    className={
                      hasError &&
                      initialValues.endHours &&
                      moment(
                        moment(
                          `${initialValues.date} ${initialValues.startHours}`,
                        ).format('YYYY-MM-DD HH:mm:ss'),
                      ).isAfter(
                        moment(`${initialValues.date} ${initialValues.endHours}`).format(
                          'YYYY-MM-DD HH:mm:ss',
                        ),
                      )
                        ? 'creneau_container type3'
                        : 'creneau_container'
                    }
                    style={{ maxHeight: '300px', overflow: 'auto' }}
                  >
                    <div className="input_and_icon" style={{ width: '100%' }}>
                      <select
                        style={{ width: '100%' }}
                        value={initialValues.endHours}
                        name="endHours"
                        onChange={this.props.handleChangeInput}
                        className="form-control"
                      >
                        {timeSlots.map((time) => (
                          <option key={time} value={time}>
                            {time}
                          </option>
                        ))}
                      </select>
                    </div>
                  </div>
                </div>
              </div>
              {absenceHours.length > 0 ? (
                <div className="right_sidebar_body_item">
                  <span style={{ color: 'red' }} className="creneau_container_title">
                    Collaborateur est absent
                  </span>
                </div>
              ) : (
                ''
              )}
            </div>
            <div className="right_sidebar_footer confirm_btn_container">
              <button
                onClick={async () => {
                  const rdvs = getAllRDV.find(
                    (element) =>
                      element.patient_id === parseInt(initialValues.patient_id) &&
                      moment(element.start, 'YYYY-MM-DD').isSame(
                        moment(initialValues.date, 'YYYY-MM-DD'),
                      ),
                  );
                  if (rdvs) {
                    toast(
                      ({ closeToast }) => (
                        <ToastAction
                          type="warning"
                          message="Ce patient a déjà un RDV dans ce jour"
                        />
                      ),
                      { hideProgressBar: true },
                    );
                  }
                  await this.props.duplicateRDVForm(
                    params,
                    initialValues,
                    userList.length > 1 ? userList : initialValues.doctor_id,
                  );
                  //this.props.changeActiveItemSideBarRDV(true);
                }}
                className="confirm_recap_info_btn"
              >
                {isLoadingDuplicateRdv ? (
                  <CircularProgress
                    style={{
                      marginLeft: '4%',
                      width: '35px',
                      height: '25px',
                      color: 'white',
                      animation: 'infinite',
                    }}
                  />
                ) : (
                  'Confirmer'
                )}
              </button>
            </div>
          </div>
        </div>
      </SwipeableDrawer>
    );
  }
}

export default SwipeableDrawerCreate;
