import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import moment from 'moment';
import InputField from '../../../components/inputField';
import EnhancedTable from './billsDatatable';
import { formatDate } from '../../../utils/helpres';
import { apiCall } from '../../../js/actions';
import DatePickerComponent from '../../../shared/datePickerComponent';
import { FORMAT_DATE_SERVER } from '../../../utils/constants';
import CircularProgress from '@material-ui/core/CircularProgress';
import { toast } from 'react-toastify';
import ToastAction from '../../../components/ToastAction';
const EmptyAsset = require('../../../assets/img/undraw_design_team_af2y.svg');

const CreateBordereauCnam = () => {
  const [number, setNumber] = useState('');
  const [bills, setBills] = useState([]);
  const [date, setDate] = useState(moment());
  const [isLoadingBills, setIsLoadingBills] = useState(true);
  const [isSubmittingBordereau, setIsSubmittingBordereau] = useState(false);

  const history = useHistory();

  const fetchLastBordereauNumber = async () => {
    try {
      const url = `/api/last/bordereau`;
      const { data } = await apiCall({
        method: 'get',
        url,
      });
      if (!!data.data) {
        setNumber(data.data);
      }
    } catch (error) {
      console.log('DEBUG :: fetchLastBordereauNumber :: Error :: ', error);
    }
  };

  const fetchBills = async () => {
    try {
      const url = `/api/bills/cnam`;
      const { data } = await apiCall({
        method: 'get',
        url,
      });
      if (!!data.data) {
        setBills(data.data);
      }
    } catch (error) {
      console.log('DEBUG :: fetchBills :: Error :: ', error);
    } finally {
      setIsLoadingBills(false);
    }
  };

  useEffect(() => {
    fetchLastBordereauNumber();
    fetchBills();
  }, []);

  const createBordereau = async (selectedBillsIds) => {
    try {
      setIsSubmittingBordereau(true);
      const values = {
        allBillsIds: selectedBillsIds,
        date: formatDate(date, FORMAT_DATE_SERVER),
        number: number,
      };
      const url = `/api/bordereau`;
      const { data } = await apiCall({
        method: 'post',
        url,
        data: values,
      });
      toast(
        ({ closeToast }) => (
          <ToastAction type="success" message="Bordereau CNAM créé avec succès" />
        ),
        { hideProgressBar: true },
      );
      history.push({
        pathname: '/module-cnam',
        state: {
          bCnam: true,
          bACT: false,
          bAutre: false,
        },
      });
    } catch (error) {
      console.log('DEBUG :: Error :: createBordereau :: ', error);
    } finally {
      setIsSubmittingBordereau(false);
    }
  };

  const getDataTableContent = () => {
    if (isLoadingBills) {
      return (
        <div
          style={{
            width: '100%',
            height: '100%',
            display: 'flex',
            alignItems: 'center',
          }}
        >
          <CircularProgress
            style={{
              width: '3rem',
              color: 'grey',
            }}
          />
        </div>
      );
    } else if (!bills || bills.length === 0) {
      return (
        <div className="container_changeable_content">
          <div className="patient_detailed_info_section">
            <div className="patient_detailed_info_section_body">
              <div className="empty_consultation_result_container">
                <div className="empty_consultation_result">
                  <img alt="Pas des factures" src={EmptyAsset} />
                  <span id="no_consultation">Pas de Factures !</span>
                  <span id="no_consultation_desc">
                    Essayez de créer des nouvelles factures. Si vous n'avez pas des cures,
                    commencez déja par en créer des nouvelles.
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      );
    } else {
      return (
        <EnhancedTable
          bills={bills}
          onCreateBordereau={createBordereau}
          isSubmittingBordereau={isSubmittingBordereau}
        />
      );
    }
  };

  return (
    <div className="main_content">
      <div className="facture_info_section_form">
        <div className="container_changeable_content">
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              padding: '1rem 5rem',
            }}
          >
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                padding: '0px 2rem',
                height: '3rem',
                backgroundColor: 'white',
                color: '#8083A3',
                border: '1px solid #8083A3',
                borderRadius: '10px',
                marginRight: '10rem',
                cursor: 'pointer',
              }}
              className="footer_btn"
              id="cancel"
              onClick={() => history.goBack()}
            >
              Retour
            </div>
            <div style={{ marginRight: '10rem' }}>
              <InputField
                width="20rem"
                className="form-group"
                type="text"
                placeholder="N° bordereau"
                label="N° bordereau"
                validInput="string"
                value={number}
                name="number"
                icon={<i className="fas fa-hashtag"></i>}
                onChange={(e) => setNumber(e.target.value)}
              />
            </div>
            <div style={{ width: '20rem' }}>
              <DatePickerComponent
                value={date}
                label={'Date bordereau'}
                onChange={setDate}
              />
            </div>
          </div>
          {getDataTableContent()}
        </div>
      </div>
    </div>
  );
};

export default CreateBordereauCnam;
