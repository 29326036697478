import React, { Component } from 'react';
import Modals from '../../../components/modal';
import moment from 'moment';
import { formatDate, isNumeric } from '../../../utils/helpres';
import InputField from '../../../components/inputField';
import { SwipeableDrawer } from '@material-ui/core';
import { toast } from 'react-toastify';
import ToastAction from '../../../components/ToastAction';
import Tooltip from '@mui/material/Tooltip';

class PrescriptionMedicale extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isOpen: false,
      type_pec: '',
      left: false,
      anchor: 'left',
      initialValues: {
        sessions_per_week: null,
        sessions_count: null,
        unit_price: '11.5',
        start_date: '',
        regime: '',
        type: 'pm',
      },
      errorDate: false,
      hasError: false,
    };
    this.submitCure = this.submitCure.bind(this);
    this.toggleDrawer = this.toggleDrawer.bind(this);
  }

  toggleDrawer = (anchor, open, id) => (event) => {
    if (
      event &&
      event.type === 'keydown' &&
      (event.key === 'Tab' || event.key === 'Shift')
    ) {
      return;
    }
    this.setState({
      isOpen: false,
      left: open,
      anchor: 'left',
    });
  };
  submitCure(initialValues) {
    const { consultation_id, pec } = this.props;
    if (
      !initialValues.start_date ||
      !initialValues.regime ||
      !initialValues.sessions_count ||
      (initialValues.sessions_per_week &&
        isNumeric(initialValues.sessions_per_week) &&
        initialValues.sessions_per_week > 6) ||
      (initialValues.sessions_per_week && !isNumeric(initialValues.sessions_per_week)) ||
      (initialValues.sessions_count &&
        isNumeric(initialValues.sessions_count) &&
        initialValues.sessions_count > 36) ||
      (initialValues.sessions_count && !isNumeric(initialValues.sessions_count))
    ) {
      this.setState({
        ...initialValues,
        hasError: true,
      });
    } else {
      if (
        initialValues.start_date &&
        moment().isAfter(moment(initialValues.start_date))
      ) {
        toast(
          ({ closeToast }) => (
            <ToastAction
              type="info"
              message="Attention ! Vous avez saisi une date de début dans le passé."
            />
          ),
          { hideProgressBar: true },
        );
      }
      initialValues.consultation_id = consultation_id;
      initialValues.id = consultation_id;
      initialValues.pecID = pec.id;
      this.props.submitFormCURE(initialValues);
    }
  }

  render() {
    const { pec, sessions, doctor, city, patientName, setIsOpenPM, consultation } =
      this.props;
    const { isOpen, errorDate, initialValues, hasError } = this.state;
    let nbrRdvCompleted = 0;
    if (sessions && sessions.length > 0) {
      const res = sessions.filter((item) => item.status === 'completed');
      nbrRdvCompleted = res.length;
    }
    return (
      <div
        key={pec.id}
        className="seance_prise_charge_container"
        style={{ paddingTop: '15px' }}
      >
        <div className="prise_en_charge_container">
          <div className="prise_en_charge_title">Prescription médicale</div>
          <div className="prise_en_charge_body">
            <div style={{ width: '80%' }} className="prise_en_charge_body_section1">
              <svg
                width="38"
                height="38"
                viewBox="0 0 38 38"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <rect
                  x="0.5"
                  y="0.5"
                  width="37"
                  height="37"
                  rx="9.5"
                  fill="white"
                  stroke="#ECEEF5"
                />
                <path
                  d="M17.4883 22.7617L23.5 16.75L22.4453 15.6953L17.4883 20.6172L15.5547 18.6836L14.5 19.7383L17.4883 22.7617ZM19 10.7383L25.75 13.7617V18.2617C25.75 20.3477 25.1055 22.2578 23.8164 23.9922C22.5273 25.7031 20.9219 26.793 19 27.2617C17.0781 26.793 15.4727 25.7031 14.1836 23.9922C12.8945 22.2578 12.25 20.3477 12.25 18.2617V13.7617L19 10.7383Z"
                  fill="#8083A3"
                />
              </svg>
              <div className="prise_en_charge_text">
                <span id="prise_en_charge_text_title">
                  {' '}
                  {doctor || 'Médecin non renseigné'}
                </span>
                <span id="prise_en_charge_text_desc">
                  {formatDate(consultation.start_date)}
                </span>
              </div>
            </div>
            <Tooltip title="Prescription médicale">
              <div
                onClick={() => {
                  this.props.openModal();
                }}
                style={{ cursor: 'pointer' }}
                className="more_icon_container"
              >
                <i
                  style={{
                    padding: '0',
                    border: '',
                    color: '',
                    borderRadius: '',
                    backgroundColor: 'white',
                  }}
                  className="fas fa-eye"
                  aria-hidden="true"
                ></i>
              </div>
            </Tooltip>
            <Tooltip title="Modifier prescription médicale">
              <div
                onClick={() => {
                  this.setState({
                    isOpen: true,
                    initialValues: {
                      ...initialValues,
                      id: pec.id,
                      sessions_per_week: consultation.sessions_per_week,
                      sessions_count: consultation.sessions_count,
                      unit_price: pec.unit_price,
                      regime: pec.regime,
                      start_date: formatDate(pec.start_date),
                    },
                  });
                }}
                style={{ cursor: 'pointer' }}
                className="more_icon_container"
              >
                <i
                  style={{
                    padding: '0',
                    border: '',
                    color: '',
                    borderRadius: '',
                    backgroundColor: 'white',
                  }}
                  className="fas fa-edit"
                  aria-hidden="true"
                ></i>
              </div>
            </Tooltip>
          </div>
        </div>
        <SwipeableDrawer
          style={{ width: '50%' }}
          anchor="right"
          open={isOpen}
          onClose={this.toggleDrawer('right', false)}
          onOpen={this.toggleDrawer('right', true)}
        >
          <div className="right_side_bar">
            <div className="right_side_bar_header">
              <span id="add_app_sidebar_title">Modifier la prescription médicale</span>
              <svg
                onClick={() => {
                  this.setState({
                    isOpen: false,
                    rdv: null,
                    hasError: false,
                    initialValues: {
                      sessions_per_week: null,
                      sessions_count: null,
                      unit_price: '11.5',
                      start_date: '',
                      regime: '',
                    },
                  });
                }}
                style={{ cursor: 'pointer' }}
                id="close-right-sidebar"
                width="40"
                height="40"
                viewBox="0 0 40 40"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <rect
                  x="0.5"
                  y="0.5"
                  width="39"
                  height="39"
                  rx="9.5"
                  fill="white"
                  stroke="#ECEEF5"
                />
                <path
                  d="M25.2383 15.8164L21.0547 20L25.2383 24.1836L24.1836 25.2383L20 21.0547L15.8164 25.2383L14.7617 24.1836L18.9453 20L14.7617 15.8164L15.8164 14.7617L20 18.9453L24.1836 14.7617L25.2383 15.8164Z"
                  fill="#8083A3"
                />
              </svg>
            </div>
            <div className="right_side_bar_body">
              <div className="form-row">
                <div style={{ gap: 10, width: '46%', display: 'flex' }}>
                  <InputField
                    width="30%"
                    className="view_item"
                    type="text"
                    placeholder="10"
                    label="Nbr de séances"
                    hasError={hasError}
                    value={initialValues.sessions_count || ''}
                    name="sessions_count"
                    icon={<i className="fas fa-pen"></i>}
                    validInput="integerSessionC"
                    onChange={(e) => {
                      this.setState({
                        initialValues: {
                          ...initialValues,
                          sessions_count: e.target.value,
                        },
                      });
                    }}
                  />
                  <InputField
                    maxLength={1}
                    width="30%"
                    className="view_item"
                    type="text"
                    label="S/semaine"
                    placeholder="S/semaine"
                    hasError={hasError}
                    value={initialValues.sessions_per_week || ''}
                    name="sessions_per_week"
                    icon={<i className="fas fa-pen"></i>}
                    validInput="integerNbrS"
                    onChange={(e) =>
                      this.setState({
                        initialValues: {
                          ...initialValues,
                          sessions_per_week: e.target.value,
                        },
                      })
                    }
                  />
                  <InputField
                    width="40%"
                    className="view_item"
                    type="text"
                    placeholder="12.00"
                    label="Cout unitaire (TTC)"
                    hasError={hasError}
                    value={initialValues.unit_price}
                    name="unit_price"
                    icon={<i className="fas fa-pen"></i>}
                    validInput="integer"
                    onChange={(e) =>
                      this.setState({
                        initialValues: {
                          ...initialValues,
                          unit_price: e.target.value,
                        },
                      })
                    }
                  />
                </div>
              </div>
              <br />
              <div className="form-row">
                <div style={{ width: '47%', display: 'flex' }}>
                  <InputField
                    width="100%"
                    className="view_item"
                    type="date"
                    placeholder="Date de début"
                    label="Date de début"
                    errorDate={errorDate}
                    hasError={hasError}
                    value={initialValues.start_date}
                    name="start_date"
                    icon=""
                    onChange={(e) =>
                      this.setState({
                        initialValues: {
                          ...initialValues,
                          start_date: e.target.value,
                        },
                      })
                    }
                  />
                </div>
                <div
                  className={
                    hasError && !initialValues.regime
                      ? 'right_sidebar_body_item type3'
                      : 'right_sidebar_body_item'
                  }
                  style={{ width: '30%', top: '10px' }}
                >
                  <label>Regime</label>
                  <div className="input_and_icon">
                    <select
                      style={{ width: '100%' }}
                      value={initialValues.regime}
                      name="regime"
                      onChange={(e) =>
                        this.setState({
                          initialValues: {
                            ...initialValues,
                            regime: e.target.value,
                          },
                        })
                      }
                    >
                      <option>Choissiez </option>
                      <option value="cnam">CNAM </option>
                      <option value="act"> Accident de travail </option>
                      <option value="autre">Autre</option>
                    </select>
                  </div>
                </div>
              </div>
              <br />
              <div className="footer_buttons">
                <button
                  style={{ marginRight: '3%', width: '285px' }}
                  onClick={() => {
                    this.submitCure(initialValues);
                  }}
                  type="button"
                  className="footer_btn"
                  id="update"
                >
                  Valider
                </button>
              </div>
            </div>
          </div>
        </SwipeableDrawer>
        <Modals
          setIsOpen={setIsOpenPM}
          openModal={this.props.openModal}
          data={
            <>
              <div
                className="modal-header"
                style={{
                  borderBottom: '1px solid #dee2e6',
                  padding: '1.5rem 0rem',
                  margin: '0px 20px',
                  alignItems: 'baseline',
                  marginLeft: '3%',
                  marginTop: '-30px',
                  width: '100%',
                  height: '50px',
                }}
              >
                <span>Prescription médicale</span>
                <button
                  type="button"
                  className="close"
                  data-dismiss="modal"
                  aria-label="Close"
                  onClick={() => this.props.openModal()}
                >
                  <span
                    aria-hidden="true"
                    style={{ marginRight: '30px', color: '#1A1C1D' }}
                  >
                    &times;
                  </span>
                </button>
              </div>
              <hr style={{ width: '95%' }} />
              <div className="modal-body" style={{ width: '500px' }}>
                <div className="fiche_patient_deatils_prev_container">
                  <div className="fiche_patient_deatils_prev_header">
                    <div className="fiche_patient_deatils_prev_header_general_info">
                      <div
                        style={{ width: '60px', height: '60px' }}
                        className="patient_contact_img_container"
                      >
                        <svg
                          width="20"
                          height="20"
                          viewBox="0 0 14 14"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M3.02734 9.35547C4.41016 8.79297 5.73438 8.51172 7 8.51172C8.26562 8.51172 9.57812 8.79297 10.9375 9.35547C12.3203 9.89453 13.0117 10.6094 13.0117 11.5V13.0117H0.988281V11.5C0.988281 10.6094 1.66797 9.89453 3.02734 9.35547ZM9.10938 6.12109C8.52344 6.70703 7.82031 7 7 7C6.17969 7 5.47656 6.70703 4.89062 6.12109C4.30469 5.53516 4.01172 4.83203 4.01172 4.01172C4.01172 3.19141 4.30469 2.48828 4.89062 1.90234C5.47656 1.29297 6.17969 0.988281 7 0.988281C7.82031 0.988281 8.52344 1.29297 9.10938 1.90234C9.69531 2.48828 9.98828 3.19141 9.98828 4.01172C9.98828 4.83203 9.69531 5.53516 9.10938 6.12109Z"
                            fill="#8083A3"
                          />
                        </svg>
                      </div>
                      <div className="fiche_patient_deatils_prev_header_text">
                        <span className="patient_name">{patientName}</span>
                        <span className="patient_city">{city}</span>
                      </div>
                    </div>
                    <div className="fiche_patient_deatils_prev_header_note">
                      <div className="note_label">
                        <span></span>
                      </div>
                      <div className="note_text">
                        <span></span>
                      </div>
                    </div>
                  </div>
                  <div className="fiche_patient_deatils_prev_body">
                    <div className="fiche_patient_deatils_container">
                      <div className="fiche_patient_deatils_item">
                        <div className="debut_label">
                          <span>À partir de </span>
                        </div>
                        <div className="debut_text">
                          <span>{formatDate(consultation.start_date)}</span>
                        </div>
                      </div>
                      <div className="fiche_patient_deatils_item">
                        <div className="ns_label">
                          <span>Type </span>
                        </div>
                        <div className="ns_text">
                          <span>
                            {pec.regime === 'cnam'
                              ? 'PEC CNAM'
                              : pec.regime === 'act'
                              ? 'PEC Accident de travail'
                              : 'Prescription médicale'}
                          </span>
                        </div>
                      </div>
                      <div className="fiche_patient_deatils_item">
                        <div className="ns_label">
                          <span>Nbr de séances </span>
                        </div>
                        <div className="ns_text">
                          <span>{consultation.sessions_count}</span>
                        </div>
                      </div>
                      <div className="fiche_patient_deatils_item">
                        <div className="ns_label">
                          <span>S/semaine</span>
                        </div>
                        <div className="ns_text">
                          <span>{consultation.sessions_per_week}</span>
                        </div>
                      </div>
                      <div className="fiche_patient_deatils_item">
                        <div className="ns_label">
                          <span>
                            {' '}
                            {pec.regime === 'cnam' ? 'Complément' : 'Tarif Séance'}
                          </span>
                        </div>
                        <div className="ns_text">
                          <span>
                            {pec.regime === 'cnam'
                              ? consultation.unit_price
                              : pec.unit_price}
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </>
          }
        />
      </div>
    );
  }
}
export default PrescriptionMedicale;
