import React, { useState, useEffect, Fragment } from 'react';
import {
  Page,
  Text,
  View,
  Document,
  StyleSheet,
  PDFViewer,
  Image,
} from '@react-pdf/renderer';
import moment from 'moment';
import IconKine from '../../assets/img/logo-kine.png';
import { formatDate } from '../../utils/helpres';
// Create styles
const newstyles = StyleSheet.create({
  page: {
    display: 'inline-grid',
    flexDirection: 'row',
    marginTop: 10,
  },
  PDFViewer: {
    width: '100%',
    height: '550px',
  },
  section: {
    marginLeft: 10,
    padding: 10,
  },
  sectionNameDoctor: {
    marginLeft: 10,
    marginRight: 90,
    padding: 10,
  },
  section2: {
    marginLeft: 10,
    padding: 10,
  },
  hr: {
    BorderTop: '1px bold red',
  },
  cabinet: {
    display: 'block',
    alignItems: 'center',
    justifyContent: 'center',
    marginBottom: 20,
  },
  sectionBill: {
    margin: 10,
    padding: 10,
    flexDirection: 'row',
  },
  detailBill: {
    margin: 10,
    padding: 10,
    flexDirection: 'row',
  },
  detailBillText: {
    color: '#070707',
    fontWeight: '1000',
    fontSize: 12,
    fontStyle: 'gras',
    //fontFamily: 'Georgia, serif',
    margin: 2,
  },
  detailBillText2: {
    color: '#070707',
    fontWeight: 'bolder',
    marginLeft: 10,
    padding: 10,
  },
  text: {
    color: '#5446db',
    fontWeight: 'bolder',
  },
  bill: {
    color: '#5446db',
    fontWeight: '500',
    marginLeft: '1%',
  },
  text1: {
    color: '#49494c',
    fontSize: 12,
    fontWeight: 'bolder',
    display: 'block',
    margin: 2,
    width: '150px',
  },
  images: {
    marginTop: 150,
    marginLeft: 20,
    // padding: 10,
    width: 50,
    height: 20,
  },
  sectionLogo: {
    padding: 10,
    justifyContent: 'center',
  },
});
function RecetteDepensePDF(props) {
  document.title = 'Recette et Depense';
  const depenses = props.location.state.depenses;
  const recettes = props.location.state.recettes;
  const paramsRecette = props.location.state.paramsRecette;
  //const pec = this.props.location.state.pec;
  const user = props.location.state.office;
  const [i, setI] = useState(0);
  const [totalR, setTotalR] = useState(0);
  const [totalD, setTotalD] = useState(0);
  const [data, setData] = useState([]);
  const [data2, setData2] = useState([]);
  const [dataD, setDataD] = useState([]);
  const [data2D, setData2D] = useState([]);

  useEffect(() => {
    let i = 0;
    let j = 0;
    let total1 = 0;
    let total2 = 0;
    props.changeActiveItemSideBar(9);
    props.changeNamePage('Recette et Depense');
    recettes &&
      recettes.length > 0 &&
      recettes.map((recette) => {
        setI(i + 1);
        i++;
        total1 = parseFloat(parseFloat(total1) + parseFloat(recette.price)).toFixed(2);
        if (i < 17)
          data.push({
            date: formatDate(recette.payment_date),
            label: recette.label,
            price: parseFloat(recette.price).toFixed(2),
          });
        else
          data2.push({
            date: formatDate(recette.payment_date),
            label: recette.label,
            price: parseFloat(recette.price).toFixed(2),
          });
      });
    depenses &&
      depenses.length > 0 &&
      depenses.map((depense) => {
        setI(i + 1);
        j++;

        total2 = parseFloat(parseFloat(total2) + parseFloat(depense.price)).toFixed(2);
        if (j < 17)
          dataD.push({
            date: formatDate(depense.payment_date),
            label: depense.label,
            price: parseFloat(depense.price).toFixed(2),
          });
        else
          data2D.push({
            date: formatDate(depense.payment_date),
            label: depense.label,
            price: parseFloat(depense.price).toFixed(2),
          });
      });
    setTotalD(total2);
    setTotalR(total1);
    setData(data);
    setData2(data2);
    setDataD(dataD);
    setData2D(data2D);
  }, [data, dataD, data2, data2D, totalR, totalD]);
  return (
    <div>
      <div
        onClick={() =>
          props.history.push({
            pathname: '/compta',
            //state: { consultation_id: consultation.id },
          })
        }
        className="bilan_details_header"
      >
        <div className="back_icon">
          <img src={require('../../assets/img/arrow1.png')} alt="" />
        </div>
        <span>Retour</span>
      </div>
      <Fragment>
        <PDFViewer style={newstyles.PDFViewer}>
          <Document orientation="landscape">
            <Page>
              <View style={newstyles.cabinet}>
                <View style={newstyles.sectionLogo}>
                  <Image
                    style={{
                      width: 60,
                      height: 60,
                    }}
                    src={IconKine}
                  />
                </View>
                <Text>ETAT DE CAISSE</Text>
              </View>
              <View
                style={{
                  marginLeft: 30,
                  marginRight: 40,
                  marginBottom: 10,
                  width: '100%',
                  flexDirection: 'row',
                }}
              >
                <Text style={{ fontSize: 12 }}>
                  du : {formatDate(paramsRecette.start_date)}
                  {'  '}
                </Text>
                <Text style={{ fontSize: 12 }}>
                  au : {formatDate(paramsRecette.end_date)}
                </Text>
              </View>
              <View
                style={{
                  marginLeft: 30,
                  marginRight: 40,
                  marginTop: 5,
                  width: '100%',
                  flexDirection: 'row',
                }}
              >
                <View style={{ width: '50%' }}>
                  <Text style={{ fontSize: 14, marginBottom: 10 }}>Recettes</Text>
                  {data.map((item) => {
                    return (
                      <View
                        style={{
                          width: 250,
                          // border: 1,
                          height: 35,
                          display: 'flex',
                          flexDirection: 'row',
                          // margin:5
                        }}
                      >
                        <View
                          style={{
                            width: 80,
                            border: 1,
                            height: 35,
                            alignItems: 'flex-start',
                            justifyContent: 'center',
                            textAlign: 'left',
                          }}
                        >
                          <Text style={{ padding: 5, fontSize: 12 }}>{item.date}</Text>
                        </View>
                        <View
                          style={{
                            width: 110,
                            border: 1,
                            height: 35,
                            alignItems: 'flex-start',
                            justifyContent: 'center',
                            textAlign: 'left',
                          }}
                        >
                          <Text style={{ padding: 5, fontSize: 12 }}>{item.label}</Text>
                        </View>
                        <View
                          style={{
                            width: 50,
                            border: 1,
                            height: 35,
                            alignItems: 'flex-start',
                            justifyContent: 'center',
                            textAlign: 'left',
                          }}
                        >
                          <Text style={{ padding: 5, fontSize: 12 }}>{item.price}</Text>
                        </View>
                      </View>
                    );
                  })}
                  {data2 && data2.length === 0 && data.length > 0 ? (
                    <View>
                      <View
                        style={{
                          width: 150,
                          marginTop: 30,
                          marginLeft: 120,
                          // border: 1,
                          height: 35,
                          display: 'flex',
                          flexDirection: 'row',
                          // margin:5
                        }}
                      >
                        <View
                          style={{
                            width: 50,
                            border: 1,
                            height: 35,
                            alignItems: 'flex-start',
                            justifyContent: 'center',
                            textAlign: 'left',
                          }}
                        >
                          <Text style={{ padding: 5, fontSize: 12 }}>Total</Text>
                        </View>
                        <View
                          style={{
                            width: 70,
                            border: 1,
                            height: 35,
                            alignItems: 'flex-start',
                            justifyContent: 'center',
                            textAlign: 'left',
                          }}
                        >
                          <Text style={{ padding: 5, fontSize: 12 }}>{totalR}</Text>
                        </View>
                      </View>
                      <View
                        style={{
                          width: 150,
                          marginTop: 30,
                          marginLeft: 120,
                          // border: 1,
                          height: 35,
                          display: 'flex',
                          flexDirection: 'row',
                          // margin:5
                        }}
                      >
                        <View
                          style={{
                            width: 80,
                            border: 1,
                            height: 35,
                            alignItems: 'flex-start',
                            justifyContent: 'center',
                            textAlign: 'left',
                          }}
                        >
                          <Text style={{ padding: 5, fontSize: 12 }}>Total Net</Text>
                        </View>
                        <View
                          style={{
                            width: 70,
                            border: 1,
                            height: 35,
                            alignItems: 'flex-start',
                            justifyContent: 'center',
                            textAlign: 'left',
                          }}
                        >
                          <Text style={{ padding: 5, fontSize: 12 }}>
                            <Text style={{ padding: 5, fontSize: 12 }}>
                              {parseFloat(
                                parseFloat(totalR) - parseFloat(totalD),
                              ).toFixed(2)}
                            </Text>
                          </Text>
                        </View>
                      </View>
                    </View>
                  ) : (
                    <Text></Text>
                  )}
                </View>
                <View style={{ width: '50%' }}>
                  <Text style={{ fontSize: 14, marginBottom: 10 }}>Depenses</Text>
                  {dataD.map((item) => {
                    return (
                      <View
                        style={{
                          width: 250,
                          // border: 1,
                          height: 35,
                          display: 'flex',
                          flexDirection: 'row',
                          // margin:5
                        }}
                      >
                        <View
                          style={{
                            width: 80,
                            border: 1,
                            height: 35,
                            alignItems: 'flex-start',
                            justifyContent: 'center',
                            textAlign: 'left',
                          }}
                        >
                          <Text style={{ padding: 5, fontSize: 12 }}>{item.date}</Text>
                        </View>
                        <View
                          style={{
                            width: 110,
                            border: 1,
                            height: 35,
                            alignItems: 'flex-start',
                            justifyContent: 'center',
                            textAlign: 'left',
                          }}
                        >
                          <Text style={{ padding: 5, fontSize: 12 }}>{item.label}</Text>
                        </View>
                        <View
                          style={{
                            width: 50,
                            border: 1,
                            height: 35,
                            alignItems: 'flex-start',
                            justifyContent: 'center',
                            textAlign: 'left',
                          }}
                        >
                          <Text style={{ padding: 5, fontSize: 12 }}>{item.price}</Text>
                        </View>
                      </View>
                    );
                  })}
                  {data2D && data2D.length === 0 && dataD.length > 0 ? (
                    <View
                      style={{
                        width: 150,
                        marginTop: 30,
                        marginLeft: 120,
                        // border: 1,
                        height: 35,
                        display: 'flex',
                        flexDirection: 'row',
                        // margin:5
                      }}
                    >
                      <View
                        style={{
                          width: 50,
                          border: 1,
                          height: 35,
                          alignItems: 'flex-start',
                          justifyContent: 'center',
                          textAlign: 'left',
                        }}
                      >
                        <Text style={{ padding: 5, fontSize: 12 }}>Total</Text>
                      </View>
                      <View
                        style={{
                          width: 50,
                          border: 1,
                          height: 35,
                          alignItems: 'flex-start',
                          justifyContent: 'center',
                          textAlign: 'left',
                        }}
                      >
                        <Text style={{ padding: 5, fontSize: 12 }}>{totalD}</Text>
                      </View>
                    </View>
                  ) : (
                    <Text></Text>
                  )}
                </View>
              </View>
              <View
                style={{
                  height: '40px',
                  position: 'absolute',
                  left: 0,
                  bottom: 0,
                  width: '100%',
                  color: 'white',
                  textAlign: 'center',
                }}
              >
                <View
                  style={{
                    borderBottomColor: 'black',
                    marginLeft: 50,
                    marginRight: 60,
                    //marginTop: 350,
                    borderBottomWidth: 1,
                  }}
                />
                <View>
                  <Text
                    style={{
                      top: 10,
                      color: '#070707',
                      fontWeight: '1000',
                      fontSize: 10,
                      fontStyle: 'gras',
                      //fontFamily: 'Georgia, serif',
                      margin: 3,
                    }}
                  >
                    {user && user.address_practitioner
                      ? `${user.address_practitioner.street} ${user.address_practitioner.city} ${user.address_practitioner.zip_code}`
                      : '-'}{' '}
                    {user.phone ? `Tél/Fax: +216 ${user.phone} ` : '-'}
                    {user.gsm ? `GSM: +216 ${user.gsm} ` : '-'}{' '}
                    {user && user.address_office
                      ? `Adresse: ${user.address_office.street}, ${user.address_office.zip_code} ${user.address_office.city} ${user.address_office.country}`
                      : '-'}
                  </Text>
                </View>
              </View>
            </Page>
            {(data2 && data2.length > 0) || (data2D && data2D.length > 0) ? (
              <Page>
                <View style={newstyles.cabinet}>
                  <View style={newstyles.sectionLogo}>
                    <Image style={{ width: 60, height: 60 }} src={IconKine} />
                  </View>
                </View>
                <View
                  style={{
                    marginLeft: 30,
                    marginRight: 40,
                    marginTop: 3,
                    width: '100%',
                    flexDirection: 'row',
                  }}
                >
                  <View style={{ width: '50%' }}>
                    {data2.map((item) => {
                      return (
                        <View
                          style={{
                            width: 250,
                            // border: 1,
                            height: 35,
                            display: 'flex',
                            flexDirection: 'row',
                            // margin:5
                          }}
                        >
                          <View
                            style={{
                              width: 80,
                              border: 1,
                              height: 35,
                              alignItems: 'flex-start',
                              justifyContent: 'center',
                              textAlign: 'left',
                            }}
                          >
                            <Text style={{ padding: 5, fontSize: 12 }}>{item.date}</Text>
                          </View>
                          <View
                            style={{
                              width: 110,
                              border: 1,
                              height: 35,
                              alignItems: 'flex-start',
                              justifyContent: 'center',
                              textAlign: 'left',
                            }}
                          >
                            <Text style={{ padding: 5, fontSize: 12 }}>{item.label}</Text>
                          </View>
                          <View
                            style={{
                              width: 50,
                              border: 1,
                              height: 35,
                              alignItems: 'flex-start',
                              justifyContent: 'center',
                              textAlign: 'left',
                            }}
                          >
                            <Text style={{ padding: 5, fontSize: 12 }}>{item.price}</Text>
                          </View>
                        </View>
                      );
                    })}
                    <View
                      style={{
                        width: 150,
                        marginTop: 30,
                        marginLeft: 120,
                        // border: 1,
                        height: 35,
                        display: 'flex',
                        flexDirection: 'row',
                        // margin:5
                      }}
                    >
                      <View
                        style={{
                          width: 50,
                          border: 1,
                          height: 35,
                          alignItems: 'flex-start',
                          justifyContent: 'center',
                          textAlign: 'left',
                        }}
                      >
                        <Text style={{ padding: 5, fontSize: 12 }}>Total</Text>
                      </View>
                      <View
                        style={{
                          width: 70,
                          border: 1,
                          height: 35,
                          alignItems: 'flex-start',
                          justifyContent: 'center',
                          textAlign: 'left',
                        }}
                      >
                        <Text style={{ padding: 5, fontSize: 12 }}>{totalR}</Text>
                      </View>
                    </View>
                    <View
                      style={{
                        width: 150,
                        marginTop: 30,
                        marginLeft: 120,
                        // border: 1,
                        height: 35,
                        display: 'flex',
                        flexDirection: 'row',
                        // margin:5
                      }}
                    >
                      <View
                        style={{
                          width: 80,
                          border: 1,
                          height: 35,
                          alignItems: 'flex-start',
                          justifyContent: 'center',
                          textAlign: 'left',
                        }}
                      >
                        <Text style={{ padding: 5, fontSize: 12 }}>Total Net</Text>
                      </View>
                      <View
                        style={{
                          width: 70,
                          border: 1,
                          height: 35,
                          alignItems: 'flex-start',
                          justifyContent: 'center',
                          textAlign: 'left',
                        }}
                      >
                        <Text style={{ padding: 5, fontSize: 12 }}>
                          {parseFloat(parseFloat(totalR) - parseFloat(totalD)).toFixed(2)}
                        </Text>
                      </View>
                    </View>
                  </View>
                  <View style={{ width: '50%' }}>
                    {data2D &&
                      data2D.length > 0 &&
                      data2D.map((item) => {
                        return (
                          <View
                            style={{
                              width: 250,
                              // border: 1,
                              height: 35,
                              display: 'flex',
                              flexDirection: 'row',
                              // margin:5
                            }}
                          >
                            <View
                              style={{
                                width: 80,
                                border: 1,
                                height: 35,
                                alignItems: 'flex-start',
                                justifyContent: 'center',
                                textAlign: 'left',
                              }}
                            >
                              <Text style={{ padding: 5, fontSize: 12 }}>
                                {item.date}
                              </Text>
                            </View>
                            <View
                              style={{
                                width: 110,
                                border: 1,
                                height: 35,
                                alignItems: 'flex-start',
                                justifyContent: 'center',
                                textAlign: 'left',
                              }}
                            >
                              <Text style={{ padding: 5, fontSize: 12 }}>
                                {item.label}
                              </Text>
                            </View>
                            <View
                              style={{
                                width: 50,
                                border: 1,
                                height: 35,
                                alignItems: 'flex-start',
                                justifyContent: 'center',
                                textAlign: 'left',
                              }}
                            >
                              <Text style={{ padding: 5, fontSize: 12 }}>
                                {item.price}
                              </Text>
                            </View>
                          </View>
                        );
                      })}
                    {data2D && data2D.length > 0 ? (
                      <View
                        style={{
                          width: 150,
                          marginTop: 30,
                          marginLeft: 120,
                          // border: 1,
                          height: 35,
                          display: 'flex',
                          flexDirection: 'row',
                          // margin:5
                        }}
                      >
                        <View
                          style={{
                            width: 50,
                            border: 1,
                            height: 35,
                            alignItems: 'flex-start',
                            justifyContent: 'center',
                            textAlign: 'left',
                          }}
                        >
                          <Text style={{ padding: 5, fontSize: 12 }}>Total</Text>
                        </View>
                        <View
                          style={{
                            width: 50,
                            border: 1,
                            height: 35,
                            alignItems: 'flex-start',
                            justifyContent: 'center',
                            textAlign: 'left',
                          }}
                        >
                          <Text style={{ padding: 5, fontSize: 12 }}>{totalD}</Text>
                        </View>
                      </View>
                    ) : (
                      <Text></Text>
                    )}
                  </View>
                </View>
                <View
                  style={{
                    height: '40px',
                    position: 'absolute',
                    left: 0,
                    bottom: 0,
                    width: '100%',
                    color: 'white',
                    textAlign: 'center',
                  }}
                >
                  <View
                    style={{
                      borderBottomColor: 'black',
                      marginLeft: 50,
                      marginRight: 60,
                      //marginTop: 350,
                      borderBottomWidth: 1,
                    }}
                  />
                  <View>
                    <Text
                      style={{
                        top: 10,
                        color: '#070707',
                        fontWeight: '1000',
                        fontSize: 10,
                        fontStyle: 'gras',
                        //fontFamily: 'Georgia, serif',
                        margin: 3,
                      }}
                    >
                      {user && user.address_practitioner
                        ? `${user.address_practitioner.street} ${user.address_practitioner.city} ${user.address_practitioner.zip_code}`
                        : '-'}{' '}
                      {user.phone ? `Tél/Fax: +216 ${user.phone} ` : '-'}
                      {user.gsm ? `GSM: +216 ${user.gsm} ` : '-'}
                      {user && user.address_office
                        ? `Adresse: ${user.address_office.street}, ${user.address_office.zip_code} ${user.address_office.city} ${user.address_office.country}`
                        : '-'}
                    </Text>
                  </View>
                </View>
              </Page>
            ) : undefined}
          </Document>
        </PDFViewer>
      </Fragment>
    </div>
  );
}

export default RecetteDepensePDF;
