import React, { Component } from 'react';
import SettingMenuItemsProfil from '../../components/settingMenuItemsProfil';

import SimpleReactValidator from 'simple-react-validator';

import '../profile/Abonnement/index.css';
import '../profile/DiplomeFormation/index.css';
import '../profile/InfoGenerale/index.css';
import '../profile/Notifications/index.css';
import '../profile/Securities/index.css';
import '../profile/index.css';
import { isHasPermission } from '../../utils/helpres';

import Payment from '../payment';
import BordereauxCnam from './bordereau-cnam';
import BordereauxAct from './bordereau-act';
import FacturesCnam from './facture-cnam';
import FacturesAct from './facture-act';
import Pec from './pec';

class ModuleCnam extends Component {
  constructor(props) {
    super(props);

    props.changeNamePage('Module cnam');
    props.changeActiveItemSideBar(10);
    let actionR = props.location.state;
    this.state = {
      item: 0,
      initialValues: {
        ...props.initialValues,
        alert: false,
      },
      activeTab: '1',
      doctor: null,
      showCompta:
        actionR && (actionR.fCnam || actionR.fAct || actionR.bCnam || actionR.bACT)
          ? actionR.fCnam === false &&
            actionR.fAct === false &&
            actionR.bCnam === false &&
            actionR.bACT === false &&
            isHasPermission(props.user.login_access.permissions, 'ACCOUNTING_STAT_ACCESS')
            ? true
            : false
          : false,
      showBCnam:
        (!isHasPermission(
          props.user.login_access.permissions,
          'ACCOUNTING_STAT_ACCESS',
        ) &&
          isHasPermission(props.user.login_access.permissions, 'CNAM_SLIP_ACCESS')) ||
        (actionR && actionR.bCnam)
          ? true
          : false,
      showBACT: actionR && actionR.bACT ? true : false,
      showFCnam:
        (!isHasPermission(
          props.user.login_access.permissions,
          'ACCOUNTING_STAT_ACCESS',
        ) &&
          isHasPermission(props.user.login_access.permissions, 'BILLS_ACCESS')) ||
        (actionR && actionR.fCnam)
          ? true
          : false,
      showFACT: actionR && actionR.fAct ? true : false,
      showPec:
        (!isHasPermission(
          props.user.login_access.permissions,
          'ACCOUNTING_STAT_ACCESS',
        ) &&
          !isHasPermission(props.user.login_access.permissions, 'BILLS_ACCESS') &&
          !isHasPermission(props.user.login_access.permissions, 'CNAM_SLIP_ACCESS') &&
          isHasPermission(props.user.login_access.permissions, 'PEC_MANAG')) ||
        (actionR && actionR.Pec)
          ? false
          : true,
      file: null,
      isOpenToggleDiplome: false,
      isOpenToggleFormation: false,
      hasError: false,
    };

    this.validator = new SimpleReactValidator({
      messages: {
        email: 'Adresse e-mail invalide',
        // OR
        default: 'Cette information est requise.', // will override all messages
      },
    });
    this.HandelChangePage = this.HandelChangePage.bind(this);
    this.handleChangeFiles = this.handleChangeFiles.bind(this);
    this.goBack = this.goBack.bind(this);
    this.goTo = this.goTo.bind(this);
  }

  goBack() {
    this.props.history.goBack();
  }

  HandelChangePage(item) {
    switch (item) {
      case 0:
        this.setState({
          showCompta: false,
          showBCnam: false,
          showBACT: false,
          showFCnam: false,
          showFACT: false,
          showPec: true,
        });
        break;
      case 1:
        this.setState({
          showCompta: false,
          showBCnam: false,
          showBACT: false,
          showFCnam: true,
          showFACT: false,
          showPec: false,
        });
        break;
      case 2:
        this.setState({
          showCompta: false,
          showBCnam: true,
          showBACT: false,
          showFCnam: false,
          showFACT: false,
          showPec: false,
        });
        break;
      case 3:
        this.setState({
          showCompta: false,
          showBCnam: false,
          showBACT: false,
          showFCnam: false,
          showFACT: true,
          showPec: false,
        });
        break;
      case 4:
        this.setState({
          showCompta: false,
          showBCnam: false,
          showBACT: true,
          showFCnam: false,
          showFACT: false,
          showPec: false,
        });
        break;
      case 5:
        this.setState({
          showCompta: true,
          showBCnam: false,
          showBACT: false,
          showFCnam: false,
          showFACT: false,
          showPec: false,
        });
        break;
      default:
        this.setState({
          showCompta: false,
          showBCnam: false,
          showBACT: false,
          showFCnam: false,
          showFACT: false,
          showPec: true,
        });
    }
  }

  handleClick(fileUploader) {
    this.refs[fileUploader].click();
  }
  goTo(id) {
    this.props.history.push({
      pathname: `/add-facture/${id}`,
    });
  }
  handleChangeFiles = (event, name) => {
    const fileUploaded = event.target.files[0];
    this.setState({
      [name]: fileUploaded,
    });
  };

  render() {
    const { showPec, showCompta, showBCnam, showBACT, showFCnam, showFACT } = this.state;
    const { user, admin } = this.props;
    return (
      <div className="main_content" style={{ paddingLeft: 0 }}>
        <div className="user_info">
          <div className="settings_menu">
            {isHasPermission(user.login_access.permissions, 'PEC_MANAG') ? (
              <>
                <SettingMenuItemsProfil
                  item={0}
                  activate={showPec}
                  HandelChangePage={this.HandelChangePage}
                  imgColor={require('../../assets/img/facture_color.svg')}
                  img={require('../../assets/img/facture_grey.svg')}
                  name="Prise en charges"
                />
                <hr />
              </>
            ) : (
              ''
            )}
            {isHasPermission(user.login_access.permissions, 'BILLS_ACCESS') ? (
              <>
                <SettingMenuItemsProfil
                  item={1}
                  activate={showFCnam}
                  HandelChangePage={this.HandelChangePage}
                  imgColor={require('../../assets/img/facture_color.svg')}
                  img={require('../../assets/img/facture_grey.svg')}
                  name="Factures CNAM"
                  //description="Ajouter, modifier un assistant"
                />
                <hr />
              </>
            ) : (
              ''
            )}
            {isHasPermission(user.login_access.permissions, 'CNAM_SLIP_ACCESS') ? (
              <>
                <SettingMenuItemsProfil
                  item={2}
                  activate={showBCnam}
                  HandelChangePage={this.HandelChangePage}
                  imgColor={require('../../assets/img/icons8-bill-96 (1).png')}
                  img={require('../../assets/img/icons8-bill-96 (1).png')}
                  name="Bordereaux CNAM"
                  //description="Autres kinés dans le centre"
                />
                <hr />
              </>
            ) : (
              ''
            )}
            {isHasPermission(user.login_access.permissions, 'BILLS_ACCESS') ? (
              <>
                <SettingMenuItemsProfil
                  item={3}
                  activate={showFACT}
                  HandelChangePage={this.HandelChangePage}
                  imgColor={require('../../assets/img/facture_color.svg')}
                  img={require('../../assets/img/facture_grey.svg')}
                  name="Factures ACT"
                  //description="Ajouter, modifier une pathologie"
                />
                <hr />
              </>
            ) : (
              ''
            )}
            {isHasPermission(user.login_access.permissions, 'CNAM_SLIP_ACCESS') ? (
              <>
                <SettingMenuItemsProfil
                  item={4}
                  activate={showBACT}
                  HandelChangePage={this.HandelChangePage}
                  imgColor={require('../../assets/img/icons8-bill-96 (1).png')}
                  img={require('../../assets/img/icons8-bill-96 (1).png')}
                  name="Bordereaux ACT"
                  //description="Ajouter, modifier une secrétaire"
                />
                <hr />
              </>
            ) : (
              ''
            )}
            {isHasPermission(user.login_access.permissions, 'ACCOUNTING_STAT_ACCESS') ? (
              <>
                <SettingMenuItemsProfil
                  activate={showCompta}
                  item={5}
                  HandelChangePage={this.HandelChangePage}
                  icon=""
                  name="Module cnam en chiffres"
                  imgColor={require('../../assets/img/icons8-insert-card-96.png')}
                  img={require('../../assets/img/icons8-insert-card-96.png')}
                  //description="Détails proprietaire &amp; centre"
                />
                <hr />
              </>
            ) : (
              ''
            )}
          </div>
        </div>
        <div className="user_settings">
          <div className="container_changeable_content">
            {showCompta &&
            isHasPermission(user.login_access.permissions, 'ACCOUNTING_STAT_ACCESS') ? (
              <Payment
                admin={admin}
                type="cnam"
                changeActiveItemSideBar={this.props.changeActiveItemSideBar}
                changeNamePage={this.props.changeNamePage}
              />
            ) : showBCnam &&
              isHasPermission(user.login_access.permissions, 'CNAM_SLIP_ACCESS') ? (
              <BordereauxCnam
                changeActiveItemSideBarCompta={this.props.changeActiveItemSideBarCompta}
                type="cnam"
                changeActiveItemSideBar={this.props.changeActiveItemSideBar}
                changeNamePage={this.props.changeNamePage}
                changeActiveItemSideBarSettings={
                  this.props.changeActiveItemSideBarSettings
                }
              />
            ) : showBACT &&
              isHasPermission(user.login_access.permissions, 'CNAM_SLIP_ACCESS') ? (
              <BordereauxAct
                changeActiveItemSideBarCompta={this.props.changeActiveItemSideBarCompta}
                type="act"
                changeActiveItemSideBar={this.props.changeActiveItemSideBar}
                changeNamePage={this.props.changeNamePage}
                changeActiveItemSideBarSettings={
                  this.props.changeActiveItemSideBarSettings
                }
              />
            ) : showFCnam &&
              isHasPermission(user.login_access.permissions, 'BILLS_ACCESS') ? (
              <FacturesCnam
                selectedId={
                  this.props.location &&
                  this.props.location.state &&
                  this.props.location.state.facture_id
                    ? this.props.location.state.facture_id
                    : null
                }
                type="cnam"
                changeActiveItemSideBarCompta={this.props.changeActiveItemSideBarCompta}
                changeActiveItemSideBar={this.props.changeActiveItemSideBar}
                changeNamePage={this.props.changeNamePage}
                changeActiveItemSideBarSettings={
                  this.props.changeActiveItemSideBarSettings
                }
              />
            ) : showFACT &&
              isHasPermission(user.login_access.permissions, 'BILLS_ACCESS') ? (
              <FacturesAct
                selectedId={
                  this.props.location &&
                  this.props.location.state &&
                  this.props.location.state.facture_id
                    ? this.props.location.state.facture_id
                    : null
                }
                changeActiveItemSideBarCompta={this.props.changeActiveItemSideBarCompta}
                type="act"
                changeActiveItemSideBar={this.props.changeActiveItemSideBar}
                changeNamePage={this.props.changeNamePage}
                changeActiveItemSideBarSettings={
                  this.props.changeActiveItemSideBarSettings
                }
              />
            ) : showPec && isHasPermission(user.login_access.permissions, 'PEC_MANAG') ? (
              <Pec
                selectedId={
                  this.props.location &&
                  this.props.location.state &&
                  this.props.location.state.facture_id
                    ? this.props.location.state.facture_id
                    : null
                }
                changeActiveItemSideBarCompta={this.props.changeActiveItemSideBarCompta}
                type="act"
                changeActiveItemSideBar={this.props.changeActiveItemSideBar}
                changeNamePage={this.props.changeNamePage}
                goTo={this.goTo}
                changeActiveItemSideBarSettings={
                  this.props.changeActiveItemSideBarSettings
                }
              />
            ) : (
              ''
            )}
          </div>
        </div>
      </div>
    );
  }
}

export default ModuleCnam;
