import React, { Component } from 'react';
import moment from 'moment';
import { Link } from 'react-router-dom';
import { formatDate } from '../../../utils/helpres';
import { FORMAT_DATE_SERVER } from '../../../utils/constants';

class Consulatation extends Component {
  render() {
    const { item } = this.props;
    const month =
      moment(item.createdAt).format('MMMM').charAt(0).toUpperCase() +
      moment(item.createdAt).format('MMMM').substring(1).toLowerCase();
    return (
      <Link
        to={{
          pathname: `/consultations/${item.id}`,
          state: {
            fromPatient: true,
            phone: `${
              item.patient_record && item.patient_record.patient
                ? `${item.patient_record.patient.first_name} ${item.patient_record.patient.last_name}`
                : ''
            }`,
            date: formatDate(item.createdAt, FORMAT_DATE_SERVER),
            consultation_id: item.id,
          },
        }}
      >
        <div key={item.id} className="patient_consultation_preview_item">
          <div className="consultation_preview_sub_item" id="patient_consulation_timing">
            <i className="far fa-clock"></i>
            <div className="text_clock_cont">
              <span id="start_time">{moment(item.createdAt).format('HH:mm')}</span>
              <span id="end_time">
                {`${moment(item.createdAt).format('DD')}.
                            ${month.substring(0, 3)}`}
              </span>
            </div>
            <div className="patient_consultation_text">
              <span id="type_consultation">
                {item.motif && item.motif.label === 'Autre'
                  ? item.diagnostic
                  : item.motif.label}
              </span>
              <span id="patient_name">
                {`${item.patient_record.patient.last_name} ${item.patient_record.patient.first_name}`}
              </span>
            </div>
          </div>
          <div className="recent_patient_icon">
            <i className="fas fa-angle-right"></i>
          </div>
          {/*<div className="more_icon_container">
          <i
            style={{
              cursor: 'pointer',
            }}
            className="fas fa-ellipsis-h"
            data-toggle="dropdown"
            aria-haspopup="true"
            aria-expanded="false"
          ></i>

          <div
            style={{
              marginTop: '10px',
              width: '239px',
            }}
            className="dropdown-menu  dropdown-menu-right"
            aria-labelledby="dropdownMenuButton"
          >
            <div className="carre_bilan"></div>
            <div className="op_bilan">
              <Link
                to={{
                  pathname: '/consultations',
                  state: {
                    fromPatient: true,
                    phone: `${
                      item.patient_record && item.patient_record.patient
                        ? item.patient_record.patient.phone
                        : ''
                    }`,
                  },
                }}
              >
                <div
                  ///onClick={() => this.props.showFormPatient()}
                  className="op_item_bilan op_view"
                  data-toggle="modal"
                  data-target="#view_details"
                >
                  <i
                    style={{
                      padding: '0',
                      border: '',
                      color: '',
                      borderRadius: '',
                      backgroundColor: 'white',
                    }}
                    className="fas fa-eye"
                    aria-hidden="true"
                  ></i>
                  <span>Voir</span>
                </div>
              </Link>
            </div>
          </div>
        </div>*/}
        </div>
      </Link>
    );
  }
}
export default Consulatation;
