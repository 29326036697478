import React, { Component, useState, useEffect } from 'react';
import './index.css';
import actions from './store/actions';
import { connect } from 'react-redux';
import { SelectorContactFacturesBodyItem } from '../../utils';
import actionsJs, { apiCall } from '../../js/actions';
import actionsBill from '../facture/store/actions';
import TablePagination from '@mui/material/TablePagination';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Modals from '../../components/modal';
import { Link } from 'react-router-dom';
import moment from 'moment';
import BordereauPdf from './bordereau';
import {
  formatAmount,
  formatDate,
  getTranslatedBordereauStatus,
} from '../../utils/helpres';

const mapStateToProps = (state) => ({
  billsByIds: state.BordereauxReducer.billsByIds,
  addBordereau: state.BordereauxReducer.addBordereau,

  user: state.AppReducer.user,
  bordereau: state.BordereauxReducer.bordereau,
  bordereaux: state.BordereauxReducer.bordereaux,
  isLoadingBordereaux: state.BordereauxReducer.isLoadingBordereaux,
  isLoadingBordereau: state.BordereauxReducer.isLoadingBordereau,
  params: state.BordereauxReducer.params,
  office: state.AppReducer.office,
  bordereauDeleted: state.BordereauxReducer.bordereauDeleted,
});

const mapDispatchToProps = (dispatch) => ({
  getOfficeByIdDoctor: () => dispatch(actionsJs.getOfficeByIdDoctor()),
  getBordereauById: (id) => dispatch(actions.getBordereauById(id)),
  getAllBordereaux: (params, regime) =>
    dispatch(actions.getAllBordereaux(params, regime)),
  deleteBordereauBill: async (values, params, regime) => {
    await dispatch(actions.deleteBordereauBill(values));
    dispatch(actions.getBordereauById(values.bordereau_id));
    dispatch(actions.getAllBordereaux(params, regime));
  },
  getBillByIds: (ids) => dispatch(actions.getBillByIds(ids)),
  submitBordereau: async (params, regime, value) => {
    await dispatch(actions.submitBordereau(value));
    dispatch(actions.getAllBordereaux(params, regime));
  },
  submitUpdateBillForm: async (values, params, regime) => {
    await dispatch(actionsBill.submitUpdateBillForm(values));
    dispatch(actions.getAllBordereaux(params, regime));
  },
  changeSearched: async (params, regime) => {
    await dispatch(actions.changeSearchedStringBordereau(params));
    dispatch(actions.getAllBordereaux(params, regime));
  },
  changeLimit: async (params, regime) => {
    await dispatch(actions.changeSearchedStringBordereau(params));
    dispatch(actions.getAllBordereaux(params, regime));
  },
  changeSearchedStringLimit: async (params, regime) => {
    await dispatch(actions.changeSearchedStringBordereau(params));
    dispatch(actions.getAllBordereaux(params, regime));
  },
  changeSearchedStatus: async (params, regime) => {
    await dispatch(actions.changeSearchedStringBordereau(params));
    dispatch(actions.getAllBordereaux(params, regime));
  },
  changeActiveItemSideBarSettings: (item, data, create) =>
    dispatch(actionsJs.changeActiveItemSideBarSettings(item, data, create)),
  changeTag: (tag) => dispatch(actionsJs.changeTag(tag)),
  changeActiveItemSideBarCompta: (tag) =>
    dispatch(actionsJs.changeActiveItemSideBarCompta(tag)),

  submitDeleteBordereau: async (id, params, regime) => {
    await dispatch(actions.submitDeleteBordereau(id));
    dispatch(actions.getAllBordereaux(params, regime));
  },
});

function Row(props) {
  const { row, openModalDelete, office, user, type, openModalStatus, openModalEdit } =
    props;
  const [bills, setBills] = useState([]);

  useEffect(() => {
    async function fetchBills() {
      const billsResult = await getBills();
      setBills(billsResult);
    }

    fetchBills();
  }, []);

  async function getBills() {
    try {
      const url = `/api/bordereau/${row.id}`;
      const { data } = await apiCall({
        method: 'get',
        url,
      });
      if (!!data.data) {
        const bills = data.data.bills;
        return bills;
      }
    } catch (error) {
      console.log('🚀 ~ getBills ~ error:', error);
      return [];
    }
  }
  return (
    <React.Fragment>
      <TableRow sx={{ '& > *': { borderBottom: 'unset' } }}>
        <TableCell>{row.number}</TableCell>
        <TableCell align="right">{formatDate(row.date)}</TableCell>
        <TableCell align="right">
          {row.total_ttc && formatAmount(row.total_ttc)}
        </TableCell>
        <TableCell align="right">{getTranslatedBordereauStatus(row.status)}</TableCell>
        <TableCell align="right">
          <div style={{ display: 'flex', justifyContent: 'end' }}>
            <div
              style={{ margin: '0px 5px', cursor: 'pointer' }}
              className="more_icon_container"
              onClick={() => openModalEdit(row)}
            >
              <i
                style={{
                  padding: '0',
                  border: '',
                  color: 'grey',
                  borderRadius: '',
                  backgroundColor: 'white',
                }}
                className="fas fa-pen"
                aria-hidden="true"
                title="Modifier"
              ></i>
            </div>

            {type === 'cnam' || type === 'act' ? (
              <Link
                to="#"
                onClick={() => {
                  BordereauPdf(row, bills, office);
                }}
              >
                <div style={{ margin: '0px 5px' }} className="more_icon_container">
                  <i
                    style={{
                      padding: '0',
                      border: '',
                      color: 'green',
                      borderRadius: '',
                      backgroundColor: 'white',
                    }}
                    className="fas fa-download"
                    aria-hidden="true"
                    title="Télécharger"
                  ></i>
                </div>
              </Link>
            ) : (
              ''
            )}

            <Link
              to={{
                pathname:
                  type === 'autre'
                    ? '/facture/bordereau-pdf/autre'
                    : '/facture/bordereau-pdf',
                state: {
                  office: office,
                  type: type,
                  billsByIds: bills,
                  addBordereau: row,
                  doctor: {
                    cnss: user.cnss,
                    name_cabinet: user.name_cabinet,
                    speciality: user.speciality ? user.speciality.name : null,
                    conventional_code: user.conventional_code,
                    tax_identification: user.tax_identification,
                    rib: user.rib,
                    bank: user.bank,
                  },
                },
              }}
            >
              <div style={{ margin: '0px 5px' }} className="more_icon_container">
                <i
                  style={{
                    padding: '0',
                    border: '',
                    color: '#5446db',
                    borderRadius: '',
                    backgroundColor: 'white',
                  }}
                  className="fas fa-print"
                  aria-hidden="true"
                  title="Imprimer"
                ></i>
              </div>
            </Link>
            {row.status === 'invoiced' && type !== 'autre' ? (
              <div
                style={{ margin: '0px 5px', cursor: 'pointer' }}
                className="more_icon_container"
                onClick={() => openModalStatus(row.id)}
              >
                <i
                  style={{
                    padding: '0',
                    border: '',
                    color: 'gold',
                    borderRadius: '',
                    backgroundColor: 'white',
                  }}
                  className="fas fa-coins"
                  aria-hidden="true"
                  title="Marquer comme payé"
                ></i>
              </div>
            ) : (
              ''
            )}
            {row.status !== 'payed' && (
              <div
                style={{ margin: '0px 5px', cursor: 'pointer' }}
                className="more_icon_container"
                onClick={() => openModalDelete(row.id)}
              >
                <i
                  style={{
                    padding: '0',
                    border: '',
                    color: 'red',
                    borderRadius: '',
                    backgroundColor: 'white',
                  }}
                  className="fas fa-trash"
                  aria-hidden="true"
                  title="Supprimer"
                ></i>
              </div>
            )}
          </div>
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
}

class Bordereaux extends Component {
  constructor(props) {
    super(props);
    props.getOfficeByIdDoctor();
    props.changeActiveItemSideBarCompta(150);
    props.getAllBordereaux(props.params, props.type);
    props.changeTag(props.type || 'autre');
    props.changeNamePage(
      props.type === 'cnam'
        ? 'Bordereaux CNAM'
        : props.type === 'act'
        ? 'Bordereaux ACT'
        : 'Bordereaux',
    );
    document.title =
      props.type === 'cnam'
        ? 'Bordereaux CNAM'
        : props.type === 'act'
        ? 'Bordereaux ACT'
        : 'Bordereaux';
    this.state = {
      errorDate: false,
      setIsOpen: false,
      selectedBill: false,
      openModalEdit: false,
      selectedBordereau: null,
      bordereau: null,
      rowBordereau: null,
      number: '',
      setIsOpenModalStatus: false,
      setIsOpenDelete: false,
      selectedBillIds: [],
      payment_date: formatDate(moment(), 'YYYY-MM-DD'),
    };
    this.handleClickInputCheckbox = this.handleClickInputCheckbox.bind(this);
    this.openModal = this.openModal.bind(this);
    this.openModalDelete = this.openModalDelete.bind(this);
    this.openModalStatus = this.openModalStatus.bind(this);
    this.handleChangePage = this.handleChangePage.bind(this);
    this.openModalEdit = this.openModalEdit.bind(this);
    this.handleChangeRowsPerPage = this.handleChangeRowsPerPage.bind(this);
  }
  openModalStatus(id) {
    this.setState({
      setIsOpenModalStatus: !this.state.setIsOpenModalStatus,
      selectedBordereau: id || null,
    });
  }
  componentDidMount() {
    SelectorContactFacturesBodyItem();
    this.props.changeSearched(
      {
        limit: 10,
        currentPage: 1,
        search: '',
        status: '',
      },
      this.props.type,
    );
  }
  openModalEdit(row) {
    this.setState({
      rowBordereau: row,
      number: row && row.number,
      selectedBordereau: row && row.id,
      openModalEdit: !this.state.openModalEdit,
    });
  }
  openModal() {
    this.setState({
      setIsOpen: !this.state.setIsOpen,
    });
  }
  handleSelectedBill(selectedBill) {
    this.setState({
      selectedBill: !selectedBill,
    });
  }
  handleClickInputCheckbox(event, id) {
    const value = event.target.checked;
    let selectedBillIds = this.state.selectedBillIds;
    if (value) {
      selectedBillIds = [...selectedBillIds, id];
    } else {
      selectedBillIds = selectedBillIds.filter((item) => item !== id);
    }
    this.setState({
      selectedBillIds: selectedBillIds,
    });
  }
  openModalDelete(id) {
    this.setState({
      setIsOpenDelete: !this.state.setIsOpenDelete,
      selectedBordereau: id || null,
    });
  }
  handleChangePage(event, newPage) {
    this.props.changeLimit(
      { ...this.props.params, currentPage: newPage + 1 },
      this.props.type,
    );
  }

  handleChangeRowsPerPage(event) {
    this.props.changeLimit(
      { ...this.props.params, currentPage: 1, limit: parseInt(event.target.value, 10) },
      this.props.type,
    );
  }

  render() {
    const { params, bordereaux, user, office, type } = this.props;
    const {
      setIsOpenDelete,
      selectedBordereau,
      setIsOpenModalStatus,
      payment_date,
      openModalEdit,
      number,
    } = this.state;
    return (
      <>
        <div className="facture_patient_header">
          <p>Rechercher un bordereau</p>
          <div className="facture_patient_controls">
            <input
              style={{ width: '50%', marginLeft: '-5%' }}
              onChange={(event) => {
                this.props.changeSearched(
                  {
                    ...params,
                    search: event.target.value ? event.target.value : '',
                  },
                  type,
                );
              }}
              type="text"
              placeholder="Numéro bordereau"
            />
            <select
              style={{ width: '50%', margin: '0px 20px' }}
              id="inputState"
              name="status"
              value={params.status}
              onChange={(event) => {
                this.props.changeSearchedStatus(
                  {
                    ...params,
                    status: event.target.value !== 'Statut' ? event.target.value : null,
                  },
                  type,
                );
              }}
            >
              <option value={null}>Statut</option>
              <option value="invoiced">Facturé </option>
              <option value="payed">Payé </option>
            </select>
          </div>
        </div>
        <TableContainer
          component={Paper}
          sx={{
            width: '100% !important',
            height: 'auto !important',
          }}
        >
          <Table size="small" aria-label="collapsible table">
            <TableHead>
              <TableRow>
                <TableCell sx={{ fontWeight: 'bold' }}>Numéro bordereau</TableCell>
                <TableCell align="right">Date</TableCell>
                <TableCell align="right">Total TTC</TableCell>
                <TableCell align="right">Statut</TableCell>
                <TableCell align="right">Actions</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {bordereaux &&
                bordereaux.data &&
                bordereaux.data.length > 0 &&
                bordereaux.data.map((row) => (
                  <Row
                    key={row.name}
                    type={type}
                    office={office}
                    user={user}
                    row={row}
                    openModalDelete={this.openModalDelete}
                    openModalStatus={this.openModalStatus}
                    openModalEdit={this.openModalEdit}
                  />
                ))}
            </TableBody>
          </Table>
        </TableContainer>
        {bordereaux && bordereaux.total > 10 && (
          <TablePagination
            rowsPerPageOptions={[10, 25, 50, 100]}
            component="div"
            count={bordereaux.total}
            rowsPerPage={bordereaux.per_page}
            page={bordereaux.current_page - 1}
            onPageChange={this.handleChangePage}
            labelRowsPerPage="Lignes par page :"
            onRowsPerPageChange={this.handleChangeRowsPerPage}
          />
        )}
        <Modals
          setIsOpen={openModalEdit}
          openModal={this.openModalEdit}
          data={
            <>
              {' '}
              <div className="modal-header" style={{ marginTop: '0' }}>
                <button
                  type="button"
                  className="close"
                  data-dismiss="modal"
                  aria-label="Close"
                  onClick={() => this.openModalEdit(null)}
                >
                  <span
                    aria-hidden="true"
                    style={{ marginRight: '10px', color: '#1A1C1D' }}
                  >
                    &times;
                  </span>
                </button>
              </div>
              <div className="modal-body">
                <h2>Modification!</h2>
                <div className="modal_body_recap_details_item">
                  <div className="modal_body_recap_details_item_left">
                    <span>Numéro bordereau </span>
                  </div>
                  <div className="modal_body_recap_details_item_right">
                    <input
                      value={number}
                      onChange={(e) =>
                        this.setState({
                          number: e.target.value,
                        })
                      }
                      name="number"
                      className="form-control"
                      type="text"
                    />
                  </div>
                </div>
              </div>
              <div className="modal-footer">
                <button
                  style={{ backgroundColor: '#f6f6f6' }}
                  onClick={() => this.openModalEdit(null)}
                  type="button"
                  className="modal_close_btn"
                  // data-dismiss="modal"
                >
                  Non
                </button>
                <button
                  onClick={() => {
                    this.props.submitBordereau(params, type, {
                      id: selectedBordereau,
                      number: number,
                      type,
                    });
                    setTimeout(() => {
                      this.openModalEdit(null);
                    }, 1000);
                  }}
                  type="button"
                  className="modal_disconnect_btn"
                >
                  Oui
                </button>
              </div>
            </>
          }
        />
        <Modals
          setIsOpen={setIsOpenModalStatus}
          openModal={this.openModalStatus}
          data={
            <>
              {' '}
              <div className="modal-header" style={{ marginTop: '0' }}>
                <button
                  type="button"
                  className="close"
                  data-dismiss="modal"
                  aria-label="Close"
                  onClick={() => this.openModalStatus()}
                >
                  <span
                    aria-hidden="true"
                    style={{ marginRight: '10px', color: '#1A1C1D' }}
                  >
                    &times;
                  </span>
                </button>
              </div>
              <div className="modal-body">
                <h2>Confirmation!</h2>
                <span>
                  Voulez-vous vraiment marquer ce bordereau comme payé par la CNAM ?
                </span>
                <div className="modal_body_recap_details_item">
                  <div className="modal_body_recap_details_item_left">
                    <span>Date de paiment</span>
                  </div>
                  <div className="modal_body_recap_details_item_right">
                    <input
                      value={payment_date}
                      onChange={(e) =>
                        this.setState({
                          payment_date: e.target.value,
                        })
                      }
                      name="payment_date"
                      className="form-control"
                      type="date"
                    />
                  </div>
                </div>
              </div>
              <div className="modal-footer">
                <button
                  style={{ backgroundColor: '#f6f6f6' }}
                  onClick={() => this.openModalStatus()}
                  type="button"
                  className="modal_close_btn"
                  // data-dismiss="modal"
                >
                  Non
                </button>
                <button
                  onClick={() => {
                    this.props.submitBordereau(params, type, {
                      id: selectedBordereau,
                      status: 'payed',
                      payment_date: payment_date,
                      type,
                    });
                    this.openModalStatus();
                  }}
                  type="button"
                  className="modal_disconnect_btn"
                >
                  Oui
                </button>
              </div>
            </>
          }
        />
        <Modals
          setIsOpen={setIsOpenDelete}
          openModal={this.openModalDelete}
          data={
            <>
              {' '}
              <div className="modal-header" style={{ marginTop: '0' }}>
                <button
                  type="button"
                  className="close"
                  data-dismiss="modal"
                  aria-label="Close"
                  onClick={() => this.openModalDelete(null)}
                >
                  <span
                    aria-hidden="true"
                    style={{ marginRight: '10px', color: '#1A1C1D' }}
                  >
                    &times;
                  </span>
                </button>
              </div>
              <div className="modal-body">
                <h2>Suppression du bordereau !</h2>
                <span>Voulez-vous vraiment supprimer ce bordereau ?</span>
              </div>
              <div className="modal-footer">
                <button
                  style={{ backgroundColor: '#f6f6f6' }}
                  onClick={() => this.openModalDelete()}
                  type="button"
                  className="modal_close_btn"
                >
                  Non
                </button>
                <button
                  onClick={() => {
                    this.props.submitDeleteBordereau(selectedBordereau, params, 'cnam');
                    this.openModalDelete();
                  }}
                  type="button"
                  className="modal_disconnect_btn"
                >
                  Oui
                </button>
              </div>
            </>
          }
        />
      </>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Bordereaux);
