import React from 'react';

import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import interactionPlugin from '@fullcalendar/interaction';
import timeGridPlugin from '@fullcalendar/timegrid';
import resourceTimeGridPlugin from '@fullcalendar/resource-timegrid';
import frLocale from '@fullcalendar/core/locales/fr';
import update from 'immutability-helper';
import CircularProgress from '@material-ui/core/CircularProgress';
import { formatDate } from '../../utils/helpres';

//import '@fullcalendar/core/main.css';
import '@fullcalendar/daygrid/main.css';
import moment from 'moment';
import {
  newDataEmployeeManagement,
  newDataEmployeeManagementDraft,
} from '../../utils/helpres';
import actions from '../agenda/store/actions';
import actionsJs from '../../js/actions';
import actionsM from '../messenger/store/actions';
import { connect } from 'react-redux';
import resourceTimelinePlugin from '@fullcalendar/resource-timeline';
import consultationActions from '../consultations/store/actions';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import OutlinedInput from '@mui/material/OutlinedInput';
import MenuItem from '@mui/material/MenuItem';
import Checkbox from '@mui/material/Checkbox';
import ListItemText from '@mui/material/ListItemText';
import ToastAction from '../../components/ToastAction';
import { toast } from 'react-toastify';
import AgendaReducer from '../agenda/store/reducers';
import { FORMAT_DATE } from '../../utils/constants';

const mapStateToProps = (state) => ({
  isLoadingDuplicateRdv: state.AgendaReducer.isLoadingDuplicateRdv,
  duplicateRdv: state.AgendaReducer.duplicateRdv,
  DestroyUnavailability: state.AgendaReducer.deleteUnavailability,
  isLoadingGetAllUnavailability: state.AgendaReducer.isLoadingGetAllUnavailability,
  isLoadingGetAllRDV: state.AgendaReducer.isLoadingGetAllRDV,
  isLoadingAddRdv: state.AgendaReducer.isLoadingAddRdv,
  created: state.AgendaReducer.created,
  canceledRdv: state.AgendaReducer.canceledRdv,
  isLoadingAddDispo: state.AgendaReducer.isLoadingAddDispo,
  createdNewDispo: state.AgendaReducer.createdNewDispo,
  isLoadingDeleteRDV: state.AgendaReducer.isLoadingDeleteRDV,
  deleteRDV: state.AgendaReducer.deleteRDV,
  getListUnavailability: state.AgendaReducer.getAllUnavailability,
  motifs: state.AgendaReducer.motifs,
  getAllRDV: state.AgendaReducer.getAllRDV,
  params: state.AgendaReducer.params,
  paymentAction: state.AgendaReducer.paymentAction,
  paramsDoctor: state.MessengerReducer.params,
  isLoadingDoctors: state.MessengerReducer.isLoadingDoctors,
  doctors: state.MessengerReducer.doctors,
  getLastAvailabilityDate: state.AgendaReducer.getLastAvailabilityDate,
  employeeManagement: state.AppReducer.employeeManagement,
  isLoadingHolidayEmployeeManagement: state.AppReducer.isLoadingHolidayEmployeeManagement,
  paymentRdv: state.AppReducer.payment,
  user: state.AppReducer.user,
  isLoadingAbsenceHours: state.AppReducer.isLoadingAbsenceHours,
  absenceHours: state.AppReducer.absenceHours,
  activeItemSideBarRDV: state.AppReducer.activeItemSideBarRDV,
  addSessions: state.ConsultationReducer.addSessions,
  isLoadingAgendaManuel: state.AgendaReducer.isLoadingAgendaManuel,
  agendaManuel: state.AgendaReducer.agendaManuel,
});

const mapDispatchToProps = (dispatch) => ({
  submitAgendaManuel: (values) => {
    dispatch(consultationActions.submitAgendaManuel(values));
  },
  getLastAvailability: (id, doctor_id) =>
    dispatch(actions.getLastAvailability(id, doctor_id)),
  getDipoDoctorByMotif: (valuse) => dispatch(actions.getDipoDoctorByMotif(valuse)),
  getAllRdvByDay: (params, id) => dispatch(actions.getAllRdv(params, id)),
  getAbsensesByHours: (values, id) => dispatch(actionsJs.getAbsensesByHours(values, id)),
  getAllDoctors: (params) => dispatch(actionsM.getAllDoctors(params)),
  getMotif: () => dispatch(actions.getMotif(true)),
  submitRDVForm: async (params, values, id) => {
    await dispatch(actions.submitRDVForm(values));
    setTimeout(() => {
      dispatch(actions.getAllRdv(params, id));
    }, 1000);
  },
  duplicateRDVForm: async (params, values, id) => {
    await dispatch(actions.duplicateRDVForm(values));
    setTimeout(() => {
      dispatch(actions.getAllRdv(params, id));
    }, 1000);
  },
  submitPaymentForm: async (params, values, id) => {
    await dispatch(actions.submitPaymentForm(values));
    setTimeout(() => {
      dispatch(actions.getAllRdv(params, id));
    }, 1000);
  },
  deleteRdv: async (params, values, id) => {
    await dispatch(actions.deleteRdv(values));
    dispatch(actions.getAllRdv(params, id));
  },
  changeDate: async (params, id) => {
    await dispatch(actions.changeParams(params));
    //dispatch(actions.getAllUnavailability(params));
    dispatch(actions.getAllRdv(params, id));
  },
  changeStatusRdv: async (params, id) => {
    await dispatch(actions.changeParams(params));
    //dispatch(actions.getAllUnavailability(params));
    dispatch(actions.getAllRdv(params, id));
  },
  changeType: async (params, id) => {
    await dispatch(actions.changeParams(params));
    //dispatch(actions.getAllUnavailability(params));
    dispatch(actions.getAllRdv(params, id));
  },
  getEmployeeManagement: (type) => dispatch(actionsJs.getEmployeeManagement(type)),
  changeActiveItemSideBarSettings: (item, data, create) =>
    dispatch(actionsJs.changeActiveItemSideBarSettings(item, data, create)),
  changeActiveItemSideBarCompta: (data) =>
    dispatch(actionsJs.changeActiveItemSideBarCompta(data)),
});
const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};
const EventDetail = (eventInfo) => {
  let pathologie = '',
    pathologieColor = '#000000';
  try {
    pathologie =
      eventInfo.event._def.extendedProps.motif &&
      eventInfo.event._def.extendedProps.motif.label === 'Autre'
        ? eventInfo.event._def.extendedProps.diagnostic
        : eventInfo.event._def.extendedProps.motif.label;
    pathologieColor =
      eventInfo.event._def.extendedProps.motif &&
      eventInfo.event._def.extendedProps.motif.color;
  } catch (error) {
    console.error(error);
  }
  return eventInfo.event._def.extendedProps.type === 'rdv' ? (
    eventInfo.view.type === 'dayGridMonth' ? (
      <>
        <div
          className="fc-daygrid-event-dot"
          style={{ borderColor: eventInfo.borderColor, width: '2px', height: '2px' }}
        ></div>
        <div className="dot">
          {eventInfo.event._def.extendedProps.urlImg &&
          eventInfo.event._def.extendedProps.urlImg.includes('/') ? (
            <img
              style={{ borderRadius: '50%' }}
              width={30}
              height={30}
              src={`${process.env.REACT_APP_PUBLIC_URL}${eventInfo.event._def.extendedProps.urlImg}`}
            />
          ) : (
            <span style={{ verticalAlign: 'sub', fontWeight: 'bold' }}>
              {eventInfo.event._def.extendedProps.urlImg}
            </span>
          )}
        </div>
        <div className="fc-event-title">
          <div style={{ fontWeight: '400' }}>{eventInfo.event.title}</div>
          {eventInfo.event._def.extendedProps.type === 'rdv' ? (
            <div style={{ fontWeight: '400' }}>
              <span style={{ color: pathologieColor }}>
                {pathologie && pathologie.toString().slice(0, 15)}.
              </span>
              {' ' + moment(eventInfo.event.start).format('HH:mm')}
            </div>
          ) : (
            <div style={{ fontWeight: '400' }}>
              {moment(eventInfo.event.start).format('HH:mm') +
                ' - ' +
                moment(eventInfo.event.end).format('HH:mm')}
            </div>
          )}
        </div>
      </>
    ) : eventInfo.view.type === 'resourceTimeGridFourDay' ||
      eventInfo.view.type === 'resourceTimeGrid7Day' ? (
      <>
        {/*<div
          className="fc-daygrid-event-dot"
          style={{ borderColor: eventInfo.borderColor, width: '2px', height: '2px' }}
        ></div>*/}
        <div className="fc-event-title">
          <div style={{ fontWeight: 'bold', color: 'black' }}>
            {eventInfo.event.title}
          </div>
          <div style={{ fontWeight: '400', color: 'black' }}>
            {moment(eventInfo.event.start).format('HH:mm')}
          </div>
        </div>
      </>
    ) : (
      <>
        {/*<div
          className="fc-daygrid-event-dot"
          style={{ borderColor: eventInfo.borderColor, width: '2px', height: '2px' }}
        ></div>*/}
        <div className="dot">
          {eventInfo.event._def.extendedProps.urlImg &&
          eventInfo.event._def.extendedProps.urlImg.includes('/') ? (
            <img
              style={{ borderRadius: '50%' }}
              width={30}
              height={30}
              src={`${process.env.REACT_APP_PUBLIC_URL}${eventInfo.event._def.extendedProps.urlImg}`}
            />
          ) : (
            <span style={{ verticalAlign: 'sub', fontWeight: 'bold' }}>
              {eventInfo.event._def.extendedProps.urlImg}
            </span>
          )}
        </div>
        <div className="fc-event-title">
          <div style={{ fontWeight: 'bold', color: 'black' }}>
            {eventInfo.event.title}
            <span style={{ color: 'black', fontWeight: '400' }}>
              {'   ' +
                moment(eventInfo.event.start).format('HH:mm') +
                ' - ' +
                moment(eventInfo.event.end).format('HH:mm')}
            </span>
          </div>
          <div style={{ fontWeight: '400' }}>
            <span style={{ color: pathologieColor }}>
              {pathologie && pathologie.toString()}
            </span>
          </div>
        </div>
      </>
    )
  ) : eventInfo.event._def.extendedProps.type === 'session' ? (
    <div
      className="sessionDisplay"
      style={{
        display: 'flex',
        alignItems: 'center',
        paddingLeft: '10px',
        backgroundColor: 'orange',
        width: '-webkit-fill-available',
        borderRadius: '5px',
        color: 'black',
      }}
    >
      <div className="fc-event-title">
        <div style={{ fontWeight: 'bold' }}>{eventInfo.event.title}</div>
        <div style={{ fontWeight: '400' }}>
          {moment(eventInfo.event.start).format('HH:mm') +
            ' - ' +
            moment(eventInfo.event.end).format('HH:mm')}
        </div>
      </div>
    </div>
  ) : (
    <div
      className="absenceDisplay"
      style={{
        display: 'flex',
        alignItems: 'center',
        paddingLeft: '10px',
        backgroundColor: 'gray',
        width: '-webkit-fill-available',
        borderRadius: '5px',
        border: '1px white',
        color: 'white',
      }}
    >
      <div className="dot">
        {eventInfo.event._def.extendedProps.urlImg &&
        eventInfo.event._def.extendedProps.urlImg.includes('/') ? (
          <img
            style={{ borderRadius: '50%' }}
            width={30}
            height={30}
            src={`${process.env.REACT_APP_PUBLIC_URL}${eventInfo.event._def.extendedProps.urlImg}`}
          />
        ) : (
          <span style={{ verticalAlign: 'sub', fontWeight: 'bold' }}>
            {eventInfo.event._def.extendedProps.urlImg}
          </span>
        )}
      </div>
      <div className="fc-event-title">
        <div style={{ fontWeight: 'bold' }}>{eventInfo.event.title}</div>
        <div style={{ fontWeight: '400' }}>
          {moment(eventInfo.event.start).format('HH:mm') +
            ' - ' +
            moment(eventInfo.event.end).format('HH:mm')}
        </div>
      </div>
    </div>
  );
};
class AgendaManuel extends React.Component {
  constructor(props) {
    super(props);
    props.changeActiveItemSideBarCompta(null);
    props.getEmployeeManagement(true);
    props.getAllDoctors(props.paramsDoctor);
    props.getAllRdvByDay(props.params, [
      props.location.state.initialValues.practitioner_id,
    ]);
    props.changeNamePage('Calendrier de prescription');
    props.changeActiveItemSideBar(100000);
    props.changeActiveItemSideBarSettings(111, false, true);
    this.state = {
      userListName: [],
      date: props.params.date,
      typeView: 'dayGridMonth',
      checked: false,
      initialValues: {
        ...props.location.state.initialValues,
        patient: props.location.state.patient,
        getDispo: [],
      },
    };
    this.handleChangeSelect = this.handleChangeSelect.bind(this);
    this.gotoPast = this.gotoPast.bind(this);
    this.handleChangeView = this.handleChangeView.bind(this);
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    const { addSessions } = this.props;
    if (addSessions !== prevProps.addSessions) {
      this.props.history.push({
        pathname: '/agenda',
      });
    }
  }

  componentWillReceiveProps(nextProps, nextContext) {
    const { checked } = this.state;
    const { employeeManagement, agendaManuel } = nextProps;
    if (agendaManuel !== this.props.agendaManuel) {
      this.props.history.push({
        pathname: '/agenda',
      });
    }
    if (employeeManagement && employeeManagement.length > 0 && !checked) {
      let userListName = [];
      employeeManagement.map((user) => {
        //if (user.id === parseInt(this.props.location.state.initialValues.practitioner_id))
        userListName.push(`${user.first_name} ${user.last_name}`);
      });
      this.setState({ userListName, checked: true });
    }
  }
  handleChangeSelect(event) {
    const {
      target: { value },
    } = event;
    const { employeeManagement } = this.props;
    let users = [];
    value.map((val) => {
      let user = employeeManagement.find(
        (item) => `${item.first_name} ${item.last_name}` === val,
      );
      users.push(user.id);
    });

    this.props.getAllRdvByDay(this.props.params, users);
    this.setState({
      userListName: typeof value === 'string' ? value.split(',') : value,
      userList: users,
      checked: true,
    });
  }
  handleChangeDate(id, practitioner_id, start, end) {
    const { initialValues } = this.state;
    this.setState((prevState) => {
      const data = [...prevState.initialValues.getDispo];
      var commentIndex = data.findIndex(function (c) {
        return c.week === id;
      });
      const updatedComment = update(data[commentIndex], {
        date: { $set: start },
        start: { $set: start },
        end: { $set: end },
        resourceIds: { $set: [practitioner_id] },
      });
      data[commentIndex] = updatedComment;

      this.setState({
        initialValues: {
          ...initialValues,
          getDispo: data,
        },
      });
    });
  }
  async submitFormSession(initialValues) {
    if (initialValues.sessions_count > initialValues.getDispo.length) {
      toast(
        ({ closeToast }) => (
          <ToastAction
            type="info"
            message="Vous n’avez pas encore pointé toutes les séances mentionnées dans le formulaire !"
          />
        ),
        { hideProgressBar: true },
      );
    } else if (initialValues.getDispo.length === 0) {
      if (
        initialValues.start_date &&
        moment().isAfter(moment(initialValues.start_date))
      ) {
        toast(
          ({ closeToast }) => (
            <ToastAction
              type="info"
              message="Vous n'avez pas encore pointé les séances !"
            />
          ),
          { hideProgressBar: true },
        );
      }
    } else await this.props.submitAgendaManuel(initialValues);
  }
  calendarComponentRef = React.createRef();
  gotoPast = (date) => {
    const { userList } = this.state;
    let calendarApi = this.calendarComponentRef.current.getApi();
    this.props.getAllRdvByDay({ ...this.props.params, date }, userList);
    this.setState({
      date,
    });
    calendarApi.gotoDate(date); // call a method on the Calendar object
  };
  handleChangeView(info) {
    this.setState({
      typeView: info.view.type,
    });
  }
  render() {
    const { initialValues, typeView, date, userList, userListName } = this.state;
    const {
      isLoadingAgendaManuel,
      account_type,
      admin,
      params,
      getAllRDV,
      employeeManagement,
    } = this.props;

    return (
      <div style={{ padding: '10px' }} className="main_content_dashboard">
        <section className="section_dashboard">
          <div className="demo-app-top">
            {account_type === 'admin' || account_type === 'secretary' ? (
              <FormControl sx={{ m: 1, width: 300 }}>
                <InputLabel id="demo-multiple-name-label">Collaborateurs</InputLabel>
                <Select
                  labelId="demo-multiple-checkbox-label"
                  id="demo-multiple-checkbox"
                  multiple
                  value={userListName}
                  onChange={this.handleChangeSelect}
                  input={<OutlinedInput label="Collaborateurs" />}
                  MenuProps={MenuProps}
                  renderValue={(selected) => selected.join(', ')}
                >
                  {employeeManagement.map((employee) => (
                    <MenuItem
                      key={employee.id}
                      value={`${employee.first_name} ${employee.last_name}`}
                    >
                      <Checkbox
                        checked={
                          userListName.indexOf(
                            `${employee.first_name} ${employee.last_name}`,
                          ) > -1
                        }
                      />
                      <ListItemText
                        primary={`${employee.first_name} ${employee.last_name}`}
                      />
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            ) : (
              ''
            )}
            <input
              style={{
                width: '20%',
                marginRight: '1%',
              }}
              id="date"
              type="date"
              onChange={(e) => {
                this.gotoPast(formatDate(e.target.value, 'YYYY-MM-DD'));
              }}
            />
            <select
              onChange={(e) => {
                this.props.changeType(
                  {
                    ...params,
                    type: e.target.value,
                  },
                  userList,
                );
              }}
              style={{
                width: '20%',
                marginRight: '1%',
                //borderBottom: errorDate ? '1px solid #ff808b' : '',
              }}
            >
              <option value="all">Choisissez</option>
              <option value="rdv">Rendez-vous</option>
              <option value="absence">Absence</option>
            </select>
            <select
              style={{ borderRadius: '6px' }}
              onChange={(e) => {
                this.props.changeStatusRdv(
                  {
                    ...params,
                    status: e.target.value,
                  },
                  userList,
                );
              }}
              //className="form-control"
              name=""
              id=""
            >
              <option value="">Statut</option>
              <option value="not_confirmed">Non confirmé</option>
              <option value="actif">Planifié</option>
              <option value="completed">Terminé</option>
              <option value="canceled">Annulé</option>
            </select>

            <button
              type="button"
              className="btn_valid"
              style={{
                width: '115px',
                marginRight: 'auto',
                float: 'right',
              }}
              disabled={isLoadingAgendaManuel}
              onClick={() => {
                this.submitFormSession(initialValues, initialValues.patient);
              }}
            >
              {isLoadingAgendaManuel ? (
                <CircularProgress
                  style={{
                    marginLeft: '4%',
                    width: '25px',
                    height: '25px',
                    color: 'white',
                    marginTop: '5%',
                  }}
                />
              ) : (
                'Valider'
              )}
            </button>
          </div>
          <div className="demo-app-calendar">
            <FullCalendar
              viewClassNames={this.handleChangeView}
              headerToolbar={{
                left: 'prev,next today',
                center: 'title',
                right: 'resourceTimeGridFourDay,resourceTimeGridDay',
              }}
              customButtons={{
                today: {
                  text: "Aujourd'hui",
                  click: async () => {
                    this.gotoPast(formatDate(moment(), 'YYYY-MM-DD'));
                  },
                },
                prev: {
                  click: async () => {
                    if (typeView === 'resourceTimeGridFourDay')
                      this.gotoPast(moment(date).add(-1, 'weeks').format('YYYY-MM-DD'));
                    if (typeView === 'resourceTimeGridDay')
                      this.gotoPast(moment(date).add(-1, 'days').format('YYYY-MM-DD'));
                  },
                },
                next: {
                  click: () => {
                    if (typeView === 'dayGridMonth')
                      this.gotoPast(moment(date).add(1, 'months').format('YYYY-MM-DD'));
                    if (typeView === 'resourceTimeGridFourDay')
                      this.gotoPast(moment(date).add(1, 'weeks').format('YYYY-MM-DD'));
                    if (typeView === 'resourceTimeGridDay')
                      this.gotoPast(moment(date).add(1, 'days').format('YYYY-MM-DD'));
                  },
                },
              }}
              titleFormat={function (date) {
                if (typeView === 'dayGridMonth')
                  return `${moment(date.date).format('MMMM')} ${moment(date.date).format(
                    'YYYY',
                  )}`;
                if (typeView === 'timeGridWeek' || typeView === 'resourceTimeGridFourDay')
                  return `De  ${moment(date.start).format('dddd')} ${moment(date.start)
                    .startOf('week')
                    .add(1, 'days')
                    .format('DD')} ${moment(date.date).format('MMMM')} à ${moment(
                    date.date,
                  )
                    .add(-3, 'days')
                    .format('dddd')} ${moment(date.date)
                    .endOf('week')
                    .add(-1, 'days')
                    .format('DD')} ${moment(date.end).format('MMMM')} ${moment(
                    date.end,
                  ).format('YYYY')}`;
                if (typeView === 'timeGridDay' || typeView === 'resourceTimeGridDay')
                  return `${moment(date.date).format('dddd')} ${moment(date.date).format(
                    'DD',
                  )} ${moment(date.date).format('MMMM')} ${moment(date.date).format(
                    'YYYY',
                  )}`;
              }}
              weekends={this.state.calendarWeekends}
              schedulerLicenseKey="GPL-My-Project-Is-Open-Source"
              ref={this.calendarComponentRef}
              defaultView="dayGridMonth"
              initialDate={formatDate(initialValues.start_date, 'YYYY-MM-DD')}
              // dateClick={this.handleDateClick}
              //displayEventTime={true}
              selectable={true}
              firstDay={1}
              slotMinTime="08:00:00"
              slotMaxTime="20:00:00"
              initialView="resourceTimeGridFourDay"
              resources={newDataEmployeeManagement(employeeManagement, userListName)}
              views={{
                resourceTimeGridFourDay: {
                  type: 'resourceTimeGridWeek',
                  //duration: { days: 7 },
                  buttonText: 'Semaine',
                  //startDays: 1,
                },
                resourceTimeGridDay: {
                  type: 'resourceTimeGridDay',
                  // duration: { days: 4, start: 1 },
                  buttonText: 'Jour',
                  startDays: 1,
                },
              }}
              plugins={[
                //CustomView,
                resourceTimelinePlugin,
                dayGridPlugin,
                interactionPlugin,
                timeGridPlugin,
                resourceTimeGridPlugin,
              ]}
              eventOrder={(a, b) => {
                var result = 0;
                if (a.sort < b.sort) result = -1;
                //event a should be listed first
                else if (a.sort > b.sort) result = 1; //event b should be listed first
                return result;
              }}
              dayMaxEventRows={true}
              eventContent={EventDetail}
              events={[...getAllRDV, ...initialValues.getDispo]}
              viewDidMount={(info) => {
                this.setState({ typeView: info.view.type });
              }}
              eventLimit={3}
              droppable={true}
              editable={false}
              eventClick={(event) => {
                this.setState((prevState) => {
                  let data = [...prevState.initialValues.getDispo];
                  data = data.filter(
                    (item) => item.id !== parseInt(event.event._def.publicId),
                  );

                  this.setState({
                    initialValues: {
                      ...initialValues,
                      getDispo: data,
                    },
                  });
                });
              }}
              select={(info) => {
                //if (typeView === 'resourceTimeGridFourDay' || typeView === 'timeGridDay')
                if (initialValues.sessions_count > initialValues.getDispo.length)
                  this.setState({
                    initialValues: {
                      ...initialValues,
                      getDispo: [
                        ...initialValues.getDispo,
                        {
                          id:
                            initialValues.getDispo.length > 0
                              ? initialValues.getDispo[initialValues.getDispo.length - 1]
                                  .id + 1
                              : 1,
                          sort: 0,
                          resourceIds: [parseInt(info.resource._resource.id)],
                          //week: crypto({ length: 2 }),
                          date: moment(info.startStr).format(`YYYY-MM-DD HH:mm:ss`),
                          start: moment(info.startStr).format(`YYYY-MM-DD HH:mm:ss`),
                          start_date: moment(info.startStr)
                            .tz('UTC')
                            .utc()
                            .format(`YYYY-MM-DD HH:mm:ss`),
                          end_date: initialValues.duration
                            ? moment(info.startStr)
                                .tz('UTC')
                                .utc()
                                .add(initialValues.duration, 'minutes')
                                .format('YYYY-MM-DD HH:mm:ss')
                            : moment(info.endStr)
                                .tz('UTC')
                                .utc()
                                .format(`YYYY-MM-DD HH:mm:ss`),
                          end: initialValues.duration
                            ? moment(info.startStr)
                                .add(initialValues.duration, 'minutes')
                                .format(`YYYY-MM-DD HH:mm:ss`)
                            : moment(info.endStr).format(`YYYY-MM-DD HH:mm:ss`),
                          title: initialValues.patient,
                          type: 'session',
                          color: 'yellow',
                          startHours: !info.allDay
                            ? moment(info.startStr).format('HH:mm:ss')
                            : '',
                          endHours: !info.allDay
                            ? moment(info.endStr).format('HH:mm:ss')
                            : '',
                        },
                      ],
                    },
                  });
                else
                  toast(
                    ({ closeToast }) => (
                      <ToastAction
                        type="info"
                        message="Vous avez pointé toutes les séances ! Merci de cliquer sur le bouton valider"
                      />
                    ),
                    { hideProgressBar: true },
                  );
              }}
              locale={frLocale}
              // timeZone="UTC"
            />
          </div>
        </section>
      </div>
    );
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(AgendaManuel);
