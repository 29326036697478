import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import actions from './store/actions';
import { registerLocale } from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import fr from 'date-fns/locale/fr';
import './index.css';
import FormFacture from '../facture/add_facture';
import CircularProgress from '@material-ui/core/CircularProgress';
import actionsJs from '../../js/actions';

registerLocale('fr', fr);

const mapStateToProps = (state) => ({
  patientRecord: state.PatientReducer.patientRecord,
  isLoadingPatientRecord: state.PatientReducer.isLoadingPatientRecord,
  patients: state.PatientReducer.patients,
  patientRecords: state.PatientReducer.patientRecords,
  params: state.PatientReducer.params,
  user: state.AppReducer.user,
  isLoadingPatient: state.PatientReducer.isLoadingPatient,
  isLoadingPatientRecords: state.PatientReducer.isLoadingPatientRecords,
  isLoadingLast10Patients: state.DashboardReducer.isLoadingLast10Patients,
  last10Patient: state.DashboardReducer.last10Patient,
  updateBill: state.FactureReducer.updateBill,
  tag: state.AppReducer.tag,
});

const mapDispatchToProps = (dispatch) => ({
  getPatientRecordById: (id) => dispatch(actions.getPatientRecordById(id)),
  initialState: () => dispatch(actions.initialState()),
  changeActiveItemSideBarSettings: (item, data, create) =>
    dispatch(actionsJs.changeActiveItemSideBarSettings(item, data, create)),
  changeActiveItemSideBarCompta: (data) =>
    dispatch(actionsJs.changeActiveItemSideBarCompta(data)),
});

class Patients extends PureComponent {
  constructor(props) {
    super(props);
    props.changeActiveItemSideBarSettings(111, false, true);
    props.changeActiveItemSideBarCompta(null);
    props.initialState();
    props.getPatientRecordById(props.location.state.patient_id);
    document.title = 'Factures';
    props.changeActiveItemSideBar(
      props.location.state && props.location.state.active
        ? props.location.state.active
        : 9,
    );
    props.changeNamePage('Ajouter une facture');
    this.state = {
      defaultFilter: true,
      errorPhone: false,
      errorName: false,
      errorDate: false,
      patient: null,
      note: '',
      last_name: props.params.last_name,
      phone: props.params.phone,
    };
  }
  componentDidUpdate(prevProps, prevState, snapshot) {
    const { updateBill } = this.props;
    if (updateBill && updateBill !== prevProps.updateReceipt) {
      this.props.history.goBack();
    }
  }

  render() {
    const { patientRecord, isLoadingPatientRecord, sandwich_menu_btn } = this.props;
    return (
      <div className="main_content">
        {isLoadingPatientRecord ? (
          <CircularProgress
            disableShrink
            style={{ marginLeft: '50%', marginTop: '32%' }}
          />
        ) : (
          <div style={{ width: '100%' }} className="patient_info_section">
            <FormFacture
              goBack={
                () => this.props.history.goBack()
                /*this.props.history.push({
                  pathname:
                    patientRecord.patient.regime === 1 ||
                    patientRecord.patient.regime === 2
                      ? '/module-cnam'
                      : '/compta',
                  state: {
                    fCnam: patientRecord.patient.regime === 1 ? true : false,
                    fAct: patientRecord.patient.regime === 2 ? true : false,
                    fAutre: patientRecord.patient.regime === 3 ? true : false,
                  },
                })*/
              }
              patient={true}
              active={
                this.props.location &&
                this.props.location.state &&
                this.props.location.state.active
              }
              changeNamePage={this.props.changeNamePage}
              changeActiveItemSideBar={this.props.changeActiveItemSideBar}
              sandwich_menu_btn={sandwich_menu_btn}
              showFormFacture={this.showFormFacture}
              showPatient={this.showPatient}
              bill={{
                id: patientRecord.id,
                patient: {
                  id: patientRecord.id,
                  regime: patientRecord.patient.regime,
                  cnam: `${patientRecord.patient.cnam_root}/${patientRecord.patient.cnam_key}`,
                  displayName: `${patientRecord.patient.last_name} ${patientRecord.patient.first_name}`,
                },
              }}
            />
          </div>
        )}
      </div>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Patients);
