import React, { Component } from 'react';
import InputField from '../../../components/inputField';
import CircularProgress from '@material-ui/core/CircularProgress';
import SimpleReactValidator from 'simple-react-validator';
import { toast } from 'react-toastify';
import ToastAction from '../../../components/ToastAction';

class ForminitialValues extends Component {
  constructor(props) {
    super(props);
    this.validator = new SimpleReactValidator({
      messages: {
        required: 'Champ requis.',
        integer: 'Champ ne doit contenir que des chiffres',
        string: 'Champ ne doit contenir que des lettres',
        default: 'Cette information est requise.',
      },
    });
  }
  render() {
    const {
      employeeManagement,
      loadingGetDispoDoctor,
      patient,
      hasError,
      errorDate,
      user,
      admin,
      initialValues,
    } = this.props;
    return (
      <form>
        {user.login_access.account_type === 'assistant' ||
        user.login_access.account_type === 'secretary' ||
        admin ? (
          <>
            <div className="form-row">
              <div
                style={{ width: '30%' }}
                className={
                  hasError && !initialValues.practitioner_id
                    ? 'right_sidebar_body_item type3'
                    : 'right_sidebar_body_item'
                }
              >
                <label>Collaborateurs</label>
                <div className="input_and_icon">
                  <select
                    style={{ width: '100%' }}
                    value={initialValues.practitioner_id}
                    onChange={this.props.handleChangeInput}
                    name="practitioner_id"
                  >
                    <option value="">Collaborateurs</option>
                    {employeeManagement.map((item) => {
                      return (
                        <option
                          value={item.id}
                        >{`${item.last_name} ${item.first_name}`}</option>
                      );
                    })}
                  </select>
                </div>
              </div>
            </div>
            <br />
          </>
        ) : (
          ''
        )}

        <div className="form-row">
          <div style={{ width: '46%', display: 'flex' }}>
            <InputField
              width="30%"
              className="add_patient_form_item"
              type="text"
              placeholder="10"
              label="Nbr de séances"
              hasError={hasError}
              value={initialValues.sessions_count || ''}
              name="sessions_count"
              icon={<i className="fas fa-pen"></i>}
              validInput="integerSessionC"
              required={this.validator.message(
                'sessions_count',
                initialValues.sessions_count,
                'required',
              )}
              onChange={this.props.handleChangeInput}
            />
            <InputField
              maxLength={1}
              width="30%"
              className="add_patient_form_item"
              type="text"
              label="S/semaine"
              placeholder="S/semaine"
              hasError={hasError}
              value={initialValues.sessions_per_week || ''}
              name="sessions_per_week"
              icon={<i className="fas fa-pen"></i>}
              validInput="integerNbrS"
              required={this.validator.message(
                'sessions_per_week',
                initialValues.sessions_per_week,
                'required',
              )}
              onChange={this.props.handleChangeInput}
            />
            {initialValues.regime === 'act' ? (
              ''
            ) : (
              <InputField
                width="40%"
                className="add_patient_form_item"
                type="text"
                placeholder="12.00"
                label="Cout unitaire"
                hasError={hasError}
                value={initialValues.unit_price}
                name="unit_price"
                icon={<i className="fas fa-pen"></i>}
                validInput="integer"
                required={this.validator.message(
                  'unit_price',
                  initialValues.unit_price,
                  'required',
                )}
                onChange={this.props.handleChangeInput}
              />
            )}
          </div>
          <div
            className={
              hasError && !initialValues.regime
                ? 'right_sidebar_body_item type3'
                : 'right_sidebar_body_item'
            }
            style={{ width: '30%' }}
          >
            <label>Regime</label>
            <div className="input_and_icon">
              <select
                style={{ width: '100%' }}
                value={initialValues.regime}
                name="regime"
                onChange={(e) => {
                  this.props.handleChangeInput(e);
                }}
              >
                <option>Choissiez </option>
                <option value="cnam">CNAM </option>
                <option value="act"> Accident de travail </option>
                <option value="autre">Autre</option>
              </select>
            </div>
          </div>
        </div>
        <br />
        <div className="form-row">
          <div style={{ width: '47%', display: 'flex' }}>
            <InputField
              width="100%"
              className="add_patient_form_item"
              type="date"
              placeholder="Date de début"
              label="Date de début"
              errorDate={errorDate}
              hasError={hasError}
              value={initialValues.start_date}
              name="start_date"
              icon=""
              required={this.validator.message(
                'start_date',
                initialValues.start_date,
                'required',
              )}
              onChange={this.props.handleChangeInput}
            />
            {/*<InputField
              width="50%"
              className="add_patient_form_item"
              type="date"
              placeholder="Date de fin"
              label="Date de fin"
              disabled={true}
              value={initialValues.end_date}
              icon=""
            />*/}
          </div>
          <div style={{ width: '46%', display: 'flex' }}>
            <InputField
              width="30%"
              className="add_patient_form_item"
              type="time"
              placeholder="10"
              label="Heur de début"
              //hasError={hasError}
              value={initialValues.hourStart || ''}
              name="hourStart"
              //icon={<i className="fas fa-pen"></i>}
              //validInput="integerSessionC"
              required={this.validator.message(
                'hourStart',
                initialValues.hourStart,
                'required',
              )}
              onChange={this.props.handleChangeInput}
            />
            <InputField
              maxLength={3}
              width="30%"
              className="add_patient_form_item"
              type="text"
              label="durée (en minutes)"
              placeholder="durée (en minutes)"
              //hasError={hasError}
              value={initialValues.duration || ''}
              name="duration"
              icon={<i className="fas fa-pen"></i>}
              validInput="integerDuration"
              required={this.validator.message(
                'duration',
                initialValues.duration,
                'required',
              )}
              onChange={this.props.handleChangeInput}
            />
          </div>
        </div>
        <br />
        {initialValues.regime === 'autre' ? (
          ''
        ) : (
          <div style={{ display: 'flex' }}>
            <span style={{ color: 'black', fontWeight: 'bold', marginRight: '2%' }}>
              Prise en charge
            </span>
            <div
              style={{ cursor: 'pointer' }}
              //className="active_btn"
              onClick={(e) => {
                if (patient.regime === 3)
                  toast(
                    ({ closeToast }) => (
                      <ToastAction
                        type="warning"
                        message="Merci de renseigner le numéro CNAM dans la fiche de ce patient"
                      />
                    ),
                    {
                      hideProgressBar: true,
                    },
                  );
                else this.props.handleCheckedPec(initialValues.pec === 1 ? 0 : 1);
              }}
              className={initialValues.pec ? 'active_btn' : 'deactive_btn'}
            >
              <div
                className={
                  initialValues.pec
                    ? 'deactive_btn_circle active_btn_circle'
                    : 'deactive_btn_circle'
                }
                // className="deactive_btn_circle active_btn_circle"
              ></div>
            </div>
          </div>
        )}
        {initialValues.pec && initialValues.regime && initialValues.regime !== 'autre' ? (
          <>
            <>
              <div className="form-row">
                <InputField
                  width="46%"
                  className="form-group"
                  type="text"
                  placeholder="N° Assuré"
                  label="N° Assuré"
                  validInput="string"
                  disabled={true}
                  value={
                    patient.cnam_root && patient.cnam_key
                      ? `${patient.cnam_root}/${patient.cnam_key}`
                      : 'N° Assuré non renseignée'
                  }
                  icon={<i className="fas fa-pen"></i>}
                />
                <div
                  style={{ width: '46%' }}
                  className={
                    hasError && !initialValues.quality ? 'form-group type3' : 'form-group'
                  }
                >
                  <label htmlFor="">Qualité</label>
                  <div className="input_and_icon">
                    <select
                      value={initialValues.quality}
                      name="quality"
                      onChange={this.props.handleChangeInput}
                      className="form-control"
                      id="exampleFormControlSelect1"
                    >
                      <option>Qualité</option>
                      <option value="Assuré lui même">Assuré lui même</option>
                      <option value="Conjoint">Conjoint</option>
                      <option value="Enfant">Enfant</option>
                      <option value="Mére">Mère</option>
                      <option value="Père">Père</option>
                    </select>
                  </div>
                </div>
              </div>
              <div className="form_subtitle">
                <span>Décision</span>
              </div>
              <div className="form-row">
                <div style={{ width: '46%', display: 'flex' }}>
                  <InputField
                    width="20%"
                    className="add_patient_form_item"
                    type="text"
                    placeholder="XX"
                    label="Code bureau"
                    maxLength={2}
                    hasError={hasError}
                    value={initialValues.code_bureau || ''}
                    validInput="integerCnamKey"
                    name="code_bureau"
                    icon={<i className="fas fa-key"></i>}
                    onChange={this.props.handleChangeInput}
                  />
                  <InputField
                    width="30%"
                    className="add_patient_form_item"
                    type="text"
                    placeholder="XXXX"
                    label="Année"
                    maxLength={4}
                    hasError={hasError}
                    value={initialValues.year || ''}
                    validInput="integerYear"
                    name="year"
                    icon={<i className="fas fa-dot-circle"></i>}
                    onChange={this.props.handleChangeInput}
                  />
                  <InputField
                    width="50%"
                    className="add_patient_form_item"
                    type="text"
                    placeholder="XXXXXX"
                    label="N° ordre"
                    hasError={hasError}
                    value={initialValues.num_order || ''}
                    validInput="string"
                    name="num_order"
                    icon={<i className="fas fa-dot-circle"></i>}
                    onChange={this.props.handleChangeInput}
                  />
                </div>
                <InputField
                  width="46%"
                  className="add_patient_form_item"
                  type="text"
                  placeholder="Combiné automatiquement"
                  label="N° décision"
                  maxLength={10}
                  hasError={hasError}
                  value={
                    initialValues.num_order &&
                    initialValues.year &&
                    initialValues.code_bureau
                      ? `${initialValues.code_bureau}/${initialValues.year}/${initialValues.num_order}`
                      : ''
                  }
                  disabled={true}
                  icon={<i className="fas fa-dot-circle"></i>}
                />
              </div>
            </>
          </>
        ) : (
          ''
        )}
        <div className="footer_buttons">
          <button
            type="button"
            onClick={() => {
              this.props.goBack();
            }}
            className="footer_btn"
            id="cancel"
          >
            Annuler
          </button>
          <button
            style={{ marginRight: '3%', width: '285px' }}
            onClick={() => {
              this.props.submitPEC(initialValues);
            }}
            type="button"
            className="footer_btn"
            id="update"
          >
            {loadingGetDispoDoctor ? (
              <CircularProgress
                style={{
                  marginLeft: '4%',
                  width: '25px',
                  height: '25px',
                  color: 'white',
                  marginTop: '5%',
                }}
              />
            ) : (
              'Générer la mémoire des séances'
            )}
          </button>
        </div>
      </form>
    );
  }
}
export default ForminitialValues;
