import React, { Component } from 'react';
import actions from '../store/actions';
import SimpleReactValidator from 'simple-react-validator';
import { isNumeric, formatDate } from '../../../utils/helpres';
import { connect } from 'react-redux';
import InputField from '../../../components/inputField';
import moment from 'moment';
import { FORMAT_DATE_SERVER, codeBureauCNAM } from '../../../utils/constants';
import CircularProgress from '@material-ui/core/CircularProgress';

const mapStateToProps = (state, ownProps) => {
  const {
    bill,
    id,
    consultation,
    unit_price = 11.5,
    sessions_count,
    cnam,
    num_pec,
    code_bureau,
    numBill,
  } = ownProps;
  let start_date;
  let end_date;
  let patient_record_id;
  if (consultation) {
    patient_record_id = consultation.patient_record_id;
    start_date =
      consultation && consultation.sessions && consultation.sessions.length > 0
        ? moment(consultation.sessions[0].date).format('YYYY-MM-DD')
        : '';
    end_date =
      consultation && consultation.sessions && consultation.sessions.length > 0
        ? moment(consultation.sessions[consultation.sessions.length - 1].date).format(
            'YYYY-MM-DD',
          )
        : '';
  }
  return {
    sandwich_menu_btn: state.AppReducer.sandwich_menu_btn,
    isLoadingAddBill: state.FactureReducer.isLoadingAddBill,
    message: state.FactureReducer.message,
    error: state.FactureReducer.error,
    user: state.AppReducer.user,
    bill: bill,
    initialValues: {
      ...bill,
      type: bill && bill.patient ? 'patient_record' : 'pec',
      type_id: id,
      tag: 'receipt',
      number: numBill,
      cnam: cnam,
      patient_record_id,
      date: formatDate(moment(), FORMAT_DATE_SERVER),
      submitted_in: code_bureau,
      number_pec: num_pec,
      start_date: start_date,
      end_date: end_date,
      line_bill: {
        act: 'SEANCE DE KINESITHERAPIE',
        matricule: '',
        start_date: start_date,
        end_date: end_date,
        ht_amount: '',
        unit_price: unit_price || 11.5,
        sessions_nbr: sessions_count,
      },
    },
  };
};

const mapDispatchToProps = (dispatch) => ({
  submitReceiptForm: async (values, tag) => {
    await dispatch(actions.submitReceiptForm(values, tag));
  },
});

class FormFacture extends Component {
  constructor(props) {
    super(props);
    this.state = {
      initialValues: {
        ...props.initialValues,
        alert: false,
      },
      activeTab: '1',
      isOpenToggleDiplome: false,
      isOpenToggleFormation: false,
      errorDate: false,
      hasError: false,
      errorDateBill: false,
    };
    this.handleInitialValues = this.handleInitialValues.bind(this);

    this.validator = new SimpleReactValidator({
      messages: {
        email: 'Adresse e-mail invalide',
        required: 'Champ requis.',
        integer: 'Champ ne doit contenir que des chiffres',
        string: 'Champ ne doit contenir que des lettres',
        // OR
        default: 'Cette information est requise.', // will override all messages
      },
    });
  }
  handleInitialValues() {
    this.setState({
      initialValues: {
        submitted_in: '',
        line_bill: {
          act: '',
          matricule: '',
          ht_amount: '',
          unit_price: '',
          sessions_nbr: '',
        },
      },
      hasError: false,
      errorDateBill: false,
    });
  }
  submitForm(initialValues, numBill) {
    const { errorDate } = this.state;
    if (
      (initialValues.unit_price && !isNumeric(initialValues.unit_price)) ||
      (initialValues.sessions_nbr && !isNumeric(initialValues.sessions_nbr)) ||
      errorDate
    ) {
      this.setState({
        ...initialValues,
        hasError: true,
      });
    } else if (
      moment(initialValues.line_bill.start_date).isAfter(
        moment(initialValues.line_bill.end_date),
      )
    ) {
      this.setState({
        ...initialValues,
        errorDate: true,
      });
    } else if (moment(initialValues.date).isAfter(moment())) {
      this.setState({
        ...initialValues,
        errorDateBill: true,
      });
    } else if (this.validator.allValid()) {
      initialValues.number = numBill;
      this.props.submitReceiptForm(initialValues, 'receipt');
    } else {
      this.validator.showMessages();
      this.setState({
        ...initialValues,
        hasError: true,
      });
      // rerender to show messages for the first time
      // you can use the autoForceUpdate option to do this automatically`
      this.forceUpdate();
    }
  }

  render() {
    const { isLoadingLastBill, isLoadingAddBill, numBill, pec } = this.props;
    const { initialValues, hasError, errorDateBill, errorDate } = this.state;
    if (isLoadingLastBill) return <div>isLoadingLastBill</div>;
    return (
      <form>
        <div className="form-row">
          <InputField
            width="46%"
            className="form-group"
            type="text"
            placeholder="N° facture"
            label="N° facture"
            validInput="string"
            value={initialValues.number}
            icon={<i className="fas fa-pen"></i>}
            onChange={(e) => {
              this.setState({
                initialValues: {
                  ...initialValues,
                  number: e.target.value,
                },
              });
            }}
          />
          <InputField
            width="46%"
            className="form-group"
            type="date"
            placeholder="Date reçu"
            label="Date reçu"
            errorDate={initialValues.date && errorDateBill}
            hasError={hasError}
            value={initialValues.date}
            name="date"
            icon=""
            required={this.validator.message('date', initialValues.date, 'required')}
            onChange={(e) => {
              if (moment(e.target.value).isAfter(moment())) {
                this.setState({
                  errorDateBill: true,
                });
              }
              this.setState({
                initialValues: {
                  ...initialValues,
                  date: e.target.value,
                },
              });
            }}
          />
        </div>
        {initialValues.number_pec ? (
          <div className="form-row">
            <InputField
              width="46%"
              className="form-group"
              type="text"
              placeholder="N°PEC"
              label="N°PEC"
              disabled={true}
              validInput="string"
              value={initialValues.number_pec}
              name="number_pec"
              icon={<i className="fas fa-pen"></i>}
            />
            <InputField
              width="46%"
              className="form-group"
              type="text"
              placeholder="Doit à"
              label="Doit à"
              validInput="string"
              value={codeBureauCNAM[initialValues.submitted_in]}
              name="submitted_in"
              disabled={true}
              icon={<i className="fas fa-pen"></i>}
            />
          </div>
        ) : (
          ''
        )}
        <div className="form_subtitle">
          <span>Détails Des Séances</span>
        </div>
        <div className="form-row">
          <InputField
            width="46%"
            className="form-group"
            type="text"
            placeholder="Acte"
            label="Acte"
            hasError={hasError}
            //  validInput="string"
            value={initialValues.line_bill.act}
            name="act"
            icon={<i className="fas fa-pen"></i>}
            required={this.validator.message(
              'act',
              initialValues.line_bill.act,
              'required|string',
            )}
            onChange={(e) => {
              this.setState({
                initialValues: {
                  ...initialValues,
                  line_bill: {
                    ...initialValues.line_bill,
                    act: e.target.value,
                  },
                },
              });
            }}
          />
        </div>
        <div className="form-row">
          <div className="start_end_date_select_container">
            <InputField
              width="100%"
              className="form-group"
              type="date"
              placeholder="Date de début"
              label="Date de début"
              errorDate={initialValues.line_bill.start_date && errorDate}
              hasError={hasError}
              value={initialValues.line_bill.start_date}
              name="start_date"
              icon=""
              required={this.validator.message(
                'start_date',
                initialValues.line_bill.start_date,
                'required',
              )}
              onChange={(e) => {
                this.setState({
                  initialValues: {
                    ...initialValues,
                    line_bill: {
                      ...initialValues.line_bill,
                      start_date: e.target.value,
                    },
                  },
                });
              }}
            />
            <InputField
              width="100%"
              className="form-group"
              type="date"
              placeholder="Date de fin"
              label="Date de fin"
              errorDate={initialValues.line_bill.end_date && errorDate}
              hasError={hasError}
              value={initialValues.line_bill.end_date}
              name="end_date"
              icon=""
              required={this.validator.message(
                'end_date',
                initialValues.line_bill.end_date,
                'required',
              )}
              onChange={(e) => {
                if (
                  moment(initialValues.line_bill.start_date).isAfter(
                    moment(e.target.value),
                  )
                ) {
                  this.setState({
                    errorDate: true,
                    initialValues: {
                      ...initialValues,
                      line_bill: {
                        ...initialValues.line_bill,
                        end_date: e.target.value,
                      },
                    },
                  });
                } else
                  this.setState({
                    errorDate: false,
                    initialValues: {
                      ...initialValues,
                      line_bill: {
                        ...initialValues.line_bill,
                        end_date: e.target.value,
                      },
                    },
                  });
              }}
            />
          </div>
          <div className="start_end_date_select_container">
            <InputField
              width="100%"
              className="form-group"
              type="text"
              placeholder="10"
              label="Nbr des séances"
              hasError={hasError}
              value={initialValues.line_bill.sessions_nbr}
              name="sessions_nbr"
              icon={<i className="fas fa-pen"></i>}
              validInput="integer"
              required={this.validator.message(
                'sessions_nbr',
                initialValues.line_bill.sessions_nbr,
                'required',
              )}
              onChange={(e) => {
                this.setState({
                  initialValues: {
                    ...initialValues,
                    line_bill: {
                      ...initialValues.line_bill,
                      sessions_nbr: e.target.value,
                    },
                  },
                });
              }}
            />
            <InputField
              width="100%"
              className="form-group"
              type="text"
              placeholder="12.00"
              label="Cout unitaire"
              hasError={hasError}
              value={initialValues.line_bill.unit_price}
              name="unit_price"
              icon={<i className="fas fa-pen"></i>}
              validInput="integer"
              required={this.validator.message(
                'unit_price',
                initialValues.line_bill.unit_price,
                'required',
              )}
              onChange={(e) => {
                this.setState({
                  initialValues: {
                    ...initialValues,
                    line_bill: {
                      ...initialValues.line_bill,
                      unit_price: e.target.value,
                    },
                  },
                });
              }}
            />
          </div>
        </div>
        <div className="footer_buttons">
          <button
            type="button"
            onClick={() => {
              this.props.goBack();
            }}
            className="footer_btn"
            id="cancel"
          >
            Annuler
          </button>
          <button
            style={{ marginRight: '5%' }}
            onClick={() => {
              this.submitForm(initialValues, numBill);
            }}
            type="button"
            className="footer_btn"
            id="update"
          >
            {isLoadingAddBill ? (
              <CircularProgress
                style={{
                  marginLeft: '4%',
                  width: '25px',
                  height: '25px',
                  color: 'white',
                  marginTop: '5%',
                }}
              />
            ) : (
              'Valider'
            )}
          </button>
        </div>
      </form>
    );
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(FormFacture);
