import React, { Component } from 'react';
import { connect } from 'react-redux';
import './index.css';
import actions from './store/actions';
import Bilan from '../../components/bilan';
import CircularProgress from '@material-ui/core/CircularProgress';
import { BilanList } from '../../utils/constants';

const mapStateToProps = (state) => ({
  sandwich_menu_btn: state.AppReducer.sandwich_menu_btn,
  listBilan: state.BilanReducer.listBilan,
  loadingBilan: state.BilanReducer.loadingBilan,
  params: state.BilanReducer.params,
});

const mapDispatchToProps = (dispatch) => ({
  getBilanList: (params) => dispatch(actions.getBilanList(params)),
  changeSearchedString: async (params) => {
    await dispatch(actions.changeSearchedString(params));
    dispatch(actions.getBilanList(params));
  },
});

class Bilans extends Component {
  constructor(props) {
    super(props);
    props.getBilanList(props.params);
    props.changeNamePage('Bilans');
    props.changeActiveItemSideBar(2);
    document.title = 'Bilans';
    this.state = {
      list_bilan: [],
      selected_item: BilanList[0].id,
    };
  }
  componentWillReceiveProps(nextProps, nextContext) {
    const { listBilan } = nextProps;
    const { data } = listBilan;
    if (data && data.length > 0) {
      this.setState({
        list_bilan: data.filter((bilan) => bilan.category === BilanList[0].name),
      });
    }
  }

  render() {
    const { listBilan, sandwich_menu_btn, loadingBilan } = this.props;
    const { data } = listBilan;
    const { selected_item, list_bilan } = this.state;
    let i = 0;
    return (
      <div className="main_content" style={{ overflow: 'auto' }}>
        <div className="bilan_container">
          <div className="main_content">
            <div className="facture_patient" style={{ height: 'auto' }}>
              <div
                className="facture_patients_body"
                style={{ height: '-webkit-fill-available' }}
              >
                {BilanList.map((item) => {
                  return (
                    <div
                      onClick={() => {
                        this.setState({
                          selected_item: item.id,
                          list_bilan: data.filter(
                            (bilan) => bilan.category === item.name,
                          ),
                        });
                      }}
                      key={item.id}
                      className={`facture_patient_body_item ${
                        selected_item === item.id ? 'selected_patient' : ''
                      }`}
                    >
                      <div className="facture_patient_info_container">
                        <div
                          // style={{ backgroundColor: selectedBill ? '#f8f9fc' : '#ECEEF5' }}
                          className="facture_patient_img_container"
                        >
                          <i class="fas fa-diagnoses" style={{ color: '#5446db' }}></i>
                        </div>
                        <div
                          className="facture_patient_info"
                          style={{ alignSelf: 'center' }}
                        >
                          <span id="facture_patient_name">{item.name}</span>
                        </div>
                      </div>

                      <div className="call_icon_bill">
                        <i className="fas fa-angle-right"></i>
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
            <div className="facture_info_section">
              <div className="bilan_main_content">
                {loadingBilan ? (
                  <CircularProgress
                    disableShrink
                    style={{ marginLeft: '47%', marginTop: '20%' }}
                  />
                ) : list_bilan && list_bilan.length === 0 && !loadingBilan ? (
                  <div className="container_changeable_content">
                    <div className="fatcure_detailed_info_section">
                      <div className="empty_consultation_result_container">
                        <div className="patient_detailed_info_section_body">
                          <div className="empty_consultation_result">
                            <img
                              src={require('../../assets/img/undraw_design_team_af2y.svg')}
                            />
                            <span id="no_consultation">Pas de Bilans !</span>
                            <span id="no_consultation_desc">
                              Essayez de créer des nouveaux bilans. Si vous n'avez pas des
                              cures, commencez déja par en créer des nouvelles.
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                ) : (
                  <>
                    <div style={{ padding: '5%' }} className="bilan_main_content">
                      {list_bilan &&
                        list_bilan.length > 0 &&
                        list_bilan.map((item) => {
                          //if (item.actif === 1) {
                          i++;
                          return (
                            <Bilan
                              sandwich_menu_btn={sandwich_menu_btn}
                              key={item.id}
                              item={item}
                              consultationId={this.props.match.params.id}
                            />
                          );
                          //}
                        })}
                    </div>
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Bilans);
