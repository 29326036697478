import React, { Component } from 'react';
import InputField from '../../../components/inputField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import {
  country,
  FORMAT_DATE_SERVER,
  ModePaiement,
  sortCities,
  typeContract,
} from '../../../utils/constants';
import TextField from '@material-ui/core/TextField';
import Modals from '../../../components/modal';
import moment from 'moment';
import actionsJs from '../../../js/actions';
import { connect } from 'react-redux';
import { isNumeric, lengthPhone, validate, formatDate } from '../../../utils/helpres';

const mapStateToProps = (state, ownProps) => {
  const { assistant } = ownProps;
  let first_name;
  let last_name;
  let function_;
  let email;
  let country;
  let city;
  let street;
  let zip_code;
  let login;
  let phone;
  let photo;
  let date_of_birth;
  let name_cabinet;
  let gender;
  let hiring_date;
  let departure_date;
  let type_of_contract;
  let contract_length;
  let hour_from;
  let hour_to;
  let iban;
  let bank;
  let payment_date;
  let payment_method;
  let salary;
  let street_number;
  let gsm;
  let cv;
  let id;
  let cnss;
  let access_status;
  if (assistant) {
    function_ = assistant && assistant.function ? assistant.function : '';
    id = assistant && assistant.id ? assistant.id : null;
    cnss = assistant && assistant.cnss ? assistant.cnss : '';
    street_number = assistant && assistant.street_number ? assistant.street_number : '';
    bank = assistant && assistant.bank ? assistant.bank : '';
    date_of_birth = assistant && formatDate(assistant.date_of_birth, FORMAT_DATE_SERVER);
    salary = assistant && assistant.salary ? assistant.salary : '';
    payment_date = assistant && assistant.payment_date ? assistant.payment_date : '';
    payment_method =
      assistant && assistant.payment_method ? assistant.payment_method : '';
    photo = assistant && assistant.photo ? assistant.photo : '';
    iban = assistant && assistant.iban ? assistant.iban : '';
    cv = assistant && assistant.cv ? assistant.cv : '';
    hiring_date = formatDate(assistant.hiring_date, FORMAT_DATE_SERVER);
    departure_date =
      assistant && formatDate(assistant.departure_date, FORMAT_DATE_SERVER);
    type_of_contract =
      assistant && assistant.type_of_contract ? assistant.type_of_contract : '';
    contract_length =
      assistant && assistant.contract_length ? assistant.contract_length : '';
    hour_from = assistant && assistant.hour_from ? assistant.hour_from : '';
    hour_to = assistant && assistant.hour_to ? assistant.hour_to : '';
    gsm = assistant && assistant.gsm ? assistant.gsm : '';
    name_cabinet = assistant && assistant.name_cabinet ? assistant.name_cabinet : '';
    first_name = assistant && assistant.first_name ? assistant.first_name : '';
    last_name = assistant && assistant.last_name ? assistant.last_name : '';
    email = assistant && assistant.email ? assistant.email : '';
    phone = assistant && assistant.phone ? assistant.phone : '';
    country = assistant && assistant.country ? assistant.country : '';
    city = assistant && assistant.city ? assistant.city : '';
    street = assistant && assistant.street ? assistant.street : '';
    zip_code = assistant && assistant.zip_code ? assistant.zip_code : '';
    login = assistant && assistant.login_access ? assistant.login_access.login : '';
    access_status =
      assistant && assistant.login_access ? assistant.login_access.access_status : '';
  }
  return {
    isLoadingassistant: state.AppReducer.isLoadingassistant,
    initialValues: {
      ...assistant,
      id,
      first_name,
      login,
      last_name,
      function_,
      email,
      country,
      access_status,
      city,
      street,
      zip_code,
      phone,
      photo,
      date_of_birth,
      name_cabinet,
      gender,
      hiring_date: hiring_date || formatDate(moment(), FORMAT_DATE_SERVER),
      departure_date,
      type_of_contract,
      contract_length,
      hour_from,
      hour_to,
      iban,
      bank,
      payment_date,
      payment_method,
      salary,
      street_number,
      cv,
    },
  };
};

const mapDispatchToProps = (dispatch) => ({
  /* submitSecretaryForm: (values, type) =>
    dispatch(actionsJs.submitSecretaryForm(values, type)),*/
  submitSecretaryForm: async (values, type) => {
    await dispatch(actionsJs.submitSecretaryForm(values, type));
    dispatch(actionsJs.getAssistants());
  },
  ActivitedDeactivetedAccount: (values) =>
    dispatch(actionsJs.ActivitedDeactivetedAccount(values)),
});

class FormOtherEmployees extends Component {
  constructor(props) {
    super(props);
    this.state = {
      setIsOpen: false,
      setIsOpenAcces: false,
      activeAcces: false,
      active: false,
      hasError: false,
      hasErrorDate: false,
      updatePassword: false,
      initialValues: {
        ...props.initialValues,
        file: null,
      },
    };
    this.openModal = this.openModal.bind(this);
    this.openModalAcces = this.openModalAcces.bind(this);
    this.openModalActif = this.openModalActif.bind(this);
  }
  submitForm(initialValues, id) {
    if (
      (!initialValues.id && !initialValues.password) ||
      !initialValues.last_name ||
      //!initialValues.date_of_birth ||
      !initialValues.first_name ||
      !initialValues.login ||
      !validate(initialValues.first_name) ||
      !validate(initialValues.last_name) ||
      (initialValues.phone && !lengthPhone(initialValues.phone)) ||
      (initialValues.phone && !isNumeric(initialValues.phone))
    ) {
      this.setState({
        ...initialValues,
        hasError: true,
      });
    } else {
      this.props.submitSecretaryForm(initialValues, this.props.type);
    }
  }
  openModal() {
    this.setState({
      setIsOpen: !this.state.setIsOpen,
    });
  }
  handleClick(fileUploader) {
    this.refs[fileUploader].click();
  }
  openModalAcces() {
    this.setState({
      setIsOpenAcces: !this.state.setIsOpenAcces,
    });
  }
  handleChangeFiles = (event, name) => {
    const fileUploaded = event.target.files[0];
    this.setState({
      [name]: fileUploaded,
    });
  };
  openModalActif() {
    this.setState({
      setIsOpenActif: !this.state.setIsOpenActif,
    });
  }
  render() {
    const { assistant, sandwich_menu_btn } = this.props;
    const {
      setIsOpen,
      setIsOpenAcces,
      setIsOpenActif,
      hasError,
      initialValues,
      updatePassword,
      hasErrorDate,
    } = this.state;
    return (
      <div className="" style={{ width: '100%' }}>
        <Modals
          setIsOpen={setIsOpenActif}
          openModal={this.openModalActif}
          data={
            <>
              {' '}
              <div className="modal-header" style={{ marginTop: '0' }}>
                <button
                  type="button"
                  className="close"
                  data-dismiss="modal"
                  aria-label="Close"
                  onClick={() => this.openModalActif()}
                >
                  <span
                    aria-hidden="true"
                    style={{ marginRight: '10px', color: '#1A1C1D' }}
                  >
                    &times;
                  </span>
                </button>
              </div>
              <div className="modal-body">
                <h2>
                  {' '}
                  {initialValues.access_status === 'active' ? ' Désactiver' : ' Activer'}!
                </h2>

                <span>
                  {initialValues.access_status === 'active'
                    ? 'Êtes vous sûrs de vouloir désactiver ce compte ?'
                    : 'Etes vous sûrs de vouloir vous activer ?'}
                </span>
              </div>
              <div className="modal-footer">
                <button
                  style={{ backgroundColor: '#f6f6f6' }}
                  onClick={() => this.openModalActif()}
                  type="button"
                  className="modal_close_btn"
                  // data-dismiss="modal"
                >
                  Annuler
                </button>
                <button
                  onClick={() => {
                    this.props.ActivitedDeactivetedAccount({
                      id: assistant.login_access.id,
                      access_status:
                        initialValues.access_status === 'active' ? 'disabled' : 'active',
                    });
                    this.setState({
                      initialValues: {
                        ...initialValues,
                        access_status:
                          initialValues.access_status === 'active'
                            ? 'disabled'
                            : 'active',
                      },
                    });
                    this.openModalActif(setIsOpenActif);
                  }}
                  type="button"
                  className="modal_disconnect_btn"
                  // data-dismiss="modal"
                >
                  Valider
                </button>
              </div>
            </>
          }
        />
        <Modals
          setIsOpen={setIsOpenAcces}
          openModal={this.openModalAcces}
          data={
            <>
              {' '}
              <div className="modal-header" style={{ marginTop: '0' }}>
                <button
                  type="button"
                  className="close"
                  data-dismiss="modal"
                  aria-label="Close"
                  onClick={() => this.openModalAcces()}
                >
                  <span
                    aria-hidden="true"
                    style={{ marginRight: '10px', color: '#1A1C1D' }}
                  >
                    &times;
                  </span>
                </button>
              </div>
              <div className="modal-body">
                <div className="not_app_container">
                  <img src={require('../../../assets/img/pas_de_disponibilite.svg')} />
                  <span id="appointment_details_indispo" style={{ marginTop: '10px' }}>
                    Pas d'historique' !
                  </span>
                  <span id="appointment_details_sub">En cours d'intégration</span>
                </div>
              </div>
              <div className="modal-footer">
                <button
                  style={{ backgroundColor: '#f6f6f6' }}
                  onClick={() => this.openModalAcces()}
                  type="button"
                  className="modal_close_btn"
                  // data-dismiss="modal"
                >
                  Fermer
                </button>
              </div>
            </>
          }
        />
        <Modals
          setIsOpen={setIsOpen}
          openModal={this.openModal}
          data={
            <>
              {' '}
              <div className="modal-header" style={{ marginTop: '0' }}>
                <button
                  type="button"
                  className="close"
                  data-dismiss="modal"
                  aria-label="Close"
                  onClick={() => this.openModal()}
                >
                  <span
                    aria-hidden="true"
                    style={{ marginRight: '10px', color: '#1A1C1D' }}
                  >
                    &times;
                  </span>
                </button>
              </div>
              <div className="modal-body">
                <div className="not_app_container">
                  <img src={require('../../../assets/img/pas_de_disponibilite.svg')} />
                  <span id="appointment_details_indispo" style={{ marginTop: '10px' }}>
                    Pas d'historique' !
                  </span>
                  <span id="appointment_details_sub">En cours d'intégration</span>
                </div>
              </div>
              <div className="modal-footer">
                <button
                  style={{ backgroundColor: '#f6f6f6' }}
                  onClick={() => this.openModal()}
                  type="button"
                  className="modal_close_btn"
                  // data-dismiss="modal"
                >
                  Fermer
                </button>
              </div>
            </>
          }
        />
        <div className="container_changeable_content" style={{ height: '100%' }}>
          <div
            className="patient_detailed_info_section"
            style={{
              width: 'fit-content',
            }}
          >
            <div className="user_img_name" style={{ margin: '0px' }}>
              <div className="user_img">
                {!initialValues.file && !initialValues.photo ? (
                  <div className="patient_avatar">
                    <svg
                      width="33"
                      height="34"
                      viewBox="0 0 33 34"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M5.73958 23.2812C9.42708 21.7812 12.9583 21.0312 16.3333 21.0312C19.7083 21.0312 23.2083 21.7812 26.8333 23.2812C30.5208 24.7188 32.3646 26.625 32.3646 29V33.0312H0.302084V29C0.302084 26.625 2.11458 24.7188 5.73958 23.2812ZM21.9583 14.6562C20.3958 16.2188 18.5208 17 16.3333 17C14.1458 17 12.2708 16.2188 10.7083 14.6562C9.14583 13.0938 8.36458 11.2188 8.36458 9.03125C8.36458 6.84375 9.14583 4.96875 10.7083 3.40625C12.2708 1.78125 14.1458 0.96875 16.3333 0.96875C18.5208 0.96875 20.3958 1.78125 21.9583 3.40625C23.5208 4.96875 24.3021 6.84375 24.3021 9.03125C24.3021 11.2188 23.5208 13.0938 21.9583 14.6562Z"
                        fill="#8083A3"
                      />
                    </svg>
                  </div>
                ) : !this.state.file &&
                  initialValues.photo &&
                  initialValues.photo.length === 0 ? (
                  <div className="patient_avatar">
                    <svg
                      width="33"
                      height="34"
                      viewBox="0 0 33 34"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M5.73958 23.2812C9.42708 21.7812 12.9583 21.0312 16.3333 21.0312C19.7083 21.0312 23.2083 21.7812 26.8333 23.2812C30.5208 24.7188 32.3646 26.625 32.3646 29V33.0312H0.302084V29C0.302084 26.625 2.11458 24.7188 5.73958 23.2812ZM21.9583 14.6562C20.3958 16.2188 18.5208 17 16.3333 17C14.1458 17 12.2708 16.2188 10.7083 14.6562C9.14583 13.0938 8.36458 11.2188 8.36458 9.03125C8.36458 6.84375 9.14583 4.96875 10.7083 3.40625C12.2708 1.78125 14.1458 0.96875 16.3333 0.96875C18.5208 0.96875 20.3958 1.78125 21.9583 3.40625C23.5208 4.96875 24.3021 6.84375 24.3021 9.03125C24.3021 11.2188 23.5208 13.0938 21.9583 14.6562Z"
                        fill="#8083A3"
                      />
                    </svg>
                  </div>
                ) : (
                  <img
                    src={
                      initialValues.file
                        ? URL.createObjectURL(initialValues.file)
                        : initialValues.photo
                        ? `${process.env.REACT_APP_PUBLIC_URL}${initialValues.photo}`
                        : require('../../../assets/img/kar.jpg')
                    }
                    alt=""
                  />
                )}

                <input
                  //onChange={(e) => this.handleChangeFiles(e, 'file')}
                  onChange={(e) => {
                    this.setState({
                      initialValues: {
                        ...initialValues,
                        file: e.target.files[0],
                      },
                    });
                  }}
                  type="file"
                  ref="fileUploaderDoctor"
                  name="file"
                  hidden
                  accept=".png, .jpg,.jpeg"
                />
                <i
                  onClick={() => this.handleClick('fileUploaderDoctor')}
                  className="fa fa-camera"
                ></i>
              </div>
              <div className="user_name" style={{ margin: '0px' }}>
                <span className="name">{`${
                  initialValues.first_name ? initialValues.first_name : ''
                } ${initialValues.last_name ? initialValues.last_name : ''}`}</span>
              </div>
            </div>
          </div>
          <div className="patient_detailed_info_section">
            <div className="general_info" style={{ width: '97%' }}>
              <form className="general_info_form">
                <div className="title_and_button">
                  <span>Informations générales</span>
                </div>
                <div className="general_info_actual_form">
                  <InputField
                    width={sandwich_menu_btn ? '45%' : '45%'}
                    className="general_info_form_item"
                    type="text"
                    placeholder="Nom"
                    label="Nom"
                    validInput="string"
                    value={initialValues.last_name}
                    hasError={hasError}
                    name="last_name"
                    icon={<i className="fas fa-user"></i>}
                    onChange={(e) => {
                      this.setState({
                        initialValues: {
                          ...initialValues,
                          last_name: e.target.value,
                        },
                      });
                    }}
                  />
                  <InputField
                    width={sandwich_menu_btn ? '45%' : '45%'}
                    type="text"
                    placeholder="Prénom"
                    className="general_info_form_item"
                    validInput="string"
                    label="Prénom"
                    hasError={hasError}
                    value={initialValues.first_name}
                    name="first_name"
                    onChange={(e) => {
                      this.setState({
                        initialValues: {
                          ...initialValues,
                          first_name: e.target.value,
                        },
                      });
                    }}
                    icon={<i className="fas fa-user"></i>}
                  />
                  <InputField
                    width={sandwich_menu_btn ? '45%' : '45%'}
                    className="general_info_form_item"
                    type="date"
                    placeholder="Date de naissance"
                    label="Date de naissance"
                    minLength={8}
                    maxLength={20}
                    errorDate={hasErrorDate}
                    //hasError={hasError}
                    value={initialValues.date_of_birth}
                    validInput=""
                    name="date_of_birth"
                    onChange={(e) => {
                      if (moment().isBefore(moment(e.target.value))) {
                        this.setState({
                          hasErrorDate: true,
                          initialValues: {
                            ...initialValues,
                            date_of_birth: e.target.value,
                          },
                        });
                      } else
                        this.setState({
                          hasErrorDate: false,
                          initialValues: {
                            ...initialValues,
                            date_of_birth: e.target.value,
                          },
                        });
                    }}
                  />
                  <InputField
                    width={sandwich_menu_btn ? '45%' : '45%'}
                    className="general_info_form_item"
                    type="text"
                    placeholder="Tél"
                    label="Tél"
                    minLength={8}
                    maxLength={20}
                    // hasError={hasError}
                    validInput="tel"
                    value={initialValues.phone}
                    name="phone"
                    icon={<i className="fas fa-phone-alt"></i>}
                    onChange={(e) => {
                      this.setState({
                        initialValues: {
                          ...initialValues,
                          phone: e.target.value,
                        },
                      });
                    }}
                  />
                </div>
                <div className="address_form">
                  <div className="title_and_button">
                    <span id="">Adresse</span>
                  </div>

                  <div className="add_patient_actual_form">
                    <div className="field_row">
                      <InputField
                        width="100%"
                        className="add_patient_form_item"
                        type="text"
                        placeholder="Adresse"
                        label="Adresse"
                        validInput=""
                        // hasError={hasError}
                        value={initialValues.street}
                        name="street"
                        icon={<i className="fas fa-city"></i>}
                        onChange={(e) => {
                          this.setState({
                            initialValues: {
                              ...initialValues,
                              street: e.target.value,
                            },
                          });
                        }}
                      />
                      <div style={{ width: '100%' }} className="add_patient_form_item">
                        <label>Ville</label>
                        <Autocomplete
                          id="size-small-standard"
                          size="small"
                          options={sortCities}
                          getOptionLabel={(option) => option.title}
                          onChange={(e, option) => {
                            this.setState({
                              initialValues: {
                                ...initialValues,
                                city: option != null ? option.title : '',
                              },
                            });
                          }}
                          defaultValue={{
                            title: initialValues.city,
                          }}
                          renderInput={(cities) => (
                            <TextField
                              style={{ width: '100%' }}
                              placeholder="Ville"
                              {...cities}
                              // error={hasError && !initialValues.city}
                              variant="standard"
                              //value={initialValues.address_practitioner && initialValues.address_practitioner.city}
                              //label={<span>Ville <span style={{color: 'red'}}>*</span> </span>}
                            />
                          )}
                        />
                      </div>
                    </div>
                    <div className="field_row">
                      <InputField
                        width="100%"
                        className="add_patient_form_item"
                        type="text"
                        placeholder="Code Postal"
                        label="Code Postal"
                        validInput="integer"
                        name="zip_code"
                        // hasError={hasError}
                        value={initialValues.zip_code}
                        icon={<i className="fas fa-globe-africa"></i>}
                        onChange={(e) => {
                          this.setState({
                            initialValues: {
                              ...initialValues,
                              zip_code: e.target.value,
                            },
                          });
                        }}
                      />
                      <div style={{ width: '100%' }} className="add_patient_form_item">
                        <label>Pays</label>
                        <Autocomplete
                          id="size-small-standard"
                          size="small"
                          options={country}
                          onChange={(e, option) => {
                            this.setState({
                              initialValues: {
                                ...initialValues,
                                country: option != null ? option.title : '',
                              },
                            });
                          }}
                          defaultValue={{
                            title: initialValues.country,
                          }}
                          getOptionLabel={(option) => option.title}
                          renderInput={(country) => (
                            <TextField
                              style={{ width: '100%' }}
                              placeholder="Pays"
                              {...country}
                              //  error={hasError && !initialValues.country}
                              variant="standard"
                              //value={initialValues.address_practitioner && initialValues.address_practitioner.city}
                              //label={<span>Ville <span style={{color: 'red'}}>*</span> </span>}
                            />
                          )}
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="address_form">
                  <div className="title_and_button">
                    <span id="">Informations professionnelles</span>
                  </div>

                  <div className="add_patient_actual_form">
                    <div className="field_row">
                      <InputField
                        width="100%"
                        className="add_patient_form_item"
                        type="date"
                        placeholder="Date d’embauche"
                        label="Date d’embauche"
                        validInput=""
                        // hasError={hasError}
                        name="hiring_date"
                        value={initialValues.hiring_date}
                        onChange={(e) => {
                          this.setState({
                            initialValues: {
                              ...initialValues,
                              hiring_date: e.target.value,
                            },
                          });
                        }}
                      />
                      <InputField
                        width="100%"
                        className="add_patient_form_item"
                        type="time"
                        placeholder="07:00"
                        label="Heure de début"
                        //validInput="integer"
                        name="hour_from"
                        //hasError={hasError}
                        value={initialValues.hour_from}
                        onChange={(e) => {
                          this.setState({
                            initialValues: {
                              ...initialValues,
                              hour_from: e.target.value,
                            },
                          });
                        }}
                      />
                      {initialValues.type_of_contract === 'CDI' ? (
                        ''
                      ) : (
                        <InputField
                          width="100%"
                          className="add_patient_form_item"
                          type="text"
                          placeholder="6"
                          label="Durée du contrat"
                          validInput="string"
                          name="contract_length"
                          maxLength="50"
                          // hasError={hasError}
                          value={initialValues.contract_length}
                          icon={<i className="fas fa-history"></i>}
                          onChange={(e) => {
                            this.setState({
                              initialValues: {
                                ...initialValues,
                                contract_length: e.target.value,
                              },
                            });
                          }}
                        />
                      )}
                      <InputField
                        width="100%"
                        className="add_patient_form_item"
                        type="text"
                        placeholder="Fonction"
                        label="Fonction"
                        maxLength="50"
                        //validInput="integer"
                        name="function_"
                        //hasError={hasError}
                        value={initialValues.function_}
                        onChange={(e) => {
                          this.setState({
                            initialValues: {
                              ...initialValues,
                              function_: e.target.value,
                            },
                          });
                        }}
                      />
                      {/* <div style={{ width: '100%' }} className="add_patient_form_item">
                        <label>Fonction</label>
                        <Autocomplete
                          id="size-small-standard"
                          size="small"
                          options={fonction}
                          getOptionLabel={(option) => option.title}
                          onChange={(e, option) => {
                            this.setState({
                              initialValues: {
                                ...initialValues,
                                function_: option != null ? option.title : '',
                              },
                            });
                          }}
                          defaultValue={{
                            title: initialValues.function_,
                          }}
                          renderInput={(cities) => (
                            <TextField
                              style={{ width: '100%' }}
                              placeholder="Fonction"
                              {...cities}
                              variant="standard"
                              // error={hasError && !initialValues.function_}
                              //value={initialValues.address_practitioner && initialValues.address_practitioner.city}
                              //label={<span>Ville <span style={{color: 'red'}}>*</span> </span>}
                            />
                          )}
                        />
                      </div> */}
                    </div>
                    <div className="field_row">
                      <div style={{ width: '100%' }} className="add_patient_form_item">
                        <label>Type de contrat</label>
                        <Autocomplete
                          id="size-small-standard"
                          size="small"
                          options={typeContract}
                          getOptionLabel={(option) => option.title}
                          onChange={(e, option) => {
                            this.setState({
                              initialValues: {
                                ...initialValues,
                                type_of_contract: option != null ? option.title : '',
                              },
                            });
                          }}
                          defaultValue={{
                            title: initialValues.type_of_contract,
                          }}
                          renderInput={(cities) => (
                            <TextField
                              style={{ width: '100%' }}
                              placeholder="Type de contrat"
                              {...cities}
                              //  error={hasError && !initialValues.type_of_contract}
                              variant="standard"
                              //value={initialValues.address_practitioner && initialValues.address_practitioner.city}
                              //label={<span>Ville <span style={{color: 'red'}}>*</span> </span>}
                            />
                          )}
                        />
                      </div>
                      <InputField
                        width="100%"
                        className="add_patient_form_item"
                        type="time"
                        placeholder="19:00"
                        label="Heure de fin"
                        //validInput="integer"
                        name="hour_to"
                        //  hasError={hasError}
                        value={initialValues.hour_to}
                        onChange={(e) => {
                          this.setState({
                            initialValues: {
                              ...initialValues,
                              hour_to: e.target.value,
                            },
                          });
                        }}
                      />
                    </div>
                    <div className="field_row"></div>
                  </div>
                </div>
                <br />
                <br />
                <div className="title_and_button">
                  <span>Gestion financière</span>
                  <p
                    onClick={() => this.openModal()}
                    style={{
                      cursor: 'pointer',
                      fontSize: '13px',
                      color: 'blue',
                      marginRight: '10%',
                    }}
                  >
                    Afficher historique de rémunérations
                  </p>
                </div>
                <div className="add_patient_actual_form">
                  <InputField
                    // hasError={hasError}
                    width={sandwich_menu_btn ? '45%' : '45%'}
                    className="general_info_form_item"
                    type="text"
                    value={initialValues.iban}
                    placeholder="IBAN"
                    label="IBAN"
                    // validInput="integer"
                    name="iban"
                    icon={<i className="fab fa-scribd"></i>}
                    onChange={(e) => {
                      this.setState({
                        initialValues: {
                          ...initialValues,
                          iban: e.target.value,
                        },
                      });
                    }}
                  />
                  <InputField
                    width={sandwich_menu_btn ? '45%' : '45%'}
                    className="general_info_form_item"
                    type="text"
                    placeholder="BANQUE"
                    label="BANQUE"
                    validInput="string"
                    // hasError={hasError}
                    name="bank"
                    value={initialValues.bank}
                    icon={<i className="fas fa-money-check-alt"></i>}
                    onChange={(e) => {
                      this.setState({
                        initialValues: {
                          ...initialValues,
                          bank: e.target.value,
                        },
                      });
                    }}
                  />
                  <InputField
                    value={initialValues.salary}
                    width={sandwich_menu_btn ? '45%' : '45%'}
                    className="general_info_form_item"
                    type="text"
                    placeholder="salaire "
                    label="salaire "
                    validInput="integer"
                    // hasError={hasError}
                    name="salary"
                    icon={<i className="fas fa-money-check-alt"></i>}
                    onChange={(e) => {
                      this.setState({
                        initialValues: {
                          ...initialValues,
                          salary: e.target.value,
                        },
                      });
                    }}
                  />
                  <div className="field_row" style={{ alignSelf: 'self-end' }}>
                    <InputField
                      width={sandwich_menu_btn ? '100%' : '100%'}
                      className="general_info_form_item"
                      type="text"
                      placeholder="Jour de paiement"
                      label="Jour de paiement"
                      validInput=""
                      // hasError={hasError}
                      value={initialValues.payment_date}
                      name="payment_date"
                      onChange={(e) => {
                        this.setState({
                          initialValues: {
                            ...initialValues,
                            payment_date: e.target.value,
                          },
                        });
                      }}
                    />
                    <div style={{ width: '45%' }} className="add_patient_form_item">
                      <label>Mode de paiement</label>
                      <Autocomplete
                        id="size-small-standard"
                        size="small"
                        options={ModePaiement}
                        getOptionLabel={(option) => option.title}
                        onChange={(e, option) => {
                          this.setState({
                            initialValues: {
                              ...initialValues,
                              payment_method: option != null ? option.title : '',
                            },
                          });
                        }}
                        defaultValue={{
                          title: initialValues.payment_method,
                        }}
                        renderInput={(cities) => (
                          <TextField
                            // error={hasError && !initialValues.payment_method}
                            style={{ width: '100%' }}
                            placeholder="Mode de paiement"
                            {...cities}
                            variant="standard"
                            //value={initialValues.address_practitioner && initialValues.address_practitioner.city}
                            //label={<span>Ville <span style={{color: 'red'}}>*</span> </span>}
                          />
                        )}
                      />
                    </div>
                  </div>
                </div>
                <div className="address_form">
                  <div className="title_and_button">
                    <span id="">Gestion des accès</span>
                    <p
                      onClick={() => this.openModalAcces()}
                      style={{
                        fontSize: '13px',
                        color: 'blue',
                        marginRight: '10%',
                        cursor: 'pointer',
                      }}
                    >
                      Afficher historique des accès
                    </p>
                  </div>

                  <div className="add_patient_actual_form">
                    <div className="field_row">
                      <InputField
                        width="100%"
                        className="add_patient_form_item"
                        type="text"
                        placeholder="Login"
                        hasError={hasError}
                        label="Login"
                        validInput="sssss"
                        value={initialValues.login}
                        name="login"
                        icon={<i className="fas fa-city"></i>}
                        onChange={(e) => {
                          this.setState({
                            initialValues: {
                              ...initialValues,
                              login: e.target.value,
                            },
                          });
                        }}
                      />
                    </div>
                    {updatePassword || !initialValues.id ? (
                      <div className="field_row">
                        <InputField
                          width="100%"
                          className="add_patient_form_item"
                          type="password"
                          hasError={hasError}
                          value={initialValues.password}
                          placeholder="*************"
                          label="Mot de passe"
                          validInput="sssssss"
                          name="password"
                          onChange={(e) => {
                            this.setState({
                              initialValues: {
                                ...initialValues,
                                password: e.target.value,
                              },
                            });
                          }}
                          icon={<i className="fas fa-globe-africa"></i>}
                        />
                      </div>
                    ) : (
                      <div
                        className="footer_buttons"
                        style={{ width: '50%', paddingBottom: '4%' }}
                      >
                        <button
                          type="button"
                          style={{ width: '50%' }}
                          onClick={() =>
                            this.setState({ updatePassword: !updatePassword })
                          }
                          className="footer_btn"
                          id="cancel"
                        >
                          Modifier Mot de passe
                        </button>
                      </div>
                    )}
                  </div>
                </div>
              </form>
              <div className="footer_buttons" style={{ paddingBottom: '4%' }}>
                <button
                  onClick={() => this.props.goBack()}
                  className="footer_btn"
                  id="cancel"
                >
                  Annuler
                </button>
                {initialValues.id ? (
                  <div
                    style={{ width: '26%', marginTop: '6%' }}
                    className="seance-status"
                  >
                    <span style={{ color: 'black', fontWeight: 'bold' }}>
                      {initialValues.access_status === 'active'
                        ? 'Désactiver le compte'
                        : 'Activer le compte'}
                    </span>
                    <div
                      onClick={() => {
                        this.openModalActif(setIsOpenActif);
                      }}
                      style={{ cursor: 'pointer' }}
                      className={
                        initialValues.access_status === 'active'
                          ? 'active_btn'
                          : 'deactive_btn'
                      }
                    >
                      <div
                        className={
                          initialValues.access_status === 'active'
                            ? 'deactive_btn_circle active_btn_circle'
                            : 'deactive_btn_circle'
                        }
                      ></div>
                    </div>
                  </div>
                ) : (
                  ''
                )}
                <button
                  style={{
                    marginRight: sandwich_menu_btn ? '6%' : '6%',
                  }}
                  onClick={() => this.submitForm(initialValues)}
                  id="update"
                  className="footer_btn"
                  //disabled={isLoadingUpdateUser}
                >
                  Valider
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(FormOtherEmployees);
