import React, { Component } from 'react';
import './index.css';
import './rendezvouslist-container.css';
import actions from './store/actions';
import actionsM from '../messenger/store/actions';
import { connect } from 'react-redux';
import DatePicker, { registerLocale } from 'react-datepicker';
import moment from 'moment';
import 'react-datepicker/dist/react-datepicker.css';
import AgendaM from '../new_agenda';
import 'moment/locale/fr';
import fr from 'date-fns/locale/fr';
import CircularProgress from '@material-ui/core/CircularProgress';
import actionsJs from '../../js/actions'; // the locale you want

import '../new_agenda/agenda.css';
import { toast } from 'react-toastify';
import ToastAction from '../../components/ToastAction';
import { formatDate } from '../../utils/helpres';

registerLocale('fr', fr); // register it with the name you want

const mapStateToProps = (state) => ({
  DestroyUnavailability: state.AgendaReducer.deleteUnavailability,
  isLoadingGetAllUnavailability: state.AgendaReducer.isLoadingGetAllUnavailability,
  isLoadingGetAllRDV: state.AgendaReducer.isLoadingGetAllRDV,
  isLoadingAddRdv: state.AgendaReducer.isLoadingAddRdv,
  created: state.AgendaReducer.created,
  canceledRdv: state.AgendaReducer.canceledRdv,
  isLoadingAddDispo: state.AgendaReducer.isLoadingAddDispo,
  createdNewDispo: state.AgendaReducer.createdNewDispo,
  isLoadingDeleteRDV: state.AgendaReducer.isLoadingDeleteRDV,
  deleteRDV: state.AgendaReducer.deleteRDV,
  getListUnavailability: state.AgendaReducer.getAllUnavailability,
  motifs: state.AgendaReducer.motifs,
  getAllRDV: state.AgendaReducer.getAllRDV,
  params: state.AgendaReducer.params,
  paymentAction: state.AgendaReducer.paymentAction,
  paramsDoctor: state.MessengerReducer.params,
  isLoadingDoctors: state.MessengerReducer.isLoadingDoctors,
  doctors: state.MessengerReducer.doctors,
  getLastAvailabilityDate: state.AgendaReducer.getLastAvailabilityDate,
  employeeManagement: state.AppReducer.employeeManagement,
  isLoadingHolidayEmployeeManagement: state.AppReducer.isLoadingHolidayEmployeeManagement,
  paymentRdv: state.AppReducer.payment,
  user: state.AppReducer.user,
  isLoadingAbsenceHours: state.AppReducer.isLoadingAbsenceHours,
  absenceHours: state.AppReducer.absenceHours,
  activeItemSideBarRDV: state.AppReducer.activeItemSideBarRDV,
});

const mapDispatchToProps = (dispatch) => ({
  getLastAvailability: (id, doctor_id) =>
    dispatch(actions.getLastAvailability(id, doctor_id)),
  getDipoDoctorByMotif: (valuse) => dispatch(actions.getDipoDoctorByMotif(valuse)),
  getAllRdvByDay: (params, id) => dispatch(actions.getAllRdvByDay(params, id)),
  getAbsensesByHours: (values, id) => dispatch(actionsJs.getAbsensesByHours(values, id)),
  getAllDoctors: (params) => dispatch(actionsM.getAllDoctors(params)),
  getMotif: () => dispatch(actions.getMotif(true)),
  submitRDVForm: async (params, values, id) => {
    await dispatch(actions.submitRDVForm(values));
    setTimeout(() => {
      dispatch(actions.getAllRdvByDay(params, id));
    }, 1000);
  },
  submitPaymentForm: async (params, values, id) => {
    await dispatch(actions.submitPaymentForm(values));
    setTimeout(() => {
      dispatch(actions.getAllRdvByDay(params, id));
    }, 1000);
  },
  deleteRdv: async (params, values, id) => {
    await dispatch(actions.deleteRdv(values));
    dispatch(actions.getAllRdvByDay(params, id));
  },
  changeDate: async (params, id) => {
    await dispatch(actions.changeParams(params));
    //dispatch(actions.getAllUnavailability(params));
    dispatch(actions.getAllRdvByDay(params, id));
  },
  changeStatusRdv: async (params, id) => {
    await dispatch(actions.changeParams(params));
    //dispatch(actions.getAllUnavailability(params));
    dispatch(actions.getAllRdvByDay(params, id));
  },
  getEmployeeManagement: (type) => dispatch(actionsJs.getEmployeeManagement(type)),
  changeActiveItemSideBarSettings: (item, data, create) =>
    dispatch(actionsJs.changeActiveItemSideBarSettings(item, data, create)),
  changeActiveItemSideBarCompta: (data) =>
    dispatch(actionsJs.changeActiveItemSideBarCompta(data)),
});

class Agenda extends Component {
  constructor(props) {
    super(props);
    // props.getAllRdv(props.params);
    // props.getAllUnavailability(props.params);
    props.getEmployeeManagement(true);
    props.getMotif();
    props.getAllDoctors(props.paramsDoctor);
    props.changeNamePage('Agenda');
    props.changeActiveItemSideBar(1);
    props.changeActiveItemSideBarSettings(111, false, true);
    document.title = 'Agenda';
    this.state = {
      showRightSideBar: false,
      showRightSideBarRDV: false,
      rdv: [],
      userList: [],
      clickUser: false,
      showUnavailability: false,
      activeRDV: this.props.location.state ? this.props.location.state.showAddRdv : false,
      payment: {
        id: null,
        total_achieved: null,
        already_paid: null,
        left_to_pay: null,
        money_pot_amount: null,
        session_price: null,
        payment_method: null,
        settled_in_session: null,
        date: null,
      },
      initialValues: {
        errorDate: false,
        errorHours: false,
        labelMotif: '',
        hour_end: '',
        hour_start: '',
        pathologie: '',
        diagnostic: '',
        labelMotif_update: '',
        date: formatDate(moment(), 'YYYY-MM-DD'),
        date_update: '',
        display_name: '',
        display_name_update: '',
        city: '',
        city_update: '',
        doctor_id: null,
        phone_number: '',
        phone_number_update: '',
        motif_id: null,
        motif_id_update: null,
        startDate: '',
        endDate: '',
        startHours: '',
        startHours_update: '',
        id_update: '',
        endHours: '',
        time: '',
        time_update: '',
        comment: '',
        name_btn_indispo: 'Ajouter',
        header_name_indispo: 'Ajouter une nouvelle indisponibilité',
      },
    };
    this.handleShowRightSideBar = this.handleShowRightSideBar.bind(this);
    this.getItemRdv = this.getItemRdv.bind(this);
    this.handleChangeTopSectionItemLeftRDV =
      this.handleChangeTopSectionItemLeftRDV.bind(this);
    this.changeStatus = this.changeStatus.bind(this);
    this.handleShowRightSideBarRDV = this.handleShowRightSideBarRDV.bind(this);
    this.handleBtnTimeClick = this.handleBtnTimeClick.bind(this);
    this.handleInitialStateUnavailability =
      this.handleInitialStateUnavailability.bind(this);
    this.handleInitialStateRDV = this.handleInitialStateRDV.bind(this);
    this.handleChangeInput = this.handleChangeInput.bind(this);
    this.handleCityChange = this.handleCityChange.bind(this);
    this.handleErrorHoursChange = this.handleErrorHoursChange.bind(this);
    this.handleChangeInputPayment = this.handleChangeInputPayment.bind(this);
    this.handleErrorDateChange = this.handleErrorDateChange.bind(this);
    this.handleChangeTopSectionItemLeft = this.handleChangeTopSectionItemLeft.bind(this);
  }

  handleShowRightSideBar(action) {
    this.setState({
      showRightSideBar:
        action === true && this.state.showRightSideBar
          ? this.state.showRightSideBar
          : !this.state.showRightSideBar,
      initialValues: {
        //...this.state.initialValues,
        startDate: '',
        errorDate: false,
        errorHours: false,
        endDate: '',
        startHours: '',
        endHours: '',
        comment: '',
        doctor_id: null,
        name_btn_indispo: 'Ajouter',
        header_name_indispo: 'Ajouter une nouvelle indisponibilité',
      },
    });
  }
  handleInitialStateUnavailability() {
    this.setState({
      initialValues: {
        ...this.state.initialValues,
        startDate: '',
        doctor_id: null,
        errorDate: false,
        errorHours: false,
        endDate: '',
        startHours: '',
        endHours: '',
        comment: '',
      },
    });
  }
  handleShowRightSideBarRDV() {
    this.setState({
      showRightSideBarRDV: this.state.activeRDV ? false : !this.state.showRightSideBarRDV,
      activeRDV: false,
      initialValues: {
        //...this.state.initialValues,
        date: '',
        display_name: '',
        city: '',
        diagnostic: '',
        labelMotif: '',
        phone_number: '',
        doctor_id: null,
        motif_id: null,
        startDate: '',
        endDate: '',
        startHours: '',
        endHours: '',
        time: '',
      },
    });
  }
  handleInitialStateRDV() {
    this.setState({
      initialValues: {
        ...this.state.initialValues,
        date: '',
        display_name: '',
        city: '',
        labelMotif: '',
        phone_number: '',
        motif_id: null,
        startDate: '',
        endDate: '',
        startHours: '',
        endHours: '',
        time: '',
      },
    });
  }
  async handleChangeTopSectionItemLeftRDV(item) {
    const { getAllRDV } = this.props;
    const { userList } = this.state;
    let userList1 = userList;
    if (userList.includes(item)) {
      if (userList1.length > 0) {
        await this.setState((prevState) => {
          let data = [...prevState.rdv];
          let users = [...prevState.userList];
          data = data.filter((el) => el.practitioner_id !== item);
          users = users.filter((e) => e !== item);
          this.setState({
            rdv: data,
            userList: users,
          });
          userList1 = users;
        });
        if (userList1.length > 0)
          this.props.getAllRdvByDay(
            this.props.params,
            userList1.length > 1
              ? userList1
              : userList1.length === 1
              ? userList1[0]
              : item,
          );
        else await this.props.getAllRdvByDay(this.props.params, []);
      } else await this.props.getAllRdvByDay(this.props.params, []);
    } else {
      userList1 = [...userList, item];
      if (userList1.length > 0) {
        await this.setState({
          userList: [item, ...userList],
          showRdv: item,
          rdv: [...this.state.rdv, ...getAllRDV],
        });
        await this.props.getAllRdvByDay(
          this.props.params,
          userList1.length > 1 ? userList1 : userList1.length === 1 ? userList1[0] : item,
        );
      } else
        this.setState({
          rdv: [],
          userList: [],
        });
    }
  }
  handleChangeTopSectionItemLeft() {
    this.setState({
      showRdv: false,
      showUnavailability: true,
    });
  }
  handleBtnTimeClick(event, time) {
    const { initialValues } = this.state;
    this.setState({
      initialValues: {
        ...initialValues,
        [time]: event.currentTarget.dataset.time,
      },
    });
  }
  handleChangeInput(event) {
    const { initialValues } = this.state;
    const target = event.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;
    this.setState({
      initialValues: {
        ...initialValues,
        [name]: value,
      },
    });
  }
  handleChangeInputPayment(event) {
    const { payment } = this.state;
    const target = event.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;
    this.setState({
      payment: {
        ...payment,
        [name]: value,
      },
    });
  }
  componentWillReceiveProps(nextProps, nextContext) {
    const { created, canceledRdv, getLastAvailabilityDate } = nextProps;

    const { initialValues } = this.state;
    if (getLastAvailabilityDate) {
      this.setState({
        initialValues: {
          ...initialValues,
          date: formatDate(getLastAvailabilityDate.date, 'YYYY-MM-DD'),
        },
      });
    }
    if (created && !canceledRdv && !initialValues.id) {
      this.handleShowRightSideBarRDV();
    }
    const { createdNewDispo } = nextProps;
    if (createdNewDispo && !initialValues.id) {
      this.handleShowRightSideBar();
    }
  }

  handleCityChange(value) {
    const { initialValues } = this.state;
    this.setState({
      initialValues: {
        ...initialValues,
        city: value,
      },
    });
  }
  handleErrorHoursChange(value) {
    const { initialValues } = this.state;
    value.map((item) => {
      this.setState({
        initialValues: {
          ...initialValues,
          [item.name]: item.value,
        },
      });
    });
  }
  handleErrorDateChange(value) {
    const { initialValues } = this.state;
    this.setState({
      initialValues: {
        ...initialValues,
        errorDate: value,
      },
    });
  }
  getItemRdv(id) {
    const { getAllRDV } = this.props;
    const rdv = getAllRDV.find((element) => element.id === parseInt(id));
    const { initialValues, paymentRdv } = this.state;
    this.setState({
      payment: {
        ...paymentRdv,
        id: rdv.payment ? rdv.payment.id : null,
        total_achieved: rdv.payment ? rdv.payment.total_achieved : null,
        already_paid: rdv.payment ? rdv.payment.already_paid : null,
        left_to_pay: rdv.payment ? rdv.payment.left_to_pay : null,
        money_pot_amount: rdv.payment ? rdv.payment.money_pot_amount : null,
        session_price: rdv.payment ? rdv.payment.session_price : null,
        payment_method: rdv.payment ? rdv.payment.payment_method : 'Espèce',
        settled_in_session: rdv.payment ? rdv.payment.settled_in_session : null,
        date: rdv.payment ? formatDate(rdv.payment.date, 'YYYY-MM-DD') : null,
      },
      initialValues: {
        ...initialValues,
        doctor: rdv.practitioner,
        id: rdv.id,
        id_update: rdv.id,
        note: rdv.note,
        doctor_id: rdv.practitioner_id,
        date_update: formatDate(rdv.date_from, 'YYYY-MM-DD'),
        display_name_update: rdv.display_name,
        city_update: rdv.city,
        city: rdv.city,
        status: rdv.status,
        labelMotif_update: rdv.motif.label === 'Autre' ? rdv.diagnostic : rdv.motif.label,
        phone_number_update: rdv.phone_number,
        motif_id_update: rdv.motif.id,
        startDate_update: formatDate(rdv.date_from, 'YYYY-MM-DD'),
        endDate: rdv.date_to,
        startHours: moment(rdv.date_from).format('HH:mm'),
        endHours: moment(rdv.date_to).format('HH:mm'),
      },
    });
  }
  changeStatus(status) {
    const { getAllRDV } = this.props;
    if (getAllRDV.length > 0) {
      let result = getAllRDV.filter((element) => element.status === status);
      this.setState({
        rdv: result,
      });
    } else
      this.setState({
        rdv: [],
      });
  }
  changeDateRDV() {
    const { getAllRDV } = this.props;
    this.setState({
      rdv: getAllRDV,
    });
  }
  componentDidMount() {
    this.props.changeDate({
      ...this.props.params,
      date: moment(),
    });
  }

  render() {
    const {
      getAllRDV,
      params,
      motifs,
      created,
      isLoadingAddRdv,
      isLoadingGetAllRDV,
      getLastAvailabilityDate,
      employeeManagement,
      user,
      isLoadingHolidayEmployeeManagement,
      isLoadingAbsenceHours,
      absenceHours,
    } = this.props;
    const { showRdv, payment, rdv, userList, initialValues } = this.state;
    if (isLoadingHolidayEmployeeManagement)
      return (
        <CircularProgress disableShrink style={{ marginLeft: '45%', marginTop: '30%' }} />
      );
    return (
      <div className="main_content">
        <div className="appointment_settings">
          <div className="appointment_settings_content">
            <div
              style={{ height: '50%', overflow: 'auto' }}
              className="appointment_settings_top_section"
            >
              <div className="appointment_settings_top_section_container">
                {employeeManagement && employeeManagement.length > 0
                  ? employeeManagement.map((item) => {
                      return (
                        <>
                          <div
                            style={{
                              borderLeft: `5px solid ${item.color}`,
                            }}
                            onClick={async () => {
                              this.handleChangeTopSectionItemLeftRDV(item.id, getAllRDV);
                              this.setState({
                                clickUser: true,
                              });
                            }}
                            className={
                              userList.includes(item.id)
                                ? 'top_section_item activated_item'
                                : 'top_section_item'
                            }
                          >
                            <div className="top_section_item_left_section">
                              <img
                                style={{ padding: '2%', width: '20%' }}
                                src={item.img}
                                alt=""
                              />
                              <div className="top_section_item_text">
                                <span
                                  style={{ width: 'max-content' }}
                                  id="main_title"
                                >{`${item.first_name} ${item.last_name}`}</span>
                                {/*<span id="sub_title">{item.speciality}</span>*/}
                                <span id="sub_title">{item.gsm}</span>
                              </div>
                            </div>
                            {/*{item.rdv.length > 0 ? (
                              <span className="badge badge-pill badge-primary">
                                {item.rdv.length}
                              </span>
                            ) : (
                              ''
                            )}*/}
                          </div>
                          <hr id="top_section_seperator" />
                        </>
                      );
                    })
                  : ''}
              </div>
            </div>
            <div
              style={{ marginBottom: '0' }}
              className="appointment_settings_bottom_section"
            >
              <div className="agenda_header">
                <div className="agenda_header_icon">
                  <svg
                    width="40"
                    height="40"
                    viewBox="0 0 40 40"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <rect
                      x="0.5"
                      y="0.5"
                      width="39"
                      height="39"
                      rx="9.5"
                      fill="white"
                      stroke="#ECEEF5"
                    />
                    <path
                      d="M21.5117 21.5117V22.9883H16.2383V21.5117H21.5117ZM25.2383 25.2383V17.0117H14.7617V25.2383H25.2383ZM25.2383 13.25C25.6367 13.25 25.9883 13.4023 26.293 13.707C26.5977 14.0117 26.75 14.3633 26.75 14.7617V25.2383C26.75 25.6367 26.5977 25.9883 26.293 26.293C25.9883 26.5977 25.6367 26.75 25.2383 26.75H14.7617C14.3398 26.75 13.9766 26.5977 13.6719 26.293C13.3906 25.9883 13.25 25.6367 13.25 25.2383V14.7617C13.25 14.3633 13.3906 14.0117 13.6719 13.707C13.9766 13.4023 14.3398 13.25 14.7617 13.25H15.5V11.7383H17.0117V13.25H22.9883V11.7383H24.5V13.25H25.2383ZM23.7617 18.4883V20H16.2383V18.4883H23.7617Z"
                      fill="#8083A3"
                    />
                  </svg>
                </div>
                <span>
                  {params.date
                    ? moment(params.date).format('MMMM').charAt(0).toUpperCase() +
                      moment(params.date).format('MMMM').substring(1).toLowerCase()
                    : moment().format('MMMM').charAt(0).toUpperCase() +
                      moment().format('MMMM').substring(1).toLowerCase()}
                </span>
                <div className="agenda_header_control">
                  <div
                    onClick={() => {
                      this.setState({
                        rdv: [],
                      });
                      if (userList.length > 0) {
                        this.props.changeDate(
                          {
                            ...params,
                            date: formatDate(moment(params.date).add(-1, 'months')),
                          },
                          userList.length > 1 ? userList : userList[0],
                        );
                        this.changeDateRDV();
                      }
                    }}
                    className="left_arrow_container"
                  >
                    <svg
                      width="40"
                      height="40"
                      viewBox="0 0 40 40"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <rect
                        x="0.5"
                        y="39.5"
                        width="39"
                        height="39"
                        rx="9.5"
                        transform="rotate(-90 0.5 39.5)"
                        fill="white"
                        stroke="#ECEEF5"
                      />
                      <path
                        d="M17.0117 20L21.5117 15.5L22.5664 16.5547L19.1211 20L22.5664 23.4453L21.5117 24.5L17.0117 20Z"
                        fill="#8083A3"
                      />
                    </svg>
                  </div>
                  <div
                    onClick={() => {
                      this.setState({
                        rdv: [],
                      });
                      if (userList.length > 0) {
                        this.props.changeDate(
                          {
                            ...params,
                            date: formatDate(moment(params.date).add(1, 'months')),
                          },
                          userList.length > 1 ? userList : userList[0],
                        );
                        this.changeDateRDV();
                      }
                    }}
                    className="right_arrow_container"
                  >
                    <svg
                      width="40"
                      height="40"
                      viewBox="0 0 40 40"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <rect
                        x="0.5"
                        y="39.5"
                        width="39"
                        height="39"
                        rx="9.5"
                        transform="rotate(-90 0.5 39.5)"
                        fill="white"
                        stroke="#ECEEF5"
                      />
                      <path
                        d="M16.8711 23.4453L20.3164 20L16.8711 16.5547L17.9258 15.5L22.4258 20L17.9258 24.5L16.8711 23.4453Z"
                        fill="#8083A3"
                      />
                    </svg>
                  </div>
                </div>
              </div>
              <div className="agenda_body">
                <div id="agenda_dates_item">
                  <DatePicker
                    locale="fr"
                    selected={params.date ? new Date(params.date) : new Date()}
                    disabledKeyboardNavigation
                    onChange={(date) => {
                      this.setState({
                        rdv: [],
                      });
                      if (userList.length > 0) {
                        this.props.changeDate(
                          {
                            ...params,
                            date: date,
                          },
                          userList.length > 1 ? userList : userList[0],
                        );
                        this.changeDateRDV();
                      } else {
                        toast(
                          () => (
                            <ToastAction
                              type="info"
                              message="Veuillez sélectionner un collaborateur"
                            />
                          ),
                          { hideProgressBar: true },
                        );
                      }
                    }}
                    inline
                  />
                </div>
              </div>
            </div>
          </div>
        </div>

        <AgendaM
          handleShowRightSideBarRDV={this.handleShowRightSideBarRDV}
          submitRDVForm={this.props.submitRDVForm}
          changeStatusRdv={this.props.changeStatusRdv}
          deleteRdv={this.props.deleteRdv}
          getAllRdvByDay={this.props.getAllRdvByDay}
          getAllRDV={getAllRDV}
          userList={userList}
          absenceHours={absenceHours}
          isLoadingAbsenceHours={isLoadingAbsenceHours}
          submitPaymentForm={this.props.submitPaymentForm}
          changeStatus={this.changeStatus}
          getItemRdv={this.getItemRdv}
          handleChangeInput={this.handleChangeInput}
          handleCityChange={this.handleCityChange}
          handleBtnTimeClick={this.handleBtnTimeClick}
          handleInitialStateRDV={this.handleInitialStateRDV}
          handleChangeTopSectionItemLeftRDV={this.handleChangeTopSectionItemLeftRDV}
          initialValues={initialValues}
          payment={payment}
          getAbsensesByHours={this.props.getAbsensesByHours}
          activeRDV={this.state.activeRDV}
          data={rdv}
          doctors={employeeManagement}
          user={user}
          handleChangeInputPayment={this.handleChangeInputPayment}
          item={showRdv}
          created={created}
          getLastAvailabilityDate={getLastAvailabilityDate}
          isLoadingAddRdv={isLoadingAddRdv}
          isLoadingGetAllRDV={isLoadingGetAllRDV}
          motifs={motifs}
          params={params}
          doctor_id={
            employeeManagement && employeeManagement.length > 0
              ? employeeManagement[0].id
              : null
          }
          employeeManagement={employeeManagement}
        />
      </div>
    );
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(Agenda);
