import React, { Component } from 'react';
import { SwipeableDrawer } from '@material-ui/core';

class SwipeableDrawerCreate extends Component {
  render() {
    const {
      activeItemSideBarRDV,
      activeRDV,
      item,
      initialValues,
      motifs,
      getAllDayDispo,
      isLoadingGetAllDayDispo,
      params,
      hasError,
      getLastAvailabilityDate,
      isLoadingGetLastAvailability,
      isLoadingAddRdv,
      employeeManagement,
      userList,
      absenceHours,
    } = this.props;
    return (
      <SwipeableDrawer
        style={{ width: '50%' }}
        anchor="right"
        open={activeItemSideBarRDV || activeRDV}
        onClose={this.props.toggleDrawer('right', false)}
        onOpen={this.props.toggleDrawer('right', true)}
      >
        <div className="right_side_bar">
          <div className="right_side_bar_header">
            <span id="add_app_sidebar_title">Ajouter un nouveau rendez-vous</span>
            <svg
              onClick={() => {
                this.props.handleInitialStateRDV();
                this.props.changeActiveItemSideBarRDV(activeItemSideBarRDV);
              }}
              style={{ cursor: 'pointer' }}
              id="close-right-sidebar"
              width="40"
              height="40"
              viewBox="0 0 40 40"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <rect
                x="0.5"
                y="0.5"
                width="39"
                height="39"
                rx="9.5"
                fill="white"
                stroke="#ECEEF5"
              />
              <path
                d="M25.2383 15.8164L21.0547 20L25.2383 24.1836L24.1836 25.2383L20 21.0547L15.8164 25.2383L14.7617 24.1836L18.9453 20L14.7617 15.8164L15.8164 14.7617L20 18.9453L24.1836 14.7617L25.2383 15.8164Z"
                fill="#8083A3"
              />
            </svg>
          </div>
          <div className="right_side_bar_body">
            {this.props.getStepContent(
              item,
              initialValues,
              motifs,
              getAllDayDispo,
              isLoadingGetAllDayDispo,
              params,
              hasError,
              getLastAvailabilityDate,
              isLoadingGetLastAvailability,
              isLoadingAddRdv,
              employeeManagement,
              userList,
              absenceHours,
            )}
            {item === 2 ? (
              ''
            ) : (
              <div
                className="right_sidebar_footer"
                style={{ marginTop: '10%', marginBottom: '10%' }}
              >
                {item > 0 ? (
                  <svg
                    style={{ cursor: 'pointer' }}
                    onClick={() => {
                      this.props.handleBack();
                    }}
                    className="patient_previous_button"
                    width="46"
                    height="46"
                    viewBox="0 0 46 46"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <rect
                      x="0.5"
                      y="45.5"
                      width="45"
                      height="45"
                      rx="9.5"
                      transform="rotate(-90 0.5 45.5)"
                      fill="white"
                      stroke="#ECEEF5"
                    />
                    <path
                      d="M20.0117 23L24.5117 18.5L25.5664 19.5547L22.1211 23L25.5664 26.4453L24.5117 27.5L20.0117 23Z"
                      fill="#8083A3"
                    />
                  </svg>
                ) : (
                  ''
                )}
                <button
                  onClick={() => {
                    this.props.handleNextItem(item);
                  }}
                  className="motif_next_button"
                >
                  Suivant
                </button>
              </div>
            )}
          </div>
        </div>
      </SwipeableDrawer>
    );
  }
}

export default SwipeableDrawerCreate;
