import React, { Component } from 'react';
import '../../facture/add_form.css';
import FormFacture from './form';
import actions from '../../facture/store/actions';
import actionsP from '../../patient_facture/store/actions';
import actionsConsultation from '../../consultations/store/actions';
import { connect } from 'react-redux';
import CircularProgress from '@material-ui/core/CircularProgress';
import { generateBillNumber } from '../../../utils/helpres';
import moment from 'moment';
import actionsJs from '../../../js/actions';

const mapStateToProps = (state) => ({
  tag: state.AppReducer.tag,
  regime: state.AppReducer.regime,
  lastBill: state.FactureReducer.lastBill,
  isLoadingLastBill: state.FactureReducer.isLoadingLastBill,
  updateBill: state.FactureReducer.updateBill,
  billData: state.FactureReducer.billData,
  office: state.AppReducer.office,
  patientRecord: state.PatientReducer.patientRecord,
  isLoadingPatientRecord: state.PatientReducer.isLoadingPatientRecord,
});

const mapDispatchToProps = (dispatch) => ({
  getPatientRecordById: (id) => dispatch(actionsP.getPatientRecordById(id)),

  getPecById: async (id) => {
    await dispatch(actions.getPecById(id));
  },
  getConsultationById: (id) => dispatch(actionsConsultation.getConsultationById(id)),
  getLastBill: async (tag) => {
    await dispatch(actions.getLastBill(tag));
  },
  getOfficeByIdDoctor: () => dispatch(actionsJs.getOfficeByIdDoctor()),
});
class AddFacturePatient extends Component {
  constructor(props) {
    super(props);
    props.changeNamePage('Ajouter une facture');
    props.changeActiveItemSideBar(props.active);
    document.title = 'Ajouter une facture';
    props.getOfficeByIdDoctor();
    props.getLastBill('bill');
    props.getPatientRecordById(
      //.location.state
      this.props.location && this.props.location.state
        ? this.props.location.state.patient_id
        : null,
    );
    this.state = {
      type: false,
    };
    this.changeActionBill = this.changeActionBill.bind(this);
  }
  changeActionBill(print) {
    this.setState({
      type: print,
    });
  }
  componentDidUpdate(prevProps, prevState) {
    const { updateBill, billData, office } = this.props;
    const { type } = this.state;
    if (updateBill && updateBill !== prevProps.updateBill) {
      if (type) {
        this.props.history.push({
          pathname: '/facture-pdf',
          state: {
            office: office,
            facture: billData.data,
            redirect: 'PEC',
            //tag: tag,
          },
        });
      } else this.props.history.push(`/patients/${this.props.location.state.patient_id}`);
    }
  }

  render() {
    const {
      isLoadingLastBill,
      patient,
      lastBill,
      tag,
      office,
      patientRecord,
      isLoadingPatientRecord,
    } = this.props;
    if (isLoadingLastBill || isLoadingPatientRecord)
      return (
        <CircularProgress disableShrink style={{ marginLeft: '50%', marginTop: '22%' }} />
      );

    return (
      <div className="main_content">
        <div className="facture_info_section_form">
          <div className="container_changeable_content">
            <div className="add_facture_section">
              <div className="selected_facture_details">
                <div className="selected_facture_info_container">
                  <div className="selected_facture_img_container">
                    <svg
                      width="14"
                      height="14"
                      viewBox="0 0 14 14"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M3.02734 9.35547C4.41016 8.79297 5.73438 8.51172 7 8.51172C8.26562 8.51172 9.57812 8.79297 10.9375 9.35547C12.3203 9.89453 13.0117 10.6094 13.0117 11.5V13.0117H0.988281V11.5C0.988281 10.6094 1.66797 9.89453 3.02734 9.35547ZM9.10938 6.12109C8.52344 6.70703 7.82031 7 7 7C6.17969 7 5.47656 6.70703 4.89062 6.12109C4.30469 5.53516 4.01172 4.83203 4.01172 4.01172C4.01172 3.19141 4.30469 2.48828 4.89062 1.90234C5.47656 1.29297 6.17969 0.988281 7 0.988281C7.82031 0.988281 8.52344 1.29297 9.10938 1.90234C9.69531 2.48828 9.98828 3.19141 9.98828 4.01172C9.98828 4.83203 9.69531 5.53516 9.10938 6.12109Z"
                        fill="#8083A3"
                      />
                    </svg>
                  </div>
                  <div className="selected_facture_patient_info">
                    <span id="selected_facture_patient_name">
                      {patientRecord && patientRecord.patient
                        ? `${patientRecord.patient.first_name} ${patientRecord.patient.last_name}`
                        : ''}
                    </span>
                    <span id="selected_facture_patient_city">
                      {patientRecord && patientRecord.patient
                        ? patientRecord.patient.phone
                        : ''}
                    </span>
                  </div>
                </div>
              </div>
              <FormFacture
                goBack={() =>
                  patient ? this.props.goBack() : this.props.history.goBack()
                }
                regime="autre"
                office={office}
                type="patient_record"
                id={patientRecord && patientRecord.id}
                tag={tag}
                start_date={moment().format('YYYY-MM-DD')}
                changeActionBill={this.changeActionBill}
                end_date={moment().add(1, 'days').format('YYYY-MM-DD')}
                numBill={
                  lastBill && lastBill.length > 0
                    ? `${generateBillNumber(
                        parseInt(lastBill[0].number.split('/')[0]) + 1,
                        moment().format('YYYY'),
                      )}`
                    : `${generateBillNumber(0, moment().format('YYYY'))}`
                }
                lastBill={lastBill && lastBill.length > 0 ? lastBill[0] : null}
                sessions_count={0}
                unit_price={11.5}
              />
            </div>
          </div>
        </div>
      </div>
    );
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(AddFacturePatient);
