import React, { Component } from 'react';
import { SelectorContactConsultationBodyItem } from '../../../utils';
import moment from 'moment';
import { Link } from 'react-router-dom';

class Item_Consultation extends Component {
  componentDidMount() {
    if (this.props.item.id === this.props.id) {
      this.props.getConsultationById(this.props.id);
    }
    SelectorContactConsultationBodyItem();
  }
  render() {
    const { item, id } = this.props;
    const month =
      moment(item.createdAt).format('MMMM').charAt(0).toUpperCase() +
      moment(item.createdAt).format('MMMM').substring(1).toLowerCase();
    return (
      <Link to={`/consultations/${item.id}`}>
        <div
          /*onClick={() => {
            this.props.getConsultationById(item.id);
          }}*/
          key={item.id}
          className="consultation_schedule_item"
        >
          <div
            style={{ width: '90%' }}
            className="consultation_schedule_sub_item"
            id="consultation_schedule_timing"
          >
            <div
              style={{
                width: '35%',
                display: 'flex',
                gap: '30px',
              }}
            >
              <i className="far fa-clock"></i>
              <div className="text_clock_cont">
                <span id="start_time">
                  {`${moment(item.createdAt).format('DD')}.
                            ${month.substring(0, 3)}`}
                </span>
                <span id="end_time">{moment(item.createdAt).format('YYYY')}</span>
              </div>
              <div className="consultation_schedule_text">
                <span id="type_consultation">
                  {item.motif && item.motif.label === 'Autre'
                    ? item.diagnostic
                    : item.motif.label}
                </span>
                <span id="patient_name">
                  {item.patient_record && item.patient_record.patient
                    ? `${item.patient_record.patient.first_name.replace(
                        /(^\w{1})|(\s+\w{1})/g,
                        (letter) => letter.toUpperCase(),
                      )} ${item.patient_record.patient.last_name.replace(
                        /(^\w{1})|(\s+\w{1})/g,
                        (letter) => letter.toUpperCase(),
                      )}`
                    : '-'}
                </span>
              </div>
            </div>
            <div style={{ width: '15%' }} className="consultation_schedule_text">
              <span id="patient_name">{item.patient_record.patient.phone || '-'}</span>
            </div>
            <div style={{ width: '15%' }} className="consultation_schedule_text">
              <span id="patient_name">
                {item.patient_record.patient.cnam_key
                  ? `${item.patient_record.patient.cnam_root}/${item.patient_record.patient.cnam_key}`
                  : '-'}
              </span>
            </div>
            <div style={{ width: '15%' }} className="consultation_schedule_text">
              <span id="patient_name">
                {item.patient_record.patient.regime === 1
                  ? 'Cnam'
                  : item.patient_record.patient.regime === 1
                  ? 'Act'
                  : 'Autre'}
              </span>
            </div>
            <div style={{ width: '15%' }} className="consultation_schedule_text">
              <span id="patient_name">{item.sessions_count || '-'}</span>
            </div>
            <div style={{ width: '15%' }} className="consultation_schedule_text">
              <span id="patient_name">{item.sessions_per_week || '-'}</span>
            </div>
          </div>

          <div className="call_icon_consultation">
            <i className="fas fa-angle-right"></i>
          </div>
        </div>
      </Link>
    );
  }
}
export default Item_Consultation;
