import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import Empty_container from '../../../components/empty_container';
import { formatDate, isHasPermission } from '../../../utils/helpres';
import moment from 'moment';

class Bill extends Component {
  render() {
    const {
      patient,
      regime,
      prise_en_charges,
      patientItem,
      pec,
      bills,
      user,
      consultationID,
      patientB,
    } = this.props;
    if (bills && bills.length === 0 && !patientB)
      return (
        <Empty_container
          hide={patient ? true : regime === 'autre' ? false : pec}
          user={user}
          permission="ADD_NEW_BILL"
          title="Pas de factures !"
          body={
            regime === 'autre'
              ? ''
              : patient
              ? "Aucune facture n'est encore enregistrée pour ce patient"
              : prise_en_charges && prise_en_charges.length === 0
              ? "Pas des factures. Essayer d'en créer une."
              : `Cette prise en charge sera facturable à partir de ${
                  prise_en_charges && prise_en_charges.length > 0
                    ? formatDate(prise_en_charges[0].end_date)
                    : formatDate(moment())
                }`
          }
          btn_name="Ajouter facture"
          url={{
            pathname: patient ? '' : `/add-facture/${consultationID}`,
            state: {
              patient_id: patientItem,
            },
          }}
        />
      );
    return (
      <div className="add_bilan_container" style={{ marginTop: '15px' }}>
        <div className="add_bilan_container_header">
          <div className="left">
            <span>Factures</span>
          </div>
        </div>
        <div className="add_document_container_body">
          {bills && bills.length > 0
            ? bills.map((bill) => {
                return (
                  <div key={bill.id} className="add_document_content_preview">
                    <div className="add_document_content_preview_item">
                      <div
                        style={{ width: '80%' }}
                        className="bilan_preview_sub_item"
                        id="patient_consulation_timing"
                      >
                        <svg
                          width="38"
                          height="38"
                          viewBox="0 0 38 38"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <rect
                            x="0.5"
                            y="0.5"
                            width="37"
                            height="37"
                            rx="9.5"
                            fill="white"
                            stroke="#ECEEF5"
                          />
                          <path
                            d="M19.7383 16.75H23.8867L19.7383 12.6367V16.75ZM21.9883 20.5117V19H16.0117V20.5117H21.9883ZM21.9883 23.5V21.9883H16.0117V23.5H21.9883ZM20.5117 11.5117L25.0117 16.0117V25.0117C25.0117 25.4102 24.8594 25.7617 24.5547 26.0664C24.25 26.3477 23.8984 26.4883 23.5 26.4883H14.5C14.1016 26.4883 13.75 26.3477 13.4453 26.0664C13.1406 25.7617 12.9883 25.4102 12.9883 25.0117L13.0234 12.9883C13.0234 12.5898 13.1641 12.25 13.4453 11.9688C13.75 11.6641 14.1016 11.5117 14.5 11.5117H20.5117Z"
                            fill="#8083A3"
                          />
                        </svg>
                        <div className="prise_en_charge_text">
                          <span id="prise_en_charge_text_title">{bill.number}</span>
                          <span id="prise_en_charge_text_desc">
                            {' '}
                            {formatDate(bill.date)}
                          </span>
                        </div>
                      </div>
                      {isHasPermission(user.login_access.permissions, 'BILLS_ACCESS') ? (
                        <Link
                          key={bill.id}
                          to={`/show-facture/${bill.id}`}
                          // to={`/bilan/${bill.bilan.label}/${consultationID}/${bill.id}`}
                        >
                          <div
                            style={{ marginRight: '1%' }}
                            className="more_icon_container"
                          >
                            <i
                              style={{
                                padding: '0',
                                border: '',
                                color: '',
                                borderRadius: '',
                                backgroundColor: 'white',
                              }}
                              className="fas fa-eye"
                              aria-hidden="true"
                            ></i>
                          </div>
                        </Link>
                      ) : (
                        ''
                      )}
                    </div>
                  </div>
                );
              })
            : ''}
          <Link
            to={{
              pathname: '/add-facture/patient',
              state: {
                patient_id: patientItem,
              },
            }}
          >
            <div
              style={{ marginLeft: 20, marginTop: 10 }}
              className="empty_container_btn"
            >
              <svg
                width="11"
                height="12"
                viewBox="0 0 11 12"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M10.7383 6.95557H6.23828V11.4556H4.76172V6.95557H0.261719V5.479H4.76172V0.979004H6.23828V5.479H10.7383V6.95557Z"
                  fill="#5446DB"
                />
              </svg>{' '}
              <span>Ajouter facture</span>
            </div>
          </Link>
        </div>
      </div>
    );
  }
}
export default Bill;
