import React, { Component } from 'react';
import Form from '../containers/form-medecins';
import actionsJs from '../../../js/actions';
import { connect } from 'react-redux';
import HeaderList from '../../../components/headerList';

const mapStateToProps = (state) => ({
  create: state.AppReducer.create,
  actionMedecin: state.AppReducer.actionMedecin,
  medecin: state.AppReducer.medecin,
  isLoadingMedecin: state.AppReducer.isLoadingMedecin,
  switchList: state.AppReducer.switchList,
  activeItemSideBarSETTINGS: state.AppReducer.activeItemSideBarSETTINGS,
});

const mapDispatchToProps = (dispatch) => ({
  getMedecin: (id) => dispatch(actionsJs.getMedecin(id)),
});
const headers = [
  { name: 'Nom & prénom', width: '20%', type: 'text' },
  { name: 'Adresse', width: '20%', type: 'string' },
  { name: 'Téléphone', width: '20%', type: 'string' },
  { name: 'Action', width: '20%', type: 'action' },
];
class Medecins extends Component {
  constructor(props) {
    super(props);
    props.changeNamePage('Gestion des médecins');
    document.title = 'Gestion des médecins';
    this.state = {
      create: false,
      switchList: false,
      employee_selected: null,
    };
    this.handleSelectItem = this.handleSelectItem.bind(this);
  }
  handleSelectItem() {
    this.setState({
      switchList: false,
      create: false,
    });
  }
  componentDidUpdate(prevProps, prevState, snapshot) {
    const { actionMedecin, activeItemSideBarSETTINGS } = this.props;
    if (actionMedecin !== prevProps.actionMedecin) {
      this.props.changeActiveItemSideBarSettings(activeItemSideBarSETTINGS, false, true);
    }
  }
  render() {
    const {
      sandwich_menu_btn,
      medecins,
      medecin,
      switchList,
      activeItemSideBarSETTINGS,
      create,
    } = this.props;

    return (
      <div
        className="general_info"
        style={{ marginTop: 5, width: '87%', height: 'auto' }}
      >
        {switchList ? (
          <div className="" style={{ width: '100%' }}>
            <div className="container_changeable_content" style={{ height: '100%' }}>
              <div className="patient_detailed_info_section">
                <svg
                  onClick={async () => {
                    this.props.changeActiveItemSideBarSettings(
                      activeItemSideBarSETTINGS,
                      false,
                    );
                  }}
                  width="46"
                  height="46"
                  viewBox="0 0 46 46"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                  style={{ cursor: 'pointer' }}
                >
                  <rect
                    x="0.5"
                    y="45.5"
                    width="45"
                    height="45"
                    rx="9.5"
                    transform="rotate(-90 0.5 45.5)"
                    fill="white"
                    stroke="#ECEEF5"
                  ></rect>
                  <path
                    d="M20.0117 23L24.5117 18.5L25.5664 19.5547L22.1211 23L25.5664 26.4453L24.5117 27.5L20.0117 23Z"
                    fill="#8083A3"
                  ></path>
                </svg>
                <Form
                  goBack={async () => {
                    this.props.changeActiveItemSideBarSettings(
                      activeItemSideBarSETTINGS,
                      false,
                      true,
                    );
                  }}
                  sandwich_menu_btn={sandwich_menu_btn}
                  handleSelectItem={this.handleSelectItem}
                  medecin={create ? null : medecin}
                />
              </div>
            </div>
          </div>
        ) : (
          <div className="contact_patients_body" style={{ height: '100%' }}>
            <div style={{ display: 'flex', gap: 10 }}>
              <svg
                onClick={async () => {
                  this.props.goBackItem('showMedecins');
                }}
                width="46"
                height="46"
                viewBox="0 0 46 46"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                style={{ cursor: 'pointer' }}
              >
                <rect
                  x="0.5"
                  y="45.5"
                  width="45"
                  height="45"
                  rx="9.5"
                  transform="rotate(-90 0.5 45.5)"
                  fill="white"
                  stroke="#ECEEF5"
                ></rect>
                <path
                  d="M20.0117 23L24.5117 18.5L25.5664 19.5547L22.1211 23L25.5664 26.4453L24.5117 27.5L20.0117 23Z"
                  fill="#8083A3"
                ></path>
              </svg>
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'space-between',
                  marginTop: 10,
                }}
              >
                <span className="patients_consultations_record_label">
                  Liste des médecins
                </span>
              </div>
            </div>
            <br />
            <HeaderList headers={headers} />
            {medecins && medecins.length > 0 ? (
              medecins.map((item) => {
                return (
                  <div key={item.id} className="contact_patients_body_item">
                    <div
                      style={{
                        alignItems: 'center',
                        width: '100%',
                      }}
                      className="patient_contact_info_container"
                    >
                      <div
                        className="patient_contact_img_container"
                        style={{ marginLeft: '40px', width: '4%', height: '60px' }}
                      >
                        <svg
                          width="20"
                          height="20"
                          viewBox="0 0 33 34"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M5.73958 23.2812C9.42708 21.7812 12.9583 21.0312 16.3333 21.0312C19.7083 21.0312 23.2083 21.7812 26.8333 23.2812C30.5208 24.7188 32.3646 26.625 32.3646 29V33.0312H0.302084V29C0.302084 26.625 2.11458 24.7188 5.73958 23.2812ZM21.9583 14.6562C20.3958 16.2188 18.5208 17 16.3333 17C14.1458 17 12.2708 16.2188 10.7083 14.6562C9.14583 13.0938 8.36458 11.2188 8.36458 9.03125C8.36458 6.84375 9.14583 4.96875 10.7083 3.40625C12.2708 1.78125 14.1458 0.96875 16.3333 0.96875C18.5208 0.96875 20.3958 1.78125 21.9583 3.40625C23.5208 4.96875 24.3021 6.84375 24.3021 9.03125C24.3021 11.2188 23.5208 13.0938 21.9583 14.6562Z"
                            fill="#8083A3"
                          ></path>
                        </svg>
                      </div>
                      <div style={{ width: '27%' }} className="patient_contact_info">
                        <span id="patient_contact_name">{`${item.first_name} ${item.last_name}`}</span>
                        <span
                          id="sub_title"
                          style={{
                            color: 'grey',
                          }}
                        >
                          {item.speciality}
                        </span>
                      </div>
                      <div
                        style={{
                          width: '18%',
                          textAlign: 'left',
                          margin: '0 2vw 0 -2vw',
                        }}
                        className="patient_contact_info"
                      >
                        <span id="patient_contact_name">{item.street || '-'}</span>
                      </div>
                      <div
                        style={{ width: '22%', textAlign: 'left' }}
                        className="patient_contact_info"
                      >
                        <span id="patient_contact_name">{item.phone || '-'}</span>
                      </div>
                      <div style={{ width: '15%' }} className="patient_contact_info">
                        <div
                          onClick={async () => {
                            await this.props.getMedecin(item.id);
                            await this.handleSelectItem(item.id);
                            this.props.changeActiveItemSideBarSettings(
                              activeItemSideBarSETTINGS,
                              true,
                            );
                          }}
                          className="more_icon_container"
                          style={{ marginRight: '20px' }}
                        >
                          <i
                            style={{
                              padding: '0',
                              border: '',
                              color: '',
                              borderRadius: '',
                              backgroundColor: 'white',
                            }}
                            className="fas fa-edit"
                            aria-hidden="true"
                          ></i>
                        </div>
                      </div>
                    </div>

                    {/* <div className="call_icon_patient">
                      <i className="fas fa-angle-right"></i>
                    </div>*/}
                  </div>
                );
              })
            ) : (
              <div className="consultation_detailed_info_section">
                <div className="empty_consultation_result_container">
                  <div className="empty_consultation_result">
                    <img
                      src={require('../../../assets/img/undraw_design_team_af2y.svg')}
                    />
                    <span id="no_consultation"> Liste vide !</span>
                    <span id="no_consultation_desc">
                      Veuillez créer des nouveaux médecins prescripteurs.
                    </span>
                  </div>
                </div>
              </div>
            )}
          </div>
        )}
      </div>
    );
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(Medecins);
