import React, { Component } from 'react';
import { withRouter } from 'react-router';
import { Redirect, Route, Switch } from 'react-router-dom';
import { ERROR_AUTH, isHasPermission, TOAST_CLOSE_DELAY } from './utils/helpres';
import { connect } from 'react-redux';
import { ToastContainer } from 'react-toastify';
/*Import jquery*/
import $ from 'jquery';
/*Layouts*/
import SideBar from './layouts/sideBar';
import Headres from './layouts/headres';
/*Pages*/
import DetailsFacture from './pages/facture/details';
import DetailsReceipt from './pages/receipt/details';
import Depenses from './pages/depense';
import FormDepense from './pages/depense/form-depense';
import Recettes from './pages/recette';
import FormRecette from './pages/recette/form-recette';
import Profil from './pages/profile';
import Dashboard from './pages/dashborad';
import Login from './pages/authentification/login';
import Register from './pages/authentification/register';
import SuccessSignUp from './pages/authentification/register/success';
import ResetPassword from './pages/authentification/resetPassword';
import Bilans from './pages/bilans';
import Agenda from './pages/full-calender';
import RDVPdf from './pages/full-calender/rdv-pdf';
import AgendaDraft from './pages/agenda-draft';
import AgendaManuel from './pages/agenda-manuel';
import AgendaPractitioner from './pages/agenda-practitioner';
import FormPatient from './pages/patient/form_patient';
import Patients from './pages/patient';
import Factures from './pages/facture';
import AddFacture from './pages/facture/add_facture';
import EditFacture from './pages/facture/edit';
import FacturePdf from './pages/facture/format_pdf';
import ReceiptPdf from './pages/receipt/format_pdf';
import Receipts from './pages/receipt';
import AddReceipt from './pages/receipt/add_received';
import SeancePdf from './pages/consultations/memoire_seance_pdf';
import PlanDeReeducation from './pages/consultations/form-protocol';
import Consulatations from './pages/consultations';
import ConsulatationsV2 from './pages/consultations/index-v2';
import PatinetsV2 from './pages/patient/index-2';
import Support from './pages/support';
import Messenger from './pages/messenger';
import PatientFacture from './pages/patient_facture';
import PatientReceipt from './pages/patient_receipt';
import Epaule from './pages/bilans/epaule';
import EpaulePdf from './pages/bilans/epaule/epaule_pdf';
import Main from './pages/bilans/main';
import Cheville from './pages/bilans/cheville';
import Coude from './pages/bilans/coude';
import Hanche from './pages/bilans/hanche';
import Genou from './pages/bilans/genou';
import RachisCervical from './pages/bilans/rachis_cervical';
import RachisDorsal from './pages/bilans/rachis_dorsal';
import RachisLombaire from './pages/bilans/rachis_lombo_sacre';
import Pied from './pages/bilans/pied';
import Poignet from './pages/bilans/poignet';
import BilanLibre from './pages/bilans/libre';
import APA from './pages/bilans/APA';
import RachisComplet from './pages/bilans/rachis_complet';
import MSupComplet from './pages/bilans/m_sup_complet';
import MInfComplet from './pages/bilans/m_inf_complet';
import Gériatrie from './pages/bilans/gériatrie';
import Pédiatrie from './pages/bilans/pediatrie';
import UroGyneco from './pages/bilans/uro_gyneco';
import ReeducationPerineale from './pages/bilans/reeducation_perineale';
import RespiPediatrique from './pages/bilans/respi-pediatrique';
import PneumologieAdulte from './pages/bilans/pneumologie_adulte';
import Bronchiolite from './pages/bilans/Bronchiolite';
import Mucoviscidose from './pages/bilans/Mucoviscidose';
import GeneralNcentrale from './pages/bilans/general_n_centrale';
import Parkinson from './pages/bilans/parkinson';
import BilanSEP from './pages/bilans/s_e_p';
import AVC_Hémiplégie from './pages/bilans/avc_hemiplegie';
import GeneralNPeripherique from './pages/bilans/general_n_peripherique';
import CervicalMckz from './pages/bilans/cervical_mckz';
import ThoraciqueMckz from './pages/bilans/thoracique_mckz';
import LombaireMckz from './pages/bilans/lombaire_mckz';
import CoureurAPiedGauche from './pages/bilans/coureura_pied_gauche';
import LymphoedemeMembreSuperieur from './pages/bilans/lymphoedeme_membre_superieur';
import LymphoedemeMembreInférieur from './pages/bilans/lymphoedeme_membre_inférieur';
import MaxilloFacial from './pages/bilans/maxillo_facial';
import ParalysieFaciale from './pages/bilans/paralysie_faciale';
import OroFacial from './pages/bilans/oro_facial';
import Vestibulaire from './pages/bilans/vestibulaire';
import CancerDuSein from './pages/bilans/cancer_du_sein';
import QuadranSupTMO from './pages/bilans/quadran_sup_TMO';
import QuadranInfTMO from './pages/bilans/quadran_inf_TMO';
// bilans
import Pec from './pages/pec';
import VirtualPec from './pages/virtual_pec';
import Bordereau from './pages/bordereau/bordereau';
import BordereauPdf from './pages/bordereau/bordereau-pdf';
import BordereauPdfAutre from './pages/bordereau/pdf_autre';
import Bordereaux from './pages/bordereau';
import Settings from './pages/settings';
import FormBordereau from './pages/bordereau/containers/form-bordereau';
import TransferListRDV from './pages/agenda/form-affectation-rdv';
import Payment from './pages/payment';
import FullCalendar from './pages/full-calender';
import ModuleCnam from './pages/module-cnam';
import Compta from './pages/compta';

/*Actions*/
import actions from './js/actions';
import actionsMessenger from './pages/messenger/store/actions';
import CircularProgress from '@material-ui/core/CircularProgress';
import RecetteDepensePDF from './pages/compta/recette-depense-pdf';
import AddFacturePatient from './pages/patient/bill';
import CreateBordereauCnam from './pages/bordereau/containers/createBordereauCnam';

window.jQuery = window.$ = $;

const mapStateToProps = (state) => ({
  tag: state.AppReducer.tag,
  sandwich_menu_btn: state.AppReducer.sandwich_menu_btn,
  activeItemSideBarRDV: state.AppReducer.activeItemSideBarRDV,
  side_bar_menu: state.AppReducer.side_bar_menu,
  isLoading: state.AppReducer.isLoading,
  isAuthorized: state.AppReducer.isAuthorized,
  user: state.AppReducer.user,
  error: state.AppReducer.error,
  countMessageIsSeen: state.AppReducer.countMessageIsSeen,
  namePage: state.AppReducer.namePage,
  activeItemSideBar: state.AppReducer.activeItemSideBar,
  countMessagesIsSeen: state.MessengerReducer.countMessagesIsSeen,
  activeItemSideBarSETTINGS: state.AppReducer.activeItemSideBarSETTINGS,
  activeItemSideBarCompta: state.AppReducer.activeItemSideBarCompta,
});

const mapDispatchToProps = (dispatch) => ({
  changeActiveItemSideBarRDV: (name) =>
    dispatch(actions.changeActiveItemSideBarRDV(name)),
  changeActiveItemSideBarCompta: (data) =>
    dispatch(actions.changeActiveItemSideBarCompta(data)),
  changeNamePage: (name) => dispatch(actions.changeNamePage(name)),
  changeActiveItemSideBar: (item) => dispatch(actions.changeActiveItemSideBar(item)),
  getMe: () => dispatch(actions.getMe()),
  changeCountMessage: (countMessage) =>
    dispatch(actions.changeCountMessage(countMessage)),
  changeSandwichMenuBtn: () => dispatch(actions.changeSandwichMenuBtn()),
  countMessagesIsSeen: () => dispatch(actionsMessenger.countMessagesIsSeen()),
});

class App extends Component {
  constructor(props) {
    super(props);
    props.getMe();
    props.countMessagesIsSeen();
    this.handleChangeMenuBtn = this.handleChangeMenuBtn.bind(this);
  }

  handleChangeMenuBtn() {
    this.props.changeSandwichMenuBtn();
  }

  render() {
    const {
      isLoading,
      side_bar_menu,
      sandwich_menu_btn,
      isAuthorized,
      user,
      error,
      countMessageIsSeen,
      countMessagesIsSeen,
      namePage,
      activeItemSideBar,
      activeItemSideBarRDV,
      activeItemSideBarSETTINGS,
      activeItemSideBarCompta,
      tag,
    } = this.props;
    let app;
    const { pathname } = this.props.location;
    if (error && error !== ERROR_AUTH && error !== 400 && error !== 600) {
      app = (
        <div className="container_changeable_content">
          <div className="fatcure_detailed_info_section">
            <div
              style={{ marginTop: '10%' }}
              className="empty_consultation_result_container"
            >
              <div className="patient_detailed_info_section_body">
                <div className="empty_consultation_result">
                  <img src={require('./assets/img/error_image.svg')} />
                  <span id="no_consultation">Ooops !</span>
                  <span id="no_consultation_desc">
                    Quelque chose a mal tourné. Merci de cliquer sur le bouton pour
                    refraichir la page.
                  </span>
                  <button id="add_rv" onClick={() => document.location.reload()}>
                    Réessayer
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      );
    } else if (isLoading) {
      app = <CircularProgress disableShrink style={{ marginTop: '25%' }} />;
    } else if (!isAuthorized) {
      app = (
        <>
          <ToastContainer
            closeOnClick={false}
            autoClose={TOAST_CLOSE_DELAY}
            position="bottom-left"
            pauseOnHover
            //toastClassName="toast"
            bodyClassName="toast-body"
            progressClassName="toast-progress"
            className="toast-container"
          />
          <Switch>
            <Route
              exact
              path="/login"
              render={(props) => <Login {...props} />}
              key="Login"
            />
            <Route
              exact
              path="/register"
              render={(props) => <Register {...props} />}
              key="Register"
            />
            <Route
              exact
              path="/register/success"
              render={(props) => <SuccessSignUp {...props} />}
              key="RegisterSuccess"
            />
            <Route
              exact
              path="/reset/password"
              render={(props) => <ResetPassword {...props} />}
              key="ResetPassword"
            />
            <Redirect to="/login" />
          </Switch>
        </>
      );
    } else if (
      user &&
      user.login_access &&
      user.login_access.access_status !== 'active'
    ) {
      app = (
        <div className="container_changeable_content">
          <div className="fatcure_detailed_info_section">
            <div
              style={{ marginTop: '10%' }}
              className="empty_consultation_result_container"
            >
              <div className="patient_detailed_info_section_body">
                <div className="empty_consultation_result">
                  <img src={require('./assets/img/error_image.svg')} />
                  <span id="no_consultation">Ooops !</span>
                  <span id="no_consultation_desc">Votre compte est déactivée</span>
                  <button id="add_rv" onClick={() => document.location.reload()}>
                    Réessayer
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      );
    } else {
      app = (
        <>
          <ToastContainer
            closeOnClick={false}
            autoClose={TOAST_CLOSE_DELAY}
            position="bottom-left"
            pauseOnHover
            //toastClassName="toast"
            bodyClassName="toast-body"
            bilan_item
            progressClassName="toast-progress"
            className="toast-container"
          />
          <section className="all_container">
            <SideBar
              admin={
                user.login_access.offices.length > 0
                  ? user.login_access.id ===
                    user.login_access.offices[0].practitioner_office.user_id
                    ? true
                    : false
                  : false
              }
              changeNamePage={this.props.changeNamePage}
              changeActiveItemSideBar={this.props.changeActiveItemSideBar}
              sandwich_menu_btn={sandwich_menu_btn}
              countMessageIsSeen={countMessageIsSeen}
              countMessagesIsSeen={countMessagesIsSeen}
              side_bar_menu={side_bar_menu}
              user={user}
              activeItemSideBar={activeItemSideBar}
            />
            <section
              style={{
                //backgroundColor: pathname === '/settings' ? '#efefef' : '#ffffff',
                width: sandwich_menu_btn ? '85%' : '95%',
              }}
              className="content"
            >
              <Headres
                admin={
                  user.login_access.offices.length > 0
                    ? user.login_access.id ===
                      user.login_access.offices[0].practitioner_office.user_id
                      ? true
                      : false
                    : false
                }
                activeItemSideBarSETTINGS={activeItemSideBarSETTINGS}
                tag={tag}
                user={user}
                namePage={namePage}
                sandwich_menu_btn={sandwich_menu_btn}
                activeItemSideBarCompta={activeItemSideBarCompta}
                activeItemSideBar={activeItemSideBar}
                activeItemSideBarRDV={activeItemSideBarRDV}
                side_bar_menu={side_bar_menu}
                handleChangeMenuBtn={this.handleChangeMenuBtn}
                changeActiveItemSideBarRDV={this.props.changeActiveItemSideBarRDV}
              />
              <Switch>
                <Route
                  exact
                  path="/dashboard"
                  render={(props) => (
                    <Dashboard
                      account_type={
                        user.login_access.offices.length > 0 &&
                        user.login_access.id ===
                          user.login_access.offices[0].practitioner_office.user_id
                          ? 'admin'
                          : user.login_access.account_type === 'user'
                          ? 'user'
                          : user.login_access.account_type === 'assistant'
                          ? 'assistant'
                          : 'secretary'
                      }
                      admin={
                        user.login_access.offices.length > 0
                          ? user.login_access.id ===
                            user.login_access.offices[0].practitioner_office.user_id
                            ? true
                            : false
                          : false
                      }
                      changeNamePage={this.props.changeNamePage}
                      changeActiveItemSideBar={this.props.changeActiveItemSideBar}
                      {...props}
                    />
                  )}
                  key="Dashboard"
                />
                <Route
                  exact
                  path="/full-calendar"
                  render={(props) => (
                    <FullCalendar
                      activeItemSideBarRDV={activeItemSideBarRDV}
                      changeNamePage={this.props.changeNamePage}
                      changeActiveItemSideBarRDV={this.props.changeActiveItemSideBarRDV}
                      changeActiveItemSideBar={this.props.changeActiveItemSideBar}
                      {...props}
                    />
                  )}
                  key="FullCalendar"
                />
                {((user.login_access.offices.length &&
                  user.login_access.id ===
                    user.login_access.offices[0].practitioner_office.user_id) ||
                  user.login_access.account_type === 'secretary') &&
                isHasPermission(user.login_access.permissions, 'CALENDAR_ACCESS') ? (
                  <Route
                    exact
                    path="/agenda"
                    render={(props) => (
                      <Agenda
                        account_type={
                          user.login_access.offices.length > 0 &&
                          user.login_access.id ===
                            user.login_access.offices[0].practitioner_office.user_id
                            ? 'admin'
                            : user.login_access.account_type === 'user'
                            ? 'user'
                            : user.login_access.account_type === 'assistant'
                            ? 'assistant'
                            : 'secretary'
                        }
                        changeActiveItemSideBar={this.props.changeActiveItemSideBar}
                        changeNamePage={this.props.changeNamePage}
                        activeItemSideBarRDV={activeItemSideBarRDV}
                        changeActiveItemSideBarRDV={this.props.changeActiveItemSideBarRDV}
                        {...props}
                      />
                    )}
                    key="Agenda"
                  />
                ) : isHasPermission(user.login_access.permissions, 'CALENDAR_ACCESS') ? (
                  <Route
                    exact
                    path="/agenda-practitioner"
                    render={(props) => (
                      <AgendaPractitioner
                        changeActiveItemSideBar={this.props.changeActiveItemSideBar}
                        changeNamePage={this.props.changeNamePage}
                        {...props}
                      />
                    )}
                    key="AgendaPractitioner"
                  />
                ) : (
                  ''
                )}
                {/*((user.login_access.offices.length &&
                  user.login_access.id ===
                    user.login_access.offices[0].practitioner_office.user_id) ||
                  user.login_access.account_type === 'secretary') &&*/}
                {isHasPermission(user.login_access.permissions, 'CALENDAR_ACCESS') ? (
                  <Route
                    exact
                    path="/agenda-manuel"
                    render={(props) => (
                      <AgendaManuel
                        account_type={
                          user.login_access.offices.length > 0 &&
                          user.login_access.id ===
                            user.login_access.offices[0].practitioner_office.user_id
                            ? 'admin'
                            : user.login_access.account_type === 'user'
                            ? 'user'
                            : user.login_access.account_type === 'assistant'
                            ? 'assistant'
                            : 'secretary'
                        }
                        admin={
                          user.login_access.offices.length > 0
                            ? user.login_access.id ===
                              user.login_access.offices[0].practitioner_office.user_id
                              ? true
                              : false
                            : false
                        }
                        changeActiveItemSideBar={this.props.changeActiveItemSideBar}
                        changeNamePage={this.props.changeNamePage}
                        activeItemSideBarRDV={activeItemSideBarRDV}
                        changeActiveItemSideBarRDV={this.props.changeActiveItemSideBarRDV}
                        {...props}
                      />
                    )}
                    key="AgendaManuel"
                  />
                ) : (
                  ''
                )}
                {isHasPermission(user.login_access.permissions, 'CALENDAR_ACCESS') ? (
                  <Route
                    exact
                    path="/agenda-prescription"
                    render={(props) => (
                      <AgendaDraft
                        account_type={
                          user.login_access.offices.length > 0 &&
                          user.login_access.id ===
                            user.login_access.offices[0].practitioner_office.user_id
                            ? 'admin'
                            : user.login_access.account_type === 'user'
                            ? 'user'
                            : user.login_access.account_type === 'assistant'
                            ? 'assistant'
                            : 'secretary'
                        }
                        changeActiveItemSideBar={this.props.changeActiveItemSideBar}
                        changeNamePage={this.props.changeNamePage}
                        activeItemSideBarRDV={activeItemSideBarRDV}
                        changeActiveItemSideBarRDV={this.props.changeActiveItemSideBarRDV}
                        {...props}
                      />
                    )}
                    key="AgendaDraft"
                  />
                ) : isHasPermission(user.login_access.permissions, 'CHAT_ACCESS') ? (
                  <Route
                    exact
                    path="/messenger"
                    render={(props) => (
                      <Messenger
                        changeActiveItemSideBar={this.props.changeActiveItemSideBar}
                        changeNamePage={this.props.changeNamePage}
                        {...props}
                      />
                    )}
                    key="messenger"
                  />
                ) : (
                  ''
                )}
                {((user.login_access.offices.length &&
                  user.login_access.id ===
                    user.login_access.offices[0].practitioner_office.user_id) ||
                  user.login_access.account_type === 'secretary') &&
                isHasPermission(user.login_access.permissions, 'CALENDAR_ACCESS') ? (
                  <Route
                    exact
                    path="/generate-rdv-pdf"
                    render={(props) => (
                      <RDVPdf
                        activeItemSideBarRDV={activeItemSideBarRDV}
                        changeNamePage={this.props.changeNamePage}
                        changeActiveItemSideBarRDV={this.props.changeActiveItemSideBarRDV}
                        changeActiveItemSideBar={this.props.changeActiveItemSideBar}
                        {...props}
                      />
                    )}
                    key="RDVPdf"
                  />
                ) : (
                  ''
                )}
                {/*{user.login_access.id !==
                    user.login_access.offices[0].practitioner_office.user_id &&
                  isHasPermission(user.login_access.permissions, 'CALENDAR_ACCESS') ? (
                    <Route
                      exact
                      path="/agenda-practitioner"
                      render={(props) => (
                        <AgendaPractitioner
                          changeActiveItemSideBar={this.props.changeActiveItemSideBar}
                          changeNamePage={this.props.changeNamePage}
                          {...props}
                        />
                      )}
                      key="AgendaPractitioner"
                    />
                  ) : (
                    ''
                  )}*/}
                {isHasPermission(user.login_access.permissions, 'CALENDAR_ACCESS') ? (
                  <Route
                    exact
                    path="/transfer-listRDV"
                    render={(props) => (
                      <TransferListRDV
                        changeActiveItemSideBar={this.props.changeActiveItemSideBar}
                        changeNamePage={this.props.changeNamePage}
                        {...props}
                      />
                    )}
                    key="TransferListRDV"
                  />
                ) : (
                  ''
                )}
                <Route
                  exact
                  path="/profil"
                  render={(props) => (
                    <Profil
                      admin={
                        user.login_access.offices.length > 0
                          ? user.login_access.id ===
                            user.login_access.offices[0].practitioner_office.user_id
                            ? true
                            : false
                          : false
                      }
                      changeActiveItemSideBar={this.props.changeActiveItemSideBar}
                      changeNamePage={this.props.changeNamePage}
                      sandwich_menu_btn={sandwich_menu_btn}
                      {...props}
                    />
                  )}
                  key="Profil"
                />
                {isHasPermission(user.login_access.permissions, 'PATIENT_LIST_ACCESS') ? (
                  <Route
                    exact
                    path="/patients/:id"
                    render={(props) => (
                      <Patients
                        admin={
                          user.login_access.offices.length > 0
                            ? user.login_access.id ===
                              user.login_access.offices[0].practitioner_office.user_id
                              ? true
                              : false
                            : false
                        }
                        changeActiveItemSideBar={this.props.changeActiveItemSideBar}
                        changeNamePage={this.props.changeNamePage}
                        {...props}
                      />
                    )}
                    key="Patients"
                  />
                ) : (
                  ''
                )}
                {isHasPermission(user.login_access.permissions, 'PATIENT_LIST_ACCESS') ? (
                  <Route
                    exact
                    path="/patients"
                    render={(props) => (
                      <PatinetsV2
                        admin={
                          user.login_access.offices.length > 0
                            ? user.login_access.id ===
                              user.login_access.offices[0].practitioner_office.user_id
                              ? true
                              : false
                            : false
                        }
                        changeActiveItemSideBar={this.props.changeActiveItemSideBar}
                        changeNamePage={this.props.changeNamePage}
                        {...props}
                      />
                    )}
                    key="PatinetsV2"
                  />
                ) : (
                  ''
                )}
                {isHasPermission(
                  user.login_access.permissions,
                  'CREATE_NEW_PROTOCOLE',
                ) ? (
                  <Route
                    exact
                    path="/form-plan-reeducation/:consultation_id"
                    render={(props) => (
                      <PlanDeReeducation
                        changeActiveItemSideBar={this.props.changeActiveItemSideBar}
                        changeNamePage={this.props.changeNamePage}
                        {...props}
                      />
                    )}
                    key="PlanDeReeducation"
                  />
                ) : (
                  ''
                )}
                <Route
                  exact
                  path="/form-plan-reeducation/:protocole_id/:consultation_id"
                  render={(props) => (
                    <PlanDeReeducation
                      changeActiveItemSideBar={this.props.changeActiveItemSideBar}
                      changeNamePage={this.props.changeNamePage}
                      {...props}
                    />
                  )}
                  key="PlanDeReeducation"
                />
                {isHasPermission(user.login_access.permissions, 'ADD_NEW_PATIENT') ? (
                  <Route
                    exact
                    path="/form-patient"
                    render={(props) => (
                      <FormPatient
                        changeActiveItemSideBar={this.props.changeActiveItemSideBar}
                        changeNamePage={this.props.changeNamePage}
                        {...props}
                      />
                    )}
                    key="FormPatient"
                  />
                ) : (
                  ''
                )}
                {isHasPermission(
                  user.login_access.permissions,
                  'ACCOUNTING_STAT_ACCESS',
                ) ? (
                  <Route
                    exact
                    path="/payment"
                    render={(props) => (
                      <Payment
                        changeActiveItemSideBar={this.props.changeActiveItemSideBar}
                        changeNamePage={this.props.changeNamePage}
                        {...props}
                      />
                    )}
                    key="Payment"
                  />
                ) : (
                  ''
                )}
                {isHasPermission(
                  user.login_access.permissions,
                  'ACCOUNTING_STAT_ACCESS',
                ) ? (
                  <Route
                    exact
                    path="/payment/cnam"
                    render={(props) => (
                      <Payment
                        changeActiveItemSideBar={this.props.changeActiveItemSideBar}
                        changeNamePage={this.props.changeNamePage}
                        {...props}
                      />
                    )}
                    key="Payment"
                  />
                ) : (
                  ''
                )}
                {isHasPermission(user.login_access.permissions, 'BILLS_ACCESS') ? (
                  <Route
                    exact
                    path="/factures/:type"
                    render={(props) => (
                      <Factures
                        changeActiveItemSideBar={this.props.changeActiveItemSideBar}
                        changeNamePage={this.props.changeNamePage}
                        {...props}
                      />
                    )}
                    key="FacturesCNAM"
                  />
                ) : (
                  ''
                )}
                {isHasPermission(user.login_access.permissions, 'CNAM_SLIP_ACCESS') ? (
                  <Route
                    exact
                    path="/bordereaux/:type"
                    render={(props) => (
                      <Bordereaux
                        changeActiveItemSideBar={this.props.changeActiveItemSideBar}
                        changeNamePage={this.props.changeNamePage}
                        {...props}
                      />
                    )}
                    key="bordereauxCnam"
                  />
                ) : (
                  ''
                )}
                {isHasPermission(
                  user.login_access.permissions,
                  'ACCOUNTING_STAT_ACCESS',
                ) ||
                isHasPermission(user.login_access.permissions, 'CNAM_SLIP_ACCESS') ||
                isHasPermission(user.login_access.permissions, 'BILLS_ACCESS') ||
                isHasPermission(user.login_access.permissions, 'PEC_MANAG') ? (
                  <Route
                    exact
                    path="/module-cnam"
                    render={(props) => (
                      <ModuleCnam
                        user={user}
                        admin={
                          user.login_access.offices.length > 0
                            ? user.login_access.id ===
                              user.login_access.offices[0].practitioner_office.user_id
                              ? true
                              : false
                            : false
                        }
                        changeActiveItemSideBarCompta={
                          this.props.changeActiveItemSideBarCompta
                        }
                        changeActiveItemSideBar={this.props.changeActiveItemSideBar}
                        changeNamePage={this.props.changeNamePage}
                        {...props}
                      />
                    )}
                    key="ModuleCnam"
                  />
                ) : (
                  ''
                )}
                {isHasPermission(
                  user.login_access.permissions,
                  'ACCOUNTING_STAT_ACCESS',
                ) ||
                isHasPermission(user.login_access.permissions, 'CNAM_SLIP_ACCESS') ||
                isHasPermission(user.login_access.permissions, 'ACCESS_CREDITS_LIST') ||
                isHasPermission(user.login_access.permissions, 'ACCESS_DEPENSES_LIST') ||
                isHasPermission(user.login_access.permissions, 'ACCESS_RECETTE_LIST') ||
                isHasPermission(user.login_access.permissions, 'BILLS_ACCESS') ||
                isHasPermission(user.login_access.permissions, 'RECEIPT_ACCESS') ? (
                  <Route
                    exact
                    path="/compta"
                    render={(props) => (
                      <Compta
                        admin={
                          user.login_access.offices.length > 0
                            ? user.login_access.id ===
                              user.login_access.offices[0].practitioner_office.user_id
                              ? true
                              : false
                            : false
                        }
                        user={user}
                        changeActiveItemSideBarCompta={
                          this.props.changeActiveItemSideBarCompta
                        }
                        changeActiveItemSideBar={this.props.changeActiveItemSideBar}
                        changeNamePage={this.props.changeNamePage}
                        {...props}
                      />
                    )}
                    key="Compta"
                  />
                ) : (
                  ''
                )}
                {isHasPermission(
                  user.login_access.permissions,
                  'ACCOUNTING_STAT_ACCESS',
                ) ||
                isHasPermission(user.login_access.permissions, 'CNAM_SLIP_ACCESS') ||
                isHasPermission(user.login_access.permissions, 'ACCESS_CREDITS_LIST') ||
                isHasPermission(user.login_access.permissions, 'ACCESS_DEPENSES_LIST') ||
                isHasPermission(user.login_access.permissions, 'ACCESS_RECETTE_LIST') ||
                isHasPermission(user.login_access.permissions, 'BILLS_ACCESS') ||
                isHasPermission(user.login_access.permissions, 'RECEIPT_ACCESS') ? (
                  <Route
                    exact
                    path="/recette-depense-pdf"
                    render={(props) => (
                      <RecetteDepensePDF
                        admin={
                          user.login_access.offices.length > 0
                            ? user.login_access.id ===
                              user.login_access.offices[0].practitioner_office.user_id
                              ? true
                              : false
                            : false
                        }
                        user={user}
                        changeActiveItemSideBarCompta={
                          this.props.changeActiveItemSideBarCompta
                        }
                        changeActiveItemSideBar={this.props.changeActiveItemSideBar}
                        changeNamePage={this.props.changeNamePage}
                        {...props}
                      />
                    )}
                    key="RecetteDepensePDF"
                  />
                ) : (
                  ''
                )}
                {isHasPermission(user.login_access.permissions, 'CNAM_SLIP_ACCESS') ? (
                  <Route
                    exact
                    path="/bordereaux/regime/:type"
                    render={(props) => (
                      <Bordereaux
                        changeActiveItemSideBar={this.props.changeActiveItemSideBar}
                        changeNamePage={this.props.changeNamePage}
                        {...props}
                      />
                    )}
                    key="bordereauxACT"
                  />
                ) : (
                  ''
                )}
                {isHasPermission(user.login_access.permissions, 'BILLS_ACCESS') ? (
                  <Route
                    exact
                    path="/factures/regime/:type"
                    render={(props) => (
                      <Factures
                        changeActiveItemSideBar={this.props.changeActiveItemSideBar}
                        changeNamePage={this.props.changeNamePage}
                        {...props}
                      />
                    )}
                    key="FacturesACT"
                  />
                ) : (
                  ''
                )}
                {isHasPermission(user.login_access.permissions, 'BILLS_ACCESS') ? (
                  <Route
                    exact
                    path="/factures"
                    render={(props) => (
                      <Factures
                        changeActiveItemSideBar={this.props.changeActiveItemSideBar}
                        changeNamePage={this.props.changeNamePage}
                        {...props}
                      />
                    )}
                    key="Factures"
                  />
                ) : (
                  ''
                )}
                {isHasPermission(user.login_access.permissions, 'RECEIPT_ACCESS') ? (
                  <Route
                    exact
                    path="/receipt"
                    render={(props) => (
                      <Receipts
                        changeActiveItemSideBar={this.props.changeActiveItemSideBar}
                        changeNamePage={this.props.changeNamePage}
                        {...props}
                      />
                    )}
                    key="Receipts"
                  />
                ) : (
                  ''
                )}
                {isHasPermission(user.login_access.permissions, 'ADD_NEW_RECEIPT') ? (
                  <Route
                    exact
                    path="/add-receipt/:consultation_id"
                    render={(props) => (
                      <AddReceipt
                        changeActiveItemSideBar={this.props.changeActiveItemSideBar}
                        changeNamePage={this.props.changeNamePage}
                        {...props}
                      />
                    )}
                    key="AddReceipt"
                  />
                ) : (
                  ''
                )}
                {isHasPermission(user.login_access.permissions, 'RECEIPT_ACCESS') ? (
                  <Route
                    exact
                    path="/receipt-pdf"
                    render={(props) => (
                      <ReceiptPdf
                        changeActiveItemSideBar={this.props.changeActiveItemSideBar}
                        changeNamePage={this.props.changeNamePage}
                        {...props}
                      />
                    )}
                    key="ReceiptPdf"
                  />
                ) : (
                  ''
                )}
                {isHasPermission(user.login_access.permissions, 'ADD_NEW_BILL') ? (
                  <Route
                    exact
                    path="/add-facture/patient"
                    render={(props) => (
                      <AddFacturePatient
                        changeActiveItemSideBar={this.props.changeActiveItemSideBar}
                        changeNamePage={this.props.changeNamePage}
                        {...props}
                      />
                    )}
                    key="FormFacture"
                  />
                ) : (
                  ''
                )}
                {isHasPermission(user.login_access.permissions, 'ADD_NEW_BILL') ? (
                  <Route
                    exact
                    path="/add-facture/:consultation_id"
                    render={(props) => (
                      <AddFacture
                        changeActiveItemSideBar={this.props.changeActiveItemSideBar}
                        changeNamePage={this.props.changeNamePage}
                        {...props}
                      />
                    )}
                    key="FormFacture"
                  />
                ) : (
                  ''
                )}{' '}
                {isHasPermission(user.login_access.permissions, 'ADD_NEW_BILL') ? (
                  <Route
                    exact
                    path="/edit-facture/:consultation_id"
                    render={(props) => (
                      <EditFacture
                        changeActiveItemSideBar={this.props.changeActiveItemSideBar}
                        changeNamePage={this.props.changeNamePage}
                        {...props}
                      />
                    )}
                    key="EditFacture"
                  />
                ) : (
                  ''
                )}
                {isHasPermission(user.login_access.permissions, 'CHAT_ACCESS') ? (
                  <Route
                    exact
                    path="/messenger"
                    render={(props) => (
                      <Messenger
                        changeActiveItemSideBar={this.props.changeActiveItemSideBar}
                        changeNamePage={this.props.changeNamePage}
                        {...props}
                      />
                    )}
                    key="messenger"
                  />
                ) : (
                  ''
                )}
                {isHasPermission(user.login_access.permissions, 'ADD_NEW_BILL') ? (
                  <Route
                    exact
                    path="/facture-pdf"
                    render={(props) => (
                      <FacturePdf
                        changeActiveItemSideBar={this.props.changeActiveItemSideBar}
                        changeNamePage={this.props.changeNamePage}
                        {...props}
                      />
                    )}
                    key="FacturePdf"
                  />
                ) : (
                  ''
                )}
                {isHasPermission(
                  user.login_access.permissions,
                  'GENERATE_SESSIONS_MEMORY',
                ) ? (
                  <Route
                    exact
                    path="/seance-pdf"
                    render={(props) => (
                      <SeancePdf
                        changeActiveItemSideBar={this.props.changeActiveItemSideBar}
                        changeNamePage={this.props.changeNamePage}
                        {...props}
                      />
                    )}
                    key="SeancePdf"
                  />
                ) : (
                  ''
                )}
                {isHasPermission(user.login_access.permissions, 'ACCESS_CURES_LIST') ? (
                  <Route
                    exact
                    path="/consultations"
                    render={(props) => (
                      <ConsulatationsV2
                        admin={
                          user.login_access.offices.length > 0
                            ? user.login_access.id ===
                              user.login_access.offices[0].practitioner_office.user_id
                              ? true
                              : false
                            : false
                        }
                        changeActiveItemSideBar={this.props.changeActiveItemSideBar}
                        changeNamePage={this.props.changeNamePage}
                        {...props}
                      />
                    )}
                    key="consultations"
                  />
                ) : (
                  ''
                )}
                {isHasPermission(user.login_access.permissions, 'ACCESS_CURES_LIST') ? (
                  <Route
                    exact
                    path="/consultations/:id"
                    render={(props) => (
                      <Consulatations
                        admin={
                          user.login_access.offices.length > 0
                            ? user.login_access.id ===
                              user.login_access.offices[0].practitioner_office.user_id
                              ? true
                              : false
                            : false
                        }
                        changeActiveItemSideBar={this.props.changeActiveItemSideBar}
                        changeNamePage={this.props.changeNamePage}
                        {...props}
                      />
                    )}
                    key="consultation"
                  />
                ) : (
                  ''
                )}
                {isHasPermission(user.login_access.permissions, 'CNAM_SLIP_ACCESS') ? (
                  <Route
                    exact
                    path="/bordereaux"
                    render={(props) => (
                      <Bordereaux
                        changeActiveItemSideBar={this.props.changeActiveItemSideBar}
                        changeNamePage={this.props.changeNamePage}
                        {...props}
                      />
                    )}
                    key="bordereaux"
                  />
                ) : (
                  ''
                )}
                {isHasPermission(user.login_access.permissions, 'COMPANY_MANAG') ||
                isHasPermission(user.login_access.permissions, 'PARTNERS_MANAG') ||
                isHasPermission(user.login_access.permissions, 'ABSENCES_MANAG') ||
                isHasPermission(user.login_access.permissions, 'ASSISTANTS_MANAG') ||
                isHasPermission(user.login_access.permissions, 'SECRETERIES_MANAG') ||
                isHasPermission(user.login_access.permissions, 'PERMISSIONS_MANAG') ||
                isHasPermission(user.login_access.permissions, 'REGISTER_MANAG') ||
                isHasPermission(user.login_access.permissions, 'PATHOLOGIES_MANAG') ||
                isHasPermission(
                  user.login_access.permissions,
                  'PRESCRIBING_DOCTORS_MANAG',
                ) ||
                isHasPermission(user.login_access.permissions, 'MISSIONS_MANAG') ? (
                  <Route
                    exact
                    path="/settings"
                    render={(props) => (
                      <Settings
                        changeActiveItemSideBar={this.props.changeActiveItemSideBar}
                        changeNamePage={this.props.changeNamePage}
                        {...props}
                      />
                    )}
                    key="Paramètres"
                  />
                ) : (
                  ''
                )}
                {isHasPermission(user.login_access.permissions, 'ADD_NEW_BILL') ? (
                  <Route
                    exact
                    path="/form-bordreau/create-b-cnam"
                    render={(props) => <CreateBordereauCnam />}
                    key="CreateBordereauCnam"
                  />
                ) : (
                  ''
                )}
                {isHasPermission(user.login_access.permissions, 'ADD_NEW_BILL') ? (
                  <Route
                    exact
                    path="/form-bordreau/:regime"
                    render={(props) => (
                      <FormBordereau
                        changeActiveItemSideBar={this.props.changeActiveItemSideBar}
                        changeNamePage={this.props.changeNamePage}
                        {...props}
                      />
                    )}
                    key="FormBordereau"
                  />
                ) : (
                  ''
                )}
                <Route
                  exact
                  path="/support"
                  render={(props) => (
                    <Support
                      changeActiveItemSideBar={this.props.changeActiveItemSideBar}
                      changeNamePage={this.props.changeNamePage}
                      {...props}
                    />
                  )}
                  key="Support"
                />
                {isHasPermission(user.login_access.permissions, 'ADD_NEW_BILL') ? (
                  <Route
                    exact
                    path="/patient/facture"
                    render={(props) => (
                      <PatientFacture
                        changeActiveItemSideBar={this.props.changeActiveItemSideBar}
                        changeNamePage={this.props.changeNamePage}
                        {...props}
                      />
                    )}
                    key="PatientFacture"
                  />
                ) : (
                  ''
                )}
                {isHasPermission(user.login_access.permissions, 'ADD_NEW_BILL') ? (
                  <Route
                    exact
                    path="/patient/receipt"
                    render={(props) => (
                      <PatientReceipt
                        changeActiveItemSideBar={this.props.changeActiveItemSideBar}
                        changeNamePage={this.props.changeNamePage}
                        {...props}
                      />
                    )}
                    key="PatientReceipt"
                  />
                ) : (
                  ''
                )}
                {isHasPermission(user.login_access.permissions, 'ADD_NEW_BILL') ? (
                  <Route
                    exact
                    path="/facture/bordereau"
                    render={(props) => (
                      <Bordereau
                        changeActiveItemSideBar={this.props.changeActiveItemSideBar}
                        changeNamePage={this.props.changeNamePage}
                        {...props}
                      />
                    )}
                    key="Bordereau"
                  />
                ) : (
                  ''
                )}
                {isHasPermission(user.login_access.permissions, 'ADD_NEW_BILL') ? (
                  <Route
                    exact
                    path="/facture/bordereau-pdf"
                    render={(props) => (
                      <BordereauPdf
                        changeActiveItemSideBar={this.props.changeActiveItemSideBar}
                        changeNamePage={this.props.changeNamePage}
                        {...props}
                      />
                    )}
                    key="BordereauPdf"
                  />
                ) : (
                  ''
                )}
                {isHasPermission(user.login_access.permissions, 'ADD_NEW_BILL') ? (
                  <Route
                    exact
                    path="/facture/bordereau-pdf/autre"
                    render={(props) => (
                      <BordereauPdfAutre
                        changeActiveItemSideBar={this.props.changeActiveItemSideBar}
                        changeNamePage={this.props.changeNamePage}
                        {...props}
                      />
                    )}
                    key="BordereauPdfAutre"
                  />
                ) : (
                  ''
                )}
                {isHasPermission(user.login_access.permissions, 'ADD_NEW_CURE') ? (
                  <Route
                    exact
                    path="/form/pec/:id"
                    render={(props) => (
                      <Pec
                        admin={
                          user.login_access.offices.length > 0
                            ? user.login_access.id ===
                              user.login_access.offices[0].practitioner_office.user_id
                              ? true
                              : false
                            : false
                        }
                        changeActiveItemSideBar={this.props.changeActiveItemSideBar}
                        changeNamePage={this.props.changeNamePage}
                        {...props}
                      />
                    )}
                    key="Pec"
                  />
                ) : (
                  ''
                )}
                {isHasPermission(user.login_access.permissions, 'ADD_NEW_CURE') ? (
                  <Route
                    exact
                    path="/form/virtual-pec/:id"
                    render={(props) => (
                      <VirtualPec
                        admin={
                          user.login_access.offices.length > 0
                            ? user.login_access.id ===
                              user.login_access.offices[0].practitioner_office.user_id
                              ? true
                              : false
                            : false
                        }
                        changeActiveItemSideBar={this.props.changeActiveItemSideBar}
                        changeNamePage={this.props.changeNamePage}
                        {...props}
                      />
                    )}
                    key="VirtualPec"
                  />
                ) : (
                  ''
                )}
                {isHasPermission(user.login_access.permissions, 'ACCESS_CURES_LIST') ? (
                  <Route
                    exact
                    path="/bilans/:id"
                    render={(props) => (
                      <Bilans
                        changeActiveItemSideBar={this.props.changeActiveItemSideBar}
                        changeNamePage={this.props.changeNamePage}
                        {...props}
                      />
                    )}
                    key="Bilans"
                  />
                ) : (
                  ''
                )}
                {isHasPermission(user.login_access.permissions, 'BILLS_ACCESS') ? (
                  <Route
                    exact
                    path="/recettes"
                    render={(props) => (
                      <Recettes
                        admin={
                          user.login_access.offices.length > 0
                            ? user.login_access.id ===
                              user.login_access.offices[0].practitioner_office.user_id
                              ? true
                              : false
                            : false
                        }
                        changeActiveItemSideBar={this.props.changeActiveItemSideBar}
                        changeNamePage={this.props.changeNamePage}
                        {...props}
                      />
                    )}
                    key="Recettes"
                  />
                ) : (
                  ''
                )}
                {isHasPermission(user.login_access.permissions, 'BILLS_ACCESS') ? (
                  <Route
                    exact
                    path="/depenses"
                    render={(props) => (
                      <Depenses
                        changeActiveItemSideBar={this.props.changeActiveItemSideBar}
                        changeNamePage={this.props.changeNamePage}
                        {...props}
                      />
                    )}
                    key="Depenses"
                  />
                ) : (
                  ''
                )}
                {isHasPermission(user.login_access.permissions, 'BILLS_ACCESS') ? (
                  <Route
                    exact
                    path="/show-facture/:id"
                    render={(props) => (
                      <DetailsFacture
                        changeActiveItemSideBar={this.props.changeActiveItemSideBar}
                        changeNamePage={this.props.changeNamePage}
                        {...props}
                      />
                    )}
                    key="DetailsFacture"
                  />
                ) : (
                  ''
                )}
                {isHasPermission(user.login_access.permissions, 'RECEIPT_ACCESS') ? (
                  <Route
                    exact
                    path="/show-receipt/:id"
                    render={(props) => (
                      <DetailsReceipt
                        changeActiveItemSideBar={this.props.changeActiveItemSideBar}
                        changeNamePage={this.props.changeNamePage}
                        {...props}
                      />
                    )}
                    key="DetailsReceipt"
                  />
                ) : (
                  ''
                )}
                {isHasPermission(user.login_access.permissions, 'BILLS_ACCESS') ? (
                  <Route
                    exact
                    path="/add-depense"
                    render={(props) => (
                      <FormDepense
                        changeActiveItemSideBar={this.props.changeActiveItemSideBar}
                        changeNamePage={this.props.changeNamePage}
                        {...props}
                      />
                    )}
                    key="FormDepense"
                  />
                ) : (
                  ''
                )}
                {isHasPermission(user.login_access.permissions, 'BILLS_ACCESS') ? (
                  <Route
                    exact
                    path="/update-depense/:id"
                    render={(props) => (
                      <FormDepense
                        changeActiveItemSideBar={this.props.changeActiveItemSideBar}
                        changeNamePage={this.props.changeNamePage}
                        {...props}
                      />
                    )}
                    key="FormDepense"
                  />
                ) : (
                  ''
                )}
                {isHasPermission(user.login_access.permissions, 'BILLS_ACCESS') ? (
                  <Route
                    exact
                    path="/add-recette"
                    render={(props) => (
                      <FormRecette
                        changeActiveItemSideBar={this.props.changeActiveItemSideBar}
                        changeNamePage={this.props.changeNamePage}
                        {...props}
                      />
                    )}
                    key="FormRecette"
                  />
                ) : (
                  ''
                )}
                {isHasPermission(user.login_access.permissions, 'BILLS_ACCESS') ? (
                  <Route
                    exact
                    path="/update-recette/:id"
                    render={(props) => (
                      <FormRecette
                        changeActiveItemSideBar={this.props.changeActiveItemSideBar}
                        changeNamePage={this.props.changeNamePage}
                        {...props}
                      />
                    )}
                    key="FormRecette"
                  />
                ) : (
                  ''
                )}
                {isHasPermission(
                  user.login_access.permissions,
                  'ADD_NEW_BALANCE_SHEET',
                ) ? (
                  <Route
                    exact
                    path="/bilan/epaule/:consultation_id/:bilan_id"
                    render={(props) => (
                      <Epaule
                        changeActiveItemSideBar={this.props.changeActiveItemSideBar}
                        changeNamePage={this.props.changeNamePage}
                        {...props}
                      />
                    )}
                    key="Epaule"
                  />
                ) : (
                  ''
                )}
                <Route
                  exact
                  path="/epaule/pdf"
                  render={(props) => (
                    <EpaulePdf
                      changeActiveItemSideBar={this.props.changeActiveItemSideBar}
                      changeNamePage={this.props.changeNamePage}
                      {...props}
                    />
                  )}
                  key="EpaulePdf"
                />
                <Route
                  exact
                  path="/bilan/cheville/:consultation_id/:bilan_id"
                  render={(props) => (
                    <Cheville
                      changeActiveItemSideBar={this.props.changeActiveItemSideBar}
                      changeNamePage={this.props.changeNamePage}
                      {...props}
                    />
                  )}
                  key="Cheville"
                />
                <Route
                  exact
                  path="/bilan/main/:consultation_id/:bilan_id"
                  render={(props) => (
                    <Main
                      changeActiveItemSideBar={this.props.changeActiveItemSideBar}
                      changeNamePage={this.props.changeNamePage}
                      {...props}
                    />
                  )}
                  key="Main"
                />
                <Route
                  exact
                  path="/bilan/coude/:consultation_id/:bilan_id"
                  render={(props) => (
                    <Coude
                      changeActiveItemSideBar={this.props.changeActiveItemSideBar}
                      changeNamePage={this.props.changeNamePage}
                      {...props}
                    />
                  )}
                  key="Coude"
                />
                <Route
                  exact
                  path="/bilan/hanche/:consultation_id/:bilan_id"
                  render={(props) => (
                    <Hanche
                      changeActiveItemSideBar={this.props.changeActiveItemSideBar}
                      changeNamePage={this.props.changeNamePage}
                      {...props}
                    />
                  )}
                  key="Hanche"
                />
                <Route
                  exact
                  path="/bilan/genou/:consultation_id/:bilan_id"
                  render={(props) => (
                    <Genou
                      changeActiveItemSideBar={this.props.changeActiveItemSideBar}
                      changeNamePage={this.props.changeNamePage}
                      {...props}
                    />
                  )}
                  key="Genou"
                />
                <Route
                  exact
                  path="/bilan/rachis_cervical/:consultation_id/:bilan_id"
                  render={(props) => (
                    <RachisCervical
                      changeActiveItemSideBar={this.props.changeActiveItemSideBar}
                      changeNamePage={this.props.changeNamePage}
                      {...props}
                    />
                  )}
                  key="RachisCervical"
                />
                <Route
                  exact
                  path="/bilan/rachis_dorsal/:consultation_id/:bilan_id"
                  render={(props) => (
                    <RachisDorsal
                      changeActiveItemSideBar={this.props.changeActiveItemSideBar}
                      changeNamePage={this.props.changeNamePage}
                      {...props}
                    />
                  )}
                  key="RachisDorsal"
                />
                <Route
                  exact
                  path="/bilan/rachis_lombo_sacre/:consultation_id/:bilan_id"
                  render={(props) => (
                    <RachisLombaire
                      changeActiveItemSideBar={this.props.changeActiveItemSideBar}
                      changeNamePage={this.props.changeNamePage}
                      {...props}
                    />
                  )}
                  key="RachisLombaire"
                />
                <Route
                  exact
                  path="/bilan/Pied/:consultation_id/:bilan_id"
                  render={(props) => (
                    <Pied
                      changeActiveItemSideBar={this.props.changeActiveItemSideBar}
                      changeNamePage={this.props.changeNamePage}
                      {...props}
                    />
                  )}
                  key="Pied"
                />
                <Route
                  exact
                  path="/bilan/poignet/:consultation_id/:bilan_id"
                  render={(props) => (
                    <Poignet
                      changeActiveItemSideBar={this.props.changeActiveItemSideBar}
                      changeNamePage={this.props.changeNamePage}
                      {...props}
                    />
                  )}
                  key="Poignet"
                />
                <Route
                  exact
                  path="/bilan/libre/:consultation_id/:bilan_id"
                  render={(props) => (
                    <BilanLibre
                      changeActiveItemSideBar={this.props.changeActiveItemSideBar}
                      changeNamePage={this.props.changeNamePage}
                      {...props}
                    />
                  )}
                  key="BilanLibre"
                />
                <Route
                  exact
                  path="/bilan/activite_physique_adaptee/:consultation_id/:bilan_id"
                  render={(props) => (
                    <APA
                      changeActiveItemSideBar={this.props.changeActiveItemSideBar}
                      changeNamePage={this.props.changeNamePage}
                      {...props}
                    />
                  )}
                  key="APA"
                />
                <Route
                  exact
                  path="/bilan/rachis_complet/:consultation_id/:bilan_id"
                  render={(props) => (
                    <RachisComplet
                      changeActiveItemSideBar={this.props.changeActiveItemSideBar}
                      changeNamePage={this.props.changeNamePage}
                      {...props}
                    />
                  )}
                  key="RachisComplet"
                />
                <Route
                  exact
                  path="/bilan/m_sup_complet/:consultation_id/:bilan_id"
                  render={(props) => (
                    <MSupComplet
                      changeActiveItemSideBar={this.props.changeActiveItemSideBar}
                      changeNamePage={this.props.changeNamePage}
                      {...props}
                    />
                  )}
                  key="MSupComplet"
                />
                <Route
                  exact
                  path="/bilan/m_inf_complet/:consultation_id/:bilan_id"
                  render={(props) => (
                    <MInfComplet
                      changeActiveItemSideBar={this.props.changeActiveItemSideBar}
                      changeNamePage={this.props.changeNamePage}
                      {...props}
                    />
                  )}
                  key="MInfComplet"
                />
                <Route
                  exact
                  path="/bilan/gériatrie/:consultation_id/:bilan_id"
                  render={(props) => (
                    <Gériatrie
                      changeActiveItemSideBar={this.props.changeActiveItemSideBar}
                      changeNamePage={this.props.changeNamePage}
                      {...props}
                    />
                  )}
                  key="Gériatrie"
                />
                <Route
                  exact
                  path="/bilan/pediatrie/:consultation_id/:bilan_id"
                  render={(props) => (
                    <Pédiatrie
                      changeActiveItemSideBar={this.props.changeActiveItemSideBar}
                      changeNamePage={this.props.changeNamePage}
                      {...props}
                    />
                  )}
                  key="Pédiatrie"
                />
                <Route
                  exact
                  path="/bilan/uro_gyneco/:consultation_id/:bilan_id"
                  render={(props) => (
                    <UroGyneco
                      changeActiveItemSideBar={this.props.changeActiveItemSideBar}
                      changeNamePage={this.props.changeNamePage}
                      {...props}
                    />
                  )}
                  key="UroGyneco"
                />
                <Route
                  exact
                  path="/bilan/reeducation_perineale/:consultation_id/:bilan_id"
                  render={(props) => (
                    <ReeducationPerineale
                      changeActiveItemSideBar={this.props.changeActiveItemSideBar}
                      changeNamePage={this.props.changeNamePage}
                      {...props}
                    />
                  )}
                  key="ReeducationPerineale"
                />
                <Route
                  exact
                  path="/bilan/respi-pediatrique/:consultation_id/:bilan_id"
                  render={(props) => (
                    <RespiPediatrique
                      changeActiveItemSideBar={this.props.changeActiveItemSideBar}
                      changeNamePage={this.props.changeNamePage}
                      {...props}
                    />
                  )}
                  key="RespiPediatrique"
                />
                <Route
                  exact
                  path="/bilan/pneumologie_adulte/:consultation_id/:bilan_id"
                  render={(props) => (
                    <PneumologieAdulte
                      changeActiveItemSideBar={this.props.changeActiveItemSideBar}
                      changeNamePage={this.props.changeNamePage}
                      {...props}
                    />
                  )}
                  key="PneumologieAdulte"
                />
                <Route
                  exact
                  path="/bilan/Bronchiolite/:consultation_id/:bilan_id"
                  render={(props) => (
                    <Bronchiolite
                      changeActiveItemSideBar={this.props.changeActiveItemSideBar}
                      changeNamePage={this.props.changeNamePage}
                      {...props}
                    />
                  )}
                  key="Bronchiolite"
                />
                <Route
                  exact
                  path="/bilan/Mucoviscidose/:consultation_id/:bilan_id"
                  render={(props) => (
                    <Mucoviscidose
                      changeActiveItemSideBar={this.props.changeActiveItemSideBar}
                      changeNamePage={this.props.changeNamePage}
                      {...props}
                    />
                  )}
                  key="Mucoviscidose"
                />
                <Route
                  exact
                  path="/bilan/general_n_centrale/:consultation_id/:bilan_id"
                  render={(props) => (
                    <GeneralNcentrale
                      changeActiveItemSideBar={this.props.changeActiveItemSideBar}
                      changeNamePage={this.props.changeNamePage}
                      {...props}
                    />
                  )}
                  key="GeneralNcentrale"
                />
                <Route
                  exact
                  path="/bilan/parkinson/:consultation_id/:bilan_id"
                  render={(props) => (
                    <Parkinson
                      changeActiveItemSideBar={this.props.changeActiveItemSideBar}
                      changeNamePage={this.props.changeNamePage}
                      {...props}
                    />
                  )}
                  key="Parkinson"
                />
                <Route
                  exact
                  path="/bilan/s_e_p/:consultation_id/:bilan_id"
                  render={(props) => (
                    <BilanSEP
                      changeActiveItemSideBar={this.props.changeActiveItemSideBar}
                      changeNamePage={this.props.changeNamePage}
                      {...props}
                    />
                  )}
                  key="BilanSEP"
                />
                <Route
                  exact
                  path="/bilan/avc_hemiplegie/:consultation_id/:bilan_id"
                  render={(props) => (
                    <AVC_Hémiplégie
                      changeActiveItemSideBar={this.props.changeActiveItemSideBar}
                      changeNamePage={this.props.changeNamePage}
                      {...props}
                    />
                  )}
                  key="AVC_Hémiplégie"
                />
                <Route
                  exact
                  path="/bilan/general_n_peripherique/:consultation_id/:bilan_id"
                  render={(props) => (
                    <GeneralNPeripherique
                      changeActiveItemSideBar={this.props.changeActiveItemSideBar}
                      changeNamePage={this.props.changeNamePage}
                      {...props}
                    />
                  )}
                  key="GeneralNPeripherique"
                />
                <Route
                  exact
                  path="/bilan/cervical_mckz/:consultation_id/:bilan_id"
                  render={(props) => (
                    <CervicalMckz
                      changeActiveItemSideBar={this.props.changeActiveItemSideBar}
                      changeNamePage={this.props.changeNamePage}
                      {...props}
                    />
                  )}
                  key="CervicalMckz"
                />
                <Route
                  exact
                  path="/bilan/thoracique_mckz/:consultation_id/:bilan_id"
                  render={(props) => (
                    <ThoraciqueMckz
                      changeActiveItemSideBar={this.props.changeActiveItemSideBar}
                      changeNamePage={this.props.changeNamePage}
                      {...props}
                    />
                  )}
                  key="ThoraciqueMckz"
                />
                <Route
                  exact
                  path="/bilan/lombaire_mckz/:consultation_id/:bilan_id"
                  render={(props) => (
                    <LombaireMckz
                      changeActiveItemSideBar={this.props.changeActiveItemSideBar}
                      changeNamePage={this.props.changeNamePage}
                      {...props}
                    />
                  )}
                  key="LombaireMckz"
                />
                <Route
                  exact
                  path="/bilan/coureura_pied_gauche/:consultation_id/:bilan_id"
                  render={(props) => (
                    <CoureurAPiedGauche
                      changeActiveItemSideBar={this.props.changeActiveItemSideBar}
                      changeNamePage={this.props.changeNamePage}
                      {...props}
                    />
                  )}
                  key="CoureurAPiedGauche"
                />
                <Route
                  exact
                  path="/bilan/lymphoedeme_membre_superieur/:consultation_id/:bilan_id"
                  render={(props) => (
                    <LymphoedemeMembreSuperieur
                      changeActiveItemSideBar={this.props.changeActiveItemSideBar}
                      changeNamePage={this.props.changeNamePage}
                      {...props}
                    />
                  )}
                  key="LymphoedemeMembreSuperieur"
                />
                <Route
                  exact
                  path="/bilan/lymphoedeme_membre_inférieur/:consultation_id/:bilan_id"
                  render={(props) => (
                    <LymphoedemeMembreInférieur
                      changeActiveItemSideBar={this.props.changeActiveItemSideBar}
                      changeNamePage={this.props.changeNamePage}
                      {...props}
                    />
                  )}
                  key="LymphoedemeMembreInférieur"
                />
                <Route
                  exact
                  path="/bilan/maxillo_facial/:consultation_id/:bilan_id"
                  render={(props) => (
                    <MaxilloFacial
                      changeActiveItemSideBar={this.props.changeActiveItemSideBar}
                      changeNamePage={this.props.changeNamePage}
                      {...props}
                    />
                  )}
                  key="MaxilloFacial"
                />
                <Route
                  exact
                  path="/bilan/paralysie_faciale/:consultation_id/:bilan_id"
                  render={(props) => (
                    <ParalysieFaciale
                      changeActiveItemSideBar={this.props.changeActiveItemSideBar}
                      changeNamePage={this.props.changeNamePage}
                      {...props}
                    />
                  )}
                  key="ParalysieFaciale"
                />
                <Route
                  exact
                  path="/bilan/oro_facial/:consultation_id/:bilan_id"
                  render={(props) => (
                    <OroFacial
                      changeActiveItemSideBar={this.props.changeActiveItemSideBar}
                      changeNamePage={this.props.changeNamePage}
                      {...props}
                    />
                  )}
                  key="OroFacial"
                />
                <Route
                  exact
                  path="/bilan/vestibulaire/:consultation_id/:bilan_id"
                  render={(props) => (
                    <Vestibulaire
                      changeActiveItemSideBar={this.props.changeActiveItemSideBar}
                      changeNamePage={this.props.changeNamePage}
                      {...props}
                    />
                  )}
                  key="Vestibulaire"
                />
                <Route
                  exact
                  path="/bilan/cancer_du_sein/:consultation_id/:bilan_id"
                  render={(props) => (
                    <CancerDuSein
                      changeActiveItemSideBar={this.props.changeActiveItemSideBar}
                      changeNamePage={this.props.changeNamePage}
                      {...props}
                    />
                  )}
                  key="CancerDuSein"
                />
                <Route
                  exact
                  path="/bilan/quadran_sup_TMO/:consultation_id/:bilan_id"
                  render={(props) => (
                    <QuadranSupTMO
                      changeActiveItemSideBar={this.props.changeActiveItemSideBar}
                      changeNamePage={this.props.changeNamePage}
                      {...props}
                    />
                  )}
                  key="QuadranSupTMO"
                />
                <Route
                  exact
                  path="/bilan/quadran_inf_TMO/:consultation_id/:bilan_id"
                  render={(props) => (
                    <QuadranInfTMO
                      changeActiveItemSideBar={this.props.changeActiveItemSideBar}
                      changeNamePage={this.props.changeNamePage}
                      {...props}
                    />
                  )}
                  key="QuadranInfTMO"
                />
                <Redirect
                  to={
                    user.login_access.permissions.length === 0
                      ? '/support'
                      : user.login_access.offices.length > 0 &&
                        user.login_access.id ===
                          user.login_access.offices[0].practitioner_office.user_id
                      ? '/dashboard'
                      : user.login_access.account_type === 'user'
                      ? '/agenda-practitioner'
                      : user.login_access.account_type === 'assistant'
                      ? '/messenger'
                      : '/agenda'
                  }
                />
              </Switch>
            </section>
          </section>
        </>
      );
    }
    return <>{app}</>;
  }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(App));
