import React, { Component } from 'react';
import Modals from '../../../components/modal';
import moment from 'moment';
import { SwipeableDrawer } from '@material-ui/core';
import InputField from '../../../components/inputField';
import { formatDate, isHasPermission, isNumeric } from '../../../utils/helpres';
import { Link } from 'react-router-dom';
import actions from '../../consultations/store/actions';
import { connect } from 'react-redux';
import Tooltip from '@mui/material/Tooltip';
import { FORMAT_DATE, FORMAT_DATE_SERVER } from '../../../utils/constants';

const mapStateToProps = (state) => ({
  sessionsVirtualPec: state.ConsultationReducer.sessionsVirtualPec,
});

const mapDispatchToProps = (dispatch) => ({
  getDispoDoctor: (values) => dispatch(actions.getSessionsVirtualPec(values)),
});

class Pec extends Component {
  constructor(props) {
    super(props);
    this.state = {
      sessionsVirtualPec: [],
      isOpen: false,
      type_pec: '',
      type: 0,
      left: false,
      setIsOpen: false,
      anchor: 'left',
      initialValues: {
        type: 'pec',
        num_order: null,
        year: null,
        code_bureau: null,
        hourStart: '10:00',
        duration: '60',
        quality: '',
        sessions_count: props.pec.sessions_count,
        sessions_per_week: props.pec.sessions_per_week,
        pecStartDate: moment(this.props.pec.start_date).format(FORMAT_DATE_SERVER),
        pecEndDate: moment(this.props.pec.end_date).format(FORMAT_DATE_SERVER),
      },
      hasError: false,
    };
    this.submitPEC = this.submitPEC.bind(this);
    this.openPec = this.openPec.bind(this);
    this.toggleDrawer = this.toggleDrawer.bind(this);
    this.openModal = this.openModal.bind(this);
    this.handleChangeDateInput = this.handleChangeDateInput.bind(this);
  }

  componentWillReceiveProps(nextProps, nextContext) {
    const { initialValues } = this.state;
    const { sessionsVirtualPec } = nextProps;
    if (sessionsVirtualPec && sessionsVirtualPec.length > 0) {
      this.setState({
        initialValues: {
          ...initialValues,
          pecEndDate: moment(
            sessionsVirtualPec[sessionsVirtualPec.length - 1].date,
          ).format(FORMAT_DATE_SERVER),
        },
      });
    }
  }

  handleChangeDateInput = (event) => {
    const { initialValues } = this.state;

    this.props.getDispoDoctor({
      consultation_id: this.props.consultation.id,
      start_date: moment(event.target.value).format('YYYY-MM-DD 07:00:00'), //"2023-02-13 07:00:00",
      end_date: moment(event.target.value).format('YYYY-MM-DD 07:00:00'),
      sessions_per_week: initialValues.sessions_per_week,
      practitioner_id: null,
      patient_id: 1,
      sessions_count: initialValues.sessions_count,
      hours08: '07',
      hours20: '19',
    });
    this.setState({
      initialValues: {
        ...initialValues,
        pecStartDate: moment(event.target.value).format(FORMAT_DATE_SERVER),
      },
    });
  };

  toggleDrawer = (anchor, open, id) => (event) => {
    if (
      event &&
      event.type === 'keydown' &&
      (event.key === 'Tab' || event.key === 'Shift')
    ) {
      return;
    }
    this.setState({
      isOpen: false,
      left: open,
      anchor: 'left',
    });
  };
  async submitPEC(initialValues, type) {
    const { pec, consultation_id, consultation } = this.props;
    if (
      !initialValues.quality ||
      !initialValues.num_order ||
      !initialValues.year ||
      !initialValues.code_bureau ||
      !initialValues.pecStartDate ||
      !initialValues.pecEndDate ||
      (initialValues.year && !isNumeric(initialValues.year)) ||
      (initialValues.code_bureau && !isNumeric(initialValues.code_bureau)) ||
      (initialValues.year &&
        isNumeric(initialValues.year) &&
        initialValues.year.toString().length !== 4) ||
      (initialValues.year &&
        isNumeric(initialValues.year) &&
        initialValues.year > moment().format('YYYY'))
    ) {
      this.setState({
        ...initialValues,
        hasError: true,
      });
    } else {
      initialValues.consultation_id = consultation_id;
      initialValues.id = pec.id;

      await this.props.submitFormPEC(initialValues, type);
      this.toggleDrawer('right', false);
    }
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    const { getDispoDate, switchItem } = this.props;
    if (getDispoDate.length > 0 && switchItem === 1) {
      this.props.goTo(getDispoDate, this.props.office, this.props.consultation);
    }
  }
  openModal() {
    this.setState({
      setIsOpen: !this.state.setIsOpen,
    });
  }
  openPec(pec) {
    const { initialValues } = this.state;
    this.setState({
      isOpen: true,
      initialValues: {
        ...initialValues,
        id: pec.id,
        num_order: pec.num_order,
        year: pec.year,
        code_bureau: pec.code_bureau,
        quality: pec.quality,
      },
    });
  }
  render() {
    const {
      pec,
      num_cnam,
      consultation,
      consultation_id,
      sessions,
      user,
      doctor,
      setIsOpenPec,
    } = this.props;
    const { setIsOpen, initialValues, hasError, isOpen } = this.state;
    return (
      <div
        key={pec.id}
        className="seance_prise_charge_container"
        style={{ paddingTop: '15px' }}
      >
        {pec.code_bureau && pec.year && pec.num_order ? (
          <div className="prise_en_charge_container">
            <div className="prise_en_charge_title">Prise en charge</div>
            <div className="prise_en_charge_body">
              <div style={{ width: '80%' }} className="prise_en_charge_body_section1">
                <svg
                  width="38"
                  height="38"
                  viewBox="0 0 38 38"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <rect
                    x="0.5"
                    y="0.5"
                    width="37"
                    height="37"
                    rx="9.5"
                    fill="white"
                    stroke="#ECEEF5"
                  />
                  <path
                    d="M17.4883 22.7617L23.5 16.75L22.4453 15.6953L17.4883 20.6172L15.5547 18.6836L14.5 19.7383L17.4883 22.7617ZM19 10.7383L25.75 13.7617V18.2617C25.75 20.3477 25.1055 22.2578 23.8164 23.9922C22.5273 25.7031 20.9219 26.793 19 27.2617C17.0781 26.793 15.4727 25.7031 14.1836 23.9922C12.8945 22.2578 12.25 20.3477 12.25 18.2617V13.7617L19 10.7383Z"
                    fill="#8083A3"
                  />
                </svg>
                <div className="prise_en_charge_text">
                  <span className="prise_en_charge_text_title">
                    {' '}
                    {pec.code_bureau
                      ? `${pec.code_bureau}/${pec.year}/${pec.num_order}`
                      : '-'}
                  </span>
                  <span className="prise_en_charge_text_desc">{pec.quality}</span>
                </div>
              </div>
              <Tooltip title="Générer mémoire des séances CNAM">
                <Link
                  // target="_blank"
                  to={
                    consultation.prise_en_charges &&
                    isHasPermission(
                      user.login_access.permissions,
                      'GENERATE_SESSIONS_MEMORY',
                    ) &&
                    consultation.prise_en_charges.length > 0
                      ? {
                          pathname: `/form/virtual-pec/${consultation.id}`,
                          state: {
                            pec,
                          },
                        }
                      : '#'
                  }
                >
                  <div
                    //  onClick={() => this.props.showFormFacture()}
                    className="more_icon_container"
                    data-toggle="modal"
                    data-target="#view_details"
                  >
                    <i className="fas fa-file-medical-alt" aria-hidden="true"></i>
                  </div>
                </Link>
              </Tooltip>
              <Tooltip title="Prise en charge">
                <div
                  onClick={() => {
                    this.props.openModal();
                  }}
                  style={{ cursor: 'pointer' }}
                  className="more_icon_container"
                >
                  <i
                    style={{
                      padding: '0',
                      border: '',
                      color: '',
                      borderRadius: '',
                      backgroundColor: 'white',
                    }}
                    className="fas fa-eye"
                    aria-hidden="true"
                  ></i>
                </div>
              </Tooltip>
              <Tooltip title="Modifier pec cnam">
                <div
                  onClick={() => {
                    this.openPec(pec);
                    this.props.handelSwitchItem(1);
                  }}
                  style={{ cursor: 'pointer' }}
                  className="more_icon_container"
                >
                  <i
                    style={{
                      padding: '0',
                      border: '',
                      color: '',
                      borderRadius: '',
                      backgroundColor: 'white',
                    }}
                    className="fas fa-edit"
                    aria-hidden="true"
                  ></i>
                </div>
              </Tooltip>
              <Tooltip title="Supprimer pec cnam">
                <div
                  onClick={() => {
                    this.openModal();
                  }}
                  style={{ cursor: 'pointer' }}
                  className="more_icon_container"
                >
                  <i
                    style={{
                      padding: '0',
                      border: '',
                      color: '',
                      borderRadius: '',
                      backgroundColor: 'white',
                    }}
                    className="fas fa-trash"
                    aria-hidden="true"
                  ></i>
                </div>
              </Tooltip>
            </div>
          </div>
        ) : (
          <div style={{ marginTop: '10px' }} className="empty_bilan_container">
            <div className="empty_container">
              <div className="empty_container_title">Pas de pec</div>
              <div className="empty_container_body">
                <span id="empty_container_text">
                  Dès qu'une consultation est créée, vous pourrez y ajouter des reçus.
                </span>
                <div style={{ display: 'flex' }}>
                  <div
                    className="empty_container_btn"
                    style={{ width: '190px', cursor: 'pointer' }}
                    onClick={() => {
                      this.props.handelSwitchItem(1);
                      this.setState({
                        isOpen: true,
                        type_pec: 'cnam',
                      });
                    }}
                  >
                    <svg
                      width="11"
                      height="12"
                      viewBox="0 0 11 12"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M10.7383 6.95557H6.23828V11.4556H4.76172V6.95557H0.261719V5.479H4.76172V0.979004H6.23828V5.479H10.7383V6.95557Z"
                        fill="#5446DB"
                      />
                    </svg>{' '}
                    <span> PEC CNAM</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
        <SwipeableDrawer
          style={{ width: '50%' }}
          anchor="right"
          open={isOpen}
          onClose={this.toggleDrawer('right', false)}
          onOpen={this.toggleDrawer('right', true)}
        >
          <div className="right_side_bar">
            <div className="right_side_bar_header">
              <span id="add_app_sidebar_title">Modifier pec cnam</span>
              <svg
                onClick={() => {
                  this.setState({
                    isOpen: false,
                    rdv: null,
                    hasError: false,
                  });
                }}
                style={{ cursor: 'pointer' }}
                id="close-right-sidebar"
                width="40"
                height="40"
                viewBox="0 0 40 40"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <rect
                  x="0.5"
                  y="0.5"
                  width="39"
                  height="39"
                  rx="9.5"
                  fill="white"
                  stroke="#ECEEF5"
                />
                <path
                  d="M25.2383 15.8164L21.0547 20L25.2383 24.1836L24.1836 25.2383L20 21.0547L15.8164 25.2383L14.7617 24.1836L18.9453 20L14.7617 15.8164L15.8164 14.7617L20 18.9453L24.1836 14.7617L25.2383 15.8164Z"
                  fill="#8083A3"
                />
              </svg>
            </div>
            <div className="right_side_bar_body">
              <div className="form-row">
                <InputField
                  width="46%"
                  className="form-group"
                  type="text"
                  placeholder="N° Assuré"
                  label="N° Assuré"
                  validInput="string"
                  disabled={true}
                  value={num_cnam ? num_cnam : 'N° Assuré non renseigné'}
                />
                <div
                  style={{ width: '46%' }}
                  className={
                    hasError && !initialValues.quality ? 'form-group type3' : 'form-group'
                  }
                >
                  <label htmlFor="">Qualité</label>
                  <div className="input_and_icon">
                    <select
                      value={initialValues.quality || pec.quality}
                      name="quality"
                      onChange={(e) =>
                        this.setState({
                          initialValues: {
                            ...initialValues,
                            quality: e.target.value,
                          },
                        })
                      }
                      className="form-control"
                      id="exampleFormControlSelect1"
                    >
                      <option>Qualité</option>
                      <option value="Assuré lui même">Assuré lui même</option>
                      <option value="Conjoint">Conjoint</option>
                      <option value="Enfant">Enfant</option>
                      <option value="Mére">Mère</option>
                      <option value="Père">Père</option>
                    </select>
                  </div>
                </div>
              </div>
              <div className="form_subtitle">
                <span>Décision</span>
              </div>
              <div className="form-row">
                <div style={{ width: '46%', display: 'flex' }}>
                  <InputField
                    width="20%"
                    className="add_patient_form_item"
                    type="text"
                    placeholder="XX"
                    label="Code bureau"
                    ///maxLength={2}
                    hasError={hasError}
                    value={parseInt(initialValues.code_bureau) || pec.code_bureau || null}
                    validInput="integerCnamKey"
                    name="code_bureau"
                    icon={<i className="fas fa-key"></i>}
                    onChange={(e) =>
                      this.setState({
                        initialValues: {
                          ...initialValues,
                          code_bureau: e.target.value,
                        },
                      })
                    }
                  />
                  <InputField
                    width="30%"
                    className="add_patient_form_item"
                    type="text"
                    placeholder="XXXX"
                    label="Année"
                    maxLength={4}
                    hasError={hasError}
                    value={parseInt(initialValues.year) || pec.year || null}
                    validInput="integerYear"
                    name="year"
                    icon={<i className="fas fa-dot-circle"></i>}
                    onChange={(e) =>
                      this.setState({
                        initialValues: {
                          ...initialValues,
                          year: e.target.value,
                        },
                      })
                    }
                  />
                  <InputField
                    width="50%"
                    className="add_patient_form_item"
                    type="text"
                    placeholder="XXXXXX"
                    label="N° ordre"
                    hasError={hasError}
                    value={initialValues.num_order || pec.num_order || ''}
                    validInput="string"
                    name="num_order"
                    icon={<i className="fas fa-dot-circle"></i>}
                    onChange={(e) =>
                      this.setState({
                        initialValues: {
                          ...initialValues,
                          num_order: e.target.value,
                        },
                      })
                    }
                  />
                </div>
                <InputField
                  width="46%"
                  className="add_patient_form_item"
                  type="text"
                  placeholder="Combiné automatiquement"
                  label="N° décision"
                  maxLength={10}
                  hasError={hasError}
                  value={
                    (initialValues.num_order || pec.num_order) &&
                    (initialValues.year || pec.year) &&
                    (initialValues.code_bureau || pec.code_bureau)
                      ? `${parseInt(initialValues.code_bureau || pec.num_order)}/${
                          initialValues.year || pec.num_order
                        }/${initialValues.num_order || pec.num_order}`
                      : ''
                  }
                  disabled={true}
                  icon={<i className="fas fa-dot-circle"></i>}
                />
              </div>
              <div className="form-row" style={{ marginTop: '30px' }}>
                <InputField
                  width="20%"
                  className="add_patient_form_item"
                  type="text"
                  placeholder="Taper.."
                  label="Nombre des séances"
                  value={initialValues.sessions_count || ''}
                  validInput="string"
                  name="sessions_count"
                  icon={<i className="fas fa-dot-circle"></i>}
                  onChange={(e) => {
                    if (e.target.value.toString() !== '') {
                      const { initialValues } = this.state;

                      this.props.getDispoDoctor({
                        consultation_id: this.props.consultation.id,
                        start_date: moment(initialValues.pecStartDate).format(
                          'YYYY-MM-DD 07:00:00',
                        ),
                        end_date: moment(initialValues.pecStartDate).format(
                          'YYYY-MM-DD 07:00:00',
                        ),
                        sessions_per_week: initialValues.sessions_per_week,
                        practitioner_id: null,
                        patient_id: 1,
                        sessions_count: e.target.value,
                        hours08: '07',
                        hours20: '19',
                      });
                    }
                    this.setState({
                      initialValues: {
                        ...initialValues,
                        sessions_count: e.target.value,
                      },
                    });
                  }}
                />
                <InputField
                  width="20%"
                  className="add_patient_form_item"
                  type="text"
                  placeholder="Taper.."
                  label="Séances / S"
                  value={initialValues.sessions_per_week || ''}
                  validInput="string"
                  name="sessions_per_week"
                  icon={<i className="fas fa-dot-circle"></i>}
                  onChange={(e) => {
                    if (e.target.value.toString() !== '') {
                      const { initialValues } = this.state;

                      this.props.getDispoDoctor({
                        consultation_id: this.props.consultation.id,
                        start_date: moment(initialValues.pecStartDate).format(
                          'YYYY-MM-DD 07:00:00',
                        ),
                        end_date: moment(initialValues.pecStartDate).format(
                          'YYYY-MM-DD 07:00:00',
                        ),
                        sessions_per_week: e.target.value,
                        practitioner_id: null,
                        patient_id: 1,
                        sessions_count: initialValues.sessions_count,
                        hours08: '07',
                        hours20: '19',
                      });
                    }

                    this.setState({
                      initialValues: {
                        ...initialValues,
                        sessions_per_week: e.target.value,
                      },
                    });
                  }}
                />
                <InputField
                  width="23%"
                  value={initialValues.pecStartDate ?? pec.start_date}
                  name="dateDebut"
                  onChange={this.handleChangeDateInput}
                  type="date"
                  label="Date de début"
                />
                <InputField
                  width="23%"
                  value={initialValues.pecEndDate ?? pec.end_date}
                  name="pecEndDate"
                  placeholder="Calculée automatiquement"
                  type="date"
                  label="Date de fin"
                  onChange={(e) =>
                    this.setState({
                      initialValues: {
                        ...initialValues,
                        pecEndDate: e.target.value,
                        end_date: e.target.value,
                      },
                    })
                  }
                />
              </div>

              <div className="footer_buttons">
                <button
                  style={{ marginRight: '3%', width: '285px' }}
                  onClick={() => {
                    this.submitPEC(initialValues, false);
                  }}
                  type="button"
                  className="footer_btn"
                  id="update"
                >
                  Valider
                </button>
              </div>
            </div>
          </div>
        </SwipeableDrawer>
        <Modals
          setIsOpen={setIsOpenPec}
          openModal={this.props.openModal}
          data={
            <>
              <div
                className="modal-header"
                style={{
                  borderBottom: '1px solid #dee2e6',
                  padding: '1.5rem 0rem',
                  margin: '0px 20px',
                  alignItems: 'baseline',
                  marginLeft: '3%',
                  marginTop: '-30px',
                  width: '100%',
                  height: '50px',
                }}
              >
                <span>Prise en charge</span>
                <button
                  type="button"
                  className="close"
                  data-dismiss="modal"
                  aria-label="Close"
                  onClick={() => this.props.openModal()}
                >
                  <span
                    aria-hidden="true"
                    style={{ marginRight: '30px', color: '#1A1C1D' }}
                  >
                    &times;
                  </span>
                </button>
              </div>
              <hr style={{ width: '95%' }} />
              <div className="modal-body" style={{ width: '500px' }}>
                <div className="fiche_patient_deatils_prev_container">
                  <div className="fiche_patient_deatils_prev_body">
                    <div className="fiche_patient_deatils_container">
                      <div className="fiche_patient_deatils_item">
                        <div className="npec_label">
                          <span>N° décision</span>
                        </div>
                        <div className="npec_text">
                          <span>
                            <span>
                              {pec.code_bureau
                                ? `${pec.code_bureau}/${pec.year}/${pec.num_order}`
                                : '-'}
                            </span>
                          </span>
                        </div>
                      </div>
                      <div className="fiche_patient_deatils_item">
                        <div className="ns_label">
                          <span>Qualité </span>
                        </div>
                        <div className="ns_text">
                          <span>{pec.quality}</span>
                        </div>
                      </div>
                      <div className="fiche_patient_deatils_item">
                        <div className="ns_label">
                          <span>Nombre des séances </span>
                        </div>
                        <div className="ns_text">
                          <span>{pec.sessions_count}</span>
                        </div>
                      </div>
                      <div className="fiche_patient_deatils_item">
                        <div className="ns_label">
                          <span>Séances par semaine </span>
                        </div>
                        <div className="ns_text">
                          <span>{pec.sessions_per_week}</span>
                        </div>
                      </div>
                      <div className="fiche_patient_deatils_item">
                        <div className="debut_label">
                          <span>Date début </span>
                        </div>
                        <div className="debut_text">
                          <span>{formatDate(pec.start_date)}</span>
                        </div>
                      </div>
                      <div className="fiche_patient_deatils_item">
                        <div className="debut_label">
                          <span>Date Fin </span>
                        </div>
                        <div className="debut_text">
                          <span>{formatDate(pec.end_date)}</span>
                        </div>
                      </div>

                      <div className="fiche_patient_deatils_item">
                        <div className="debut_label">
                          <span>Montant Total (TTC)</span>
                        </div>
                        <div className="debut_text">
                          <span>
                            {parseFloat(pec.sessions_count * pec.unit_price || 0).toFixed(
                              3,
                            )}
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </>
          }
        />
        <Modals
          setIsOpen={setIsOpen}
          openModal={this.openModal}
          data={
            <>
              <div className="modal-header" style={{ marginTop: '-30px' }}>
                <button
                  type="button"
                  className="close"
                  data-dismiss="modal"
                  aria-label="Close"
                  onClick={() => this.openModal()}
                  style={{ marginTop: '1%' }}
                >
                  <span
                    aria-hidden="true"
                    style={{ marginRight: '5px', color: '#1A1C1D' }}
                  >
                    &times;
                  </span>
                </button>
              </div>
              <div className="modal-body">
                <h2>Suppression PEC!</h2>
                <span>Voulez-vous vraiment supprimer cette PEC ?</span>
              </div>
              <div className="modal-footer">
                <button
                  onClick={() => this.openModal()}
                  type="button"
                  className="modal_close_btn"
                  // data-dismiss="modal"
                  style={{ backgroundColor: '#f6f6f6' }}
                >
                  Non
                </button>
                <button
                  onClick={() => {
                    this.props.deletePEC(pec.id, consultation_id);
                  }}
                  type="button"
                  className="modal_disconnect_btn"
                >
                  Oui
                </button>
              </div>
            </>
          }
        />
      </div>
    );
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(Pec);
