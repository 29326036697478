import React, { Component } from 'react';
import actions from '../store/actions';
import SimpleReactValidator from 'simple-react-validator';
import { isNumeric, formatDate } from '../../../utils/helpres';
import { connect } from 'react-redux';
import InputField from '../../../components/inputField';
import moment from 'moment';
import {
  FORMAT_DATE_SERVER,
  UNIT_PRICE_CNAM,
  codeBureauCNAM,
} from '../../../utils/constants';
import CircularProgress from '@material-ui/core/CircularProgress';

const mapStateToProps = (state, ownProps) => {
  const {
    bill,
    id,
    consultation,
    unit_price = UNIT_PRICE_CNAM,
    sessions_count,
    cnam,
    num_pec,
    regime,
    numBill,
  } = ownProps;
  let start_date;
  let end_date;
  let patient_record_id;
  let code_bureau;
  let year;
  let num_order;
  let pec_id;
  let number = numBill;
  let price_ttc;
  let billId;
  let tva = '7';

  let price_ht = parseFloat((sessions_count * UNIT_PRICE_CNAM) / 1.07)
    .toFixed(3)
    .toString();

  let price_tva = parseFloat(UNIT_PRICE_CNAM * sessions_count - price_ht)
    .toFixed(3)
    .toString();
  let amm = '';
  let amm_accorde = '';
  let amm_total;
  let price_unitaire = null;
  let conventionnel_price;
  if (bill && bill.id) {
    billId = bill.id;
    number = bill.number;
    tva = bill.tva;
    price_ht = bill.price_ht;
    price_tva = bill.price_tva;
    amm = bill.amm;
    amm_accorde = bill.amm_accorde;
    amm_total = bill.amm_total;
    price_ttc = bill.price_ttc;
  }
  if (
    consultation &&
    consultation.id &&
    consultation.prise_en_charges &&
    consultation.prise_en_charges.length > 0
  ) {
    patient_record_id = consultation.patient_record_id;
    code_bureau = consultation.prise_en_charges[0].code_bureau;
    pec_id = consultation.prise_en_charges[0].id;
    year = consultation.prise_en_charges[0].year;
    num_order = consultation.prise_en_charges[0].num_order;
    start_date =
      consultation &&
      consultation.prise_en_charges &&
      consultation.prise_en_charges.length > 0
        ? moment(consultation.prise_en_charges[0].start_date).format('YYYY-MM-DD')
        : '';
    end_date =
      consultation &&
      consultation.prise_en_charges &&
      consultation.prise_en_charges.length > 0
        ? moment(consultation.prise_en_charges[0].end_date).format('YYYY-MM-DD')
        : '';
  }

  return {
    sandwich_menu_btn: state.AppReducer.sandwich_menu_btn,
    isLoadingAddBill: state.FactureReducer.isLoadingAddBill,
    message: state.FactureReducer.message,
    error: state.FactureReducer.error,
    user: state.AppReducer.user,
    bill: bill,
    initialValues: {
      ...bill,
      type: bill && bill.patient ? 'patient_record' : 'pec',
      type_id: id || null,
      tag: 'bill',
      billId,
      number,
      cnam: cnam,
      date:
        bill && bill.date
          ? formatDate(moment(bill.date), FORMAT_DATE_SERVER)
          : formatDate(moment(), FORMAT_DATE_SERVER),
      submitted_in: code_bureau,
      number_pec: num_pec,
      start_date: start_date,
      end_date: end_date,
      patient_record_id,
      code_bureau,
      year,
      num_order: num_order || '',
      pec_id,
      tva,
      price_tva,
      price_ht,
      price_ttc,
      price_unitaire,
      amm,
      amm_accorde,
      conventionnel_price,
      amm_total,
      regime: regime,
      line_bill: {
        act: 'SEANCES DE KINESITHERAPIE',
        matricule: '',
        start_date: start_date,
        end_date: end_date,
        ht_amount: '',
        unit_price: regime === 'cnam' ? UNIT_PRICE_CNAM : unit_price,
        sessions_nbr: sessions_count,
      },
    },
  };
};

const mapDispatchToProps = (dispatch) => ({
  submitBillForm: async (values, tag, action) => {
    await dispatch(actions.submitBillForm(values, tag, action));
  },
});

class FormFacture extends Component {
  constructor(props) {
    super(props);
    this.state = {
      initialValues: {
        ...props.initialValues,
        alert: false,
      },
      activeTab: '1',
      isOpenToggleDiplome: false,
      isOpenToggleFormation: false,
      errorDate: false,
      hasError: false,
      errorDateBill: false,
    };
    this.handleInitialValues = this.handleInitialValues.bind(this);

    this.validator = new SimpleReactValidator({
      messages: {
        email: 'Adresse e-mail invalide',
        required: 'Champ requis.',
        integer: 'Champ ne doit contenir que des chiffres',
        string: 'Champ ne doit contenir que des lettres',
        // OR
        default: 'Cette information est requise.', // will override all messages
      },
    });
  }
  handleInitialValues() {
    this.setState({
      initialValues: {
        submitted_in: '',
        line_bill: {
          act: '',
          matricule: '',
          ht_amount: '',
          unit_price: '',
          sessions_nbr: '',
        },
      },
      hasError: false,
      errorDateBill: false,
    });
  }
  submitForm(initialValues, numBill) {
    const { errorDate } = this.state;
    const { regime } = this.props;
    if (
      (initialValues.price_ht && !isNumeric(initialValues.price_ht)) ||
      (regime === 'act' &&
        initialValues.amm_accorde &&
        !isNumeric(initialValues.amm_accorde)) ||
      (initialValues.price_ttc && !isNumeric(initialValues.price_ttc)) ||
      (initialValues.price_tva && !isNumeric(initialValues.price_tva)) ||
      (initialValues.conventionnel_price &&
        !isNumeric(initialValues.conventionnel_price)) ||
      (regime === 'act' && initialValues.amm && !isNumeric(initialValues.amm)) ||
      (initialValues.tva && !isNumeric(initialValues.tva)) ||
      (initialValues.unit_price && !isNumeric(initialValues.unit_price)) ||
      (initialValues.sessions_nbr && !isNumeric(initialValues.sessions_nbr)) ||
      errorDate
    ) {
      this.setState({
        ...initialValues,
        hasError: true,
      });
    } else if (
      moment(initialValues.line_bill.start_date).isAfter(
        moment(initialValues.line_bill.end_date),
      )
    ) {
      this.setState({
        ...initialValues,
        errorDate: true,
      });
    } else if (moment(initialValues.date).isAfter(moment())) {
      this.setState({
        ...initialValues,
        errorDateBill: true,
      });
    } else if (this.validator.allValid()) {
      this.props.submitBillForm(initialValues, 'bill', this.props.action);
    } else {
      this.validator.showMessages();
      this.setState({
        ...initialValues,
        hasError: true,
      });
      // rerender to show messages for the first time
      // you can use the autoForceUpdate option to do this automatically`
      this.forceUpdate();
    }
  }

  render() {
    const { regime, action, isLoadingLastBill, isLoadingAddBill, numBill, consultation } =
      this.props;
    const { initialValues, hasError, errorDateBill, errorDate } = this.state;
    console.log('DEBUG :: initialValues :: ', initialValues);
    if (isLoadingLastBill) return <div>isLoadingLastBill</div>;
    return (
      <form>
        <div className="form-row">
          <InputField
            width="46%"
            className="form-group"
            type="text"
            placeholder="N° facture"
            label="N° facture"
            validInput="string"
            value={initialValues.number}
            name="number"
            icon={<i className="fas fa-pen"></i>}
            onChange={(e) => {
              this.setState({
                initialValues: {
                  ...initialValues,
                  number: e.target.value,
                },
              });
            }}
          />
          <InputField
            width="46%"
            className="form-group"
            type="date"
            placeholder="Date facture"
            label="Date facture"
            errorDate={initialValues.date && errorDateBill}
            hasError={hasError}
            value={initialValues.date}
            name="date"
            icon=""
            required={this.validator.message('date', initialValues.date, 'required')}
            onChange={(e) => {
              this.setState({
                initialValues: {
                  ...initialValues,
                  date: e.target.value,
                },
              });
            }}
          />
        </div>
        {initialValues.number_pec ? (
          <div className="form-row">
            <InputField
              width="46%"
              className="form-group"
              type="text"
              placeholder="N°PEC"
              label="N°PEC"
              disabled={true}
              validInput="string"
              value={initialValues.number_pec}
              name="number_pec"
              icon={<i className="fas fa-pen"></i>}
            />
            <InputField
              width="46%"
              className="form-group"
              type="text"
              placeholder="Doit à"
              label="Doit à"
              validInput="string"
              value={codeBureauCNAM[initialValues.submitted_in]}
              name="submitted_in"
              disabled={true}
              icon={<i className="fas fa-pen"></i>}
            />
          </div>
        ) : (
          ''
        )}
        <div className="form_subtitle">
          <span>Détails Des Séances !!!</span>
        </div>
        <div className="form-row">
          <InputField
            width="46%"
            className="form-group"
            type="text"
            placeholder="Acte"
            label="Acte"
            hasError={hasError}
            //  validInput="string"
            value={initialValues.line_bill.act}
            name="act"
            icon={<i className="fas fa-pen"></i>}
            required={this.validator.message(
              'act',
              initialValues.line_bill.act,
              'required|string',
            )}
            onChange={(e) => {
              this.setState({
                initialValues: {
                  ...initialValues,
                  line_bill: {
                    ...initialValues.line_bill,
                    act: e.target.value,
                  },
                },
              });
            }}
          />
          {consultation &&
          consultation.prise_en_charges &&
          consultation.prise_en_charges.length > 0 &&
          (regime === 'cnam' || regime === 'act') ? (
            <InputField
              width="46%"
              className="form-group"
              type="text"
              placeholder="Combiné automatiquement"
              label="N° décision"
              maxLength={10}
              hasError={hasError}
              value={
                initialValues.num_order && initialValues.year && initialValues.code_bureau
                  ? `${initialValues.code_bureau}/${initialValues.year}/${initialValues.num_order}`
                  : ''
              }
              disabled={true}
              icon={<i className="fas fa-dot-circle"></i>}
            />
          ) : (
            ''
          )}
        </div>
        <div className="form-row">
          <div className="start_end_date_select_container">
            <InputField
              width="100%"
              className="form-group"
              type="date"
              placeholder="Date de début"
              disabled={true}
              label="Date de début"
              errorDate={initialValues.line_bill.start_date && errorDate}
              hasError={hasError}
              value={initialValues.line_bill.start_date}
              name="start_date"
              icon=""
              required={this.validator.message(
                'start_date',
                initialValues.line_bill.start_date,
                'required',
              )}
              onChange={(e) => {
                this.setState({
                  initialValues: {
                    ...initialValues,
                    line_bill: {
                      ...initialValues.line_bill,
                      start_date: e.target.value,
                    },
                  },
                });
              }}
            />
            <InputField
              width="100%"
              className="form-group"
              type="date"
              placeholder="Date de fin"
              label="Date de fin"
              disabled={true}
              errorDate={initialValues.line_bill.end_date && errorDate}
              hasError={hasError}
              value={initialValues.line_bill.end_date}
              name="end_date"
              icon=""
              required={this.validator.message(
                'end_date',
                initialValues.line_bill.end_date,
                'required',
              )}
              onChange={(e) => {
                if (
                  moment(initialValues.line_bill.start_date).isAfter(
                    moment(e.target.value),
                  )
                ) {
                  this.setState({
                    errorDate: true,
                    initialValues: {
                      ...initialValues,
                      line_bill: {
                        ...initialValues.line_bill,
                        end_date: e.target.value,
                      },
                    },
                  });
                } else
                  this.setState({
                    errorDate: false,
                    initialValues: {
                      ...initialValues,
                      line_bill: {
                        ...initialValues.line_bill,
                        end_date: e.target.value,
                      },
                    },
                  });
              }}
            />
          </div>
          <div className="start_end_date_select_container">
            <InputField
              disabled={true}
              width="100%"
              className="form-group"
              type="text"
              placeholder="10"
              label="Nbr des séances"
              hasError={hasError}
              value={initialValues.line_bill.sessions_nbr}
              name="sessions_nbr"
              icon={<i className="fas fa-pen"></i>}
              validInput="integer"
              required={this.validator.message(
                'sessions_nbr',
                initialValues.line_bill.sessions_nbr,
                'required',
              )}
              onChange={(e) => {
                this.setState({
                  initialValues: {
                    ...initialValues,
                    price_ht: initialValues.line_bill.unit_price
                      ? parseFloat(
                          initialValues.line_bill.unit_price * e.target.value -
                            initialValues.line_bill.unit_price *
                              e.target.value *
                              (initialValues.tva / 100),
                        )
                          .toFixed(3)
                          .toString()
                      : '',
                    price_tva:
                      initialValues.line_bill.unit_price && initialValues.tva
                        ? parseFloat(
                            initialValues.line_bill.unit_price *
                              e.target.value *
                              (initialValues.tva / 100),
                          )
                            .toFixed(3)
                            .toString()
                        : '',
                    line_bill: {
                      ...initialValues.line_bill,
                      sessions_nbr: e.target.value,
                    },
                  },
                });
              }}
            />
            <InputField
              width="100%"
              className="form-group"
              type="text"
              placeholder="12.00"
              label="Cout unitaire"
              hasError={hasError}
              value={initialValues.line_bill.unit_price}
              name="unit_price"
              icon={<i className="fas fa-pen"></i>}
              validInput="integer"
              required={this.validator.message(
                'unit_price',
                initialValues.line_bill.unit_price,
                'required',
              )}
              onChange={(e) => {
                this.setState({
                  initialValues: {
                    ...initialValues,
                    price_ht: initialValues.line_bill.sessions_nbr
                      ? parseFloat(
                          (initialValues.line_bill.sessions_nbr * e.target.value) / 1.07,
                        )
                          .toFixed(3)
                          .toString()
                      : '',
                    price_tva:
                      initialValues.line_bill.sessions_nbr && initialValues.tva
                        ? parseFloat(
                            initialValues.line_bill.sessions_nbr * e.target.value -
                              parseFloat(
                                (initialValues.line_bill.sessions_nbr * e.target.value) /
                                  1.07,
                              ),
                          )
                            .toFixed(3)
                            .toString()
                        : '',
                    line_bill: {
                      ...initialValues.line_bill,
                      unit_price: e.target.value,
                    },
                  },
                });
              }}
            />
          </div>
        </div>
        {regime === 'cnam' || regime === 'autre' ? (
          <div className="form-row">
            <div className="start_end_date_select_container">
              <InputField
                width="100%"
                className="form-group"
                type="text"
                placeholder="18.50"
                label="Total HT"
                hasError={hasError}
                value={initialValues.price_ht}
                name="price_ht"
                icon={<i className="fas fa-pen"></i>}
                validInput="integer"
              />
              <InputField
                width="100%"
                disabled={true}
                className="form-group"
                type="text"
                placeholder="7"
                label="TVA (%)"
                hasError={hasError}
                value={initialValues.tva}
                name="tva"
                icon={<i className="fas fa-pen"></i>}
                validInput="integer"
                required={this.validator.message('tva', initialValues.tva, 'required')}
                onChange={(e) => {
                  this.setState({
                    initialValues: {
                      ...initialValues,
                      price_ht:
                        initialValues.line_bill.sessions_nbr &&
                        initialValues.line_bill.unit_price
                          ? parseFloat(
                              initialValues.line_bill.unit_price *
                                initialValues.line_bill.sessions_nbr -
                                initialValues.line_bill.unit_price *
                                  initialValues.line_bill.sessions_nbr *
                                  (e.target.value / 100),
                            )
                              .toFixed(3)
                              .toString()
                          : '',
                      tva: e.target.value,
                      price_tva:
                        initialValues.line_bill.sessions_nbr &&
                        initialValues.line_bill.unit_price
                          ? parseFloat(
                              initialValues.line_bill.unit_price *
                                initialValues.line_bill.sessions_nbr *
                                (e.target.value / 100),
                            )
                              .toFixed(3)
                              .toString()
                          : '',
                    },
                  });
                }}
              />
            </div>
            <div className="start_end_date_select_container">
              <InputField
                width="100%"
                className="form-group"
                type="text"
                placeholder="16.00"
                label="Montant TVA"
                hasError={hasError}
                value={initialValues.price_tva}
                name="price_tva"
                icon={<i className="fas fa-pen"></i>}
                validInput="integer"
              />
              <InputField
                width="100%"
                className="form-group"
                type="text"
                placeholder="182.50"
                label="Total TTC"
                hasError={hasError}
                value={
                  initialValues.price_tva && initialValues.price_ht
                    ? parseFloat(
                        parseFloat(initialValues.price_ht) +
                          parseFloat(initialValues.price_tva),
                      )
                        .toFixed(3)
                        .toString()
                    : ''
                }
                name="price_ttc"
                icon={<i className="fas fa-pen"></i>}
                validInput="integer"
              />
            </div>
          </div>
        ) : (
          <div className="form-row">
            <div className="start_end_date_select_container">
              <InputField
                width="100%"
                className="form-group"
                type="text"
                placeholder="12.00"
                label="AMM unitaire"
                hasError={hasError}
                value={initialValues.amm}
                name="amm"
                icon={<i className="fas fa-pen"></i>}
                validInput="integer"
                required={this.validator.message('amm', initialValues.amm, 'required')}
                onChange={(e) => {
                  this.setState({
                    initialValues: {
                      ...initialValues,
                      conventionnel_price: initialValues.amm_accorde
                        ? parseFloat(initialValues.amm_accorde * e.target.value)
                            .toFixed(3)
                            .toString()
                        : '',
                      amm: e.target.value,
                    },
                  });
                }}
              />
              <InputField
                width="100%"
                className="form-group"
                type="text"
                placeholder="12.00"
                label="Amm accordé"
                hasError={hasError}
                value={initialValues.amm_accorde}
                name="amm_accorde"
                icon={<i className="fas fa-pen"></i>}
                validInput="integer"
                required={this.validator.message(
                  'amm_accorde',
                  initialValues.amm_accorde,
                  'required',
                )}
                onChange={(e) => {
                  this.setState({
                    initialValues: {
                      ...initialValues,
                      amm_total: initialValues.line_bill.sessions_nbr
                        ? parseFloat(
                            initialValues.line_bill.sessions_nbr * e.target.value,
                          )
                            .toFixed(3)
                            .toString()
                        : '',
                      conventionnel_price: initialValues.amm
                        ? parseFloat(initialValues.amm * e.target.value)
                            .toFixed(3)
                            .toString()
                        : '',
                      price_ht:
                        initialValues.line_bill.sessions_nbr && initialValues.amm
                          ? parseFloat(
                              initialValues.line_bill.sessions_nbr *
                                e.target.value *
                                initialValues.amm,
                            )
                              .toFixed(3)
                              .toString()
                          : '',
                      amm_accorde: e.target.value,
                    },
                  });
                }}
              />
            </div>
            <div className="start_end_date_select_container">
              <InputField
                width="100%"
                className="form-group"
                type="text"
                placeholder="12.00"
                label="AMM total"
                hasError={hasError}
                value={initialValues.amm_total}
                name="amm"
                icon={<i className="fas fa-pen"></i>}
                validInput="integer"
              />
              <InputField
                width="100%"
                className="form-group"
                type="text"
                placeholder="12.00"
                label="Prix conventionnel"
                hasError={hasError}
                value={initialValues.conventionnel_price}
                name="conventionnel_price"
                icon={<i className="fas fa-pen"></i>}
                validInput="integer"
              />
            </div>
            <div className="start_end_date_select_container">
              <InputField
                width="100%"
                className="form-group"
                type="text"
                placeholder="18.50"
                label="Total HT"
                hasError={hasError}
                value={initialValues.price_ht}
                name="price_ht"
                icon={<i className="fas fa-pen"></i>}
                validInput="integer"
                /*required={this.validator.message(
                  'price_ht',
                  initialValues.price_ht,
                  'required',
                )}
                onChange={(e) => {
                  this.setState({
                    initialValues: {
                      ...initialValues,
                      price_ht: e.target.value,
                    },
                  });
                }}*/
              />
              <InputField
                width="100%"
                className="form-group"
                type="text"
                placeholder="7"
                label="TVA (%)"
                hasError={hasError}
                value={initialValues.tva}
                name="tva"
                icon={<i className="fas fa-pen"></i>}
                validInput="integer"
                required={this.validator.message('tva', initialValues.tva, 'required')}
                onChange={(e) => {
                  this.setState({
                    initialValues: {
                      ...initialValues,
                      tva: e.target.value,
                      price_tva: parseFloat(
                        initialValues.price_ht * (e.target.value / 100),
                      )
                        .toFixed(3)
                        .toString(),
                    },
                  });
                }}
              />
            </div>
            <div className="start_end_date_select_container">
              <InputField
                width="100%"
                className="form-group"
                type="text"
                placeholder="16.00"
                label="Montant TVA"
                hasError={hasError}
                value={initialValues.price_tva}
                name="price_tva"
                icon={<i className="fas fa-pen"></i>}
                validInput="integer"
                /*required={this.validator.message(
                  'price_tva',
                  initialValues.price_tva,
                  'required',
                )}
                onChange={(e) => {
                  this.setState({
                    initialValues: {
                      ...initialValues,
                      price_tva: e.target.value,
                    },
                  });
                }}*/
              />
              <InputField
                width="100%"
                className="form-group"
                type="text"
                placeholder="182.50"
                label="Totla TTC"
                hasError={hasError}
                value={
                  initialValues.price_tva && initialValues.price_ht
                    ? parseFloat(
                        parseFloat(initialValues.price_ht) +
                          parseFloat(initialValues.price_tva),
                      ).toFixed(3)
                    : ''
                }
                name="price_ttc"
                icon={<i className="fas fa-pen"></i>}
                validInput="integer"
              />
            </div>
          </div>
        )}
        <div className="footer_buttons">
          <button
            type="button"
            onClick={() => {
              this.props.goBack();
            }}
            className="footer_btn"
            id="cancel"
          >
            Annuler
          </button>
          <button
            style={{ marginRight: '5%' }}
            onClick={() => {
              this.props.changeActionBill(false);
              this.submitForm(initialValues, numBill, action);
            }}
            type="button"
            className="footer_btn"
            id="update"
          >
            {isLoadingAddBill ? (
              <CircularProgress
                style={{
                  marginLeft: '4%',
                  width: '25px',
                  height: '25px',
                  color: 'white',
                  marginTop: '5%',
                }}
              />
            ) : (
              'Valider'
            )}
          </button>
          <button
            style={{ width: '-webkit-fit-content' }}
            onClick={() => {
              this.props.changeActionBill(true);
              this.submitForm(initialValues, numBill, action);
            }}
            type="button"
            className="footer_btn"
            id="update"
          >
            {isLoadingAddBill ? (
              <CircularProgress
                style={{
                  marginLeft: '4%',
                  width: '25px',
                  height: '25px',
                  color: 'white',
                  marginTop: '5%',
                }}
              />
            ) : (
              'Valider et Imprimer'
            )}
          </button>
        </div>
      </form>
    );
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(FormFacture);
