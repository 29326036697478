import Agenda from './pages/agenda';
import Consultations from './pages/consultations';
import Patients from './pages/patient';
import Messenger from './pages/messenger';
import Factures from './pages/facture';
import Supprot from './pages/support';
import Dashboard from './pages/dashborad';
import Settings from './pages/settings';

const routes = [
  {
    id: 0,
    path: '/dashboard',
    name: 'Tableau de bord',
    title: 'Tableau de bord',
    sous_title: 'Home',
    imgColor: require('./assets/img/dashboard_blue.png'),
    img: require('./assets/img/dashboard.png'),
    component: Dashboard,
    layout: '/dashboard',
  },
  {
    id: 1,
    path: '/agenda',
    name: 'Agenda',
    title: 'Agenda',
    permission: 'CALENDAR_ACCESS',
    sous_title: 'Agenda',
    admin: true,
    imgColor: require('./assets/img/agenda_blue.png'),
    img: require('./assets/img/agenda.png'),
    component: Agenda,
    layout: '/',
  },
  {
    id: 100,
    path: '/agenda-practitioner',
    name: 'Agenda',
    sous_title: 'Agenda',
    title: 'Agenda',
    admin: false,
    permission: 'CALENDAR_ACCESS',
    imgColor: require('./assets/img/agenda_blue.png'),
    img: require('./assets/img/agenda.png'),
    component: Agenda,
    layout: '/',
  },
  {
    id: 2,
    path: '/consultations',
    name: 'Cures',
    title: 'Cures',
    permission: 'ACCESS_CURES_LIST',
    imgColor: require('./assets/img/consultation_blue.png'),
    img: require('./assets/img/consultation.png'),
    component: Consultations,
    sous_title: 'Cures',
    layout: '/',
  },
  {
    id: 3,
    path: '/patients',
    name: 'Patients',
    sous_title: 'Patients',
    title: 'Patients',
    permission: 'PATIENT_LIST_ACCESS',
    imgColor: require('./assets/img/patient_blue.png'),
    img: require('./assets/img/patient.png'),
    component: Patients,
    layout: '/',
    type: true,
  },
  {
    id: 4,
    path: '/messenger',
    name: 'Messenger',
    title: 'Messenger',
    permission: 'CHAT_ACCESS',
    imgColor: require('./assets/img/messenger_blue.png'),
    img: require('./assets/img/messenger.png'),
    component: Messenger,
    sous_title: 'Chat',
    layout: '/',
    type: true,
  },
  /* {
    id: 5,
    path: '/factures',
    name: 'Factures',
    imgColor: require('./assets/img/facture_color.svg'),
    img: require('./assets/img/facture_grey.svg'),
    component: Factures,
    layout: '/admin',
    type: true,
  },
  {
    id: 6,
    path: '/bordereaux',
    name: 'Bordereaux',
    imgColor: require('./assets/img/bordereau_color.svg'),
    img: require('./assets/img/bordereau_grey.svg'),
    component: Bordereaux,
    layout: '/admin',
    type: true,
  },*/

  {
    id: 9,
    //path: '/support',
    name: 'Comptabilité',
    sous_title: 'Compta',
    title: 'Compta en chiffres',
    permission: 'ACCOUNTING_STAT_ACCESS',
    imgColor: require('./assets/img/icons8-comptabilité-96-blue.png'),
    img: require('./assets/img/icons8-comptabilité-96-col.png'),
    component: Supprot,
    layout: '/',
    type: true,
    compt: {
      id: '1',
      path: '/bordereaux',
      permission: 'ACCOUNTING_STAT_ACCESS',
      name: 'Bordereaux',
      title: 'Accéder à la comptabilité en chiffres',
      imgColor: require('./assets/img/icons8-insert-card-96.png'),
      img: require('./assets/img/icons8-insert-card-96.png'),
    },
    bordereau: {
      id: '2',
      path: '/bordereaux',
      permission: 'CNAM_SLIP_ACCESS',
      name: 'Bordereaux',
      title: 'Bordereaux',
      imgColor: require('./assets/img/icons8-bill-96 (1).png'),
      img: require('./assets/img/icons8-bill-96 (1).png'),
    },
    bill: {
      id: '3',
      path: '/factures',
      permission: 'BILLS_ACCESS',
      name: 'Factures',
      title: 'Factures',
      imgColor: require('./assets/img/facture_color.svg'),
      img: require('./assets/img/facture_grey.svg'),
      component: Factures,
      layout: '/admin',
      type: true,
    },
    receipt: {
      id: '4',
      path: '/factures',
      permission: 'RECEIPT_ACCESS',
      name: 'Factures',
      title: 'Factures',
      imgColor: require('./assets/img/icons8-payment-check-96.png'),
      img: require('./assets/img/icons8-payment-check-96.png'),
      component: Factures,
      layout: '/admin',
      type: true,
    },
    recettes: {
      id: '5',
      path: '/recettes',
      permission: 'RECEIPT_ACCESS',
      name: 'Recettes',
      title: 'Recettes',
      imgColor: require('./assets/img/icons8-payment-check-96.png'),
      img: require('./assets/img/icons8-payment-check-96.png'),
      layout: '/admin',
      type: true,
    },
    depense: {
      id: '6',
      path: '/depenses',
      permission: 'RECEIPT_ACCESS',
      name: 'Depenses',
      title: 'Depenses',
      imgColor: require('./assets/img/icons8-payment-check-96.png'),
      img: require('./assets/img/icons8-payment-check-96.png'),
      layout: '/admin',
      type: true,
    },
  },
  {
    id: 10,
    //path: '/support',
    name: 'Module CNAM',
    sous_title: 'CNAM',
    title: 'Module cnam en chiffres',
    permission: 'ACCOUNTING_STAT_ACCESS',
    imgColor: require('./assets/img/icons8-comptabilité-96-blue.png'),
    img: require('./assets/img/icons8-comptabilité-96-col.png'),
    //component: Supprot,
    layout: '/',
    type: true,
    compt: {
      id: '1',
      path: '/bordereaux',
      permission: 'ACCOUNTING_STAT_ACCESS',
      name: 'Bordereaux',
      title: 'Comptabilité CNAM',
      imgColor: require('./assets/img/icons8-insert-card-96.png'),
      img: require('./assets/img/icons8-insert-card-96.png'),
    },
    bordereau_cnam: {
      id: '3',
      path: '/bordereaux/cnam',
      permission: 'ACCOUNTING_STAT_ACCESS',
      name: 'Bordereaux CNAM',
      title: 'Bordereaux CNAM',
      imgColor: require('./assets/img/icons8-bill-96 (1).png'),
      img: require('./assets/img/icons8-bill-96 (1).png'),
    },
    bordereau_act: {
      id: '5',
      path: '/bordereaux/act',
      permission: 'ACCOUNTING_STAT_ACCESS',
      name: 'Bordereaux ACT',
      title: 'Bordereaux ACT',
      imgColor: require('./assets/img/icons8-bill-96 (1).png'),
      img: require('./assets/img/icons8-bill-96 (1).png'),
    },
    bill_cnam: {
      id: '2',
      path: '/factures/cnam',
      permission: 'BILLS_ACCESS',
      name: 'Factures CNAM',
      title: 'Factures CNAM',
      imgColor: require('./assets/img/facture_color.svg'),
      img: require('./assets/img/facture_grey.svg'),
      component: Factures,
      layout: '/admin',
      type: true,
    },
    bill_act: {
      id: '4',
      path: '/factures/act',
      permission: 'BILLS_ACCESS',
      name: 'Factures ACT',
      title: 'Factures ACT',
      imgColor: require('./assets/img/facture_color.svg'),
      img: require('./assets/img/facture_grey.svg'),
      component: Factures,
      layout: '/admin',
      type: true,
    },
  },
  {
    id: 8,
    sous_title: 'Params',
    path: '/settings',
    name: 'Parametrage',
    title: 'Parametrage',
    permission: 'SETTINGS_ACCESS',
    imgColor: require('./assets/img/support_blue.png'),
    img: require('./assets/img/support.png'),
    component: Settings,
    layout: '/admin',
    type: true,
  },
  {
    id: 7,
    path: '/support',
    sous_title: 'Support',
    name: 'Support',
    title: 'Support',
    imgColor: require('./assets/img/lifeguard_blue.png'),
    img: require('./assets/img/lifeguard.png'),
    component: Supprot,
    layout: '/',
    type: true,
  },
];

export default routes;
