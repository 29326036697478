import React, { Component } from 'react';
import './add_form.css';
import FormFacture from './containers/form_facture';
import actions from './store/actions';
import actionsConsultation from '../consultations/store/actions';
import { connect } from 'react-redux';
import CircularProgress from '@material-ui/core/CircularProgress';
import { generateBillNumber } from '../../utils/helpres';
import moment from 'moment';

const mapStateToProps = (state) => ({
  tag: state.AppReducer.tag,
  isLoadingGetPec: state.ReceiptReducer.isLoadingGetPec,
  pec: state.ReceiptReducer.pec,
  lastReceipt: state.ReceiptReducer.lastReceipt,
  isLoadingLastReceipt: state.ReceiptReducer.isLoadingLastReceipt,
  updateReceipt: state.ReceiptReducer.updateReceipt,
  consultation: state.ConsultationReducer.consultation,
  loadingConsultationById: state.ConsultationReducer.loadingConsultationById,
});

const mapDispatchToProps = (dispatch) => ({
  getConsultationById: (id) => dispatch(actionsConsultation.getConsultationById(id)),
  getLastReceipt: async (tag) => {
    await dispatch(actions.getLastReceipt(tag));
  },
});
class AddReceipt extends Component {
  constructor(props) {
    super(props);
    props.changeNamePage('Ajouter un Reçu');
    props.changeActiveItemSideBar(9);
    document.title = 'Ajouter un Reçu';
    props.getLastReceipt('receipt');
    props.getConsultationById(
      this.props.match && this.props.match.params
        ? this.props.match.params.consultation_id
        : null,
    );
  }
  componentDidUpdate(prevProps, prevState, snapshot) {
    const { updateReceipt, consultation } = this.props;
    if (
      updateReceipt &&
      updateReceipt !== prevProps.updateReceipt &&
      consultation &&
      consultation.id
    ) {
      this.props.history.goBack();
    }
  }

  render() {
    const {
      bill,
      isLoadingLastReceipt,
      patient,
      consultation,
      loadingConsultationById,
      lastReceipt,
      tag,
    } = this.props;
    if ((loadingConsultationById && !bill) || isLoadingLastReceipt)
      return (
        <CircularProgress disableShrink style={{ marginLeft: '50%', marginTop: '22%' }} />
      );
    return (
      <div className={bill && bill.patient ? '' : 'main_content'}>
        <div className="facture_info_section_form">
          <div className="container_changeable_content">
            <div className="add_facture_section">
              <div className="selected_facture_details">
                <div className="selected_facture_info_container">
                  <div className="selected_facture_img_container">
                    <svg
                      width="14"
                      height="14"
                      viewBox="0 0 14 14"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M3.02734 9.35547C4.41016 8.79297 5.73438 8.51172 7 8.51172C8.26562 8.51172 9.57812 8.79297 10.9375 9.35547C12.3203 9.89453 13.0117 10.6094 13.0117 11.5V13.0117H0.988281V11.5C0.988281 10.6094 1.66797 9.89453 3.02734 9.35547ZM9.10938 6.12109C8.52344 6.70703 7.82031 7 7 7C6.17969 7 5.47656 6.70703 4.89062 6.12109C4.30469 5.53516 4.01172 4.83203 4.01172 4.01172C4.01172 3.19141 4.30469 2.48828 4.89062 1.90234C5.47656 1.29297 6.17969 0.988281 7 0.988281C7.82031 0.988281 8.52344 1.29297 9.10938 1.90234C9.69531 2.48828 9.98828 3.19141 9.98828 4.01172C9.98828 4.83203 9.69531 5.53516 9.10938 6.12109Z"
                        fill="#8083A3"
                      />
                    </svg>
                  </div>
                  <div className="selected_facture_patient_info">
                    <span id="selected_facture_patient_name">
                      {bill && bill.patient
                        ? bill.patient.displayName
                        : consultation &&
                          consultation.patient_record &&
                          consultation.patient_record.patient
                        ? `${consultation.patient_record.patient.first_name} ${consultation.patient_record.patient.last_name}`
                        : ''}
                    </span>
                    <span id="selected_facture_patient_city">
                      {bill && bill.patient
                        ? bill.patient.phone
                        : consultation &&
                          consultation.patient_record &&
                          consultation.patient_record.patient
                        ? consultation.patient_record.patient.phone
                        : ''}
                    </span>
                  </div>
                </div>
              </div>
              <FormFacture
                goBack={() =>
                  patient ? this.props.goBack() : this.props.history.goBack()
                }
                bill={bill}
                cnam={
                  bill && bill.patient
                    ? bill.patient.cnam
                    : consultation &&
                      consultation.patient_record &&
                      consultation.patient_record.patient
                    ? consultation.patient_record.patient.cnam
                    : ''
                }
                type={bill && bill.patient ? 'patient_record' : 'patient_record'}
                id={bill && bill.patient ? bill.id : consultation && consultation.id}
                tag={tag}
                consultation={consultation}
                start_date={
                  consultation &&
                  consultation.sessions &&
                  consultation.sessions.length > 0
                    ? consultation.sessions[0].date
                    : ''
                }
                end_date={
                  consultation &&
                  consultation.sessions &&
                  consultation.sessions.length > 0
                    ? consultation.sessions[consultation.sessions.length - 1].date
                    : ''
                }
                numBill={
                  lastReceipt && lastReceipt.length > 0
                    ? `${generateBillNumber(
                        parseInt(lastReceipt[0].number.split('/')[0]) + 1,
                        moment().format('YYYY'),
                      )}`
                    : `${generateBillNumber(0, moment().format('YYYY'))}`
                }
                lastReceipt={
                  lastReceipt && lastReceipt.length > 0 ? lastReceipt[0] : null
                }
                sessions_count={consultation ? consultation.sessions_count : ''}
                unit_price={consultation ? consultation.unit_price : ''}
              />
            </div>
          </div>
        </div>
      </div>
    );
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(AddReceipt);
