import React, { Component } from 'react';
import moment from 'moment';
import { FORMAT_DATE_SERVER, ModePaiement } from '../../utils/constants';
import { SwipeableDrawer } from '@material-ui/core';
import CircularProgress from '@material-ui/core/CircularProgress';
import { toast } from 'react-toastify';
import ToastAction from '../../components/ToastAction';
import {
  calculateAlreadyPaid,
  calculateMoneyPotAmount,
  formatDate,
  isHasPermission,
  isNumeric,
  newDataEmployeeManagementDraft,
  validate,
} from '../../utils/helpres';
import Modals from '../../components/modal';
import InputField from '../../components/inputField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import TextField from '@material-ui/core/TextField';
import SimpleReactValidator from 'simple-react-validator';
import logoCure from '../../assets/img/consultation.png';
import Tooltip from '@mui/material/Tooltip';
import DatePickerComponent from '../../shared/datePickerComponent';

class SwipeableDrawerUpdate extends Component {
  constructor(props) {
    super(props);
    this.state = {
      rdvId: null,
      isPec: true,
      isCure: true,
      detailsRdv: false,
      isOpenPec: false,
      hasErrorPec: false,
      hasErrorPM: false,
      isOpen: false,
      payed: false,
      hasErrorPayment: false,
      setIsOpenDeleteRDV: false,
      isOpenModal: false,
      isOpenDeleteModal: false,
      isOpenCancelModal: false,
      errorDate: false,
      anchor: 'left',
      valuesPec: {
        num_order: null,
        year: null,
        code_bureau: null,
        quality: '',
      },
      values: {
        motif_id: null,
        doctor: '',
        diagnostic: '',
        date: '',
        note: '',
        start_date: '',
        end_date: '',
        unit_price: null,
        quality: '',
        price_with_out_cnam: '',
        price_cnam: '',
        type: 0,
        hourStart: '',
        regime: 'autre',
        duration: null,
        sessions_per_week: null,
        consultation_id: null,
        sessions_count: null,
      },
    };
    this.handleChangeInput = this.handleChangeInput.bind(this);
    this.openModalConsultation = this.openModalConsultation.bind(this);
    this.openDeleteModal = this.openDeleteModal.bind(this);
    this.toggleDrawer = this.toggleDrawer.bind(this);
    this.validator = new SimpleReactValidator({
      messages: {
        required: 'Champ requis.',
        integer: 'Champ ne doit contenir que des chiffres',
        string: 'Champ ne doit contenir que des lettres',
        // OR
        default: 'Cette information est requise.', // will override all messages
      },
    });
  }
  a11yProps(index) {
    return {
      id: `simple-tab-${index}`,
      'aria-controls': `simple-tabpanel-${index}`,
    };
  }
  openModal() {
    this.setState({
      isOpenModal: !this.state.isOpenModal,
    });
  }
  openDeleteModal() {
    this.setState({
      isOpenDeleteModal: !this.state.isOpenDeleteModal,
    });
  }
  openCancelModal() {
    this.setState({
      isOpenCancelModal: !this.state.isOpenCancelModal,
    });
  }
  openDeleteModalRDV() {
    this.setState({
      setIsOpenDeleteRDV: !this.state.setIsOpenDeleteRDV,
    });
  }
  toggleDrawer = (anchor, open, id) => (event) => {
    if (
      event &&
      event.type === 'keydown' &&
      (event.key === 'Tab' || event.key === 'Shift')
    ) {
      return;
    }
    this.setState({
      left: open,
      anchor: 'left',
    });
  };
  submitFormConsultation(values) {
    const { initialValues, params, userList } = this.props;
    if (
      // !initialValues.start_date ||
      !values.sessions_count ||
      (values.sessions_per_week &&
        isNumeric(values.sessions_per_week) &&
        values.sessions_per_week > 6) ||
      (values.sessions_per_week && !isNumeric(values.sessions_per_week)) ||
      (values.sessions_count &&
        isNumeric(values.sessions_count) &&
        values.sessions_count > 36) ||
      (values.sessions_count && !isNumeric(values.sessions_count)) ||
      !values.date ||
      (values.note && !validate(values.note)) ||
      (values.doctor && !validate(values.doctor))
    ) {
      this.setState({
        ...values,
        hasErrorPM: true,
      });
    } else {
      values.patient_id = initialValues.patient_id;
      values.motif_id = initialValues.motif_id;
      values.rdv_id = initialValues.id;
      this.props.createConsultation(params, values, userList);
      setTimeout(() => {
        this.props.getItemRdv(initialValues.id);
      }, 2000);
    }
  }
  componentDidUpdate(prevProps, prevState, snapshot) {
    const { updateConsultation, addPec } = this.props;
    if (updateConsultation && updateConsultation !== prevProps.updateConsultation) {
      this.setState({
        isCure: false,
      });
      this.openModalConsultation();
    }
    if (addPec && addPec !== prevProps.addPec) {
      this.setState({
        isPec: false,
        isOpenPec: false,
        hasErrorPec: false,
        valuesPec: {
          num_order: null,
          year: null,
          code_bureau: null,
          quality: '',
        },
      });
    }
  }
  async submitPEC(valuesPec) {
    const { initialValues, params, userList } = this.props;
    if (
      !valuesPec.quality ||
      !valuesPec.num_order ||
      !valuesPec.year ||
      !valuesPec.code_bureau ||
      (valuesPec.year && !isNumeric(valuesPec.year)) ||
      (valuesPec.code_bureau && !isNumeric(valuesPec.code_bureau)) ||
      (valuesPec.year &&
        isNumeric(valuesPec.year) &&
        valuesPec.year.toString().length !== 4) ||
      (valuesPec.year &&
        isNumeric(valuesPec.year) &&
        valuesPec.year > moment().format('YYYY'))
    ) {
      this.setState({
        ...valuesPec,
        hasErrorPec: true,
      });
    } else {
      valuesPec.consultation_id = initialValues.consultation_id;
      valuesPec.id =
        initialValues.consultation && initialValues.consultation.prise_en_charges[0].id;

      await this.props.submitFormPEC(params, valuesPec, userList);
    }
  }
  handleChangeInput(event) {
    const { values } = this.state;
    const target = event.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;
    if (name === 'regime') {
      this.setState({
        values: {
          ...values,
          unit_price:
            value === 'cnam'
              ? values.price_medical_prescription
              : value === 'autre'
              ? values.price_with_out_cnam
              : '',
          [name]: value,
        },
      });
    } else
      this.setState({
        values: {
          ...values,
          [name]: value,
        },
      });
  }
  openModalConsultation() {
    const { values } = this.state;
    this.setState({
      isOpen: !this.state.isOpen,
      values: {
        ...values,
        motif_id: null,
        note: '',
      },
    });
  }
  render() {
    const {
      switchList,
      payment,
      initialValues,
      loadingAddSessions,
      motifs,
      params,
      userList,
      errorDate,
      user,
      showInput,
      hasError,
      detailtPyamentRdv,
      isLoadingDetailtPyamentRdv,
      patientStatistical,
      regime,
      isLoadingAllSessionsPatient,
      allSessionsPatient,
      medecins,
      account_type,
    } = this.props;
    let i = 0;
    let pathologie = '';
    let pathologieColor = '#000000';
    const {
      detailsRdv,
      isOpenCancelModal,
      hasErrorPM,
      payed,
      hasErrorPayment,
      isOpenModal,
      isOpenDeleteModal,
      values,
      rdv,
      isOpen,
      setIsOpenDeleteRDV,
      isOpenPec,
      hasErrorPec,
      valuesPec,
      isCure,
      isPec,
    } = this.state;
    let countIsNotCanceled = [];
    countIsNotCanceled =
      allSessionsPatient &&
      allSessionsPatient.length > 0 &&
      allSessionsPatient.filter((ele) => ele.status !== 'canceled');
    let countIsCompleted = [];
    countIsCompleted =
      allSessionsPatient &&
      allSessionsPatient.length > 0 &&
      allSessionsPatient.filter((ele) => ele.status === 'completed');
    return (
      <SwipeableDrawer
        style={{ width: '80% !important' }}
        anchor="right"
        open={switchList}
        onClose={this.props.toggleDrawer('right', false)}
        onOpen={this.props.toggleDrawer('right', true)}
      >
        <Modals
          setIsOpen={setIsOpenDeleteRDV}
          openModal={this.openDeleteModalRDV}
          data={
            <>
              <div className="modal-header" style={{ marginTop: '-30px' }}>
                <button
                  type="button"
                  className="close"
                  data-dismiss="modal"
                  aria-label="Close"
                  onClick={() => this.openDeleteModalRDV()}
                  style={{ marginTop: '1%' }}
                >
                  <span
                    aria-hidden="true"
                    style={{ marginRight: '5px', color: '#1A1C1D' }}
                  >
                    &times;
                  </span>
                </button>
              </div>
              <div className="modal-body">
                <h2>Suppression rendez-vous!</h2>
                <span>Voulez-vous vraiment supprimer ce rendez-vous ?</span>
              </div>
              <div className="modal-footer">
                <button
                  onClick={() => this.openDeleteModalRDV()}
                  type="button"
                  className="modal_close_btn"
                  // data-dismiss="modal"
                  style={{ backgroundColor: '#f6f6f6' }}
                >
                  Non
                </button>
                <button
                  onClick={async () => {
                    await this.props.deleteSession(rdv.id, rdv.patient.id);
                    this.openDeleteModalRDV();
                  }}
                  type="button"
                  className="modal_disconnect_btn"
                >
                  Oui
                </button>
              </div>
            </>
          }
        />
        <Modals
          setIsOpen={isOpenCancelModal}
          openModal={this.openCancelModal}
          data={
            <>
              <div className="modal-header" style={{ marginTop: '-30px' }}>
                <button
                  type="button"
                  className="close"
                  data-dismiss="modal"
                  aria-label="Close"
                  onClick={() => this.openCancelModal()}
                  style={{ marginTop: '1%' }}
                >
                  <span
                    aria-hidden="true"
                    style={{ marginRight: '5px', color: '#1A1C1D' }}
                  >
                    &times;
                  </span>
                </button>
              </div>
              <div className="modal-body">
                <h2>Annulation rendez-vous!</h2>
                <span>Voulez-vous vraiment annuler ce rendez-vous ?</span>
              </div>
              <div className="modal-footer">
                <button
                  onClick={() => this.openCancelModal()}
                  type="button"
                  className="modal_close_btn"
                  // data-dismiss="modal"
                  style={{ backgroundColor: '#f6f6f6' }}
                >
                  Non
                </button>
                <button
                  onClick={async () => {
                    await this.props.submitRDVForm(
                      {
                        ...params,
                        date: formatDate(
                          initialValues.startDate_update,
                          FORMAT_DATE_SERVER,
                        ),
                      },
                      {
                        status: 'canceled',
                        id: initialValues.id,
                      },
                      userList,
                    );
                    this.setState({ payed: false, detailsRdv: false });
                    this.props.handleSwitchList();
                    this.props.handleShowInput(false);
                    this.openCancelModal();
                  }}
                  type="button"
                  className="modal_disconnect_btn"
                >
                  Oui
                </button>
              </div>
            </>
          }
        />
        <Modals
          setIsOpen={isOpenDeleteModal}
          openModal={this.openDeleteModal}
          data={
            <>
              <div className="modal-header" style={{ marginTop: '-30px' }}>
                <button
                  type="button"
                  className="close"
                  data-dismiss="modal"
                  aria-label="Close"
                  onClick={() => this.openDeleteModal()}
                  style={{ marginTop: '1%' }}
                >
                  <span
                    aria-hidden="true"
                    style={{ marginRight: '5px', color: '#1A1C1D' }}
                  >
                    &times;
                  </span>
                </button>
              </div>
              <div className="modal-body">
                <h2>Suppression rendez-vous!</h2>
                <span>Voulez-vous vraiment supprimer ce rendez-vous ?</span>
              </div>
              <div className="modal-footer">
                <button
                  onClick={() => this.openDeleteModal()}
                  type="button"
                  className="modal_close_btn"
                  // data-dismiss="modal"
                  style={{ backgroundColor: '#f6f6f6' }}
                >
                  Non
                </button>
                <button
                  onClick={async () => {
                    await this.props.deleteRdv(
                      {
                        ...params,
                        date: formatDate(
                          initialValues.startDate_update,
                          FORMAT_DATE_SERVER,
                        ),
                      },
                      initialValues.id_update,
                      userList,
                    );
                    this.setState({ payed: false, detailsRdv: false });
                    this.props.handleSwitchList();
                    this.props.handleShowInput(false);
                    this.openDeleteModal();
                  }}
                  type="button"
                  className="modal_disconnect_btn"
                >
                  Oui
                </button>
              </div>
            </>
          }
        />
        <Modals
          setIsOpen={isOpenModal}
          openModal={this.openModal}
          data={
            <>
              <div className="modal-header" style={{ marginTop: '-30px' }}>
                <button
                  type="button"
                  className="close"
                  data-dismiss="modal"
                  aria-label="Close"
                  onClick={() => this.openModal()}
                  style={{ marginTop: '1%' }}
                >
                  <span
                    aria-hidden="true"
                    style={{ marginRight: '5px', color: '#1A1C1D' }}
                  >
                    &times;
                  </span>
                </button>
              </div>
              <div className="modal-body">
                <h2>Modification rendez-vous!</h2>
                <span>Voulez-vous vraiment modifier le statut de ce rendez-vous ?</span>
              </div>
              <div className="modal-footer">
                <button
                  onClick={() => this.openModal()}
                  type="button"
                  className="modal_close_btn"
                  // data-dismiss="modal"
                  style={{ backgroundColor: '#f6f6f6' }}
                >
                  Non
                </button>
                <button
                  onClick={async () => {
                    await this.props.submitRDVForm(
                      {
                        ...params,
                        date: formatDate(
                          initialValues.startDate_update,
                          FORMAT_DATE_SERVER,
                        ),
                      },
                      {
                        status: 'not_confirmed',
                        id: initialValues.id_update,
                      },
                      userList,
                    );
                    this.setState({ payed: false, detailsRdv: false });
                    this.props.handleSwitchList();
                    this.props.handleShowInput(false);
                    this.openModal();
                  }}
                  type="button"
                  className="modal_disconnect_btn"
                >
                  Oui
                </button>
              </div>
            </>
          }
        />
        {isLoadingDetailtPyamentRdv ? (
          <CircularProgress
            disableShrink
            style={{ marginLeft: '45%', marginTop: '23%' }}
          />
        ) : (
          <div className="right_side_bar">
            <div
              className="right_side_bar_header"
              style={{
                display: 'inline-flex',
                width: '-webkit-fill-available',
              }}
            >
              {detailsRdv ? (
                <div
                  style={{ width: '100%' }}
                  onClick={() => this.setState({ detailsRdv: !detailsRdv })}
                >
                  <img
                    src={require('../../assets/img/back_btn.svg')}
                    alt="Retour"
                    style={{ border: 'unset' }}
                  />
                </div>
              ) : (
                <span id="add_app_sidebar_title" style={{ width: 'inherit' }}>
                  Modifier un rendez-vous
                </span>
              )}
              {initialValues.consultation_id && (
                <div
                  onClick={() => this.props.goToCure(initialValues.consultation_id)}
                  className="more_icon_container"
                  style={{ cursor: 'pointer', marginRight: '20px' }}
                >
                  <img style={{ border: '0' }} src={logoCure} />
                </div>
              )}
              {!payed &&
              isHasPermission(user.login_access.permissions, 'UPDATE_APPOINTMENT') ? (
                !detailsRdv ? (
                  initialValues.status === 'canceled' ? (
                    ''
                  ) : !showInput ? (
                    <Tooltip title="Modifier rdv">
                      <svg
                        onClick={() => this.props.handleShowInput(true)}
                        style={{ cursor: 'pointer', marginRight: '10px' }}
                        id="close-right-sidebar"
                        width="40"
                        height="40"
                        viewBox="0 0 40 40"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <rect
                          x="0.5"
                          y="0.5"
                          width="39"
                          height="39"
                          rx="9.5"
                          fill="white"
                          stroke="#ECEEF5"
                        />
                        <path
                          transform="translate(10,10)"
                          d="M13.586 3.586a2 2 0 112.828 2.828l-.793.793-2.828-2.828.793-.793zM11.379 5.793L3 14.172V17h2.828l8.38-8.379-2.83-2.828z"
                          fill="#8083A3"
                        />
                      </svg>
                    </Tooltip>
                  ) : (
                    <svg
                      onClick={async () => {
                        await this.props.submitRDVForm(
                          {
                            ...params,
                            date: moment(initialValues.startDate_update).format(
                              'YYYY-MM-DD',
                            ),
                          },
                          {
                            doctor_id: initialValues.doctor_id,
                            display_name: initialValues.display_name_update,
                            //startHours: initialValues.startHours,
                            note: initialValues.note || '',
                            // endHours: initialValues.endHours,
                            city: initialValues.city,
                            time: initialValues.time_update,
                            endHours: moment(
                              moment(
                                `${initialValues.startDate_update} ${initialValues.endHours}`,
                              ),
                            ).format('HH:mm'),
                            startHours: moment(
                              moment(
                                `${initialValues.startDate_update} ${initialValues.startHours}`,
                              ),
                            ).format('HH:mm'),
                            date: moment(initialValues.startDate_update).format(
                              'YYYY-MM-DD',
                            ),
                            motif_id: initialValues.motif_id,
                            phone_number: initialValues.phone_number_update,
                            id: initialValues.id_update,
                          },
                          userList,
                        );
                        this.props.handleShowInput(false);
                        this.setState({
                          payed: false,
                        });
                      }}
                      style={{ cursor: 'pointer', marginRight: '10px' }}
                      id="close-right-sidebar"
                      width="40"
                      height="40"
                      viewBox="0 0 40 40"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <rect
                        x="0.5"
                        y="0.5"
                        width="39"
                        height="39"
                        rx="9.5"
                        fill="white"
                        stroke="#ECEEF5"
                      />
                      <path
                        transform="translate(10,10)"
                        fillRule="evenodd"
                        d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                        clipRule="evenodd"
                        fill="#8083A3"
                      />
                    </svg>
                  )
                ) : (
                  ''
                )
              ) : (
                ''
              )}

              <div
                onClick={() => {
                  if (
                    initialValues.status === 'completed' &&
                    payment.settled_in_session > 0
                  ) {
                    toast(
                      ({ closeToast }) => (
                        <ToastAction
                          type="error"
                          message="Impossible de supprimer un RDV avec des données de paiement"
                        />
                      ),
                      { hideProgressBar: true },
                    );
                  } else this.openDeleteModal();
                }}
                className="more_icon_container"
                style={{ marginRight: '20px', cursor: 'pointer' }}
              >
                <i
                  className="fas fa-trash"
                  aria-hidden="true"
                  style={{ padding: '0px', backgroundColor: 'white' }}
                ></i>
              </div>
              <svg
                onClick={async () => {
                  this.setState({ payed: false, detailsRdv: false });
                  this.props.handleSwitchList();
                  this.props.handleShowInput(false);
                }}
                style={{ cursor: 'pointer' }}
                id="close-right-sidebar"
                width="40"
                height="40"
                viewBox="0 0 40 40"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <rect
                  x="0.5"
                  y="0.5"
                  width="39"
                  height="39"
                  rx="9.5"
                  fill="white"
                  stroke="#ECEEF5"
                />
                <path
                  d="M25.2383 15.8164L21.0547 20L25.2383 24.1836L24.1836 25.2383L20 21.0547L15.8164 25.2383L14.7617 24.1836L18.9453 20L14.7617 15.8164L15.8164 14.7617L20 18.9453L24.1836 14.7617L25.2383 15.8164Z"
                  fill="#8083A3"
                />
              </svg>
            </div>
            <div className="right_side_bar_body">
              <div className="modal-body">
                <div
                  className="modal_body_section_1"
                  style={{ marginBottom: 0, paddingLeft: '0' }}
                >
                  <div className="selected_facture_details" style={{ width: 'inherit' }}>
                    <div
                      onClick={() => {
                        //if (initialValues.consultation_id && regime !== 'act')
                        this.setState({ detailsRdv: true });
                      }}
                      style={{
                        width: '100%',
                        cursor: initialValues.consultation_id ? 'pointer' : '',
                      }}
                      className="selected_facture_info_container"
                    >
                      <div
                        className="selected_facture_img_container"
                        style={{
                          margin: '0% 2%',
                          width: '45px',
                        }}
                      >
                        <svg
                          width="14"
                          height="14"
                          viewBox="0 0 14 14"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M3.02734 9.35547C4.41016 8.79297 5.73438 8.51172 7 8.51172C8.26562 8.51172 9.57812 8.79297 10.9375 9.35547C12.3203 9.89453 13.0117 10.6094 13.0117 11.5V13.0117H0.988281V11.5C0.988281 10.6094 1.66797 9.89453 3.02734 9.35547ZM9.10938 6.12109C8.52344 6.70703 7.82031 7 7 7C6.17969 7 5.47656 6.70703 4.89062 6.12109C4.30469 5.53516 4.01172 4.83203 4.01172 4.01172C4.01172 3.19141 4.30469 2.48828 4.89062 1.90234C5.47656 1.29297 6.17969 0.988281 7 0.988281C7.82031 0.988281 8.52344 1.29297 9.10938 1.90234C9.69531 2.48828 9.98828 3.19141 9.98828 4.01172C9.98828 4.83203 9.69531 5.53516 9.10938 6.12109Z"
                            fill="#8083A3"
                          />
                        </svg>
                      </div>
                      <div
                        style={{ width: 'inherit' }}
                        className="selected_facture_patient_info"
                      >
                        <span
                          style={{
                            width: 'auto',
                            textAlign: 'left',
                          }}
                          id="selected_facture_patient_name"
                        >
                          {initialValues.display_name_update}{' '}
                          {initialValues.pr_number
                            ? `(FN° ${initialValues.pr_number})`
                            : ''}{' '}
                        </span>
                        {initialValues.phone_number_update &&
                        initialValues.city_update ? (
                          <span
                            id="selected_facture_patient_city"
                            style={{ textAlign: 'left' }}
                          >
                            <strong>Ville :</strong> {initialValues.city_update} -{' '}
                            <strong>Tél :</strong> {initialValues.phone_number_update}
                          </span>
                        ) : initialValues.city_update ? (
                          <span
                            id="selected_facture_patient_city"
                            style={{ textAlign: 'left' }}
                          >
                            <strong>Ville :</strong> {initialValues.city_update}
                          </span>
                        ) : (
                          <span
                            id="selected_facture_patient_city"
                            style={{ textAlign: 'left' }}
                          >
                            <strong>Tél :</strong> {initialValues.phone_number_update}
                          </span>
                        )}
                      </div>
                      <div
                        className="selected_facture_img_container"
                        style={{
                          margin: '0px 2% 0px 0px',
                          width: '45px',
                        }}
                      >
                        <svg
                          width="14"
                          height="14"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 320 512"
                        >
                          <path
                            d="M96 480c-8.188 0-16.38-3.125-22.62-9.375c-12.5-12.5-12.5-32.75 0-45.25L242.8 256L73.38 86.63c-12.5-12.5-12.5-32.75 0-45.25s32.75-12.5 45.25 0l192 192c12.5 12.5 12.5 32.75 0 45.25l-192 192C112.4 476.9 104.2 480 96 480z"
                            fill="#8083A3"
                          />
                        </svg>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  style={{
                    overflowX: 'hidden',
                    width: detailsRdv ? '100%' : '',
                  }}
                >
                  {/* Left section */}
                  {detailsRdv ? (
                    <div className="right_side_bar_body">
                      {/*<div
                        className="selected_facture_details"
                        style={{ width: 'inherit' }}
                      >
                        <div
                          style={{
                            width: '100%',
                          }}
                          className="selected_facture_info_container"
                        >
                          <div
                            style={{ width: 'inherit' }}
                            className="selected_facture_patient_info"
                          >
                            <span
                              style={{
                                width: 'auto',
                                textAlign: 'left',
                              }}
                              id="selected_facture_patient_name"
                            >
                              {initialValues.pr_number
                                ? `(FN° ${initialValues.pr_number})`
                                : ''}{' '}
                            </span>
                            {initialValues.phone_number_update &&
                            initialValues.city_update ? (
                              <span
                                id="selected_facture_patient_city"
                                style={{ textAlign: 'left' }}
                              >
                                <strong>Ville :</strong> {initialValues.city_update} -{' '}
                                <strong>Tél :</strong> {initialValues.phone_number_update}
                              </span>
                            ) : initialValues.city_update ? (
                              <span
                                id="selected_facture_patient_city"
                                style={{ textAlign: 'left' }}
                              >
                                <strong>Ville :</strong> {initialValues.city_update}
                              </span>
                            ) : (
                              <span
                                id="selected_facture_patient_city"
                                style={{ textAlign: 'left' }}
                              >
                                <strong>Tél :</strong> {initialValues.phone_number_update}
                              </span>
                            )}
                          </div>
                          <div
                            style={{ width: '160px' }}
                            className="selected_facture_patient_info"
                          >
                            <span
                              id="selected_facture_patient_city"
                              style={{ textAlign: 'left' }}
                            >
                              <strong>Régime :</strong>{' '}
                              {initialValues.regime === 1
                                ? 'CNAM'
                                : initialValues.regime === 2
                                ? 'ACT'
                                : 'Autre'}
                            </span>
                            <span
                              id="selected_facture_patient_name"
                              style={{ textAlign: 'left' }}
                            >
                              <strong>Nbr S :</strong> {allSessionsPatient.length}
                            </span>
                          </div>
                        </div>
                      </div>*/}
                      <div
                        className="selected_facture_details"
                        style={{ width: 'inherit' }}
                      >
                        <div
                          style={{
                            width: '100%',
                          }}
                          className="selected_facture_info_container"
                        >
                          <div
                            style={{ width: '25%' }}
                            className="selected_facture_patient_info"
                          >
                            <span
                              id="selected_facture_patient_name"
                              style={{ textAlign: 'left' }}
                            >
                              <strong>Réalisé / Total séances</strong>
                            </span>
                            <span
                              id="selected_facture_patient_city"
                              style={{ textAlign: 'left' }}
                            >
                              {countIsCompleted.length} / {countIsNotCanceled.length}
                            </span>
                          </div>
                          <div
                            style={{ width: '25%' }}
                            className="selected_facture_patient_info"
                          >
                            <span
                              id="selected_facture_patient_name"
                              style={{ textAlign: 'left' }}
                            >
                              <strong>Montant séances réalisées</strong>
                            </span>
                            <span
                              id="selected_facture_patient_city"
                              style={{ textAlign: 'left' }}
                            >
                              {patientStatistical && patientStatistical.session_price
                                ? patientStatistical.session_price
                                : 0}
                            </span>
                          </div>
                          <div
                            style={{ width: '25%' }}
                            className="selected_facture_patient_info"
                          >
                            <span
                              id="selected_facture_patient_name"
                              style={{ textAlign: 'left' }}
                            >
                              <strong>Total Payé</strong>
                            </span>
                            <span
                              id="selected_facture_patient_city"
                              style={{ textAlign: 'left' }}
                            >
                              {patientStatistical && patientStatistical.sumCreditPayed}
                            </span>
                          </div>
                          <div
                            style={{ width: '25%' }}
                            className="selected_facture_patient_info"
                          >
                            <span
                              id="selected_facture_patient_name"
                              style={{ textAlign: 'left' }}
                            >
                              <strong>Reste</strong>
                            </span>
                            <span
                              id="selected_facture_patient_city"
                              style={{ textAlign: 'left' }}
                            >
                              {patientStatistical && patientStatistical.sumCreditInPayed}
                            </span>
                          </div>
                        </div>
                      </div>
                      {isLoadingAllSessionsPatient ? (
                        <div className="seances_panel_container">
                          <CircularProgress disableShrink />
                        </div>
                      ) : (
                        <div
                          style={{ height: '350px', padding: '0px', gap: '6px' }}
                          className="seances_panel_container"
                        >
                          {allSessionsPatient &&
                            allSessionsPatient.length > 0 &&
                            allSessionsPatient.map((item) => {
                              pathologie =
                                item.motif && item.motif.label === 'Autre'
                                  ? item.diagnostic
                                  : item.motif.label;
                              pathologieColor = item.motif && item.motif.color;
                              i++;
                              return (
                                <div
                                  style={{ height: '55px' }}
                                  key={item.id}
                                  className="seances_panel_item"
                                >
                                  <div
                                    style={{ width: '0%' }}
                                    className="selected_facture_patient_info"
                                  >
                                    <button
                                      style={{ cursor: 'auto' }}
                                      className={
                                        item.status === 'actif'
                                          ? 'status_rdv btn_status actif_status-'
                                          : item.status === 'not_confirmed'
                                          ? 'status_rdv btn_status actif_not_confirmed'
                                          : item.status === 'completed'
                                          ? 'status_rdv btn_status actif_completed'
                                          : 'status_rdv btn_status actif_canceled'
                                      }
                                    >
                                      <span>{i}</span>
                                    </button>
                                  </div>
                                  <div
                                    style={{ width: '20%' }}
                                    className="selected_facture_patient_info"
                                  >
                                    <span id="selected_facture_patient_name">
                                      {moment(item.date_from).format('dddd')}{' '}
                                      {formatDate(item.date_from)}
                                    </span>
                                    <span id="selected_facture_patient_city">
                                      {moment(item.date_from).format('HH:mm')} -{' '}
                                      {moment(item.date_to).format('HH:mm')}
                                    </span>
                                  </div>
                                  <div
                                    style={{ width: '27%' }}
                                    className="selected_facture_patient_info"
                                  >
                                    <span id="selected_facture_patient_name">
                                      {`${item.practitioner.first_name} ${item.practitioner.last_name}`}
                                    </span>
                                    <span
                                      style={{ color: pathologieColor }}
                                      id="selected_facture_patient_city"
                                    >
                                      {pathologie}
                                    </span>
                                  </div>

                                  <div
                                    style={{ width: '10%' }}
                                    className="selected_facture_patient_info"
                                  >
                                    <span id="selected_facture_patient_name">
                                      Payé:{' '}
                                      {item.payment ? item.payment.settled_in_session : 0}{' '}
                                      / {item.payment ? item.payment.session_price : 0}
                                    </span>
                                    <span id="selected_facture_patient_city">
                                      {item.payment ? item.payment.payment_method : ''}
                                    </span>
                                  </div>
                                  <div
                                    style={{ width: '10%' }}
                                    className="selected_facture_patient_info"
                                  >
                                    <span id="selected_facture_patient_name">
                                      Reste: {item.payment ? item.payment.left_to_pay : 0}
                                    </span>
                                  </div>
                                  <div style={{ width: '7%' }} className="conto">
                                    <div
                                      className="more_icon_container"
                                      style={{ cursor: 'pointer' }}
                                      onClick={() => {
                                        this.setState({
                                          rdv: item,
                                        });
                                        this.openDeleteModalRDV();
                                      }}
                                    >
                                      <i
                                        className="fas fa-trash"
                                        aria-hidden="true"
                                        style={{
                                          padding: '0px',
                                          backgroundColor: 'white',
                                        }}
                                      ></i>
                                    </div>
                                    {account_type === 'admin' ? (
                                      <div
                                        className="more_icon_container"
                                        style={{ cursor: 'pointer' }}
                                        onClick={() => {
                                          this.props.changeInitialValues(item);
                                          //if (initialValues.consultation_id && regime !== 'act')
                                          this.setState({ detailsRdv: false });
                                          this.props.handleShowInput(true);
                                        }}
                                      >
                                        <i
                                          className="fas fa-edit"
                                          aria-hidden="true"
                                          style={{
                                            padding: '0px',
                                            backgroundColor: 'white',
                                          }}
                                        ></i>
                                      </div>
                                    ) : (
                                      ''
                                    )}
                                  </div>
                                </div>
                              );
                            })}
                        </div>
                      )}
                    </div>
                  ) : (
                    <>
                      <div
                        className="modal_body_recap_details"
                        style={{
                          width:
                            (payed || initialValues.status === 'completed') &&
                            regime !== 'act'
                              ? '50%'
                              : '100%',
                          height:
                            (payed || initialValues.status === 'completed') &&
                            regime !== 'act'
                              ? ''
                              : '340px',
                          float: 'left',
                          paddingRight: '5px',
                        }}
                      >
                        <div className="modal_body_recap_details_item">
                          <div className="modal_body_recap_details_item_left">
                            <span>Collaborateur</span>
                          </div>
                          <div className="modal_body_recap_details_item_right">
                            <div className="conto">
                              <span style={{ alignSelf: 'center' }}>
                                {initialValues.doctor
                                  ? `${initialValues.doctor.first_name} ${initialValues.doctor.last_name}`
                                  : ''}
                              </span>
                              <button
                                onClick={() => {
                                  if (initialValues.status === 'actif') this.openModal();
                                }}
                                style={{
                                  cursor:
                                    initialValues.status === 'actif' ? 'pointer' : 'auto',
                                }}
                                className={
                                  initialValues.status === 'actif'
                                    ? 'status_rdv btn_status actif_status'
                                    : 'status_rdv btn_status span_completed'
                                }
                                //  className="status_rdv span_completed"
                              >
                                {initialValues.status === 'actif'
                                  ? 'Planifié'
                                  : initialValues.status === 'canceled'
                                  ? 'Annulé'
                                  : initialValues.status === 'not_confirmed'
                                  ? 'Non confirmé'
                                  : 'Terminé'}
                              </button>
                            </div>
                          </div>
                        </div>
                        <div className="modal_body_recap_details_item">
                          <div className="modal_body_recap_details_item_left">
                            <span>Pathologie </span>
                          </div>
                          <div className="modal_body_recap_details_item_right">
                            <div className="conto">
                              <span
                                style={{
                                  display:
                                    initialValues.status === 'completed'
                                      ? ''
                                      : showInput
                                      ? 'none'
                                      : '',
                                }}
                              >
                                {initialValues.labelMotif_update}
                              </span>
                              <select
                                value={initialValues.motif_id}
                                style={{
                                  display:
                                    initialValues.status === 'completed'
                                      ? 'none'
                                      : !showInput
                                      ? 'none'
                                      : '',
                                  width: '100%',
                                }}
                                onChange={(e) => {
                                  this.props.handleChangeInput(e);
                                }}
                                name="motif_id"
                              >
                                <option value="">Pathologie</option>;
                                {motifs &&
                                  motifs.map((item) => {
                                    if (item.actif)
                                      return (
                                        <option value={item.id}>{item.label}</option>
                                      );
                                  })}
                              </select>
                            </div>
                          </div>
                        </div>
                        <div className="modal_body_recap_details_item">
                          <div className="modal_body_recap_details_item_left">
                            <span>Date</span>
                          </div>
                          <div className="modal_body_recap_details_item_right">
                            <span
                              style={{
                                display:
                                  initialValues.status === 'completed'
                                    ? ''
                                    : showInput
                                    ? 'none'
                                    : '',
                              }}
                            >
                              {formatDate(initialValues.startDate_update)}
                            </span>
                            <input
                              style={{
                                display:
                                  initialValues.status === 'completed'
                                    ? 'none'
                                    : !showInput
                                    ? 'none'
                                    : '',
                                width: '79%',
                              }}
                              value={initialValues.startDate_update}
                              // min={moment()}
                              name="startDate_update"
                              onChange={(e) => {
                                this.props.handleChangeInput(e);
                              }}
                              className="form-control"
                              type="date"
                            />
                          </div>
                        </div>

                        <div
                          className="modal_body_recap_details_item"
                          style={{ height: showInput ? '145px' : '50px' }}
                        >
                          <div className="modal_body_recap_details_item_left">
                            <span>Heure</span>
                          </div>
                          <div
                            className="modal_body_recap_details_item_right"
                            style={{
                              marginLeft: showInput ? '2%' : '',
                            }}
                          >
                            {initialValues.status !== 'completed' && showInput ? (
                              <div
                                className="creneau_container"
                                style={{
                                  width: '80%',
                                  maxHeight: '125px',
                                  overflow: 'auto',
                                }}
                              >
                                <div className="right_sidebar_body_item">
                                  <span className="creneau_container_title">
                                    Heure début
                                  </span>
                                  <div
                                    className={
                                      hasError &&
                                      (!initialValues.startHours ||
                                        moment(
                                          moment(
                                            `${initialValues.date} ${initialValues.startHours}`,
                                          ).format('YYYY-MM-DD HH:mm:ss'),
                                        ).isAfter(
                                          moment(
                                            `${initialValues.date} ${initialValues.endHours}`,
                                          ).format('YYYY-MM-DD HH:mm:ss'),
                                        ))
                                        ? 'creneau_container type3'
                                        : 'creneau_container'
                                    }
                                    style={{ maxHeight: '300px', overflow: 'auto' }}
                                  >
                                    <div
                                      className="input_and_icon"
                                      style={{ width: '100%' }}
                                    >
                                      <input
                                        style={{ width: '100%' }}
                                        value={initialValues.startHours}
                                        name="startHours"
                                        onChange={this.props.handleChangeInput}
                                        className="form-control"
                                        type="time"
                                        placeholder="John Doe"
                                      />
                                    </div>
                                  </div>
                                </div>
                                <div className="right_sidebar_body_item">
                                  <span className="creneau_container_title">
                                    Heure fin
                                  </span>
                                  <div
                                    className={
                                      hasError &&
                                      (!initialValues.endHours ||
                                        moment(
                                          moment(
                                            `${initialValues.date} ${initialValues.startHours}`,
                                          ).format('YYYY-MM-DD HH:mm:ss'),
                                        ).isAfter(
                                          moment(
                                            `${initialValues.date} ${initialValues.endHours}`,
                                          ).format('YYYY-MM-DD HH:mm:ss'),
                                        ))
                                        ? 'creneau_container type3'
                                        : 'creneau_container'
                                    }
                                    style={{ maxHeight: '300px', overflow: 'auto' }}
                                  >
                                    <div
                                      style={{ width: '100%' }}
                                      className="input_and_icon"
                                    >
                                      <input
                                        style={{ width: '100%' }}
                                        value={initialValues.endHours}
                                        name="endHours"
                                        onChange={this.props.handleChangeInput}
                                        className="form-control"
                                        type="time"
                                        placeholder="John Doe"
                                      />
                                    </div>
                                  </div>
                                </div>
                              </div>
                            ) : (
                              <span
                                style={{
                                  display:
                                    initialValues.status === 'completed'
                                      ? ''
                                      : showInput
                                      ? 'none'
                                      : '',
                                }}
                              >
                                {initialValues.startHours}
                              </span>
                            )}
                          </div>
                        </div>
                        <div className="modal_body_recap_details_item">
                          <div className="modal_body_recap_details_item_left">
                            <span>Note</span>
                          </div>
                          <div
                            className="modal_body_recap_details_item_right"
                            style={{ height: 'inherit' }}
                          >
                            <textarea
                              disabled={
                                !showInput || initialValues.status === 'completed'
                              }
                              style={{ width: '100%' }}
                              value={initialValues.note}
                              onChange={this.props.handleChangeInput}
                              name="note"
                              rows={4}
                              className="form-control"
                            >
                              {initialValues.note}
                            </textarea>
                          </div>
                        </div>
                      </div>
                      {(payed || initialValues.status === 'completed') &&
                      regime !== 'act' ? (
                        <div
                          className="modal_body_recap_details"
                          style={{
                            width: '50%',
                            float: 'right',
                            borderLeft: '1px solid gray',
                          }}
                        >
                          <div className="modal_body_recap_details_item">
                            <div className="modal_body_recap_details_item_left">
                              <span>Total réalisé </span>
                            </div>
                            <div className="modal_body_recap_details_item_right">
                              <div className="conto">
                                <input
                                  style={{
                                    width: '79%',
                                  }}
                                  disabled={true}
                                  defaultValue={
                                    detailtPyamentRdv
                                      ? parseFloat(
                                          detailtPyamentRdv.total_achieved,
                                        ).toFixed(2) || 0
                                      : payment.total_achieved || 0
                                  }
                                  name="num_cheque"
                                  className="form-control"
                                  type="text"
                                />
                              </div>
                            </div>
                          </div>
                          <hr id="top_section_seperator" />
                          <div className="modal_body_recap_details_item">
                            <div className="modal_body_recap_details_item_left">
                              <span>Déjà payé</span>
                            </div>
                            <div className="modal_body_recap_details_item_right">
                              <input
                                style={{
                                  width: '79%',
                                }}
                                disabled={true}
                                defaultValue={
                                  detailtPyamentRdv
                                    ? parseFloat(detailtPyamentRdv.already_paid).toFixed(
                                        2,
                                      ) || 0
                                    : payment.already_paid || 0
                                }
                                className="form-control"
                                type="text"
                              />
                            </div>
                          </div>

                          <div
                            className="modal_body_recap_details_item"
                            style={{ height: showInput ? '50px' : '50px' }}
                          >
                            <div className="modal_body_recap_details_item_left">
                              <span>Reste à payer</span>
                            </div>
                            <div className="modal_body_recap_details_item_right">
                              <input
                                style={{
                                  width: '79%',
                                }}
                                disabled={true}
                                defaultValue={
                                  /*detailtPyamentRdv
                                    ? calculateAlreadyPaid(
                                        detailtPyamentRdv.money_pot_amount,
                                        detailtPyamentRdv.already_paid,
                                        payment.settled_in_session,
                                        payment.session_price,
                                      )
                                    : calculateAlreadyPaid(
                                        0,
                                        0,
                                        payment.settled_in_session,
                                        payment.session_price,
                                      )*/
                                  detailtPyamentRdv
                                    ? parseFloat(detailtPyamentRdv.left_to_pay).toFixed(
                                        2,
                                      ) || 0
                                    : payment.left_to_pay || 0
                                }
                                name="num_cheque"
                                className="form-control"
                                type="text"
                              />
                            </div>
                          </div>
                          <div className="modal_body_recap_details_item">
                            <div className="modal_body_recap_details_item_left">
                              <span>Montant cagnotte</span>
                            </div>
                            <div className="modal_body_recap_details_item_right">
                              <input
                                style={{
                                  width: '79%',
                                }}
                                disabled={true}
                                defaultValue={
                                  detailtPyamentRdv
                                    ? detailtPyamentRdv.money_pot_amount
                                    : 0.0
                                  /*detailtPyamentRdv
                                    ? calculateMoneyPotAmount(
                                        detailtPyamentRdv.money_pot_amount,
                                        detailtPyamentRdv.already_paid,
                                        payment.settled_in_session,
                                        payment.session_price,
                                      )
                                    : calculateMoneyPotAmount(
                                        0,
                                        0,
                                        payment.settled_in_session,
                                        payment.session_price,
                                      )*/
                                }
                                name="num_cheque"
                                className="form-control"
                                type="text"
                              />
                            </div>
                          </div>
                          <div className="modal_body_recap_details_item">
                            <div className="modal_body_recap_details_item_left">
                              <span>
                                {regime === 'cnam' ? 'Complément' : 'Tarif Séance'}
                              </span>
                            </div>
                            <div className="modal_body_recap_details_item_right">
                              <span
                                style={{
                                  display: showInput
                                    ? 'none'
                                    : !showInput && !payment.id
                                    ? 'none'
                                    : '',
                                }}
                              >
                                {payment.session_price || ''}
                              </span>
                              <input
                                style={{
                                  border:
                                    hasErrorPayment && !payment.session_price
                                      ? '1px solid red'
                                      : 'none',
                                  display: !showInput && payment.id ? 'none' : '',
                                  width: '79%',
                                }}
                                value={payment.session_price}
                                onChange={this.props.handleChangeInputPayment}
                                name="session_price"
                                className="form-control"
                                type="text"
                              />
                            </div>
                          </div>
                          <div className="modal_body_recap_details_item">
                            <div className="modal_body_recap_details_item_left">
                              <span>Mode paiement</span>
                            </div>
                            <div className="modal_body_recap_details_item_right">
                              <span
                                style={{
                                  display: showInput
                                    ? 'none'
                                    : !showInput && !payment.id
                                    ? 'none'
                                    : '',
                                }}
                              >
                                {payment.payment_method}
                              </span>
                              <select
                                style={{
                                  border:
                                    hasErrorPayment && !payment.payment_method
                                      ? '1px solid red'
                                      : 'none',
                                  display: !showInput && payment.id ? 'none' : '',
                                  width: '82%',
                                }}
                                onChange={(e) => {
                                  if (e.target.value === 'Mode paiement') {
                                    this.setState({
                                      hasErrorPayment: true,
                                    });
                                  } else {
                                    this.setState({
                                      hasErrorPayment: false,
                                    });
                                    this.props.handleChangeInputPayment(e);
                                  }
                                }}
                                name="payment_method"
                                value={payment.payment_method}
                              >
                                <option>Mode paiement</option>;
                                {ModePaiement.map((modePaiement) => {
                                  return (
                                    <option value={modePaiement.title}>
                                      {modePaiement.title}
                                    </option>
                                  );
                                })}
                              </select>
                            </div>
                          </div>
                          {payment.payment_method === 'Chèque' ? (
                            <>
                              <div className="modal_body_recap_details_item">
                                <div className="modal_body_recap_details_item_left">
                                  <span>Numéro du chèque</span>
                                </div>
                                <div className="modal_body_recap_details_item_right">
                                  <span
                                    style={{
                                      display: showInput
                                        ? 'none'
                                        : !showInput && !payment.id
                                        ? 'none'
                                        : '',
                                    }}
                                  >
                                    {payment.num_cheque}
                                  </span>
                                  <input
                                    style={{
                                      border:
                                        hasErrorPayment &&
                                        payment.payment_method === 'Chèque' &&
                                        !payment.num_cheque
                                          ? '1px solid red'
                                          : 'none',
                                      display: !showInput && payment.id ? 'none' : '',
                                      width: '79%',
                                    }}
                                    defaultValue={payment.num_cheque}
                                    onChange={this.props.handleChangeInputPayment}
                                    name="num_cheque"
                                    className="form-control"
                                    type="text"
                                  />
                                </div>
                              </div>
                              <div className="modal_body_recap_details_item">
                                <div className="modal_body_recap_details_item_left">
                                  <span>Date d'échéance</span>
                                </div>
                                <div className="modal_body_recap_details_item_right">
                                  <span
                                    style={{
                                      display: showInput
                                        ? 'none'
                                        : !showInput && !payment.id
                                        ? 'none'
                                        : '',
                                    }}
                                  >
                                    {formatDate(payment.date_cheque)}
                                  </span>
                                  <input
                                    style={{
                                      border:
                                        hasErrorPayment &&
                                        payment.payment_method === 'Chèque' &&
                                        !payment.date_cheque
                                          ? '1px solid red'
                                          : 'none',
                                      display: !showInput && payment.id ? 'none' : '',
                                      width: '79%',
                                    }}
                                    defaultValue={payment.date_cheque}
                                    onChange={this.props.handleChangeInputPayment}
                                    name="date_cheque"
                                    className="form-control"
                                    type="date"
                                  />
                                </div>
                              </div>
                            </>
                          ) : (
                            ''
                          )}
                          {payment.payment_method === 'Virement' ? (
                            <div className="modal_body_recap_details_item">
                              <div className="modal_body_recap_details_item_left">
                                <span>Ordre de virement</span>
                              </div>
                              <div className="modal_body_recap_details_item_right">
                                <span
                                  style={{
                                    display: showInput
                                      ? 'none'
                                      : !showInput && !payment.id
                                      ? 'none'
                                      : '',
                                  }}
                                >
                                  {payment.orde_virement}
                                </span>
                                <input
                                  style={{
                                    border:
                                      hasErrorPayment &&
                                      payment.payment_method === 'Virement' &&
                                      !payment.orde_virement
                                        ? '1px solid red'
                                        : 'none',
                                    display: !showInput && payment.id ? 'none' : '',
                                    width: '79%',
                                  }}
                                  defaultValue={payment.orde_virement}
                                  onChange={this.props.handleChangeInputPayment}
                                  name="orde_virement"
                                  className="form-control"
                                  type="text"
                                />
                              </div>
                            </div>
                          ) : (
                            ''
                          )}
                          <div className="modal_body_recap_details_item">
                            <div className="modal_body_recap_details_item_left">
                              <span>Payé en séance</span>
                            </div>
                            <div className="modal_body_recap_details_item_right">
                              <span
                                style={{
                                  display: showInput
                                    ? 'none'
                                    : !showInput && !payment.id
                                    ? 'none'
                                    : '',
                                }}
                              >
                                {payment.settled_in_session}
                              </span>
                              <input
                                style={{
                                  border:
                                    hasErrorPayment && !payment.settled_in_session
                                      ? '1px solid red'
                                      : 'none',
                                  display: !showInput && payment.id ? 'none' : '',
                                  width: '79%',
                                }}
                                defaultValue={payment.settled_in_session}
                                onChange={this.props.handleChangeInputPayment}
                                name="settled_in_session"
                                className="form-control"
                                type="text"
                              />
                            </div>
                          </div>
                          <div className="modal_body_recap_details_item">
                            <div className="modal_body_recap_details_item_left">
                              <span>Date de réglement</span>
                            </div>
                            <div className="modal_body_recap_details_item_right">
                              <span
                                style={{
                                  display: showInput
                                    ? 'none'
                                    : !showInput && !payment.id
                                    ? 'none'
                                    : '',
                                }}
                              >
                                {payment.date}
                              </span>
                              <input
                                style={{
                                  border:
                                    hasErrorPayment && !payment.date
                                      ? '1px solid red'
                                      : 'none',
                                  display: !showInput && payment.id ? 'none' : '',
                                  width: '79%',
                                }}
                                defaultValue={payment.date}
                                onChange={this.props.handleChangeInputPayment}
                                name="date"
                                className="form-control"
                                type="date"
                              />
                            </div>
                          </div>
                        </div>
                      ) : (
                        ''
                      )}
                    </>
                  )}
                </div>
                <div className="footer_buttons">
                  {initialValues.status === 'canceled' ||
                  detailsRdv ||
                  initialValues.status === 'completed' ||
                  initialValues.status === 'not_confirmed' ? (
                    ''
                  ) : isHasPermission(
                      user.login_access.permissions,
                      'UPDATE_APPOINTMENT',
                    ) ? (
                    regime === 'act' && initialValues.status !== 'completed' ? (
                      <button
                        onClick={async () => {
                          await this.props.submitRDVForm(
                            params,
                            {
                              status: 'completed',
                              id: initialValues.id,
                            },
                            user.id,
                          );
                          this.props.handleSwitchList();
                          //this.openModal();
                        }}
                        style={{ width: '25%' }}
                        className="btn_valid"
                      >
                        Finaliser
                      </button>
                    ) : (
                      <button
                        onClick={async () => {
                          this.setState({ payed: true });
                          if (payed) {
                            if (
                              !payment.session_price ||
                              !payment.date ||
                              !payment.payment_method ||
                              !payment.settled_in_session
                            )
                              this.setState({
                                hasErrorPayment: true,
                              });
                            else if (
                              (payment.payment_method === 'Virement' &&
                                !payment.orde_virement) ||
                              (payment.payment_method === 'Chèque' &&
                                !payment.num_cheque) ||
                              (payment.payment_method === 'Chèque' &&
                                !payment.date_cheque)
                            )
                              this.setState({
                                hasErrorPayment: true,
                              });
                            else {
                              this.setState({
                                hasErrorPayment: false,
                              });
                              if (payment.id && payment.left_to_pay > 0) {
                                toast(
                                  ({ closeToast }) => (
                                    <ToastAction
                                      type="info"
                                      message={`Un nouveau crédit est affecté à ${initialValues.display_name_update}`}
                                    />
                                  ),
                                  { hideProgressBar: true },
                                );
                              }
                              if (
                                !hasErrorPayment &&
                                (payment.date ||
                                  payment.settled_in_session ||
                                  payment.payment_method ||
                                  payment.session_price ||
                                  payment.money_pot_amount ||
                                  payment.total_achieved ||
                                  payment.already_paid ||
                                  payment.left_to_pay)
                              ) {
                                await this.props.submitPaymentForm(
                                  {
                                    ...params,
                                    date: moment(initialValues.startDate_update).format(
                                      'YYYY-MM-DD',
                                    ),
                                  },
                                  {
                                    orde_virement: payment.orde_virement || '',
                                    num_cheque: payment.num_cheque || '',
                                    patient_id: initialValues.patient_id,
                                    date_cheque: payment.date_cheque,
                                    total_achieved:
                                      detailtPyamentRdv &&
                                      detailtPyamentRdv.total_achieved > 0
                                        ? parseFloat(detailtPyamentRdv.total_achieved) +
                                          parseFloat(payment.session_price)
                                        : payment.session_price,
                                    already_paid:
                                      detailtPyamentRdv && detailtPyamentRdv.already_paid
                                        ? parseFloat(
                                            detailtPyamentRdv.already_paid +
                                              parseFloat(payment.settled_in_session),
                                          ).toFixed(2)
                                        : payment.settled_in_session,

                                    left_to_pay: detailtPyamentRdv
                                      ? await calculateAlreadyPaid(
                                          detailtPyamentRdv.money_pot_amount || 0,
                                          detailtPyamentRdv.left_to_pay,
                                          payment.settled_in_session,
                                          payment.session_price,
                                        )
                                      : await calculateAlreadyPaid(
                                          0,
                                          0,
                                          payment.settled_in_session,
                                          payment.session_price,
                                        ),
                                    money_pot_amount: detailtPyamentRdv
                                      ? await calculateMoneyPotAmount(
                                          detailtPyamentRdv.money_pot_amount || 0,
                                          detailtPyamentRdv.left_to_pay,
                                          payment.settled_in_session,
                                          payment.session_price,
                                        )
                                      : await calculateMoneyPotAmount(
                                          0,
                                          0,
                                          payment.settled_in_session,
                                          payment.session_price,
                                        ),
                                    session_price: payment.session_price,
                                    payment_method: payment.payment_method,
                                    settled_in_session: payment.settled_in_session,
                                    date: formatDate(payment.date, FORMAT_DATE_SERVER),
                                    id: payment.id,
                                    appointment_id: initialValues.id,
                                  },
                                  userList,
                                );
                                this.setState({
                                  payed: false,
                                });
                                this.props.handleShowInput(false);
                              }
                            }
                          }
                        }}
                        style={{ width: '25%' }}
                        className="btn_valid"
                      >
                        {payed ? 'Valider le paiment' : 'Finaliser'}
                      </button>
                    )
                  ) : (
                    ''
                  )}
                  {initialValues.status === 'completed' && showInput ? (
                    <button
                      onClick={async () => {
                        this.setState({ payed: true });
                        if (
                          !payment.session_price ||
                          !payment.date ||
                          !payment.payment_method ||
                          !payment.settled_in_session
                        )
                          this.setState({
                            hasErrorPayment: true,
                          });
                        else if (
                          (payment.payment_method === 'Virement' &&
                            !payment.orde_virement) ||
                          (payment.payment_method === 'Chèque' && !payment.num_cheque) ||
                          (payment.payment_method === 'Chèque' && !payment.date_cheque)
                        )
                          this.setState({
                            hasErrorPayment: true,
                          });
                        else {
                          this.setState({
                            hasErrorPayment: false,
                          });
                          if (payment.id && payment.left_to_pay > 0) {
                            toast(
                              ({ closeToast }) => (
                                <ToastAction
                                  type="info"
                                  message={`Un nouveau crédit est affecté à ${initialValues.display_name_update}`}
                                />
                              ),
                              { hideProgressBar: true },
                            );
                          }
                          if (
                            !hasErrorPayment &&
                            (payment.date ||
                              payment.settled_in_session ||
                              payment.payment_method ||
                              payment.session_price ||
                              payment.money_pot_amount ||
                              payment.total_achieved ||
                              payment.already_paid ||
                              payment.left_to_pay)
                          ) {
                            await this.props.submitPaymentForm(
                              {
                                ...params,
                                date: formatDate(
                                  initialValues.startDate_update,
                                  'YYYY-MM-DD',
                                ),
                              },
                              {
                                orde_virement: payment.orde_virement || '',
                                num_cheque: payment.num_cheque || '',
                                patient_id: initialValues.patient_id,
                                date_cheque: payment.date_cheque,
                                total_achieved:
                                  detailtPyamentRdv &&
                                  detailtPyamentRdv.total_achieved > 0
                                    ? parseFloat(detailtPyamentRdv.total_achieved) +
                                      parseFloat(payment.session_price)
                                    : payment.session_price,
                                already_paid:
                                  detailtPyamentRdv && detailtPyamentRdv.already_paid
                                    ? parseFloat(
                                        detailtPyamentRdv.already_paid +
                                          parseFloat(payment.settled_in_session),
                                      ).toFixed(2)
                                    : payment.settled_in_session,

                                left_to_pay: detailtPyamentRdv
                                  ? await calculateAlreadyPaid(
                                      detailtPyamentRdv.money_pot_amount || 0,
                                      detailtPyamentRdv.left_to_pay,
                                      payment.settled_in_session,
                                      payment.session_price,
                                    )
                                  : await calculateAlreadyPaid(
                                      0,
                                      0,
                                      payment.settled_in_session,
                                      payment.session_price,
                                    ),
                                money_pot_amount: detailtPyamentRdv
                                  ? await calculateMoneyPotAmount(
                                      detailtPyamentRdv.money_pot_amount || 0,
                                      detailtPyamentRdv.left_to_pay,
                                      payment.settled_in_session,
                                      payment.session_price,
                                    )
                                  : await calculateMoneyPotAmount(
                                      0,
                                      0,
                                      payment.settled_in_session,
                                      payment.session_price,
                                    ),
                                session_price: payment.session_price,
                                payment_method: payment.payment_method,
                                settled_in_session: payment.settled_in_session,
                                date: formatDate(payment.date, FORMAT_DATE_SERVER),
                                id: payment.id,
                                appointment_id: initialValues.id,
                              },
                              userList,
                            );
                            this.setState({
                              payed: false,
                            });
                            this.props.handleShowInput(false);
                          }
                        }
                      }}
                      style={{ width: '25%' }}
                      className="btn_valid"
                    >
                      Valider le paiment
                    </button>
                  ) : (
                    ''
                  )}
                  {initialValues.status === 'not_confirmed' &&
                  isHasPermission(user.login_access.permissions, 'UPDATE_APPOINTMENT') &&
                  !detailsRdv ? (
                    <button
                      onClick={async () => {
                        await this.props.submitRDVForm(
                          {
                            ...params,
                            date: moment(initialValues.startDate_update).format(
                              'YYYY-MM-DD',
                            ),
                          },
                          {
                            status: 'actif',
                            id: initialValues.id,
                          },
                          userList,
                        );
                        this.props.handleSwitchList();
                        //this.openModal();
                      }}
                      style={{ width: '34%', marginRight: '1px' }}
                      className="btn_valid"
                    >
                      Confirmer le rendez-vous
                    </button>
                  ) : (
                    ''
                  )}
                  {initialValues.status === 'canceled' || detailsRdv ? (
                    ''
                  ) : isHasPermission(
                      user.login_access.permissions,
                      'ADD_NEW_APPOINTMENT',
                    ) ? (
                    <button
                      onClick={async () => {
                        this.props.handleSwitchDuplicateRDV();
                        this.props.handleSwitchList();
                        this.props.getItemRdvDuplicated(initialValues);
                        //await this.props.changeActiveItemSideBarRDV(true)
                        //this.openModal();
                      }}
                      style={{ width: '34%', marginRight: '1px' }}
                      id="cancel"
                      className="footer_btn"
                    >
                      Dupliquer ce rendez-vous
                    </button>
                  ) : (
                    ''
                  )}

                  {isHasPermission(
                    user.login_access.permissions,
                    'ADD_NEW_APPOINTMENT',
                  ) &&
                  initialValues.status === 'canceled' &&
                  !detailsRdv ? (
                    <button
                      onClick={async () => {
                        this.props.handleSwitchDuplicateRDV();
                        this.props.handleSwitchList();
                        initialValues.type = 'reprogrammer';
                        this.props.getItemRdvDuplicated(initialValues);
                        //await this.props.changeActiveItemSideBarRDV(true)
                        //this.openModal();
                      }}
                      id="cancel"
                      style={{ width: '34%', marginRight: '1px' }}
                      className="footer_btn"
                    >
                      Reprogrammer le rendez-vous
                    </button>
                  ) : (
                    ''
                  )}
                  {initialValues.status === 'canceled' ||
                  detailsRdv ||
                  initialValues.status === 'completed' ? (
                    ''
                  ) : isHasPermission(
                      user.login_access.permissions,
                      'CANCEL_APPOINTMENT',
                    ) ? (
                    <button
                      onClick={async () => {
                        this.openCancelModal();
                      }}
                      id="cancel"
                      style={{ width: '34%', marginRight: '1px' }}
                      className="footer_btn"
                    >
                      Annuler le rendez-vous
                    </button>
                  ) : (
                    ''
                  )}

                  {!initialValues.consultation_id && isCure ? (
                    isHasPermission(user.login_access.permissions, 'ADD_NEW_CURE') ? (
                      <button
                        onClick={async () => {
                          this.setState({ isOpen: true });
                        }}
                        id="cancel"
                        style={{ width: '34%', marginRight: '1px' }}
                        className="footer_btn"
                      >
                        Créer cure
                      </button>
                    ) : (
                      ''
                    )
                  ) : (
                    ''
                  )}
                  {initialValues.consultation &&
                  initialValues.consultation.id &&
                  !initialValues.consultation.prise_en_charges[0].code_bureau &&
                  !initialValues.consultation.prise_en_charges[0].year &&
                  !initialValues.consultation.prise_en_charges[0].num_order &&
                  isPec &&
                  (regime === 'cnam' || regime === 'act') ? (
                    isHasPermission(user.login_access.permissions, 'ADD_NEW_CURE') ? (
                      <button
                        onClick={async () => {
                          this.setState({ isOpenPec: true });
                        }}
                        id="cancel"
                        style={{ width: '34%', marginRight: '1px' }}
                        className="footer_btn"
                      >
                        Ajouter PEC
                      </button>
                    ) : (
                      ''
                    )
                  ) : (
                    ''
                  )}
                </div>
              </div>
            </div>
          </div>
        )}
        <SwipeableDrawer
          style={{ width: '50%' }}
          anchor="right"
          open={isOpenPec}
          onClose={this.toggleDrawer('right', false)}
          onOpen={this.toggleDrawer('right', true)}
        >
          <div className="right_side_bar">
            <div className="right_side_bar_header">
              <span id="add_app_sidebar_title">Modifier pec cnam</span>
              <svg
                onClick={() => {
                  this.setState({
                    isOpenPec: false,
                    rdv: null,
                    hasErrorPec: false,
                    valuesPec: {
                      num_order: null,
                      year: null,
                      code_bureau: null,
                      quality: '',
                    },
                  });
                }}
                style={{ cursor: 'pointer' }}
                id="close-right-sidebar"
                width="40"
                height="40"
                viewBox="0 0 40 40"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <rect
                  x="0.5"
                  y="0.5"
                  width="39"
                  height="39"
                  rx="9.5"
                  fill="white"
                  stroke="#ECEEF5"
                />
                <path
                  d="M25.2383 15.8164L21.0547 20L25.2383 24.1836L24.1836 25.2383L20 21.0547L15.8164 25.2383L14.7617 24.1836L18.9453 20L14.7617 15.8164L15.8164 14.7617L20 18.9453L24.1836 14.7617L25.2383 15.8164Z"
                  fill="#8083A3"
                />
              </svg>
            </div>
            <div className="right_side_bar_body">
              <div className="form-row">
                <InputField
                  width="46%"
                  className="form-group"
                  type="text"
                  placeholder="N° Assuré"
                  label="N° Assuré"
                  validInput="string"
                  disabled={true}
                  value={
                    initialValues.num_cnam
                      ? initialValues.num_cnam
                      : 'N° Assuré non renseignée'
                  }
                />
                <div
                  style={{ width: '46%' }}
                  className={
                    hasErrorPec && !valuesPec.quality ? 'form-group type3' : 'form-group'
                  }
                >
                  <label htmlFor="">Qualité</label>
                  <div className="input_and_icon">
                    <select
                      value={valuesPec.quality}
                      name="quality"
                      onChange={(e) =>
                        this.setState({
                          valuesPec: {
                            ...valuesPec,
                            quality: e.target.value,
                          },
                        })
                      }
                      className="form-control"
                      id="exampleFormControlSelect1"
                    >
                      <option>Qualité</option>
                      <option value="Assuré lui même">Assuré lui même</option>
                      <option value="Conjoint">Conjoint</option>
                      <option value="Enfant">Enfant</option>
                      <option value="Mére">Mère</option>
                      <option value="Père">Père</option>
                    </select>
                  </div>
                </div>
              </div>
              <div className="form_subtitle">
                <span>Décision</span>
              </div>
              <div className="form-row">
                <div style={{ width: '46%', display: 'flex' }}>
                  <InputField
                    width="20%"
                    className="add_patient_form_item"
                    type="text"
                    placeholder="XX"
                    label="Code bureau"
                    ///maxLength={2}
                    hasError={hasErrorPec}
                    value={parseInt(valuesPec.code_bureau) || null}
                    validInput="integerCnamKey"
                    name="code_bureau"
                    icon={<i className="fas fa-key"></i>}
                    onChange={(e) =>
                      this.setState({
                        valuesPec: {
                          ...valuesPec,
                          code_bureau: e.target.value,
                        },
                      })
                    }
                  />
                  <InputField
                    width="30%"
                    className="add_patient_form_item"
                    type="text"
                    placeholder="XXXX"
                    label="Année"
                    maxLength={4}
                    hasError={hasErrorPec}
                    value={parseInt(valuesPec.year) || null}
                    validInput="integerYear"
                    name="year"
                    icon={<i className="fas fa-dot-circle"></i>}
                    onChange={(e) =>
                      this.setState({
                        valuesPec: {
                          ...valuesPec,
                          year: e.target.value,
                        },
                      })
                    }
                  />
                  <InputField
                    width="50%"
                    className="add_patient_form_item"
                    type="text"
                    placeholder="XXXXXX"
                    label="N° ordre"
                    hasError={hasErrorPec}
                    value={valuesPec.num_order || ''}
                    validInput="string"
                    name="num_order"
                    icon={<i className="fas fa-dot-circle"></i>}
                    onChange={(e) =>
                      this.setState({
                        valuesPec: {
                          ...valuesPec,
                          num_order: e.target.value,
                        },
                      })
                    }
                  />
                </div>
                <InputField
                  width="46%"
                  className="add_patient_form_item"
                  type="text"
                  placeholder="Combiné automatiquement"
                  label="N° décision"
                  maxLength={10}
                  hasError={hasErrorPec}
                  value={
                    valuesPec.num_order && valuesPec.year && valuesPec.code_bureau
                      ? `${parseInt(valuesPec.code_bureau)}/${valuesPec.year}/${
                          valuesPec.num_order
                        }`
                      : ''
                  }
                  disabled={true}
                  icon={<i className="fas fa-dot-circle"></i>}
                />
              </div>

              <div className="footer_buttons">
                <button
                  style={{ marginRight: '3%', width: '285px' }}
                  onClick={() => {
                    this.submitPEC(valuesPec);
                  }}
                  type="button"
                  className="footer_btn"
                  id="update"
                >
                  Valider
                </button>
              </div>
            </div>
          </div>
        </SwipeableDrawer>
        <SwipeableDrawer
          style={{ width: '50%' }}
          anchor="right"
          open={isOpen}
          onClose={this.toggleDrawer('right', false)}
          onOpen={this.toggleDrawer('right', true)}
        >
          <div className="right_side_bar">
            <div className="right_side_bar_header">
              <span id="add_app_sidebar_title">Nouvelle cure</span>
              <svg
                onClick={() => {
                  this.openModalConsultation();
                }}
                style={{ cursor: 'pointer' }}
                id="close-right-sidebar"
                width="40"
                height="40"
                viewBox="0 0 40 40"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <rect
                  x="0.5"
                  y="0.5"
                  width="39"
                  height="39"
                  rx="9.5"
                  fill="white"
                  stroke="#ECEEF5"
                />
                <path
                  d="M25.2383 15.8164L21.0547 20L25.2383 24.1836L24.1836 25.2383L20 21.0547L15.8164 25.2383L14.7617 24.1836L18.9453 20L14.7617 15.8164L15.8164 14.7617L20 18.9453L24.1836 14.7617L25.2383 15.8164Z"
                  fill="#8083A3"
                />
              </svg>
            </div>
            <div className="right_side_bar_body">
              <div className="modal-body">
                <form style={{ padding: '2px 0px' }}>
                  <div className="form-row">
                    <div style={{ width: '100%' }} className="'right_sidebar_body_item'">
                      <label htmlFor="">Diagnostique</label>
                    </div>
                    <div style={{ width: '100%' }} className="'right_sidebar_body_item'">
                      <label htmlFor="">{initialValues.labelMotif_update}</label>
                    </div>
                  </div>
                  <div className="form-row">
                    <div
                      style={{ width: '100%' }}
                      className={
                        hasErrorPM && !values.date ? 'form-group type3' : 'form-group'
                      }
                    >
                      <label>Date </label>
                      <div className="input_and_icon">
                        <input
                          style={{ width: '100%' }}
                          value={values.date}
                          name="date"
                          onChange={this.handleChangeInput}
                          type="date"
                        />
                      </div>
                    </div>
                  </div>
                  <div className="form-row">
                    <div style={{ width: '100%' }} className="form-group">
                      <label>Note</label>
                      <div className="input_and_icon">
                        <textarea
                          maxLength={200}
                          style={{
                            border: '0px',
                            borderBottom: '1px #ECEEF5 solid',
                            width: '100%',
                          }}
                          value={values.note}
                          name="note"
                          onChange={this.handleChangeInput}
                          placeholder="Vos notes ici."
                        ></textarea>
                      </div>
                    </div>
                  </div>
                  <div className="form-row">
                    <div style={{ width: '100%' }} className="form-group">
                      <label>Médecin prescripteur </label>
                      <Autocomplete
                        id="size-small-standard"
                        size="small"
                        options={newDataEmployeeManagementDraft(medecins)}
                        onChange={(event, option) => {
                          this.setState({
                            initialValues: {
                              ...initialValues,
                              doctor: option != null ? option.title : '',
                            },
                          });
                        }}
                        defaultValue={{
                          title: initialValues.doctor,
                        }}
                        getOptionLabel={(option) => option.title}
                        renderInput={(cities) => (
                          <TextField
                            style={{ width: '100%' }}
                            placeholder="Choisissez un médecin prescripteur"
                            {...cities}
                            variant="standard"
                            //value={initialValues.address_practitioner && initialValues.address_practitioner.city}
                            //label={<span>Ville <span style={{color: 'red'}}>*</span> </span>}
                            // error={hasError && !initialValues.address_practitioner.city}
                          />
                        )}
                      />
                    </div>
                  </div>
                  <br />

                  <div style={{ display: 'flex' }}>
                    <span
                      style={{
                        textAlign: 'initial',
                        color: 'black',
                        fontWeight: 'bold',
                        marginRight: '2%',
                      }}
                    >
                      Prescription médicale
                    </span>
                  </div>
                  <br />
                  <div className="form-row">
                    <div
                      className={
                        hasErrorPM && !values.regime
                          ? 'right_sidebar_body_item type3'
                          : 'right_sidebar_body_item'
                      }
                      style={{ width: '30%' }}
                    >
                      <label>Regime</label>
                      <div className="input_and_icon">
                        <select
                          style={{ width: '100%' }}
                          value={values.regime || 'autre'}
                          name="regime"
                          onChange={(e) => {
                            this.handleChangeInput(e);
                          }}
                        >
                          <option>Choissiez </option>
                          <option value="cnam">CNAM </option>
                          <option value="act"> Accident de travail </option>
                          <option value="autre">Autre</option>
                        </select>
                      </div>
                    </div>
                    <div style={{ width: '50%', display: 'flex' }}>
                      <InputField
                        width="30%"
                        className="add_patient_form_item"
                        type="text"
                        placeholder="10"
                        label="Nbr de séances"
                        hasError={hasErrorPM}
                        value={values.sessions_count || ''}
                        name="sessions_count"
                        icon={<i className="fas fa-pen"></i>}
                        validInput="integerSessionC"
                        onChange={this.handleChangeInput}
                      />
                      <InputField
                        maxLength={1}
                        width="30%"
                        className="add_patient_form_item"
                        type="text"
                        label="S/semaine"
                        placeholder="S/semaine"
                        hasError={hasErrorPM}
                        value={values.sessions_per_week || ''}
                        name="sessions_per_week"
                        icon={<i className="fas fa-pen"></i>}
                        validInput="integerNbrS"
                        onChange={this.handleChangeInput}
                      />
                      {values.regime === 'act' ? (
                        ''
                      ) : (
                        <InputField
                          width="40%"
                          className="add_patient_form_item"
                          type="text"
                          placeholder="12.00"
                          label="Cout unitaire"
                          hasError={hasErrorPM}
                          value={values.unit_price}
                          name="unit_price"
                          icon={<i className="fas fa-pen"></i>}
                          validInput="integer"
                          onChange={this.handleChangeInput}
                        />
                      )}
                    </div>
                  </div>
                  <br />
                </form>
              </div>
              <div
                className="modal-footer create_prise_en_charge_1_footer"
                style={{
                  borderBottom: '1px solid #dee2e6',
                  padding: '1.5rem 0rem',
                  margin: '0px 20px',
                }}
              >
                <button
                  onClick={() => this.openModalConsultation()}
                  type="button"
                  className="modal_close_btn"
                  id="retour"
                  style={{ backgroundColor: '#f6f6f6' }}
                >
                  Annuler
                </button>
                <button
                  onClick={() => this.submitFormConsultation(values)}
                  id="valider"
                  style={{ marginLeft: '0' }}
                  type="button"
                  className="btn_valid"
                >
                  {loadingAddSessions ? (
                    <CircularProgress
                      style={{
                        marginLeft: '4%',
                        width: '35px',
                        height: '25px',
                        color: 'white',
                        animation: 'infinite',
                      }}
                    />
                  ) : (
                    'Valider'
                  )}
                </button>
              </div>
            </div>
          </div>
        </SwipeableDrawer>
      </SwipeableDrawer>
    );
  }
}

export default SwipeableDrawerUpdate;
