import React, { Component } from 'react';
import './index.css';
import actions from './store/actions';
import actionsJs from '../../js/actions';
import { connect } from 'react-redux';
import Facture from './containers/facture';
import CircularProgress from '@material-ui/core/CircularProgress';

const mapStateToProps = (state) => ({
  user: state.AppReducer.user,
  bill: state.FactureReducer.bill,
  isLoadingBill: state.FactureReducer.isLoadingBill,
  office: state.AppReducer.office,
});

const mapDispatchToProps = (dispatch) => ({
  getBillById: (id) => dispatch(actions.getBillById(id)),
  submitUpdateBillForm: async (values) => {
    await dispatch(actions.submitUpdateBillForm(values));
    dispatch(actions.getBillById(values.id));
  },
  changeActiveItemSideBarCompta: (regime) =>
    dispatch(actionsJs.changeActiveItemSideBarCompta(regime)),
  getOfficeByIdDoctor: () => dispatch(actionsJs.getOfficeByIdDoctor()),
  deleteFacture: async (id, params, type, regime) => {
    await dispatch(actions.deleteFacture(id));
    dispatch(actions.getAllBill(params, type, regime));
  },
});

class Details extends Component {
  constructor(props) {
    super(props);
    props.changeActiveItemSideBarCompta(151);
    props.changeActiveItemSideBar(9);
    props.getOfficeByIdDoctor();
    props.getBillById(props.match.params.id);
    props.changeNamePage('Détail facture');
    document.title = 'Détail facture';
    this.state = {
      errorDate: false,
    };
  }

  render() {
    const { bill, office, isLoadingBill } = this.props;
    if (isLoadingBill)
      return (
        <CircularProgress disableShrink style={{ marginLeft: '47%', marginTop: '30%' }} />
      );
    return (
      <div>
        <div className="main_content">
          <div className="facture_info_section" style={{ width: '100%' }}>
            <Facture
              office={office}
              submitUpdateBillForm={this.props.submitUpdateBillForm}
              deleteFacture={this.props.deleteFacture}
              bill={bill}
            />
          </div>
        </div>
      </div>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Details);
