import React, { Component } from 'react';
import { SelectorBilanItemSection, SelectorColorInputCheckbox } from '../../../../utils';
import Slider from 'rc-slider';
import 'rc-slider/assets/index.css';
class Douleur extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showEntretien: true,
      val: 0,
      showDouleur: false,
      showBilanArticulaire: false,
      showScore: false,
    };
    this.handleChangeSubSectionBtn = this.handleChangeSubSectionBtn.bind(this);
    this.handleClick = this.handleClick.bind(this);
  }
  componentDidMount() {
    SelectorColorInputCheckbox();
    SelectorBilanItemSection();
  }
  handleChangeSubSectionBtn(name) {
    this.setState({
      [name]: !this.state[name],
    });
  }
  handleClick(fileUploader) {
    this.refs[fileUploader].click();
  }
  render() {
    const { sandwich_menu_btn, initialValues } = this.props;
    return (
      <div className="actual_bilan_settings">
        <div className="actual_bilan_settings_item head_shaking_test" id="headShaking1">
          <div
            style={{ backgroundColor: '#6c89bc' }}
            onClick={() => this.handleChangeSubSectionBtn('showEntretien')}
            className="actual_bilan_settings_item_header sub_section_btn"
          >
            <span style={{ color: '#ffffff' }}> Douleur</span>
          </div>
          <br />
          <div
            style={{ backgroundColor: '#035ef8' }}
            onClick={() => this.handleChangeSubSectionBtn('showEntretien')}
            className="actual_bilan_settings_item_header sub_section_btn"
          >
            <span style={{ color: '#ffffff' }}> Rapport des douleurs</span>
          </div>
          <br />
          <div
            className="actual_bilan_settings_item_body "
            id="headShaking1_sub_section"
            style={{ display: 'flex', width: '100%' }}
          >
            <div
              className="bilan_item"
              style={{
                width: '50%',

                backgroundColor: '#f3f4f9',
              }}
            >
              <div className="bilan_handler">
                <div className="bilan_handler_info">
                  <div
                    style={{
                      alignItems: 'start',
                    }}
                    className="bilan_contact_info"
                  >
                    <span
                      style={{
                        marginLeft: '3%',
                      }}
                      id="name"
                    >
                      Douleur EVA
                    </span>
                  </div>
                  <div className="field_sub_section_container_item">
                    <div
                      style={{ textAlign: 'center' }}
                      className="additional_container_item_field"
                    >
                      <input
                        value={initialValues.douelur_1}
                        maxLength="500"
                        style={{ width: '60%' }}
                        name="douelur_1"
                        type="number"
                        placeholder=""
                      />
                      %
                    </div>
                  </div>
                  <div style={{ padding: '2%' }}>
                    <Slider
                      progress
                      defaultValue={initialValues.douelur_1}
                      style={{ marginTop: 16 }}
                      onChange={(value) => {
                        this.props.handleChangeAnyInput(value, 'douelur_1');
                      }}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div
              className="bilan_item"
              style={{
                width: '50%',

                backgroundColor: '#f3f4f9',
              }}
            >
              <div className="bilan_handler">
                <div className="bilan_handler_info">
                  <div
                    style={{
                      alignItems: 'start',
                    }}
                    className="bilan_contact_info"
                  >
                    <span
                      style={{
                        marginLeft: '3%',
                      }}
                      id="name"
                    >
                      Douleur déclenchement
                    </span>
                  </div>
                  <div style={{ padding: '2%' }}>
                    <div className="field_sub_section_container_item">
                      <div className="checkbox_options">
                        <div className="checkbox_options_item">
                          <div className="form-check form-check-inline">
                            <input
                              className="form-check-input"
                              type="checkbox"
                              id="frequence_itemd_x31"
                              onChange={this.props.handleChangeInput}
                              name="check_douleur_8"
                              checked={initialValues.check_douleur_8}
                              value="A l'effort"
                            />
                            <label
                              className="form-check-label"
                              htmlFor={`frequence_itemd_x31`}
                            >
                              A l'effort
                            </label>
                          </div>
                        </div>
                        <div className="checkbox_options_item">
                          <div className="form-check form-check-inline">
                            <input
                              className="form-check-input"
                              type="checkbox"
                              onChange={this.props.handleChangeInput}
                              name="check_douleur_9"
                              checked={initialValues.check_douleur_9}
                              value="Au dérouillage"
                              id={`frequence_itemd_3f2`}
                            />
                            <label
                              className="form-check-label"
                              htmlFor={`frequence_itemd_3f2`}
                            >
                              Au dérouillage
                            </label>
                          </div>
                        </div>
                        <div className="checkbox_options_item">
                          <div className="form-check form-check-inline">
                            <input
                              onChange={this.props.handleChangeInput}
                              name="check_douleur_10"
                              checked={initialValues.check_douleur_10}
                              value="Sur un mouvement précis"
                              className="form-check-input"
                              type="checkbox"
                              id={`frequence_itemd_c33`}
                            />
                            <label
                              className="form-check-label"
                              htmlFor={`frequence_itemd_c33`}
                            >
                              Sur un mouvement précis
                            </label>
                          </div>
                        </div>
                        <div className="checkbox_options_item">
                          <div className="form-check form-check-inline">
                            <input
                              onChange={this.props.handleChangeInput}
                              name="check_douleur_11"
                              checked={initialValues.check_douleur_11}
                              value="Au repos"
                              className="form-check-input"
                              type="checkbox"
                              id={`frequence_item_1f22`}
                            />
                            <label
                              className="form-check-label"
                              htmlFor={`frequence_item_1f22`}
                            >
                              Au repos
                            </label>
                          </div>
                        </div>
                        <div className="checkbox_options_item">
                          <div className="form-check form-check-inline">
                            <input
                              onChange={this.props.handleChangeInput}
                              name="check_douleur_12"
                              checked={initialValues.check_douleur_12}
                              value="Permanente"
                              className="form-check-input"
                              type="checkbox"
                              id={`frequence_item_s1f22`}
                            />
                            <label
                              className="form-check-label"
                              htmlFor={`frequence_item_s1f22`}
                            >
                              Permanente
                            </label>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            className="actual_bilan_settings_item_body "
            id="headShaking1_sub_section"
            style={{ display: 'flex', width: '100%' }}
          >
            <div
              className="bilan_item"
              style={{
                width: '50%',

                backgroundColor: '#f3f4f9',
              }}
            >
              <div className="bilan_handler">
                <div className="bilan_handler_info">
                  <div
                    style={{
                      alignItems: 'start',
                    }}
                    className="bilan_contact_info"
                  >
                    <span
                      style={{
                        marginLeft: '3%',
                      }}
                      id="name"
                    >
                      Horaire douleur
                    </span>
                  </div>
                  <div style={{ padding: '2%' }}>
                    <div className="field_sub_section_container_item">
                      <div className="checkbox_options">
                        <div className="checkbox_options_item">
                          <div className="form-check form-check-inline">
                            <input
                              className="form-check-input"
                              type="checkbox"
                              id="frequence_itemd_xc31"
                              onChange={this.props.handleChangeInput}
                              name="check_douleur_13"
                              checked={initialValues.check_douleur_13}
                              value="Matin"
                            />
                            <label
                              className="form-check-label"
                              htmlFor={`frequence_itemd_xc31`}
                            >
                              Matin
                            </label>
                          </div>
                        </div>
                        <div className="checkbox_options_item">
                          <div className="form-check form-check-inline">
                            <input
                              onChange={this.props.handleChangeInput}
                              name="check_douleur_14"
                              checked={initialValues.check_douleur_14}
                              value="Soir"
                              className="form-check-input"
                              type="checkbox"
                              id={`frequence_itemd_ss32`}
                            />
                            <label
                              className="form-check-label"
                              htmlFor={`frequence_itemd_ss32`}
                            >
                              Soir
                            </label>
                          </div>
                        </div>
                        <div className="checkbox_options_item">
                          <div className="form-check form-check-inline">
                            <input
                              onChange={this.props.handleChangeInput}
                              name="check_douleur_15"
                              checked={initialValues.check_douleur_15}
                              value="Diurne"
                              className="form-check-input"
                              type="checkbox"
                              id={`frequence_itemd_x3e3`}
                            />
                            <label
                              className="form-check-label"
                              htmlFor={`frequence_itemd_x3e3`}
                            >
                              Diurne
                            </label>
                          </div>
                        </div>
                        <div className="checkbox_options_item">
                          <div className="form-check form-check-inline">
                            <input
                              onChange={this.props.handleChangeInput}
                              name="check_douleur_16"
                              checked={initialValues.check_douleur_16}
                              value="Nocturne"
                              className="form-check-input"
                              type="checkbox"
                              id={`frequence_item_q12d2`}
                            />
                            <label
                              className="form-check-label"
                              htmlFor={`frequence_item_q12d2`}
                            >
                              Nocturne
                            </label>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div
              className="bilan_item"
              style={{
                width: '50%',

                backgroundColor: '#f3f4f9',
              }}
            >
              <div className="bilan_handler">
                <div className="bilan_handler_info">
                  <div
                    style={{
                      alignItems: 'start',
                    }}
                    className="bilan_contact_info"
                  >
                    <span
                      style={{
                        marginLeft: '3%',
                      }}
                      id="name"
                    >
                      Evalution de la douleur
                    </span>
                  </div>
                  <div style={{ padding: '2%' }}>
                    <div className="field_sub_section_container_item">
                      <div className="checkbox_options">
                        <div className="checkbox_options_item">
                          <div className="form-check form-check-inline">
                            <input
                              className="form-check-input"
                              type="checkbox"
                              id="frequence_itemd_csd31"
                              onChange={this.props.handleChangeInput}
                              name="check_douleur_17"
                              checked={initialValues.check_douleur_17}
                              value="Beaucoup mieux"
                            />
                            <label
                              className="form-check-label"
                              htmlFor={`frequence_itemd_csd31`}
                            >
                              Beaucoup mieux
                            </label>
                          </div>
                        </div>
                        <div className="checkbox_options_item">
                          <div className="form-check form-check-inline">
                            <input
                              onChange={this.props.handleChangeInput}
                              name="check_douleur_18"
                              checked={initialValues.check_douleur_18}
                              value="Mieux mieux"
                              className="form-check-input"
                              type="checkbox"
                              id={`frequence_itemd_css32`}
                            />
                            <label
                              className="form-check-label"
                              htmlFor={`frequence_itemd_css32`}
                            >
                              Mieux
                            </label>
                          </div>
                        </div>
                        <div className="checkbox_options_item">
                          <div className="form-check form-check-inline">
                            <input
                              onChange={this.props.handleChangeInput}
                              name="check_douleur_19"
                              checked={initialValues.check_douleur_19}
                              value="Pas de changement"
                              className="form-check-input"
                              type="checkbox"
                              id={`frequence_itemd_s3de3`}
                            />
                            <label
                              className="form-check-label"
                              htmlFor={`frequence_itemd_s3de3`}
                            >
                              Pas de changement
                            </label>
                          </div>
                        </div>

                        <div className="checkbox_options_item">
                          <div className="form-check form-check-inline">
                            <input
                              onChange={this.props.handleChangeInput}
                              name="check_douleur_20"
                              checked={initialValues.check_douleur_20}
                              value="Moins bien"
                              className="form-check-input"
                              type="checkbox"
                              id={`frequence_item_zsq122`}
                            />
                            <label
                              className="form-check-label"
                              htmlFor={`frequence_item_zsq122`}
                            >
                              Moins bien
                            </label>
                          </div>
                        </div>
                        <div className="checkbox_options_item">
                          <div className="form-check form-check-inline">
                            <input
                              onChange={this.props.handleChangeInput}
                              name="check_douleur_21"
                              checked={initialValues.check_douleur_21}
                              value="Beaucoup moins bien"
                              className="form-check-input"
                              type="checkbox"
                              id={`frequence_item_dsq121`}
                            />
                            <label
                              className="form-check-label"
                              htmlFor={`frequence_item_dsq121`}
                            >
                              Beaucoup moins bien
                            </label>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            className="actual_bilan_settings_item_body "
            id="headShaking1_sub_section"
            style={{ display: 'flex', width: '100%' }}
          >
            <div
              className="bilan_item"
              style={{
                width: '50%',

                backgroundColor: '#f3f4f9',
              }}
            >
              <div className="bilan_handler">
                <div className="bilan_handler_info">
                  <div
                    style={{
                      alignItems: 'start',
                    }}
                    className="bilan_contact_info"
                  >
                    <span
                      style={{
                        marginLeft: '3%',
                      }}
                      id="name"
                    >
                      Remarques
                    </span>
                  </div>
                  <div style={{ padding: '2%' }}>
                    <div className="field_sub_section_container_item">
                      <div className="additional_container_item_field">
                        <textarea
                          onChange={this.props.handleChangeInput}
                          maxLength="500"
                          style={{ width: '60%' }}
                          value={initialValues.notes_1111}
                          name="notes_1111"
                          placeholder="Remarques "
                        ></textarea>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div
            style={{ backgroundColor: '#035ef8' }}
            onClick={() => this.handleChangeSubSectionBtn('showEntretien')}
            className="actual_bilan_settings_item_header sub_section_btn"
          >
            <span style={{ color: '#ffffff' }}> Localisation douleur</span>
          </div>
          <br />
          <div
            className="actual_bilan_settings_item_body "
            id="headShaking1_sub_section"
            style={{ display: 'flex', width: '100%' }}
          >
            <div
              className="bilan_item"
              style={{
                width: '50%',

                backgroundColor: '#f3f4f9',
              }}
            >
              <div className="bilan_handler">
                <div className="bilan_handler_info">
                  <div
                    style={{
                      alignItems: 'start',
                    }}
                    className="bilan_contact_info"
                  >
                    <span
                      style={{
                        marginLeft: '3%',
                      }}
                      id="name"
                    >
                      Irradiation douloureuse
                    </span>
                  </div>
                  <div style={{ padding: '2%' }}>
                    <div className="field_sub_section_container_item">
                      <div className="checkbox_options">
                        <div className="checkbox_options_item">
                          <div className="form-check form-check-inline">
                            <input
                              className="form-check-input"
                              type="checkbox"
                              id="frequence_item_qx1x"
                              onChange={this.props.handleChangeInput}
                              name="check_douleur_22"
                              checked={initialValues.check_douleur_22}
                              value="Avant bras postérieur"
                            />
                            <label
                              className="form-check-label"
                              htmlFor={`frequence_item_qx1x`}
                            >
                              Avant bras postérieur
                            </label>
                          </div>
                        </div>
                        <div className="checkbox_options_item">
                          <div className="form-check form-check-inline">
                            <input
                              className="form-check-input"
                              type="checkbox"
                              onChange={this.props.handleChangeInput}
                              name="check_douleur_23"
                              checked={initialValues.check_douleur_23}
                              value="Avant bras antérieur"
                              id={`frequence_item_xxc2`}
                            />
                            <label
                              className="form-check-label"
                              htmlFor={`frequence_item_xxc2`}
                            >
                              Avant bras antérieur
                            </label>
                          </div>
                        </div>
                        <div className="checkbox_options_item">
                          <div className="form-check form-check-inline">
                            <input
                              onChange={this.props.handleChangeInput}
                              name="check_douleur_24"
                              checked={initialValues.check_douleur_24}
                              value="Bras postérieur"
                              className="form-check-input"
                              type="checkbox"
                              id={`frequence_item_xxsc2`}
                            />
                            <label
                              className="form-check-label"
                              htmlFor={`frequence_item_xxsc2`}
                            >
                              Bras postérieur
                            </label>
                          </div>
                        </div>
                        <div className="checkbox_options_item">
                          <div className="form-check form-check-inline">
                            <input
                              onChange={this.props.handleChangeInput}
                              name="check_douleur_25"
                              checked={initialValues.check_douleur_25}
                              value="Bras antérieur"
                              className="form-check-input"
                              type="checkbox"
                              id={`frequence_item_xxsc2x`}
                            />
                            <label
                              className="form-check-label"
                              htmlFor={`frequence_item_xxsc2x`}
                            >
                              Bras antérieur
                            </label>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div
              className="bilan_item"
              style={{
                width: '50%',

                backgroundColor: '#f3f4f9',
              }}
            >
              <div className="bilan_handler">
                <div className="bilan_handler_info">
                  <div
                    style={{
                      alignItems: 'start',
                    }}
                    className="bilan_contact_info"
                  >
                    <span
                      style={{
                        marginLeft: '3%',
                      }}
                      id="name"
                    >
                      Typographie de la douleur
                    </span>
                  </div>
                  <div style={{ padding: '2%' }}>
                    <div className="field_sub_section_container_item">
                      <div className="checkbox_options">
                        <div className="checkbox_options_item">
                          <div className="form-check form-check-inline">
                            <input
                              className="form-check-input"
                              type="checkbox"
                              id="check_douleur_122"
                              onChange={this.props.handleChangeInput}
                              name="check_douleur_121"
                              checked={initialValues.check_douleur_121}
                              value="Imprécise"
                            />
                            <label
                              className="form-check-label"
                              htmlFor={`check_douleur_122`}
                            >
                              Imprécise
                            </label>
                          </div>
                        </div>
                        <div className="checkbox_options_item">
                          <div className="form-check form-check-inline">
                            <input
                              className="form-check-input"
                              type="checkbox"
                              id="check_douleur_1202"
                              onChange={this.props.handleChangeInput}
                              name="check_douleur_122"
                              checked={initialValues.check_douleur_122}
                              value="Postérieure"
                            />
                            <label
                              className="form-check-label"
                              htmlFor={`check_douleur_1202`}
                            >
                              Postérieure
                            </label>
                          </div>
                        </div>
                        <div className="checkbox_options_item">
                          <div className="form-check form-check-inline">
                            <input
                              onChange={this.props.handleChangeInput}
                              name="check_douleur_124"
                              checked={initialValues.check_douleur_124}
                              value="En regard du bec acromial"
                              className="form-check-input"
                              type="checkbox"
                              id={`check_douleur_124`}
                            />
                            <label
                              className="form-check-label"
                              htmlFor={`check_douleur_124`}
                            >
                              En regard du bec acromial
                            </label>
                          </div>
                        </div>
                        <div className="checkbox_options_item">
                          <div className="form-check form-check-inline">
                            <input
                              onChange={this.props.handleChangeInput}
                              name="check_douleur_205"
                              checked={initialValues.check_douleur_205}
                              value="Antérieure"
                              className="form-check-input"
                              type="checkbox"
                              id={`check_douleur_205`}
                            />
                            <label
                              className="form-check-label"
                              htmlFor={`check_douleur_205`}
                            >
                              Antérieure
                            </label>
                          </div>
                        </div>
                        <div className="checkbox_options_item">
                          <div className="form-check form-check-inline">
                            <input
                              onChange={this.props.handleChangeInput}
                              name="check_douleur_2005"
                              checked={initialValues.check_douleur_2005}
                              value="Acromio-claviculaire"
                              className="form-check-input"
                              type="checkbox"
                              id={`check_douleur_2005`}
                            />
                            <label
                              className="form-check-label"
                              htmlFor={`check_douleur_2005`}
                            >
                              Acromio-claviculaire
                            </label>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            className="actual_bilan_settings_item_body "
            id="headShaking1_sub_section"
            style={{ display: 'flex', width: '100%' }}
          >
            <div
              className="bilan_item"
              style={{
                width: '50%',

                backgroundColor: '#f3f4f9',
              }}
            >
              <div className="bilan_handler">
                <div className="bilan_handler_info">
                  <div
                    style={{
                      alignItems: 'start',
                    }}
                    className="bilan_contact_info"
                  >
                    <span
                      style={{
                        marginLeft: '3%',
                      }}
                      id="name"
                    >
                      Irradiation douloureuse
                    </span>
                  </div>
                  <div style={{ padding: '2%' }}>
                    <div className="field_sub_section_container_item">
                      <div className="checkbox_options">
                        <div className="checkbox_options_item">
                          <div className="form-check form-check-inline">
                            <input
                              className="form-check-input"
                              type="checkbox"
                              id="frequence_item_qx10x"
                              onChange={this.props.handleChangeInput}
                              name="frequence_item_qx10x"
                              checked={initialValues.frequence_item_qx10x}
                              value="Externe deltoïdienne"
                            />
                            <label
                              className="form-check-label"
                              htmlFor={`frequence_item_qx10x`}
                            >
                              Externe deltoïdienne
                            </label>
                          </div>
                        </div>
                        <div className="checkbox_options_item">
                          <div className="form-check form-check-inline">
                            <input
                              className="form-check-input"
                              type="checkbox"
                              onChange={this.props.handleChangeInput}
                              name="frequence_item_x0xc2"
                              checked={initialValues.frequence_item_x0xc2}
                              value="Antérieure le long de la coulisse bicipitale"
                              id={`frequence_item_x0xc2`}
                            />
                            <label
                              className="form-check-label"
                              htmlFor={`frequence_item_x0xc2`}
                            >
                              Antérieure le long de la coulisse bicipitale
                            </label>
                          </div>
                        </div>
                        <div className="checkbox_options_item">
                          <div className="form-check form-check-inline">
                            <input
                              onChange={this.props.handleChangeInput}
                              name="frequence_item_0xxsc2"
                              checked={initialValues.frequence_item_0xxsc2}
                              value="Imprécise"
                              className="form-check-input"
                              type="checkbox"
                              id={`frequence_item_0xxsc2`}
                            />
                            <label
                              className="form-check-label"
                              htmlFor={`frequence_item_0xxsc2`}
                            >
                              Imprécise
                            </label>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div
              className="bilan_item"
              style={{
                width: '50%',

                backgroundColor: '#f3f4f9',
              }}
            >
              <div className="bilan_handler">
                <div className="bilan_handler_info">
                  <div
                    style={{
                      alignItems: 'start',
                    }}
                    className="bilan_contact_info"
                  >
                    <span
                      style={{
                        marginLeft: '3%',
                      }}
                      id="name"
                    >
                      Remarques
                    </span>
                  </div>
                  <div style={{ padding: '2%' }}>
                    <div className="field_sub_section_container_item">
                      <div className="additional_container_item_field">
                        <textarea
                          onChange={this.props.handleChangeInput}
                          maxLength="500"
                          style={{ width: '60%' }}
                          value={initialValues.notes_01111}
                          name="notes_01111"
                          placeholder="Remarques "
                        ></textarea>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Douleur;
