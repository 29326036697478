import React, { Component } from 'react';
import './add_form.css';
import FormFacture from './containers/form_facture';
import actions from './store/actions';
import patientActions from '../patient/store/actions';
import actionsConsultation from '../consultations/store/actions';
import { connect } from 'react-redux';
import CircularProgress from '@material-ui/core/CircularProgress';
import { generateBillNumber } from '../../utils/helpres';
import moment from 'moment';
import actionsJs from '../../js/actions';
import TextField from '@mui/material/TextField';
import { Button, Modal, Box, Typography } from '@mui/material';

const mapStateToProps = (state) => ({
  tag: state.AppReducer.tag,
  regime: state.AppReducer.regime,
  isLoadingGetPec: state.FactureReducer.isLoadingGetPec,
  pec: state.FactureReducer.pec,
  lastBill: state.FactureReducer.lastBill,
  isLoadingLastBill: state.FactureReducer.isLoadingLastBill,
  updateBill: state.FactureReducer.updateBill,
  billData: state.FactureReducer.billData,
  consultation: state.ConsultationReducer.consultation,
  loadingConsultationById: state.ConsultationReducer.loadingConsultationById,
  office: state.AppReducer.office,
});

const mapDispatchToProps = (dispatch) => ({
  getPecById: async (id) => {
    await dispatch(actions.getPecById(id));
  },
  getConsultationById: (id) => dispatch(actionsConsultation.getConsultationById(id)),
  getLastBill: async (tag) => {
    await dispatch(actions.getLastBill(tag));
  },
  getOfficeByIdDoctor: () => dispatch(actionsJs.getOfficeByIdDoctor()),
  updatePatient: async (values) =>
    await dispatch(patientActions.submitPatientForm(values)),
});
class AddFacture extends Component {
  constructor(props) {
    super(props);
    props.changeNamePage('Ajouter une facture');
    props.changeActiveItemSideBar(props.active);
    document.title = 'Ajouter une facture';
    props.getOfficeByIdDoctor();
    props.getLastBill('bill');
    props.getConsultationById(
      this.props.match && this.props.match.params
        ? this.props.match.params.consultation_id
        : null,
    );
    this.state = {
      type: false,
      open: false,
      cnamKey: props.consultation?.patient_record?.patient?.cnam_key,
      cnamRoot: props.consultation?.patient_record?.patient?.cnam_root,
      isSubmittingUpdate: false,
    };
    this.changeActionBill = this.changeActionBill.bind(this);
  }
  changeActionBill(print) {
    this.setState({
      type: print,
    });
  }
  componentDidUpdate(prevProps, prevState) {
    if (
      prevProps.consultation?.patient_record?.patient?.cnam_key !==
        this.props.consultation?.patient_record?.patient?.cnam_key ||
      prevProps.consultation?.patient_record?.patient?.cnam_root !==
        this.props.consultation?.patient_record?.patient?.cnam_root
    ) {
      this.setState({
        cnamKey: this.props.consultation?.patient_record?.patient?.cnam_key,
        cnamRoot: this.props.consultation?.patient_record?.patient?.cnam_root,
      });
    }
    const { updateBill, billData, office, consultation } = this.props;
    const { type } = this.state;
    if (
      updateBill &&
      updateBill !== prevProps.updateBill &&
      consultation &&
      consultation.id
    ) {
      if (type) {
        this.props.history.push({
          pathname: '/facture-pdf',
          state: {
            office: office,
            facture: billData.data,
            redirect: 'PEC',
            //tag: tag,
          },
        });
      } else this.props.history.push('/module-cnam');
    }
  }

  handleOpen = () => {
    this.setState({ ...this.state, open: true });
  };

  handleClose = () => {
    this.setState({ ...this.state, open: false });
  };

  handleFormSubmit = async (event) => {
    event.preventDefault();
    const { cnamRoot, cnamKey } = event.target.elements;
    this.setState({
      ...this.state,
      cnamRoot: cnamRoot.value,
      cnamKey: cnamKey.value,
      isSubmittingUpdate: true,
    });
    await this.props.updatePatient({
      ...this.props.consultation?.patient_record?.patient,
      cnam_key: cnamKey.value,
      cnam_root: cnamRoot.value,
      birthday: undefined,
    });
    this.setState({
      ...this.state,
      isSubmittingUpdate: false,
    });
    this.handleClose();
  };

  render() {
    const {
      bill,
      isLoadingLastBill,
      patient,
      consultation,
      loadingConsultationById,
      lastBill,
      tag,
      office,
    } = this.props;

    if ((loadingConsultationById && !bill) || isLoadingLastBill)
      return (
        <CircularProgress disableShrink style={{ marginLeft: '50%', marginTop: '22%' }} />
      );

    return (
      <div className={bill && bill.patient ? '' : 'main_content'}>
        <div className="facture_info_section_form">
          <div className="container_changeable_content">
            <div className="add_facture_section">
              <div
                className="selected_facture_details"
                style={{
                  width: '100%',
                  padding: '0 2%',
                }}
              >
                <div
                  className="selected_facture_info_container"
                  style={{
                    width: '100%',
                    justifyContent: 'space-between',
                  }}
                >
                  <div className="selected_facture_patient_info">
                    <span id="selected_facture_patient_name">
                      {bill?.patient?.displayName ||
                        (consultation?.patient_record?.patient &&
                          `${consultation.patient_record.patient.first_name} ${consultation.patient_record.patient.last_name}`) ||
                        ''}
                    </span>
                    <span id="selected_facture_patient_city">
                      {(this.state.cnamRoot &&
                        `N° assuré : ${this.state.cnamRoot}/${this.state.cnamKey}`) ||
                        "Numéro d'assuré non fourni"}
                    </span>
                  </div>

                  <div
                    className="selected_facture_img_container"
                    style={{
                      cursor: 'pointer',
                    }}
                    onClick={this.handleOpen}
                  >
                    <svg
                      width="14"
                      height="14"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 512 512"
                    >
                      <path
                        fill="#8083A3"
                        d="M471.6 21.7c-21.9-21.9-57.3-21.9-79.2 0L362.3 51.7l97.9 97.9 30.1-30.1c21.9-21.9 21.9-57.3 0-79.2L471.6 21.7zm-299.2 220c-6.1 6.1-10.8 13.6-13.5 21.9l-29.6 88.8c-2.9 8.6-.6 18.1 5.8 24.6s15.9 8.7 24.6 5.8l88.8-29.6c8.2-2.7 15.7-7.4 21.9-13.5L437.7 172.3 339.7 74.3 172.4 241.7zM96 64C43 64 0 107 0 160V416c0 53 43 96 96 96H352c53 0 96-43 96-96V320c0-17.7-14.3-32-32-32s-32 14.3-32 32v96c0 17.7-14.3 32-32 32H96c-17.7 0-32-14.3-32-32V160c0-17.7 14.3-32 32-32h96c17.7 0 32-14.3 32-32s-14.3-32-32-32H96z"
                      />
                    </svg>
                  </div>
                </div>
              </div>
              <FormFacture
                goBack={() =>
                  patient ? this.props.goBack() : this.props.history.goBack()
                }
                bill={bill}
                cnam={
                  bill && bill.patient
                    ? bill.patient.cnam
                    : consultation &&
                      consultation.patient_record &&
                      consultation.patient_record.patient
                    ? consultation.patient_record.patient.cnam
                    : ''
                }
                regime={
                  consultation &&
                  consultation.prise_en_charges &&
                  consultation.prise_en_charges.length > 0
                    ? consultation.prise_en_charges[0].regime
                    : 'autre'
                }
                office={office}
                type={bill && bill.patient ? 'patient_record' : 'patient_record'}
                id={
                  bill && bill.patient ? bill.patient.id : consultation && consultation.id
                }
                tag={tag}
                consultation={consultation}
                start_date={
                  consultation &&
                  consultation.prise_en_charges &&
                  consultation.prise_en_charges.length > 0
                    ? consultation.prise_en_charges[0].start_date
                    : ''
                }
                changeActionBill={this.changeActionBill}
                end_date={
                  consultation &&
                  consultation.prise_en_charges &&
                  consultation.prise_en_charges.length > 0
                    ? consultation.prise_en_charges[0].end_date
                    : ''
                }
                numBill={
                  lastBill && lastBill.length > 0
                    ? `${generateBillNumber(
                        parseInt(lastBill[0].number.split('/')[0]) + 1,
                        moment().format('YYYY'),
                      )}`
                    : `${generateBillNumber(0, moment().format('YYYY'))}`
                }
                lastBill={lastBill && lastBill.length > 0 ? lastBill[0] : null}
                sessions_count={
                  consultation &&
                  consultation.prise_en_charges &&
                  consultation.prise_en_charges.length > 0
                    ? consultation.prise_en_charges[0].sessions_count
                    : 0
                }
                unit_price={
                  consultation && consultation.motif
                    ? consultation.motif.price_medical_prescription
                    : '11.500'
                }
              />
            </div>
          </div>
        </div>

        <Modal
          open={this.state.open}
          onClose={this.handleClose}
          aria-labelledby="modal-title"
          aria-describedby="modal-description"
        >
          <form onSubmit={this.handleFormSubmit}>
            <Box
              sx={{
                position: 'absolute',
                top: '50%',
                left: '50%',
                transform: 'translate(-50%, -50%)',
                width: '30vw',
                bgcolor: 'background.paper',
                boxShadow: 24,
                p: 4,
                borderRadius: 2,
              }}
            >
              <Typography id="modal-title" variant="h6" component="h2">
                Modifier numéro assuré
              </Typography>
              <Box
                display="flex"
                alignItems="center"
                style={{
                  margin: '2% 0',
                }}
              >
                <TextField
                  id="cnamRoot"
                  name="cnamRoot"
                  label="Racine"
                  variant="standard"
                  style={{ flex: '70%', marginRight: '10px' }}
                  defaultValue={this.state.cnamRoot}
                  value={this.cnamRoot}
                />
                <TextField
                  id="cnam_key"
                  name="cnamKey"
                  label="Clé"
                  variant="standard"
                  style={{ flex: '30%' }}
                  defaultValue={this.state.cnamKey}
                  value={this.cnamKey}
                />
              </Box>
              <div
                style={{
                  width: '100%',
                  display: 'flex',
                  justifyContent: 'space-between',
                  marginTop: '4%',
                }}
              >
                <Button variant="outlined" onClick={this.handleClose}>
                  Annuler
                </Button>
                <Button
                  type="submit"
                  variant="contained"
                  color="primary"
                  disabled={this.state.isSubmittingUpdate}
                >
                  {this.state.isSubmittingUpdate ? (
                    <CircularProgress
                      style={{
                        height: 'auto',
                      }}
                    />
                  ) : (
                    'Valider'
                  )}
                </Button>
              </div>
            </Box>
          </form>
        </Modal>
      </div>
    );
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(AddFacture);
