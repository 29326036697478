import React from 'react';
import { Link } from 'react-router-dom';
import ReplyIcon from '@mui/icons-material/Reply';

const NotFound = () => {
  return (
    <div style={styles.container}>
      <div style={styles.content}>
        <h1 style={styles.title}> Oops</h1>
        <img
          style={styles.image}
          src="/static/media/undraw_design_team_af2y.4d784698.svg"
        ></img>
        <p style={styles.text}>
          Désolé, la ressource demandée n'existe pas ou vous n'avez pas les autorisations
          nécessaires pour y accéder.
        </p>
        <Link to="/dashboard" style={styles.link}>
          <ReplyIcon style={styles.icon} />
          <span>Accueil</span>
        </Link>
      </div>
    </div>
  );
};

const styles = {
  container: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: '100vh',
    width: '100vw',
  },
  image: {
    marginBottom: '1rem',
    width: '30vw',
    height: '20vh',
  },
  content: {
    textAlign: 'center',
  },
  title: {
    fontSize: '3rem',
    marginBottom: '1rem',
  },
  text: {
    fontSize: '1.5rem',
    marginBottom: '1.5rem',
  },
  link: {
    display: 'inline-block',
    textDecoration: 'none',
    color: '#000',
    fontSize: '1.2rem',
    border: '2px solid #007bff',
    padding: '10px 20px',
    borderRadius: '5px',
    transition: 'background-color 0.3s, color 0.3s',
  },
  icon: {
    marginRight: '0.5rem',
    fontSize: '2rem',
  },
};

export default NotFound;
