import React, { Component } from 'react';
import { SelectorBilanItemSection, SelectorColorInputCheckbox } from '../../../../utils';
import 'rc-slider/assets/index.css';
class EvaluationSynthetique extends Component {
  constructor(props) {
    super(props);
    this.state = {
      poids: 0,
      taille: 0,
      showEntretien: true,
      showDouleur: false,
      showBilanArticulaire: false,
      showScore: false,
    };
    this.handleChangeSubSectionBtn = this.handleChangeSubSectionBtn.bind(this);
    this.handleClick = this.handleClick.bind(this);
  }
  componentDidMount() {
    SelectorColorInputCheckbox();
    SelectorBilanItemSection();
  }
  handleChangeSubSectionBtn(name) {
    this.setState({
      [name]: !this.state[name],
    });
  }
  handleClick(fileUploader) {
    this.refs[fileUploader].click();
  }
  render() {
    const { initialValues } = this.props;
    return (
      <div className="actual_bilan_settings">
        <div className="actual_bilan_settings_item head_shaking_test" id="headShaking1">
          <div
            style={{ backgroundColor: '#6c89bc' }}
            onClick={() => this.handleChangeSubSectionBtn('showEntretien')}
            className="actual_bilan_settings_item_header sub_section_btn"
          >
            <span style={{ color: '#ffffff' }}> Evaluation synthétique</span>
          </div>
          <br />
          <div
            style={{ backgroundColor: '#035ef8' }}
            onClick={() => this.handleChangeSubSectionBtn('showEntretien')}
            className="actual_bilan_settings_item_header sub_section_btn"
          >
            <span style={{ color: '#ffffff' }}> Evaluation globale</span>
          </div>
          <br />
          <div
            className="actual_bilan_settings_item_body "
            id="headShaking1_sub_section"
            style={{ display: 'flex', width: '100%' }}
          >
            <div
              className="bilan_item"
              style={{
                width: '50%',

                backgroundColor: '#f3f4f9',
              }}
            >
              <div className="bilan_handler">
                <div className="bilan_handler_info">
                  <div
                    style={{
                      alignItems: 'start',
                    }}
                    className="bilan_contact_info"
                  >
                    <span
                      style={{
                        marginLeft: '3%',
                      }}
                      id="name"
                    >
                      Douleur
                    </span>
                  </div>
                  <div style={{ padding: '2%' }}>
                    <div className="field_sub_section_container_item">
                      <div className="checkbox_options">
                        <div className="checkbox_options_item">
                          <div className="form-check form-check-inline">
                            <input
                              className="form-check-input"
                              type="radio"
                              id="check_contexte1_1_1"
                              onChange={this.props.handleChangeInput}
                              name="check_contexte1_1_1"
                              checked={
                                initialValues.check_contexte1_1_1 === 'Mauvais'
                                  ? true
                                  : false
                              }
                              //checked={initialValues.check_contexte_1}
                              value="Mauvais"
                            />
                            <label
                              className="form-check-label"
                              htmlFor={`check_contexte1_1_1`}
                            >
                              Mauvais
                            </label>
                          </div>
                        </div>
                        <div className="checkbox_options_item">
                          <div className="form-check form-check-inline">
                            <input
                              className="form-check-input"
                              type="radio"
                              onChange={this.props.handleChangeInput}
                              name="check_contexte1_1_1"
                              checked={
                                initialValues.check_contexte1_1_1 === 'Moyen'
                                  ? true
                                  : false
                              }
                              value="Moyen"
                              id={`check_contexte2_1_1`}
                            />
                            <label
                              className="form-check-label"
                              htmlFor={`check_contexte2_1_1`}
                            >
                              Moyen
                            </label>
                          </div>
                        </div>
                        <div className="checkbox_options_item">
                          <div className="form-check form-check-inline">
                            <input
                              onChange={this.props.handleChangeInput}
                              name="check_contexte1_1_1"
                              checked={
                                initialValues.check_contexte1_1_1 === 'Bon' ? true : false
                              }
                              value="Bon"
                              className="form-check-input"
                              type="radio"
                              id={`check_contexte3_1_2`}
                            />
                            <label
                              className="form-check-label"
                              htmlFor={`check_contexte3_1_2`}
                            >
                              Bon
                            </label>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div
              className="bilan_item"
              style={{
                width: '50%',

                backgroundColor: '#f3f4f9',
              }}
            >
              <div className="bilan_handler">
                <div className="bilan_handler_info">
                  <div
                    style={{
                      alignItems: 'start',
                    }}
                    className="bilan_contact_info"
                  >
                    <span
                      style={{
                        marginLeft: '3%',
                      }}
                      id="name"
                    >
                      Articulaire
                    </span>
                  </div>
                  <div style={{ padding: '2%' }}>
                    <div className="field_sub_section_container_item">
                      <div className="checkbox_options">
                        <div className="checkbox_options_item">
                          <div className="form-check form-check-inline">
                            <input
                              className="form-check-input"
                              type="radio"
                              id="check_contexte1_2_1"
                              onChange={this.props.handleChangeInput}
                              name="check_contexte1_2_1"
                              checked={
                                initialValues.check_contexte1_2_1 === 'Mauvais'
                                  ? true
                                  : false
                              }
                              //checked={initialValues.check_contexte_1}
                              value="Mauvais"
                            />
                            <label
                              className="form-check-label"
                              htmlFor={`check_contexte1_2_1`}
                            >
                              Mauvais
                            </label>
                          </div>
                        </div>
                        <div className="checkbox_options_item">
                          <div className="form-check form-check-inline">
                            <input
                              className="form-check-input"
                              type="radio"
                              onChange={this.props.handleChangeInput}
                              name="check_contexte1_2_1"
                              checked={
                                initialValues.check_contexte1_2_1 === 'Moyen'
                                  ? true
                                  : false
                              }
                              value="Moyen"
                              id={`check_contexte2_2_1`}
                            />
                            <label
                              className="form-check-label"
                              htmlFor={`check_contexte2_2_1`}
                            >
                              Moyen
                            </label>
                          </div>
                        </div>
                        <div className="checkbox_options_item">
                          <div className="form-check form-check-inline">
                            <input
                              onChange={this.props.handleChangeInput}
                              name="check_contexte1_2_1"
                              checked={
                                initialValues.check_contexte1_2_1 === 'Bon' ? true : false
                              }
                              value="Bon"
                              className="form-check-input"
                              type="radio"
                              id={`check_contexte3_2_2`}
                            />
                            <label
                              className="form-check-label"
                              htmlFor={`check_contexte3_2_2`}
                            >
                              Bon
                            </label>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            className="actual_bilan_settings_item_body "
            id="headShaking1_sub_section"
            style={{ display: 'flex', width: '100%' }}
          >
            <div
              className="bilan_item"
              style={{
                width: '50%',

                backgroundColor: '#f3f4f9',
              }}
            >
              <div className="bilan_handler">
                <div className="bilan_handler_info">
                  <div
                    style={{
                      alignItems: 'start',
                    }}
                    className="bilan_contact_info"
                  >
                    <span
                      style={{
                        marginLeft: '3%',
                      }}
                      id="name"
                    >
                      Musculaire
                    </span>
                  </div>
                  <div style={{ padding: '2%' }}>
                    <div className="field_sub_section_container_item">
                      <div className="checkbox_options">
                        <div className="checkbox_options_item">
                          <div className="form-check form-check-inline">
                            <input
                              className="form-check-input"
                              type="radio"
                              id="check_contexte1_3_1"
                              onChange={this.props.handleChangeInput}
                              name="check_contexte1_3_1"
                              checked={
                                initialValues.check_contexte1_3_1 === 'Mauvais'
                                  ? true
                                  : false
                              }
                              //checked={initialValues.check_contexte_1}
                              value="Mauvais"
                            />
                            <label
                              className="form-check-label"
                              htmlFor={`check_contexte1_3_1`}
                            >
                              Mauvais
                            </label>
                          </div>
                        </div>
                        <div className="checkbox_options_item">
                          <div className="form-check form-check-inline">
                            <input
                              className="form-check-input"
                              type="radio"
                              onChange={this.props.handleChangeInput}
                              name="check_contexte1_3_1"
                              checked={
                                initialValues.check_contexte1_3_1 === 'Moyen'
                                  ? true
                                  : false
                              }
                              value="Moyen"
                              id={`check_contexte22_2_1`}
                            />
                            <label
                              className="form-check-label"
                              htmlFor={`check_contexte22_2_1`}
                            >
                              Moyen
                            </label>
                          </div>
                        </div>
                        <div className="checkbox_options_item">
                          <div className="form-check form-check-inline">
                            <input
                              onChange={this.props.handleChangeInput}
                              name="check_contexte1_3_1"
                              checked={
                                initialValues.check_contexte1_3_1 === 'Bon' ? true : false
                              }
                              value="Bon"
                              className="form-check-input"
                              type="radio"
                              id={`check_contexte32_2_2`}
                            />
                            <label
                              className="form-check-label"
                              htmlFor={`check_contexte32_2_2`}
                            >
                              Bon
                            </label>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            className="actual_bilan_settings_item_body "
            id="headShaking1_sub_section"
            style={{ display: 'flex', width: '100%' }}
          >
            <div
              className="bilan_item"
              style={{
                width: '50%',

                backgroundColor: '#f3f4f9',
              }}
            >
              <div className="bilan_handler">
                <div className="bilan_handler_info">
                  <div
                    style={{
                      alignItems: 'start',
                    }}
                    className="bilan_contact_info"
                  >
                    <span
                      style={{
                        marginLeft: '3%',
                      }}
                      id="name"
                    >
                      Incapacités
                    </span>
                  </div>
                  <div style={{ padding: '2%' }}>
                    <div className="field_sub_section_container_item">
                      <div className="checkbox_options">
                        <div className="checkbox_options_item">
                          <div className="form-check form-check-inline">
                            <input
                              className="form-check-input"
                              type="radio"
                              id="check_contexte10_3_1"
                              onChange={this.props.handleChangeInput}
                              name="check_contexte10_3_1"
                              checked={
                                initialValues.check_contexte10_3_1 === 'Mauvais'
                                  ? true
                                  : false
                              }
                              //checked={initialValues.check_contexte_1}
                              value="Mauvais"
                            />
                            <label
                              className="form-check-label"
                              htmlFor={`check_contexte10_3_1`}
                            >
                              Mauvais
                            </label>
                          </div>
                        </div>
                        <div className="checkbox_options_item">
                          <div className="form-check form-check-inline">
                            <input
                              className="form-check-input"
                              type="radio"
                              onChange={this.props.handleChangeInput}
                              name="check_contexte10_3_1"
                              checked={
                                initialValues.check_contexte10_3_1 === 'Moyen'
                                  ? true
                                  : false
                              }
                              value="Moyen"
                              id={`check_contexte202_2_1`}
                            />
                            <label
                              className="form-check-label"
                              htmlFor={`check_contexte202_2_1`}
                            >
                              Moyen
                            </label>
                          </div>
                        </div>
                        <div className="checkbox_options_item">
                          <div className="form-check form-check-inline">
                            <input
                              onChange={this.props.handleChangeInput}
                              name="check_contexte10_3_1"
                              checked={
                                initialValues.check_contexte10_3_1 === 'Bon'
                                  ? true
                                  : false
                              }
                              value="Bon"
                              className="form-check-input"
                              type="radio"
                              id={`check_contexte302_2_2`}
                            />
                            <label
                              className="form-check-label"
                              htmlFor={`check_contexte302_2_2`}
                            >
                              Bon
                            </label>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div
              className="bilan_item"
              style={{
                width: '50%',

                backgroundColor: '#f3f4f9',
              }}
            >
              <div className="bilan_handler">
                <div className="bilan_handler_info">
                  <div
                    style={{
                      alignItems: 'start',
                    }}
                    className="bilan_contact_info"
                  >
                    <span
                      style={{
                        marginLeft: '3%',
                      }}
                      id="name"
                    >
                      Général
                    </span>
                  </div>
                  <div style={{ padding: '2%' }}>
                    <div className="field_sub_section_container_item">
                      <div className="checkbox_options">
                        <div className="checkbox_options_item">
                          <div className="form-check form-check-inline">
                            <input
                              className="form-check-input"
                              type="radio"
                              id="check_contexte110_3_1"
                              onChange={this.props.handleChangeInput}
                              name="check_contexte110_3_1"
                              checked={
                                initialValues.check_contexte110_3_1 === 'Mauvais'
                                  ? true
                                  : false
                              }
                              //checked={initialValues.check_contexte_1}
                              value="Mauvais"
                            />
                            <label
                              className="form-check-label"
                              htmlFor={`check_contexte110_3_1`}
                            >
                              Mauvais
                            </label>
                          </div>
                        </div>
                        <div className="checkbox_options_item">
                          <div className="form-check form-check-inline">
                            <input
                              className="form-check-input"
                              type="radio"
                              onChange={this.props.handleChangeInput}
                              name="check_contexte110_3_1"
                              checked={
                                initialValues.check_contexte110_3_1 === 'Moyen'
                                  ? true
                                  : false
                              }
                              value="Moyen"
                              id={`check_contexte0202_2_1`}
                            />
                            <label
                              className="form-check-label"
                              htmlFor={`check_contexte0202_2_1`}
                            >
                              Moyen
                            </label>
                          </div>
                        </div>
                        <div className="checkbox_options_item">
                          <div className="form-check form-check-inline">
                            <input
                              onChange={this.props.handleChangeInput}
                              name="check_contexte110_3_1"
                              checked={
                                initialValues.check_contexte110_3_1 === 'Bon'
                                  ? true
                                  : false
                              }
                              value="Bon"
                              className="form-check-input"
                              type="radio"
                              id={`check_contexte3002_2_2`}
                            />
                            <label
                              className="form-check-label"
                              htmlFor={`check_contexte3002_2_2`}
                            >
                              Bon
                            </label>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            className="actual_bilan_settings_item_body "
            id="headShaking1_sub_section"
            style={{ display: 'flex', width: '100%' }}
          >
            <div
              className="bilan_item"
              style={{
                width: '50%',

                backgroundColor: '#f3f4f9',
              }}
            >
              <div className="bilan_handler">
                <div className="bilan_handler_info">
                  <div
                    style={{
                      alignItems: 'start',
                    }}
                    className="bilan_contact_info"
                  >
                    <span
                      style={{
                        marginLeft: '3%',
                      }}
                      id="name"
                    >
                      Note
                    </span>
                  </div>
                  <div style={{ padding: '2%' }}>
                    <div className="field_sub_section_container_item">
                      <div className="additional_container_item_field">
                        <textarea
                          onChange={this.props.handleChangeInput}
                          maxLength="500"
                          style={{ width: '60%' }}
                          value={initialValues.notes_11}
                          name="notes_11"
                          placeholder="Note"
                        ></textarea>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default EvaluationSynthetique;
