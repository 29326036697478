import React from 'react';
import ReactDOM from 'react-dom';
import { applyMiddleware, combineReducers, createStore } from 'redux';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import { routerMiddleware, routerReducer } from 'react-router-redux';
import thunk from 'redux-thunk';
import { createBrowserHistory } from 'history';
import { reducer as formReducer } from 'redux-form';
import './index.css';
import App from './App';

import * as serviceWorker from './serviceWorker';
/*Reducers*/
import AppReducer from './js/reducers';
import RegisterReducer from './pages/authentification/register/store/reducers';
import DashboardReducer from './pages/dashborad/store/reducers';
import ProfileReducer from './pages/profile/store/reducers';

import BilanReducer from './pages/bilans/store/reducers';
import CreditReducer from './pages/credit/store/reducers';
import RecetteReducer from './pages/recette/store/reducers';
import DepenseReducer from './pages/depense/store/reducers';
import AgendaReducer from './pages/agenda/store/reducers';
import PatientReducer from './pages/patient/store/reducers';

import ConsultationReducer from './pages/consultations/store/reducers';
import FactureReducer from './pages/facture/store/reducers';
import MessengerReducer from './pages/messenger/store/reducers';
import SupportReducer from './pages/support/store/reducers';
import BordereauxReducer from './pages/bordereau/store/reducers';
import ReceiptReducer from './pages/receipt/store/reducers';
import ResetPasswordReducer from './pages/authentification/resetPassword/store/reducers';
//require('dotenv').config();
const history = createBrowserHistory();

const middlewares = [thunk, routerMiddleware(history)];
const store = createStore(
  combineReducers({
    AppReducer,
    ReceiptReducer,
    RegisterReducer,
    ResetPasswordReducer,
    DashboardReducer,
    ProfileReducer,
    BilanReducer,
    AgendaReducer,
    PatientReducer,
    ConsultationReducer,
    FactureReducer,
    MessengerReducer,
    SupportReducer,
    BordereauxReducer,
    DepenseReducer,
    RecetteReducer,
    CreditReducer,
    router: routerReducer,

    form: formReducer,
  }),
  applyMiddleware(...middlewares),
);

ReactDOM.render(
  <Provider store={store}>
    <BrowserRouter basename="/">
      <App />
    </BrowserRouter>
  </Provider>,
  document.getElementById('root'),
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
//serviceWorker.register();
