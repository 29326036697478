import React, { Component } from 'react';
import CircularProgress from '@material-ui/core/CircularProgress';
import { SwipeableDrawer } from '@material-ui/core';
import moment from 'moment';
import { formatDate } from '../../../utils/helpres';

class Session extends Component {
  render() {
    const {
      patient,
      isLoadingAllSessionsPatient,
      allSessionsPatient,
      activeItemSideBarRDV,
      patientStatistical,
      patient_record,
    } = this.props;
    let i = 0;
    let pathologie = '';
    let pathologieColor = '#000000';
    let countIsNotCanceled = [];
    countIsNotCanceled =
      allSessionsPatient &&
      allSessionsPatient.length > 0 &&
      allSessionsPatient.filter((ele) => ele.status !== 'canceled');
    let countIsCompleted = [];
    countIsCompleted =
      allSessionsPatient &&
      allSessionsPatient.length > 0 &&
      allSessionsPatient.filter((ele) => ele.status === 'completed');
    return (
      <SwipeableDrawer
        style={{ width: '50%' }}
        anchor="right"
        open={activeItemSideBarRDV}
        onClose={this.props.toggleDrawer('right', false)}
        onOpen={this.props.toggleDrawer('right', true)}
      >
        {isLoadingAllSessionsPatient ? (
          <CircularProgress
            disableShrink
            style={{ marginLeft: '50%', marginTop: '22%' }}
          />
        ) : (
          <div className="right_side_bar">
            <div className="right_side_bar_header">
              <span id="add_app_sidebar_title">Journal des séances</span>
              <svg
                onClick={() => {
                  this.props.onCancel();
                }}
                style={{ cursor: 'pointer' }}
                id="close-right-sidebar"
                width="40"
                height="40"
                viewBox="0 0 40 40"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <rect
                  x="0.5"
                  y="0.5"
                  width="39"
                  height="39"
                  rx="9.5"
                  fill="white"
                  stroke="#ECEEF5"
                />
                <path
                  d="M25.2383 15.8164L21.0547 20L25.2383 24.1836L24.1836 25.2383L20 21.0547L15.8164 25.2383L14.7617 24.1836L18.9453 20L14.7617 15.8164L15.8164 14.7617L20 18.9453L24.1836 14.7617L25.2383 15.8164Z"
                  fill="#8083A3"
                />
              </svg>
            </div>
            <div className="right_side_bar_body">
              <div className="right_side_bar_body">
                <div className="selected_facture_details" style={{ width: 'inherit' }}>
                  <div
                    style={{
                      width: '100%',
                    }}
                    className="selected_facture_info_container"
                  >
                    <div
                      style={{ width: 'inherit' }}
                      className="selected_facture_patient_info"
                    >
                      <span
                        style={{
                          width: 'auto',
                          textAlign: 'left',
                        }}
                        id="selected_facture_patient_name"
                      >
                        {`${patient.first_name} ${patient.last_name}`}{' '}
                        {patient_record.pr_number
                          ? `(FN° ${patient_record.pr_number})`
                          : ''}{' '}
                      </span>
                      {patient.phone &&
                      patient.address_patient &&
                      patient.address_patient.city ? (
                        <span
                          id="selected_facture_patient_city"
                          style={{ textAlign: 'left' }}
                        >
                          <strong>Ville :</strong> {patient.address_patient.city} -{' '}
                          <strong>Tél :</strong> {patient.phone}
                        </span>
                      ) : patient.address_patient && patient.address_patient.city ? (
                        <span
                          id="selected_facture_patient_city"
                          style={{ textAlign: 'left' }}
                        >
                          <strong>Ville :</strong> {patient.address_patient.city}
                        </span>
                      ) : (
                        <span
                          id="selected_facture_patient_city"
                          style={{ textAlign: 'left' }}
                        >
                          <strong>Tél :</strong> {patient.phone}
                        </span>
                      )}
                    </div>
                    <div
                      style={{ width: '160px' }}
                      className="selected_facture_patient_info"
                    >
                      <span
                        id="selected_facture_patient_city"
                        style={{ textAlign: 'left' }}
                      >
                        <strong>Régime :</strong>{' '}
                        {patient.regime === 1
                          ? 'CNAM'
                          : patient.regime === 2
                          ? 'ACT'
                          : 'Autre'}
                      </span>
                      <span
                        id="selected_facture_patient_name"
                        style={{ textAlign: 'left' }}
                      >
                        <strong>Nbr S :</strong> {allSessionsPatient.length}
                      </span>
                    </div>
                  </div>
                </div>
                <div className="selected_facture_details" style={{ width: 'inherit' }}>
                  <div
                    style={{
                      width: '100%',
                    }}
                    className="selected_facture_info_container"
                  >
                    <div
                      style={{ width: '25%' }}
                      className="selected_facture_patient_info"
                    >
                      <span
                        id="selected_facture_patient_name"
                        style={{ textAlign: 'left' }}
                      >
                        <strong>Réalisé / Total séances</strong>
                      </span>
                      <span
                        id="selected_facture_patient_city"
                        style={{ textAlign: 'left' }}
                      >
                        {countIsCompleted.length} / {countIsNotCanceled.length}
                      </span>
                    </div>
                    <div
                      style={{ width: '25%' }}
                      className="selected_facture_patient_info"
                    >
                      <span
                        id="selected_facture_patient_name"
                        style={{ textAlign: 'left' }}
                      >
                        <strong>Montant séances réalisées</strong>
                      </span>
                      <span
                        id="selected_facture_patient_city"
                        style={{ textAlign: 'left' }}
                      >
                        {patientStatistical && patientStatistical.session_price
                          ? patientStatistical.session_price
                          : 0}
                      </span>
                    </div>
                    <div
                      style={{ width: '25%' }}
                      className="selected_facture_patient_info"
                    >
                      <span
                        id="selected_facture_patient_name"
                        style={{ textAlign: 'left' }}
                      >
                        <strong>Total Payé</strong>
                      </span>
                      <span
                        id="selected_facture_patient_city"
                        style={{ textAlign: 'left' }}
                      >
                        {patientStatistical && patientStatistical.sumCreditPayed}
                      </span>
                    </div>
                    <div
                      style={{ width: '25%' }}
                      className="selected_facture_patient_info"
                    >
                      <span
                        id="selected_facture_patient_name"
                        style={{ textAlign: 'left' }}
                      >
                        <strong>Reste</strong>
                      </span>
                      <span
                        id="selected_facture_patient_city"
                        style={{ textAlign: 'left' }}
                      >
                        {patientStatistical && patientStatistical.sumCreditInPayed}
                      </span>
                    </div>
                  </div>
                </div>
                {isLoadingAllSessionsPatient ? (
                  <div className="seances_panel_container">
                    <CircularProgress disableShrink />
                  </div>
                ) : (
                  <div
                    style={{ padding: '0px', gap: '6px' }}
                    className="seances_panel_container"
                  >
                    {allSessionsPatient &&
                      allSessionsPatient.length > 0 &&
                      allSessionsPatient.map((item) => {
                        //if (item.status !== 'not_confirmed') {
                        pathologie =
                          item.motif && item.motif.label === 'Autre'
                            ? item.diagnostic
                            : item.motif.label;
                        pathologieColor = item.motif && item.motif.color;
                        i++;
                        return (
                          <div
                            /*onClick={() =>
                                            this.setState({
                                              rdv: item,
                                            })
                                          }*/
                            key={item.id}
                            className="seances_panel_item"
                            style={{ height: '55px' }}
                          >
                            <div
                              style={{ width: '0%' }}
                              className="selected_facture_patient_info"
                            >
                              <button
                                style={{ cursor: 'auto' }}
                                className={
                                  item.status === 'actif'
                                    ? 'status_rdv btn_status actif_status-'
                                    : item.status === 'not_confirmed'
                                    ? 'status_rdv btn_status actif_not_confirmed'
                                    : item.status === 'completed'
                                    ? 'status_rdv btn_status actif_completed'
                                    : 'status_rdv btn_status actif_canceled'
                                }
                              >
                                <span>{i}</span>
                              </button>
                            </div>
                            <div
                              style={{ width: '20%' }}
                              className="selected_facture_patient_info"
                            >
                              <span id="selected_facture_patient_name">
                                {moment(item.date_from).format('dddd')}{' '}
                                {formatDate(item.date_from)}
                              </span>
                              <span id="selected_facture_patient_city">
                                {moment(item.date_from).format('HH:mm')} -{' '}
                                {moment(item.date_to).format('HH:mm')}
                              </span>
                            </div>
                            <div
                              style={{ width: '27%' }}
                              className="selected_facture_patient_info"
                            >
                              <span id="selected_facture_patient_name">
                                {`${item.practitioner.first_name} ${item.practitioner.last_name}`}
                              </span>
                              <span
                                style={{ color: pathologieColor }}
                                id="selected_facture_patient_city"
                              >
                                {pathologie}
                              </span>
                            </div>

                            <div
                              style={{ width: '10%' }}
                              className="selected_facture_patient_info"
                            >
                              <span id="selected_facture_patient_name">
                                Payé: {item.payment ? item.payment.settled_in_session : 0}{' '}
                                / {item.payment ? item.payment.session_price : 0}
                              </span>
                              <span id="selected_facture_patient_city">
                                {item.payment ? item.payment.payment_method : ''}
                              </span>
                            </div>
                            <div
                              style={{ width: '10%' }}
                              className="selected_facture_patient_info"
                            >
                              <span id="selected_facture_patient_name">
                                Reste: {item.payment ? item.payment.left_to_pay : 0}
                              </span>
                            </div>
                          </div>
                        );
                        // }
                      })}
                  </div>
                )}
              </div>
            </div>
          </div>
        )}
      </SwipeableDrawer>
    );
  }
}
export default Session;
