import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import actions from './store/actions';
import actionsJs from '../../../js/actions';
import { Link } from 'react-router-dom';
import SimpleReactValidator from 'simple-react-validator';
import { isNumeric, lengthPhone, validate } from '../../../utils/helpres';
import { sortCities } from '../../../utils/constants';
import '../index.css';
import InputField from '../../../components/inputFieldV2';
import CircularProgress from '@material-ui/core/CircularProgress';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';

const mapStateToProps = (state) => ({
  specialities: state.AppReducer.specialities,
  isLoadingSpecialities: state.AppReducer.isLoadingSpecialities,
  emailDoctorExist: state.RegisterReducer.emailDoctorExist,
  isLoadingGetEmail: state.RegisterReducer.isLoadingGetEmail,
  isLoadingNewAccountRequest: state.RegisterReducer.isLoadingNewAccountRequest,
  newUser: state.RegisterReducer.newUser,
  message: state.RegisterReducer.message,
  error: state.RegisterReducer.error,
  addAccount: state.RegisterReducer.addAccount,
  isLoading: state.RegisterReducer.isLoading,
});

const mapDispatchToProps = (dispatch) => ({
  getDoctorByEmail: (email) => dispatch(actions.getDoctorByEmail(email)),
  InitialState: () => dispatch(actions.InitialState()),
  submitAccountRequestForm: (values) =>
    dispatch(actions.submitAccountRequestForm(values)),
  getSpecialities: () => dispatch(actionsJs.getSpecialities()),
});
const iconError = (
  <svg
    width="10"
    height="10"
    viewBox="0 0 10 10"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M9.71094 0.289062C10.0022 0.580307 10.0022 1.05251 9.71094 1.34375L6.05469 5L9.71094 8.65625C10.0022 8.94749 10.0022 9.41969 9.71094 9.71094C9.41969 10.0022 8.94749 10.0022 8.65625 9.71094L5 6.05469L1.34375 9.71094C1.05251 10.0022 0.580307 10.0022 0.289062 9.71094C-0.0021815 9.41969 -0.00218129 8.94749 0.289063 8.65625L3.94531 5L0.289063 1.34375C-0.00218055 1.05251 -0.0021815 0.580307 0.289062 0.289062C0.580307 -0.0021815 1.05251 -0.00218129 1.34375 0.289063L5 3.94531L8.65625 0.289063C8.94749 -0.00218055 9.41969 -0.0021815 9.71094 0.289062Z"
      fill="#F6583E"
    />
  </svg>
);
class Register extends PureComponent {
  constructor(props) {
    super(props);
    props.getSpecialities();
    document.title = 'Authentification | Inscription';
    this.state = {
      initialState: {
        speciality: '',
        city: '',
        first_name: '',
        checked1: true,
        checked2: false,
        hasError: false,
        last_name: '',
        phone: '',
        code_promo: '',
        email: '',
      },
      onClick: false,
      checkedBtnRadio: false,
      ShowFormEmail: true,
      ShowFormInfo: true,
      showMessageSuccessCreatedAccount: false,
    };
    this.validator = new SimpleReactValidator({
      messages: {
        email: 'Adresse e-mail invalide',
        min: 'Saisissez un mot de passe comprenant au moins 6 caractères.',
        max: 'Le mot de passe doit comporter de 6 à 20 caractères. ',
        // OR
        default: 'Champ requise.', // will override all messages
      },
    });

    this.handleClickShowPassword = this.handleClickShowPassword.bind(this);
    this.keyPressed = this.keyPressed.bind(this);
    this.keyPressedEmail = this.keyPressedEmail.bind(this);
    this.checkedBtnRadio = this.checkedBtnRadio.bind(this);
  }

  keyPressed(event) {
    if (event.key === 'Enter') {
      const { isLoading } = this.props;
      const { initialState } = this.state;
      if (
        !initialState.first_name ||
        !initialState.last_name ||
        !initialState.city ||
        !validate(initialState.first_name) ||
        !validate(initialState.last_name) ||
        (initialState.phone && !lengthPhone(initialState.phone)) ||
        (initialState.phone && !isNumeric(initialState.phone))
      )
        this.setState({
          ...initialState,
          hasError: true,
        });
      else if (
        !isLoading &&
        initialState.first_name &&
        initialState.last_name &&
        initialState.city &&
        validate(initialState.first_name) &&
        validate(initialState.last_name)
      )
        this.submitForm(initialState);
    }
  }
  keyPressedEmail(event) {
    if (event.key === 'Enter') {
      const { initialState } = this.state;
      if (this.validator.allValid()) {
        this.props.getDoctorByEmail(initialState.email);
      } else {
        this.setState({
          hasError: true,
        });
        this.validator.showMessages();
        // rerender to show messages for the first time
        // you can use the autoForceUpdate option to do this automatically`
        this.forceUpdate();
      }
    }
  }

  handleClickShowPassword = () => {
    const { initialState } = this.state;
    this.setState({
      initialState: {
        ...initialState,
        showPassword: !initialState.showPassword,
      },
    });
  };
  checkedBtnRadio = () => {
    this.setState({
      checkedBtnRadio: !this.state.checkedBtnRadio,
    });
  };
  handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  componentDidMount() {
    this.props.InitialState();
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
  }

  handleCityChange(value) {
    const { initialState } = this.state;
    this.setState({
      initialState: {
        ...initialState,
        city: value.city,
      },
    });
  }

  componentWillReceiveProps(nextProps, nextContext) {
    const { addAccount, emailDoctorExist } = nextProps;
    if (emailDoctorExist) {
      this.setState({
        ShowFormEmail: false,
      });
    }
    if (addAccount && addAccount.id) nextProps.history.push('/register/success');
  }

  submitFormEmail(email) {
    if (this.validator.allValid()) {
      this.props.getDoctorByEmail(email);
    } else {
      this.setState({
        hasError: true,
      });
      this.validator.showMessages();
      this.forceUpdate();
    }
  }

  submitForm(initialState) {
    if (
      !initialState.first_name ||
      !initialState.last_name ||
      !initialState.city ||
      !validate(initialState.first_name) ||
      !validate(initialState.last_name) ||
      (initialState.phone && !lengthPhone(initialState.phone)) ||
      (initialState.phone && !isNumeric(initialState.phone))
    ) {
      this.setState({
        ...initialState,
        hasError: true,
      });
    } else if (this.validator.allValid()) {
      this.props.submitAccountRequestForm(initialState);
    } else {
      this.setState({
        ...initialState,
        hasError: true,
      });
      this.validator.showMessages();
      // rerender to show messages for the first time
      // you can use the autoForceUpdate option to do this automatically`
      this.forceUpdate();
    }
  }

  render() {
    const { initialState, ShowFormEmail, hasError } = this.state;
    const { isLoadingGetEmail, isLoadingNewAccountRequest } = this.props;
    return (
      <>
        {ShowFormEmail ? (
          <div className="authentication_view">
            <div className="view_header">
              <span>Bienvenue à Cuida</span>
              <span>Connectez-vous</span>
            </div>
            <p id="header_subtext">Entrez vos coordonnées pour continuer</p>
            <form className="sign_in_form">
              <InputField
                width={'60%'}
                className="view_item"
                type="text"
                placeholder="Adresse e-mail"
                label=""
                hasError={hasError}
                value={initialState.email}
                onKeyPress={this.keyPressedEmail}
                name="email"
                icon={<i className="fas fa-envelope"></i>}
                required={this.validator.message(
                  'email',
                  initialState.email,
                  'required|email',
                )}
                onChange={(e) => {
                  this.setState({
                    initialState: {
                      ...initialState,
                      email: e.target.value,
                    },
                  });
                }}
              />
              <div className="view_item connextion_controls"></div>
              <div className="view_item">
                <button
                  type="button"
                  onClick={() => {
                    this.submitFormEmail(initialState.email);
                  }}
                  id="connect_btn"
                >
                  {isLoadingGetEmail ? (
                    <CircularProgress
                      style={{
                        marginRight: '45%',
                        width: '30px',
                        height: '30px',
                        color: 'white',
                      }}
                    />
                  ) : (
                    'Créer un compte'
                  )}
                </button>
                <Link to="/login">
                  <button type="button" id="create_account_btn">
                    Se connecter
                  </button>
                </Link>
              </div>
            </form>
          </div>
        ) : (
          <div className="authentication_view">
            <div className="view_header">
              <span>Dites-nous en plus</span>
            </div>
            <p id="header_subtext">
              Indiquez vos coordonnées pour passer à l'étape suivante
            </p>
            <form className="sign_in_form">
              <div className="view_item_container">
                <InputField
                  width={'60%'}
                  className="view_item"
                  type="text"
                  placeholder="Nom"
                  label=""
                  hasError={hasError}
                  value={initialState.last_name}
                  onKeyPress={this.keyPressedEmail}
                  name="last_name"
                  icon={<i className="fas fa-user"></i>}
                  onChange={(e) => {
                    this.setState({
                      initialState: {
                        ...initialState,
                        last_name: e.target.value,
                      },
                    });
                  }}
                />
                <InputField
                  width={'60%'}
                  className="view_item"
                  type="text"
                  placeholder="Prénom"
                  label=""
                  hasError={hasError}
                  value={initialState.first_name}
                  onKeyPress={this.keyPressedEmail}
                  name="first_name"
                  icon={<i className="fas fa-user"></i>}
                  onChange={(e) => {
                    this.setState({
                      initialState: {
                        ...initialState,
                        first_name: e.target.value,
                      },
                    });
                  }}
                />
              </div>

              <div style={{ height: 73 }} className="view_item_container">
                <InputField
                  width={'60%'}
                  className="view_item"
                  type="text"
                  placeholder="Téléphone"
                  label=""
                  validInput="tel"
                  hasError={hasError}
                  value={initialState.phone}
                  onKeyPress={this.keyPressedEmail}
                  name="phone"
                  icon={<i className="fas fa-phone"></i>}
                  onChange={(e) => {
                    this.setState({
                      initialState: {
                        ...initialState,
                        phone: e.target.value,
                      },
                    });
                  }}
                />
                <div
                  id="new-input"
                  className={
                    hasError && !initialState.city ? `view_item type3` : 'view_item'
                  }
                  style={{ display: 'flex', width: hasError ? '42%' : '42%' }}
                >
                  <span className="input-group-text" id="basic-addon1">
                    {hasError && !initialState.city ? (
                      <svg
                        width="10"
                        height="10"
                        viewBox="0 0 10 10"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M9.71094 0.289062C10.0022 0.580307 10.0022 1.05251 9.71094 1.34375L6.05469 5L9.71094 8.65625C10.0022 8.94749 10.0022 9.41969 9.71094 9.71094C9.41969 10.0022 8.94749 10.0022 8.65625 9.71094L5 6.05469L1.34375 9.71094C1.05251 10.0022 0.580307 10.0022 0.289062 9.71094C-0.0021815 9.41969 -0.00218129 8.94749 0.289063 8.65625L3.94531 5L0.289063 1.34375C-0.00218055 1.05251 -0.0021815 0.580307 0.289062 0.289062C0.580307 -0.0021815 1.05251 -0.00218129 1.34375 0.289063L5 3.94531L8.65625 0.289063C8.94749 -0.00218055 9.41969 -0.0021815 9.71094 0.289062Z"
                          fill="#F6583E"
                        />
                      </svg>
                    ) : (
                      <i className="fas fa-city"></i>
                    )}
                  </span>
                  <div className="view_input_container" style={{ marginTop: '-10px' }}>
                    <div style={{ borderBottom: '0' }} className="view_input">
                      <Autocomplete
                        style={
                          {
                            // display: !showInput ? 'none' : '',
                          }
                        }
                        id="size-small-standard"
                        size="small"
                        options={sortCities}
                        onChange={(event, option) => {
                          this.setState({
                            initialState: {
                              ...initialState,
                              city: option !== null ? option.title : '',
                            },
                          });
                        }}
                        /*  onChange={(event, option) => {
                          this.props.handleCityChange(
                            option !== null ? option.title : '',
                          );
                        }}*/
                        defaultValue={{
                          title: initialState.city,
                        }}
                        getOptionLabel={(option) => option.title}
                        renderInput={(cities) => (
                          <TextField
                            fullWidth={100}
                            style={{ width: '100%' }}
                            placeholder="Ville"
                            {...cities}
                            variant="outlined"
                            error={hasError && !initialState.city}
                            //value={initialValues.address_practitioner && initialValues.address_practitioner.city}
                            //label={<span>Ville <span style={{color: 'red'}}>*</span> </span>}
                          />
                        )}
                      />
                    </div>
                  </div>
                </div>
              </div>
              <InputField
                width={'60%'}
                className="view_item"
                type="text"
                placeholder="Code Promo"
                label=""
                validInput=""
                //hasError={hasError}
                value={initialState.code_promo}
                onKeyPress={this.keyPressedEmail}
                name="code_promo"
                icon={<i className="fas fa-key"></i>}
                onChange={(e) => {
                  this.setState({
                    initialState: {
                      ...initialState,
                      code_promo: e.target.value,
                    },
                  });
                }}
              />
              {/*
              <div className="view_item_container">
                <div className="view_item">
                  <div className="view_input_container">
                    <div className="view_input">
                      <input
                        minLength={8}
                        maxLength={20}
                        onKeyPress={this.keyPressed}
                        width={'63%'}
                        type="text"
                        placeholder="Code Promo"
                        value={initialState.code_promo}
                        name="phone"
                        required={this.validator.message(
                          'code_promo',
                          initialState.code_promo,
                          'string',
                        )}
                        onChange={(e) => {
                          this.setState({
                            initialState: {
                              ...initialState,
                              code_promo: e.target.value,
                            },
                          });
                        }}
                      />
                    </div>
                  </div>
                </div>
              </div>*/}
              <div className="view_item additional_btns">
                <div className="accept_terms">
                  {/* <div{/*onClick={() => this.checkedBtnRadio()}
                    className={checkedBtnRadio ? 'checkbox-btn checked' : 'checkbox-btn'}
                  >
                    {checkedBtnRadio ? <div className="selection_opt"></div> : ''}
                  </div>*/}
                  <label id="accept_terms">
                    {' '}
                    En vous inscrivant, vous acceptez nos conditions générales
                    d’utilisation
                  </label>
                </div>
              </div>
              <div className="view_item">
                <button
                  onClick={() => {
                    this.submitForm(initialState);
                  }}
                  id="valider_btn"
                  type="button"
                >
                  {isLoadingNewAccountRequest ? (
                    <CircularProgress
                      style={{
                        marginRight: '45%',
                        width: '30px',
                        height: '30px',
                        color: 'white',
                      }}
                    />
                  ) : (
                    'Rejoindre maintenant'
                  )}
                </button>
                <Link to="/login">
                  <button type="button" id="create_account_btn">
                    Se connecter
                  </button>
                </Link>
              </div>
            </form>
          </div>
        )}
      </>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Register);
