import React from 'react';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import Table from '@mui/material/Table';
import TableHead from '@mui/material/TableHead';
import TableBody from '@mui/material/TableBody';
import Paper from '@mui/material/Paper';
import TableContainer from '@mui/material/TableContainer';
import { formatAmount, formatDate } from '../../../utils/helpres';

function Row(props) {
  const { row, handleChangeBill, index } = props;

  return (
    <React.Fragment>
      <TableRow sx={{ '& > *': { borderBottom: 'unset' } }}>
        <TableCell>{index + 1}</TableCell>
        <TableCell align="left">{`${row.consultation.prise_en_charges[0].code_bureau}/${row.consultation.prise_en_charges[0].year}/${row.consultation.prise_en_charges[0].num_order}`}</TableCell>
        <TableCell align="left">
          {row.patient_record
            ? `${row.patient_record.patient.last_name} ${row.patient_record.patient.first_name}`
            : `${row.consultation.patient_record.patient.last_name} ${row.consultation.patient_record.patient.first_name}`}
        </TableCell>
        <TableCell align="left">F° {row.number}</TableCell>
        <TableCell align="left">{formatDate(row.date)}</TableCell>
        <TableCell align="left">{formatAmount(row.price_ht)}</TableCell>
        <TableCell align="left">{formatAmount(row.price_tva)}</TableCell>
        <TableCell align="left">{formatAmount(row.price_ttc)}</TableCell>
        <TableCell align="left">
          <div
            onClick={() => handleChangeBill(row.id)}
            style={{ cursor: 'pointer' }}
            className={row.is_done ? 'active_btn' : 'deactive_btn'}
          >
            <div
              className={
                row.is_done
                  ? 'deactive_btn_circle active_btn_circle'
                  : 'deactive_btn_circle'
              }
            ></div>
          </div>
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
}

export function ListBill({
  bills,
  handleSelectedAllBill,
  selectedAll,
  handleChangeBill,
}) {
  return (
    <TableContainer
      component={Paper}
      sx={{
        width: '100% !important',
        height: 'auto !important',
      }}
    >
      <Table stickyHeader aria-label="collapsible table">
        <TableHead>
          <TableRow>
            <TableCell sx={{ fontWeight: 'bold' }}>N°</TableCell>
            <TableCell align="left">N° DÉCISION</TableCell>
            <TableCell align="left">PATIENT</TableCell>
            <TableCell align="left">N° FACTURE</TableCell>
            <TableCell align="left">DATE</TableCell>
            <TableCell align="left">TOTAL HT</TableCell>
            <TableCell align="left">TOTAL TVA</TableCell>
            <TableCell align="left">TOTAL TTC</TableCell>
            <TableCell align="left">
              <div
                onClick={() => {
                  // this.setState({ selectedAll: !selectedAll });
                  handleSelectedAllBill(selectedAll);
                }}
                style={{ cursor: 'pointer' }}
                className={selectedAll ? 'active_btn' : 'deactive_btn'}
              >
                <div
                  className={
                    selectedAll
                      ? 'deactive_btn_circle active_btn_circle'
                      : 'deactive_btn_circle'
                  }
                ></div>
              </div>
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {bills.map((item, index) => {
            if (item.consultation)
              return <Row handleChangeBill={handleChangeBill} index={index} row={item} />;
          })}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
