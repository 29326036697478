import React, { Component } from 'react';
import CircularProgress from '@material-ui/core/CircularProgress';
import actions from '../../profile/store/actions';
import { connect } from 'react-redux';
import update from 'immutability-helper';
import { SwipeableDrawer } from '@material-ui/core';
import HeaderList from '../../../components/headerList';

const mapStateToProps = (state) => ({
  motifs: state.ProfileReducer.motifs,
  user: state.AppReducer.user,
  newMotif: state.ProfileReducer.newMotif,
  userMotifs: state.AgendaReducer.userMotifs,
  isLoadingUserMotif: state.AgendaReducer.isLoadingUserMotif,
  isLoadingMotifs: state.ProfileReducer.isLoadingMotifs,
  switchList: state.AppReducer.switchList,
  create: state.AppReducer.create,
  activeItemSideBarSETTINGS: state.AppReducer.activeItemSideBarSETTINGS,
});

const mapDispatchToProps = (dispatch) => ({
  getMotifs: () => dispatch(actions.getMotifs(null)),
  getUserMotifs: () => dispatch(actions.getUserMotifs()),
  submitMotifForm: async (values) => {
    await dispatch(actions.submitMotifForm(values));
    dispatch(actions.getMotifs(null));
  },
  deleteMotif: async (values, id) => {
    await dispatch(actions.deleteMotif(values));
    dispatch(actions.getMotifs(null));
  },
  addUserMotif: async (values, id) => {
    await dispatch(actions.addUserMotif(values));
    dispatch(actions.getMotifs(null));
  },
});
const headers = [
  { name: 'Label', width: '27%', type: 'string' },
  { name: 'Prix prescription', width: '20%', type: 'string' },
  { name: 'Prix Cnam', width: '25%', type: 'string' },
  { name: 'Action', width: '20%', type: 'action' },
];
class ManagementOfPathologies extends Component {
  constructor(props) {
    super(props);
    props.getMotifs();
    props.getUserMotifs();
    props.changeNamePage('Gestion des pathologies');
    document.title = 'Gestion des pathologies';
    this.state = {
      hasError: false,
      switchList: false,
      motif_selected: null,
      motif: null,
      initialValues: {
        motifs: [],
        id: null,
        color: '',
        price_with_out_cnam: '',
        label: '',
        price_medical_prescription: '11.5',
        complement: '',
      },
    };
    this.handleChangeMotif = this.handleChangeMotif.bind(this);
  }

  handleChangeMotif(id) {
    const { initialValues } = this.state;
    this.setState((prevState) => {
      const data = [...prevState.initialValues.motifs];
      var commentIndex = data.findIndex(function (c) {
        return c.id === id;
      });
      const updatedComment = update(data[commentIndex], {
        actif: { $set: !data[commentIndex].actif },
      });
      data[commentIndex] = updatedComment;
      this.setState({
        initialValues: {
          ...initialValues,
          motifs: data,
        },
      });
    });
  }

  handleSelectItem(id) {
    const { motifs } = this.props;
    const { initialValues } = this.state;
    const data = motifs.find((item) => item.id === id);
    this.setState({
      motif_selected: true,
      initialValues: {
        ...initialValues,
        id: data.id,
        color: data.color,
        price_with_out_cnam: data.price_with_out_cnam,
        label: data.label,
        price_medical_prescription: data.price_medical_prescription,
        complement: data.complement,
      },
    });
  }
  componentDidUpdate(prevProps, prevState, snapshot) {
    const { newMotif, activeItemSideBarSETTINGS, create } = this.props;
    const { initialValues } = this.state;
    if (newMotif !== prevProps.newMotif) {
      this.props.changeActiveItemSideBarSettings(activeItemSideBarSETTINGS, false, false);
      this.setState({
        initialValues: {
          ...initialValues,
          anchor: false,
          left: false,
          id: null,
          color: '',
          price_with_out_cnam: '',
          label: '',
          price_medical_prescription: '',
          complement: '',
        },
      });
    }
  }

  componentWillReceiveProps(nextProps, nextContext) {
    const { motifs, newMotif, create } = nextProps;
    const { initialValues } = this.state;

    if (!newMotif && motifs && motifs.length > 0) {
      this.setState({
        initialValues: {
          ...initialValues,
          motifs: motifs,
        },
      });
    }
  }
  submitForm(initialValues, id) {
    if (
      !initialValues.color ||
      !initialValues.label ||
      !initialValues.price_with_out_cnam ||
      !initialValues.price_medical_prescription ||
      !initialValues.complement
    ) {
      this.setState({
        ...initialValues,
        hasError: true,
      });
    } else {
      this.setState({
        motif_selected: null,
        hasError: false,
      });

      this.props.submitMotifForm(initialValues);
    }
  }
  toggleDrawer = (anchor, open, id) => (event) => {
    if (
      event &&
      event.type === 'keydown' &&
      (event.key === 'Tab' || event.key === 'Shift')
    ) {
      return;
    }
    this.setState({
      left: open,
      anchor: 'left',
      selected_id: id,
      hasError: false,
    });
  };
  render() {
    const { hasError, motif_selected, initialValues, left, anchor } = this.state;
    const { isLoadingMotifs, motifs, switchList, activeItemSideBarSETTINGS, userMotifs } =
      this.props;
    if (isLoadingMotifs)
      return (
        <CircularProgress
          disableShrink
          style={{ marginLeft: '500px', marginTop: '25%' }}
        />
      );
    return (
      <div
        className="main_content"
        style={{
          overflow: 'hidden',
          width: '100%',
          padding: '5%',
          height: '100%',
        }}
      >
        <div
          className="general_info"
          style={{ marginTop: 5, display: 'contents', width: '87%', height: '100%' }}
        >
          <div className="contact_patients_body" style={{ height: '100%' }}>
            <div style={{ display: 'flex', gap: 10 }}>
              <svg
                onClick={async () => {
                  this.props.goBackItem('showManagementOfPathologies');
                }}
                width="46"
                height="46"
                viewBox="0 0 46 46"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                style={{ cursor: 'pointer' }}
              >
                <rect
                  x="0.5"
                  y="45.5"
                  width="45"
                  height="45"
                  rx="9.5"
                  transform="rotate(-90 0.5 45.5)"
                  fill="white"
                  stroke="#ECEEF5"
                ></rect>
                <path
                  d="M20.0117 23L24.5117 18.5L25.5664 19.5547L22.1211 23L25.5664 26.4453L24.5117 27.5L20.0117 23Z"
                  fill="#8083A3"
                ></path>
              </svg>
              <span
                style={{ marginTop: 10 }}
                className="patients_consultations_record_label"
              >
                Liste des pathologies
              </span>
            </div>
            <br />
            <HeaderList headers={headers} />
            {motifs && motifs.length > 0 ? (
              motifs.map((item) => {
                return (
                  <div className="rendez_vous_list_body_item_container">
                    <div
                      style={{
                        paddingLeft: '20px',
                        display: 'flex',
                        borderLeft: `10px solid ${item.color}`,
                      }}
                      className="rendez_vous_list_body_item"
                    >
                      <div
                        style={{ width: '32%' }}
                        className="rv_list_body_sub_item rv_details"
                      >
                        <div style={{ paddingLeft: 0 }} className="rv_text_details">
                          <span>{item.label}</span>
                        </div>
                      </div>
                      <div
                        style={{ width: '20%' }}
                        className="rv_list_body_sub_item rv_details"
                      >
                        <div style={{ paddingLeft: 0 }} className="rv_text_details">
                          {parseFloat(item.price_medical_prescription || 0).toFixed(2) ||
                            0}{' '}
                          TND
                        </div>
                      </div>
                      <div
                        style={{ width: '25%' }}
                        className="rv_list_body_sub_item rv_details"
                      >
                        <div style={{ paddingLeft: 0 }} className="rv_text_details">
                          {parseFloat(item.price_with_out_cnam || 0).toFixed(2) || 0} TND
                        </div>
                      </div>
                      <div
                        style={{ width: '10%', display: 'flex', alignItems: 'center' }}
                        className="rv_list_body_sub_item rv_details"
                      >
                        <div
                          onClick={async () => {
                            await this.handleSelectItem(item.id);
                            this.props.changeActiveItemSideBarSettings(
                              activeItemSideBarSETTINGS,
                              true,
                              false,
                            );
                          }}
                          className="more_icon_container"
                          style={{ marginRight: '20px' }}
                        >
                          <i
                            style={{
                              padding: '0',
                              border: '',
                              color: '',
                              borderRadius: '',
                              backgroundColor: 'white',
                            }}
                            className="fas fa-edit"
                            aria-hidden="true"
                          ></i>
                        </div>
                        <div
                          onClick={(e) => {
                            this.props.addUserMotif({
                              motif_id: item.id,
                              checked: item.actif ? false : true,
                            });
                          }}
                          style={{ cursor: 'pointer' }}
                          className={item.actif ? 'active_btn' : 'deactive_btn'}
                        >
                          <div
                            className={
                              item.actif
                                ? 'deactive_btn_circle active_btn_circle'
                                : 'deactive_btn_circle'
                            }
                          ></div>
                        </div>
                      </div>
                    </div>

                    {/*<div style={{ width: '5%' }} className="seance-status">
                      <div
                        onClick={() => {
                          this.handleChangeMotif(item.id);
                          this.props.submitMotifForm({
                            id: item.id,
                            actif: item.actif ? false : true,
                          });
                        }}
                        style={{ cursor: 'pointer' }}
                        className={item.actif ? 'active_btn' : 'deactive_btn'}
                      >
                        <div
                          className={
                            item.actif
                              ? 'deactive_btn_circle active_btn_circle'
                              : 'deactive_btn_circle'
                          }
                        ></div>
                      </div>
                    </div>*/}
                  </div>
                );
              })
            ) : (
              <div className="consultation_detailed_info_section">
                <div className="empty_consultation_result_container">
                  <div className="empty_consultation_result">
                    <img
                      src={require('../../../assets/img/undraw_design_team_af2y.svg')}
                    />
                    <span id="no_consultation">Pas de Pathologies !</span>
                    <span id="no_consultation_desc">
                      Essayez de créer des nouveaux pathologies.
                    </span>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>

        <SwipeableDrawer
          style={{ width: '50%' }}
          anchor="right"
          open={switchList}
          onClose={this.toggleDrawer(anchor, false)}
          onOpen={this.toggleDrawer(anchor, true)}
        >
          <div className="right_side_bar_body">
            <div>
              <span style={{ color: 'black' }}>
                {motif_selected ? 'Modifier une pathologie' : 'Nouvelle pathologie'}
              </span>
              <span
                style={{
                  cursor: 'pointer',
                  float: 'right',
                  fontWeight: '700',
                  color: 'black',
                }}
                onClick={async () => {
                  await this.setState({
                    initialValues: {
                      //...initialValues,
                      id: null,
                      color: '',
                      price_with_out_cnam: '11.5',
                      label: '',
                      price_medical_prescription: '',
                      complement: '',
                    },
                    motif_selected: null,
                  });
                  this.props.changeActiveItemSideBarSettings(
                    activeItemSideBarSETTINGS,
                    false,
                    false,
                  );
                }}
              >
                X
              </span>
            </div>
            <div className="right_sidebar_body" id="Patient">
              <div
                className={
                  hasError && !initialValues.label
                    ? 'right_sidebar_body_item type3'
                    : 'right_sidebar_body_item'
                }
              >
                <label>Libellé </label>
                <div className="input_and_icon">
                  <input
                    style={{ width: '100%' }}
                    className="form-control"
                    type="text"
                    name="label"
                    value={initialValues.label}
                    onChange={(e) => {
                      this.setState({
                        initialValues: {
                          ...initialValues,
                          label: e.target.value,
                        },
                      });
                    }}
                    placeholder="Libellé"
                  />
                </div>
              </div>
              <div
                className={
                  hasError && !initialValues.color
                    ? 'right_sidebar_body_item type3'
                    : 'right_sidebar_body_item'
                }
              >
                <label>Couleur </label>
                <div className="input_and_icon">
                  <input
                    minLength={8}
                    style={{ width: '100%' }}
                    name="color"
                    value={initialValues.color}
                    onChange={(e) => {
                      this.setState({
                        initialValues: {
                          ...initialValues,
                          color: e.target.value,
                        },
                      });
                    }}
                    className="form-control"
                    type="color"
                    placeholder="Green"
                  />
                </div>
              </div>
              <div>
                <span>Configuration du prix</span>
                <div
                  className={
                    hasError && !initialValues.price_with_out_cnam
                      ? 'right_sidebar_body_item type3'
                      : 'right_sidebar_body_item'
                  }
                >
                  <label> Prix sans cnam</label>
                  <div className="input_and_icon">
                    <input
                      minLength={8}
                      style={{ width: '100%' }}
                      name="price_with_out_cnam"
                      value={initialValues.price_with_out_cnam}
                      onChange={(e) => {
                        this.setState({
                          initialValues: {
                            ...initialValues,
                            price_with_out_cnam: e.target.value,
                          },
                        });
                      }}
                      className="form-control"
                      type="text"
                      placeholder="50"
                    />
                  </div>
                </div>
                <div
                  className={
                    hasError && !initialValues.price_medical_prescription
                      ? 'right_sidebar_body_item type3'
                      : 'right_sidebar_body_item'
                  }
                >
                  <label> Prix avec prescription médicale</label>
                  <div className="input_and_icon">
                    <input
                      minLength={8}
                      style={{ width: '100%' }}
                      name="price_medical_prescription"
                      value={initialValues.price_medical_prescription}
                      onChange={(e) => {
                        this.setState({
                          initialValues: {
                            ...initialValues,
                            price_medical_prescription: e.target.value,
                          },
                        });
                      }}
                      className="form-control"
                      type="text"
                      placeholder="11.5"
                    />
                  </div>
                </div>
                <div
                  className={
                    hasError && !initialValues.complement
                      ? 'right_sidebar_body_item type3'
                      : 'right_sidebar_body_item'
                  }
                >
                  <label> Complement</label>
                  <div className="input_and_icon">
                    <input
                      minLength={8}
                      style={{ width: '100%' }}
                      name="complement"
                      value={initialValues.complement}
                      onChange={(e) => {
                        this.setState({
                          initialValues: {
                            ...initialValues,
                            complement: e.target.value,
                          },
                        });
                      }}
                      className="form-control"
                      type="text"
                      placeholder="0"
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="right_sidebar_footer confirm_btn_container">
              <button
                onClick={() => this.submitForm(initialValues)}
                className="confirm_recap_info_btn"
              >
                {motif_selected ? 'Valider' : 'Ajouter'}
              </button>
            </div>
          </div>
        </SwipeableDrawer>
      </div>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ManagementOfPathologies);
