import React, { Component } from 'react';
import FormPec from './containers/form';
import actions from '../consultations/store/actions';
import { connect } from 'react-redux';
import CircularProgress from '@material-ui/core/CircularProgress';
import { formatDate, isCodeCNAM, isNumeric } from '../../utils/helpres';
import update from 'immutability-helper';
import moment from 'moment';
import SimpleReactValidator from 'simple-react-validator';
import consultationActions from '../consultations/store/actions';
import { toast } from 'react-toastify';
import ToastAction from '../../components/ToastAction';
import actionsJs from '../../js/actions';
import { FORMAT_DATE_SERVER } from '../../utils/constants';

const mapStateToProps = (state) => ({
  user: state.AppReducer.user,
  loadingConsultationById: state.ConsultationReducer.loadingConsultationById,
  consultation: state.ConsultationReducer.consultation,
  error: state.ConsultationReducer.error,
  sessionsVirtualPec: state.ConsultationReducer.sessionsVirtualPec,
  isLoadingSessionVirtualPec: state.ConsultationReducer.isLoadingSessionVirtualPec,
  loadingAddSessions: state.ConsultationReducer.loadingAddSessions,
  addSessions: state.ConsultationReducer.addSessions,
  employeeManagement: state.AppReducer.employeeManagement,
  isLoadingHolidayEmployeeManagement: state.AppReducer.isLoadingHolidayEmployeeManagement,
  office: state.AppReducer.office,
});

const mapDispatchToProps = (dispatch) => ({
  getConsultationById: (id) => dispatch(actions.getConsultationById(id)),
  getDispoDoctor: (values) => dispatch(actions.getSessionsVirtualPec(values)),
  initialState: () => dispatch(actions.initialState()),
  submitFormSession: (values) => {
    dispatch(consultationActions.submitFormSession(values));
  },
  getOfficeByIdDoctor: () => {
    dispatch(actionsJs.getOfficeByIdDoctor());
  },
});

class VirtualPec extends Component {
  constructor(props) {
    super(props);
    props.getOfficeByIdDoctor();
    props.initialState();
    props.getConsultationById(props.match.params.id);
    props.changeActiveItemSideBar(2);
    props.changeNamePage('Générer mémoire des séances CNAM');
    document.title = 'Générer mémoire des séances CNAM';
    const pec = props.location.state.pec;
    this.state = {
      initialValues: {
        getDispo: [],
        practitioner_id: null,
        patient_id: null,
        num_order: pec && pec.num_order,
        year: pec && pec.year,
        code_bureau: pec && pec.code_bureau,
        start_date: pec && formatDate(pec.start_date, FORMAT_DATE_SERVER),
        end_date: '',
        unit_price: pec && pec.unit_price,
        quality: pec && pec.quality,
        sessions_per_week: pec && pec.sessions_per_week,
        consultation_id: props.match.params.id,
        sessions_count: pec && pec.sessions_count,
        pec: false,
      },
      errorDate: false,
      hasError: false,
    };
    this.handleCheckedPec = this.handleCheckedPec.bind(this);
    this.handleChangeInput = this.handleChangeInput.bind(this);
    this.submitFormSession = this.submitFormSession.bind(this);
    this.submitPEC = this.submitPEC.bind(this);
    this.handleNexItem = this.handleNexItem.bind(this);
    this.goBack = this.goBack.bind(this);
    this.validator = new SimpleReactValidator({
      messages: {
        required: 'Champ requis.',
        integer: 'Champ ne doit contenir que des chiffres',
        string: 'Champ ne doit contenir que des lettres',
        // OR
        default: 'Cette information est requise.', // will override all messages
      },
    });
  }
  componentDidMount() {
    this.props.getDispoDoctor({
      practitioner_id: null,
      patient_id: null,
      num_order: null,
      year: null,
      code_bureau: null,
      start_date: '',
      end_date: '',
      unit_price: '11.5',
      quality: '',
      sessions_per_week: null,
      //consultation_id: props.match.params.id,
      sessions_count: null,
      pec: false,
    });
  }

  async submitFormSession(initialValues, patient) {
    initialValues.end_date =
      initialValues.getDispo[initialValues.getDispo.length - 1].date;
    initialValues.patient_id = patient.id;
    if (!initialValues.pec) initialValues.quality = 'Assuré lui même';
    await this.props.submitFormSession(initialValues);
  }
  submitPEC(initialValues) {
    const { consultation } = this.props;
    if (
      !initialValues.sessions_count ||
      (initialValues.pec && !initialValues.quality) ||
      (initialValues.pec && !initialValues.num_order) ||
      (initialValues.pec && !initialValues.year) ||
      (initialValues.pec && !initialValues.code_bureau) ||
      (initialValues.pec &&
        initialValues.num_order &&
        !isNumeric(initialValues.num_order)) ||
      (initialValues.pec &&
        initialValues.code_bureau &&
        !isCodeCNAM(initialValues.code_bureau)) ||
      (initialValues.pec &&
        initialValues.num_order &&
        isNumeric(initialValues.num_order) &&
        initialValues.num_order <= 4) ||
      (initialValues.pec &&
        initialValues.num_order &&
        isNumeric(initialValues.num_order) &&
        initialValues.num_order <= 4) ||
      (initialValues.pec && initialValues.year && !isNumeric(initialValues.year)) ||
      (initialValues.pec &&
        initialValues.code_bureau &&
        !isNumeric(initialValues.code_bureau)) ||
      (initialValues.pec &&
        initialValues.code_bureau &&
        isNumeric(initialValues.code_bureau) &&
        initialValues.code_bureau.length !== 2) ||
      (initialValues.pec &&
        initialValues.num_order &&
        !isNumeric(initialValues.num_order)) ||
      (initialValues.pec &&
        initialValues.year &&
        isNumeric(initialValues.year) &&
        initialValues.year.length !== 4) ||
      (initialValues.sessions_per_week &&
        isNumeric(initialValues.sessions_per_week) &&
        initialValues.sessions_per_week > 6) ||
      (initialValues.sessions_per_week && !isNumeric(initialValues.sessions_per_week)) ||
      (initialValues.sessions_count &&
        isNumeric(initialValues.sessions_count) &&
        initialValues.sessions_count > 80) ||
      (initialValues.sessions_count && !isNumeric(initialValues.sessions_count)) ||
      (initialValues.pec &&
        initialValues.year &&
        isNumeric(initialValues.year) &&
        initialValues.year > moment().format('YYYY'))
    ) {
      this.setState({
        ...initialValues,
        hasError: true,
      });
    } else if (this.validator.allValid()) {
      this.setState({
        ...initialValues,
        hasError: false,
      });
      if (
        initialValues.start_date &&
        moment().isAfter(moment(initialValues.start_date))
      ) {
        toast(
          ({ closeToast }) => (
            <ToastAction
              type="info"
              message="Attention ! Vous avez saisi une date de début dans le passé."
            />
          ),
          { hideProgressBar: true },
        );
      }
      const { patient_record } = consultation;
      const { patient } = patient_record;
      initialValues.patient_id = patient.id;
      initialValues.consultation_id = this.props.match.params.id;
      this.props.getDispoDoctor(initialValues);
      // this.props.submitFormPEC(initialValues);
    } else {
      this.validator.showMessages();
      this.setState({
        ...initialValues,
        hasError: true,
      });
      // rerender to show messages for the first time
      // you can use the autoForceUpdate option to do this automatically`
      this.forceUpdate();
    }
  }
  handleCheckedPec(pec) {
    const { initialValues } = this.state;
    this.setState({
      initialValues: {
        ...initialValues,
        pec: !pec,
      },
    });
  }
  handleChangeInput(event) {
    const { initialValues } = this.state;
    const target = event.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;
    this.setState({
      initialValues: {
        ...initialValues,
        [name]: value,
      },
    });
  }
  goBack() {
    this.props.history.goBack();
  }
  componentWillReceiveProps(nextProps, nextContext) {
    const { addSessions } = nextProps;
    const { initialValues } = this.state;
    if (addSessions) {
      this.props.history.push({
        pathname: '/consultations',
        state: { consultation_id: this.props.match.params.id },
      });
    }
    const { sessionsVirtualPec, consultation } = nextProps;
    if (sessionsVirtualPec && sessionsVirtualPec.length > 0) {
      this.setState({
        initialValues: {
          ...initialValues,
          getDispo: sessionsVirtualPec,
          phone:
            consultation.patient_record && consultation.patient_record.patient
              ? consultation.patient_record.patient.phone
              : '',
          end_date: moment(sessionsVirtualPec[sessionsVirtualPec.length - 1].date).format(
            'YYYY-MM-DD',
          ),
        },
      });
    }
  }
  handlePrevItem(event, id, item) {
    const { initialValues } = this.state;
    this.setState((prevState) => {
      const data = [...prevState.initialValues.getDispo];
      var commentIndex = data.findIndex(function (c) {
        return c.week === id;
      });
      const date =
        initialValues.getDispo[commentIndex].values[
          (initialValues[item] ? initialValues[item] - 1 : 1) %
            initialValues.getDispo[commentIndex].values.length
        ].date;
      const updatedComment = update(data[commentIndex], {
        date: { $set: moment(date).add(-1, 'days').format('YYYY-MM-DD') },
      });
      data[commentIndex] = updatedComment;

      this.setState({
        initialValues: {
          ...initialValues,
          [item]: initialValues[item] ? initialValues[item] - 1 : 1,
          getDispo: data,
        },
      });
    });
  }
  handleNexItem(event, id, item) {
    const { initialValues } = this.state;
    this.setState((prevState) => {
      const data = [...prevState.initialValues.getDispo];
      var commentIndex = data.findIndex(function (c) {
        return c.week === id;
      });
      const date =
        initialValues.getDispo[commentIndex].values[
          (initialValues[item] ? initialValues[item] + 1 : 1) %
            initialValues.getDispo[commentIndex].values.length
        ].date;
      const updatedComment = update(data[commentIndex], {
        date: { $set: moment(date).add(1, 'days').format('YYYY-MM-DD') },
      });
      data[commentIndex] = updatedComment;

      this.setState({
        initialValues: {
          ...initialValues,
          [item]: initialValues[item] ? initialValues[item] + 1 : 1,
          getDispo: data,
        },
      });
    });
  }

  render() {
    const {
      consultation,
      employeeManagement,
      isLoadingSessionVirtualPec,
      loadingAddSessions,
      admin,
      user,
      office,
      sessionsVirtualPec,
    } = this.props;
    const { initialValues, hasError, errorDate } = this.state;
    if (!consultation.id)
      return (
        <CircularProgress disableShrink style={{ marginLeft: '33%', marginTop: '20%' }} />
      );
    const { patient_record } = consultation;
    const { patient } = patient_record;
    let i = 0;
    return (
      <div className="main_content">
        <div className="facture_info_section_form">
          <div className="container_changeable_content">
            <div className="add_facture_section">
              <div className="selected_facture_details">
                <div className="selected_facture_info_container">
                  <div className="selected_facture_img_container">
                    <svg
                      width="14"
                      height="14"
                      viewBox="0 0 14 14"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M3.02734 9.35547C4.41016 8.79297 5.73438 8.51172 7 8.51172C8.26562 8.51172 9.57812 8.79297 10.9375 9.35547C12.3203 9.89453 13.0117 10.6094 13.0117 11.5V13.0117H0.988281V11.5C0.988281 10.6094 1.66797 9.89453 3.02734 9.35547ZM9.10938 6.12109C8.52344 6.70703 7.82031 7 7 7C6.17969 7 5.47656 6.70703 4.89062 6.12109C4.30469 5.53516 4.01172 4.83203 4.01172 4.01172C4.01172 3.19141 4.30469 2.48828 4.89062 1.90234C5.47656 1.29297 6.17969 0.988281 7 0.988281C7.82031 0.988281 8.52344 1.29297 9.10938 1.90234C9.69531 2.48828 9.98828 3.19141 9.98828 4.01172C9.98828 4.83203 9.69531 5.53516 9.10938 6.12109Z"
                        fill="#8083A3"
                      />
                    </svg>
                  </div>
                  <div className="selected_facture_patient_info">
                    <span id="selected_facture_patient_name">
                      {patient ? `${patient.last_name} ${patient.first_name}` : ''}
                    </span>
                    <span id="selected_facture_patient_city">
                      {patient ? patient.phone : ''}
                    </span>
                  </div>
                </div>
              </div>
              <FormPec
                getDispo={initialValues.getDispo}
                initialValues={initialValues}
                patient={patient}
                loadingGetDispoDoctor={isLoadingSessionVirtualPec}
                errorDate={errorDate}
                admin={admin}
                user={user}
                hasError={hasError}
                employeeManagement={employeeManagement}
                getDispoDoctor={this.props.getDispoDoctor}
                handleChangeInput={this.handleChangeInput}
                handleCheckedPec={this.handleCheckedPec}
                goBack={this.goBack}
                submitPEC={this.submitPEC}
              />
            </div>
          </div>
        </div>
        {sessionsVirtualPec.length > 0 ? (
          isLoadingSessionVirtualPec ? (
            <CircularProgress
              disableShrink
              style={{ marginLeft: '10%', marginRight: '10%', marginTop: '20%' }}
            />
          ) : (
            <div className="facture_patient">
              <div className="facture_patient_header">
                <p>Listes des séances</p>
              </div>
              <div style={{ height: '70%' }} className="facture_patients_body">
                {sessionsVirtualPec &&
                  sessionsVirtualPec.length > 0 &&
                  sessionsVirtualPec.map((week) => {
                    i++;
                    if (initialValues.sessions_count <= sessionsVirtualPec.length)
                      return (
                        <div
                          style={{ margin: '6px', width: '95%' }}
                          key={i}
                          className="creation_prise_en_charge_2_item"
                        >
                          <div style={{ width: '20%' }} className="seance_number">
                            <span>S N°{i}</span>
                          </div>
                          <div style={{ width: '37%' }} className="seance_date">
                            <div className="options_container_item option1">
                              <div className="seance_number">
                                <span>{formatDate(week.date)}</span>
                              </div>
                            </div>
                          </div>

                          {/* <div style={{ width: '28%' }} className="seance-status">
                             <div className="status_text">
                              <span>{moment(week.date).format('HH:mm')}</span>
                            </div>
                            <div
                              onClick={(e) =>
                                this.handlePrevItem(e, week.week, `date_${week.week}`)
                              }
                              className="clock_refresh"
                            >
                              <i className="fas fa-angle-left"></i>
                            </div>
                            <div
                              onClick={(e) =>
                                this.handleNexItem(e, week.week, `date_${week.week}`)
                              }
                              className="clock_refresh"
                            >
                              <i className="fas fa-angle-right"></i>
                            </div>
                          </div>*/}
                        </div>
                      );
                  })}
              </div>
              <div className="footer_buttons">
                <div
                  style={{
                    marginRight: '1px',
                    marginTop: '12px',
                    flexDirection: 'inherit',
                  }}
                  className="facture_patient_header"
                >
                  <button
                    style={{ marginRight: '5%' }}
                    type="button"
                    className="footer_btn"
                    id="update"
                    onClick={() => {
                      this.props.history.push({
                        pathname: '/seance-pdf',
                        state: {
                          sessions: initialValues.getDispo,
                          office: office,
                          //pec: pec,
                          consultation: consultation,
                        },
                      });
                      //this.submitFormSession(initialValues, patient);
                    }}
                  >
                    {loadingAddSessions ? (
                      <CircularProgress
                        style={{
                          marginLeft: '4%',
                          width: '25px',
                          height: '25px',
                          color: 'white',
                          marginTop: '5%',
                        }}
                      />
                    ) : (
                      'Valider'
                    )}
                  </button>
                </div>
              </div>
            </div>
          )
        ) : (
          ''
        )}
      </div>
    );
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(VirtualPec);
