import React, { useEffect, useState } from 'react';
import moment from 'moment';
import { apiCall } from '../../js/actions';

function SingleAppointment({ eventInfo, pathologieColor, pathologie }) {
  const patientId = eventInfo?.event?.extendedProps?.patient_id;
  const [upcomingAppointmentsCount, setUpcomingAppointmentsCount] = useState(0);
  const [firstUpcomingAppointment, setFirstUpcomingAppointment] = useState(null);

  const fetchUpcomingAppointments = async () => {
    try {
      const url = `/api/list/upcomingAppointmentsBrief/patient/${patientId}`;
      const { data } = await apiCall({
        method: 'post',
        url,
        params: {
          fromDate: moment(eventInfo.event.start).add(1, 'day').format('YYYY-MM-DD'),
        },
      });
      if (!!data.data) {
        setUpcomingAppointmentsCount(data.data.count);
        setFirstUpcomingAppointment(data.data.rows[0].date_from);
      }
    } catch (error) {
      setUpcomingAppointmentsCount(0);
      setFirstUpcomingAppointment(null);
    }
  };

  useEffect(() => {
    patientId && fetchUpcomingAppointments();
  }, []);

  return (
    <div
      className="fc-event-title"
      style={{
        width: '-webkit-fill-available',
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      <div style={{ fontWeight: 'bold', color: 'black' }}>
        {eventInfo.event.title}
        <span style={{ color: 'black', fontWeight: '400' }}>
          {'   ' +
            moment(eventInfo.event.start).format('HH:mm') +
            ' - ' +
            moment(eventInfo.event.end).format('HH:mm')}
        </span>
      </div>

      <div style={{ fontWeight: '400' }}>
        <span style={{ color: pathologieColor }}>
          {pathologie && pathologie.toString()}
        </span>
      </div>

      <div
        style={{
          color: 'black',
          fontWeight: '400',
          height: '-webkit-fill-available',
          alignContent: 'flex-end',
          textAlign: 'end',
        }}
      >
        <span
          style={{
            fontWeight: 'bold',
            color: 'indigo',
          }}
        >
          {upcomingAppointmentsCount > 0
            ? `${upcomingAppointmentsCount} RDV`
            : 'Aucun RDV'}
        </span>

        <span
          style={{
            fontWeight: 'bold',
          }}
        >
          {firstUpcomingAppointment
            ? ` - PR ${moment(firstUpcomingAppointment).format('DD/MM')}`
            : ''}
        </span>
      </div>
    </div>
  );
}

export default SingleAppointment;
