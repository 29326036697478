import moment from 'moment';
import { formatDate } from '../../../utils/helpres';
import { FORMAT_DATE_SERVER } from '../../../utils/constants';

const initialUserState = {
  isLoadingActionDepense: false,
  actionDepense: false,
  depenses: [],
  depense: null,
  isLoadingDepenses: false,
  isLoadingDepenseById: true,
  receiptById: null,
  isLoadingDepense: true,
  params: {
    limit: 15,
    currentPage: 1,
    start_date: formatDate(moment(), FORMAT_DATE_SERVER),
    end_date: formatDate(moment(), FORMAT_DATE_SERVER),
    search: '',
    status: '',
  },
  destroyDepense: false,
  isLoadingDestroyDepense: true,
  sumDepense: null,
};

const DepenseReducer = (state = initialUserState, action) => {
  switch (action.type) {
    case 'DELETE_DEPENSE_REQUESTING': {
      return {
        ...state,
        isLoadingDestroyDepense: true,
      };
    }
    case 'DELETE_DEPENSE_SUCCESS': {
      return {
        ...state,
        isLoadingDestroyDepense: false,
        destroyDepense: true,
      };
    }
    case 'DELETE_DEPENSE_FAILURE': {
      return {
        ...state,
        isLoadingDestroyDepense: false,
        destroyDepense: null,
      };
    }

    case 'GET_DEPENSE_BY_ID_REQUESTING': {
      return {
        ...state,
        isLoadingDepenseById: true,
      };
    }
    case 'GET_DEPENSE_BY_ID_SUCCESS': {
      return {
        ...state,
        isLoadingDepenseById: false,
        depense: action.data,
      };
    }
    case 'GET_DEPENSE_BY_ID_FAILURE': {
      return {
        ...state,
        isLoadingDepenseById: false,
        depense: null,
      };
    }

    case 'GET_DEPENSES_REQUESTING': {
      return {
        ...state,
        isLoadingDepenses: true,
        actionDepense: false,
        destroyDepense: false,
      };
    }
    case 'GET_DEPENSES_SUCCESS': {
      return {
        ...state,
        isLoadingDepenses: false,
        depenses: action.data.data,
        sumDepense: action.data.sumDepense,
      };
    }
    case 'GET_DEPENSES_FAILURE': {
      return {
        ...state,
        isLoadingDepenses: false,
        depenses: [],
      };
    }

    case 'UPDATE_DEPENSE_REQUESTING': {
      return {
        ...state,
        isLoadingActionDepense: true,
        actionDepense: false,
      };
    }
    case 'UPDATE_DEPENSE_SUCCESS': {
      return {
        ...state,
        isLoadingActionDepense: false,
        actionDepense: true,
      };
    }
    case 'UPDATE_DEPENSE_FAILURE': {
      return {
        ...state,
        isLoadingActionDepense: false,
        actionDepense: false,
      };
    }

    case 'UPDATE_SEARCH_DEPENSE_PARAMS': {
      return {
        ...state,
        params: action.params,
      };
    }
    default: {
      return state;
    }
  }
};

export default DepenseReducer;
