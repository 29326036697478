import moment from 'moment';

function BordereauPdf(bordereau, bills, office) {
  const padString = '0';
  const tvaValue = 7;

  let bordereauTxtContent = '';

  if (bills && bills.length > 0) {
    let totalPrice = 0;
    bills.map((bill) => (totalPrice += bill.total_ttc));
    const parsedTotalPrice = totalPrice
      .toFixed(3)
      .toString()
      .replace('.', '')
      .padStart(10, padString);
    const bordereauYear = bordereau.number.split('/')[1];
    const bordereauNumber = bordereau.number.split('/')[0].padStart(3, padString);
    const conventionalCodeType = office.conventional_code
      .split('/')[0]
      .padStart(2, padString);
    const conventionalCodeNumber = office.conventional_code
      .split('/')[1]
      .padStart(8, padString);
    const conventionalCodeKey = office.conventional_code
      .split('/')[2]
      .padStart(2, padString);
    const billsNumber = bills.length.toString().padStart(3, padString);
    const employerNumber = office.number_employer
      .replace('/', '')
      .toString()
      .padStart(12, padString);
    let firstLine = `1${bordereauYear}${bordereauNumber}${conventionalCodeType}${conventionalCodeNumber}${conventionalCodeKey}${padString.repeat(
      33,
    )}${employerNumber}${padString.repeat(3)}${billsNumber}${padString.repeat(
      16,
    )}${parsedTotalPrice}${padString.repeat(38)}\n`;

    bordereauTxtContent += firstLine;

    bills.map((bill) => {
      const billYear = moment(bill.date).get('year');
      const billNumber = bill.number;
      const CNAMRegionalOffice = 75;
      const PecOfficeNumber =
        bill.consultation.prise_en_charges.length > 0 &&
        bill.consultation.prise_en_charges[0].code_bureau
          ? bill.consultation.prise_en_charges[0].code_bureau
              .toString()
              .padStart(2, padString)
          : 'X';
      const PecYear =
        bill.consultation.prise_en_charges.length > 0 &&
        bill.consultation.prise_en_charges[0].year
          ? bill.consultation.prise_en_charges[0].year
          : 'X';
      const PecOrderNumber =
        bill.consultation.prise_en_charges.length > 0 &&
        bill.consultation.prise_en_charges[0].num_order
          ? bill.consultation.prise_en_charges[0].num_order
              .toString()
              .padStart(6, padString)
          : 'X';
      const patientCNAMRoot = bill.consultation.patient_record.patient.cnam_root.padStart(
        10,
        padString,
      );
      const patientCNAMKey = bill.consultation.patient_record.patient.cnam_key.padStart(
        2,
        padString,
      );
      const sessionsPerWeek =
        bill.consultation.prise_en_charges.length > 0
          ? bill.consultation.prise_en_charges[0].sessions_per_week
              .toString()
              .padStart(3, padString)
          : 'XXX';
      const sessionsTotal = bill.line_bill.sessions_nbr.toString().padStart(3, padString);
      const startDate =
        bill.consultation.prise_en_charges.length > 0
          ? moment(bill.consultation.prise_en_charges[0].start_date).format('YYYYMMDD')
          : 'XXXXXXXX';
      const endDate =
        bill.consultation.prise_en_charges.length > 0
          ? moment(bill.consultation.prise_en_charges[0].end_date).format('YYYYMMDD')
          : 'XXXXXXXX';
      const singleBillTtc = parseFloat(bill.total_ttc)
        .toFixed(3)
        .toString()
        .replace('.', '')
        .padStart(10, padString);
      const singleBillHt = parseFloat(bill.total_ttc / 1.07)
        .toFixed(3)
        .toString()
        .replace('.', '')
        .padStart(10, padString);
      const singleBillTva = parseFloat(tvaValue)
        .toFixed(3)
        .toString()
        .replace('.', '')
        .padStart(10, padString);
      const singleBillTvaAmount = parseFloat(bill.total_ttc - bill.total_ttc / 1.07)
        .toFixed(3)
        .toString()
        .replace('.', '')
        .padStart(10, padString);
      const billDate = moment(bill.date).format('YYYYMMDD');
      const billLine = `2${bordereauYear}${bordereauNumber}${conventionalCodeType}${conventionalCodeNumber}${conventionalCodeKey}${billYear}${' '.repeat(
        15 - billNumber.length,
      )}${billNumber}${PecOfficeNumber}${CNAMRegionalOffice}${PecYear}${PecOrderNumber}${patientCNAMRoot}${patientCNAMKey}${sessionsPerWeek}${sessionsTotal}${startDate}${endDate}${singleBillTtc}${singleBillHt}${singleBillTva}${singleBillTvaAmount}${billDate}\n`;
      bordereauTxtContent += billLine;
    });
  }

  var uri = `data:application/octet-stream,${encodeURIComponent(bordereauTxtContent)}`;
  var link = document.createElement('a');
  link.setAttribute(
    'download',
    `BORDEREAU_${bordereau.number.split('/')[0]}_ANNEE_${
      bordereau.number.split('/')[1]
    }.txt`,
  );
  link.setAttribute('href', uri);
  link.click();
  return true;
}
export default BordereauPdf;
