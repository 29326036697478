import * as React from 'react';
import PropTypes from 'prop-types';
import { alpha } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableSortLabel from '@mui/material/TableSortLabel';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import Checkbox from '@mui/material/Checkbox';
import Tooltip from '@mui/material/Tooltip';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { visuallyHidden } from '@mui/utils';
import { Button } from '@material-ui/core';
import { formatAmount, formatDate } from '../../../utils/helpres';

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function ascendingComparator(a, b, orderBy) {
  return -descendingComparator(a, b, orderBy);
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => ascendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

const headCells = [
  {
    id: 'index',
    numeric: false,
    disablePadding: true,
    label: 'N°',
  },

  {
    id: 'number',
    numeric: false,
    disablePadding: false,
    label: 'N° FACTURE',
  },
  {
    id: 'decision',
    numeric: false,
    disablePadding: false,
    label: 'N° DÉCISION',
  },
  {
    id: 'patient',
    numeric: false,
    disablePadding: false,
    label: 'PATIENT',
  },
  {
    id: 'date',
    numeric: false,
    disablePadding: false,
    label: 'DATE',
  },
  {
    id: 'totalHt',
    numeric: false,
    disablePadding: false,
    label: 'TOTAL HT',
  },
  {
    id: 'totalTva',
    numeric: false,
    disablePadding: false,
    label: 'TOTAL TVA',
  },
  {
    id: 'totalTtc',
    numeric: false,
    disablePadding: false,
    label: 'TOTAL TTC',
  },
];

function EnhancedTableHead(props) {
  const { onSelectAllClick, order, orderBy, numSelected, rowCount, onRequestSort } =
    props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        <TableCell padding="checkbox">
          <Checkbox
            color="primary"
            indeterminate={numSelected > 0 && numSelected < rowCount}
            checked={rowCount > 0 && numSelected === rowCount}
            onChange={onSelectAllClick}
            inputProps={{
              'aria-label': 'sélectionner toutes les factures',
            }}
          />
        </TableCell>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.numeric ? 'right' : 'left'}
            padding={headCell.disablePadding ? 'none' : 'normal'}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : 'asc'}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <Box component="span" sx={visuallyHidden}>
                  {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                </Box>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(['asc', 'desc']).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};

function EnhancedTableToolbar(props) {
  const { numSelected, onCreateBordereau, isSubmittingBordereau, totalAmount } = props;

  return (
    <Toolbar
      sx={{
        pl: { sm: 2 },
        pr: { xs: 1, sm: 1 },
        ...(numSelected > 0 && {
          bgcolor: (theme) =>
            alpha(theme.palette.primary.main, theme.palette.action.activatedOpacity),
        }),
      }}
    >
      {numSelected > 0 ? (
        <Typography
          sx={{ flex: '1 1 100%' }}
          color="inherit"
          variant="subtitle1"
          component="div"
        >
          {numSelected} sélectionnée(s) ~ {totalAmount} dinars
        </Typography>
      ) : (
        <Typography
          sx={{ flex: '1 1 100%' }}
          variant="h6"
          id="tableTitle"
          component="div"
        >
          Liste des factures
        </Typography>
      )}

      {numSelected > 0 && (
        <Tooltip title="Confirmer la sélection">
          <Button
            variant="outlined"
            onClick={onCreateBordereau}
            disabled={isSubmittingBordereau}
          >
            <CheckCircleIcon style={{ marginRight: '10px', color: 'green' }} />
            Valider
          </Button>
        </Tooltip>
      )}
    </Toolbar>
  );
}

EnhancedTableToolbar.propTypes = {
  numSelected: PropTypes.number.isRequired,
  totalAmount: PropTypes.number.isRequired,
  onCreateBordereau: PropTypes.func,
  isSubmittingBordereau: PropTypes.bool,
};

export default function EnhancedTable(props) {
  const { bills, onCreateBordereau, isSubmittingBordereau } = props;

  const [order, setOrder] = React.useState('asc');
  const [orderBy, setOrderBy] = React.useState('number');
  const [selected, setSelected] = React.useState([]);
  const [totalAmount, setTotalAmount] = React.useState(0);

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelected = bills.map((n) => n.id);
      const totalBillsAmount = bills.reduce(
        (accumulator, currentValue) =>
          parseFloat(accumulator) + parseFloat(currentValue.price_ttc),
        0,
      );
      setSelected(newSelected);
      setTotalAmount(formatAmount(totalBillsAmount));
      return;
    }
    setSelected([]);
  };

  const updateTotalAmount = (newValue) => {
    setTotalAmount((prevAmount) => parseFloat(prevAmount) + parseFloat(newValue));
  };

  const handleClick = (event, id) => {
    const selectedIndex = selected.indexOf(id);
    const selectedBillAmount = bills.find((bill) => bill.id === id)?.price_ttc ?? 0;
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, id);
      updateTotalAmount(selectedBillAmount);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
      updateTotalAmount(-selectedBillAmount);
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
      updateTotalAmount(-selectedBillAmount);
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1),
      );
      updateTotalAmount(selectedBillAmount);
    }
    setSelected(newSelected);
  };

  const isSelected = (id) => selected.indexOf(id) !== -1;

  const visibleRows = React.useMemo(
    () => stableSort(bills, getComparator(order, orderBy)),
    [order, orderBy, bills],
  );

  const handleCreateBordereau = () => {
    onCreateBordereau(selected);
  };

  return (
    <Box sx={{ width: '100%', padding: '0px 1rem', marginBottom: '1rem' }}>
      <Paper
        sx={{
          width: '100% !important',
          height: '100% !important',
          overflow: 'hidden',
        }}
      >
        <EnhancedTableToolbar
          numSelected={selected.length}
          totalAmount={totalAmount}
          onCreateBordereau={handleCreateBordereau}
          isSubmittingBordereau={isSubmittingBordereau}
        />
        <TableContainer sx={{ maxHeight: 500 }}>
          <Table
            stickyHeader
            sx={{ minWidth: 750 }}
            aria-label="Liste des factures"
            size={'small'}
          >
            <EnhancedTableHead
              numSelected={selected.length}
              totalAmount={totalAmount}
              order={order}
              orderBy={orderBy}
              onSelectAllClick={handleSelectAllClick}
              onRequestSort={handleRequestSort}
              rowCount={visibleRows.length}
              onCreateBordereau={handleCreateBordereau}
            />
            <TableBody>
              {visibleRows.map((row, index) => {
                const isItemSelected = isSelected(row.id);
                const labelId = `enhanced-table-checkbox-${index}`;
                const patient = row.patient_record.patient;
                const pec = row.consultation.prise_en_charges[0];
                return (
                  <TableRow
                    hover
                    onClick={(event) => handleClick(event, row.id)}
                    role="checkbox"
                    aria-checked={isItemSelected}
                    tabIndex={-1}
                    key={row.id}
                    selected={isItemSelected}
                    sx={{ cursor: 'pointer' }}
                  >
                    <TableCell padding="checkbox">
                      <Checkbox
                        color="primary"
                        checked={isItemSelected}
                        inputProps={{
                          'aria-labelledby': labelId,
                        }}
                      />
                    </TableCell>
                    <TableCell component="th" id={labelId} scope="row" padding="none">
                      {index + 1}
                    </TableCell>
                    <TableCell align="left">{row.number}</TableCell>
                    <TableCell align="left">{`${pec.code_bureau}/${pec.year}/${pec.num_order}`}</TableCell>
                    <TableCell align="left">{`${patient.first_name} ${patient.last_name}`}</TableCell>
                    <TableCell align="left">{formatDate(row.date)}</TableCell>
                    <TableCell align="left">{formatAmount(row.price_ht)}</TableCell>
                    <TableCell align="left">{formatAmount(row.price_tva)}</TableCell>
                    <TableCell align="left">{formatAmount(row.price_ttc)}</TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>
    </Box>
  );
}

EnhancedTable.propTypes = {
  bills: PropTypes.array,
  onCreateBordereau: PropTypes.func,
  isSubmittingBordereau: PropTypes.bool,
};
