import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { DateField } from '@mui/x-date-pickers/DateField';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { FORMAT_DATE } from '../utils/constants';
import dayjs from 'dayjs';
import moment from 'moment';

DatePickerComponent.protoTypes = {
  value: PropTypes.string.isRequired,
  style: PropTypes.object,
  onChange: PropTypes.func,
  label: PropTypes.string | null,
  clearable: PropTypes.bool,
  defaultValue: PropTypes.any | null,
};

function DatePickerComponent({
  value,
  style,
  onChange,
  label,
  clearable = false,
  defaultValue = null,
}) {
  const parsedDate = !!value ? dayjs(value) : undefined;
  const [selectedDate, setSelectedDate] = useState(parsedDate);

  const handleChange = (newValue) => {
    setSelectedDate(newValue);
    if (isValidDate(newValue)) {
      onChange(newValue.$d);
    }
  };

  const handleClear = () => {
    if (isValidDate(selectedDate)) {
      onChange(null);
    }
  };

  const isValidDate = (date) => {
    return (
      date !== null && date !== '' && moment(date).isValid() && !isNaN(date.$d.getTime())
    );
  };

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <DateField
        label={label}
        value={selectedDate}
        defaultValue={defaultValue}
        format={FORMAT_DATE}
        clearable={clearable}
        fullWidth={true}
        onChange={handleChange}
        onClear={handleClear}
        size="large"
      />
    </LocalizationProvider>
  );
}

export default DatePickerComponent;
