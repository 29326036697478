import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import actions from './store/actions';
import actionsJs from '../../js/actions';
import actionsDashboard from './../dashborad/store/actions';
import { registerLocale } from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import fr from 'date-fns/locale/fr';
import { firstNumberPhone, isNumeric, validate, formatDate } from '../../utils/helpres';
import { SelectorContactPatientsBodyItem } from '../../utils';
import './index.css';
import Item_patient from './composent/item_patient';
import { FORMAT_DATE_SERVER, sortCities } from '../../utils/constants';
import consultationActions from '../consultations/store/actions';
import CircularProgress from '@material-ui/core/CircularProgress';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import debounce from 'lodash/debounce';

registerLocale('fr', fr); // register it with the name you want

const mapStateToProps = (state) => ({
  patientRecord: state.PatientReducer.patientRecord,
  isLoadingPatientRecord: state.PatientReducer.isLoadingPatientRecord,
  patients: state.PatientReducer.patients,
  patientRecords: state.PatientReducer.patientRecords,
  params: state.PatientReducer.params,
  user: state.AppReducer.user,
  medecins: state.AppReducer.medecins,
  newPatient: state.PatientReducer.newPatient,
  isLoadingPatient: state.PatientReducer.isLoadingPatient,
  isLoadingPatientRecords: state.PatientReducer.isLoadingPatientRecords,
  isLoadingBilanPatientRecord: state.PatientReducer.isLoadingBilanPatientRecord,
  bilanPatientRecord: state.PatientReducer.bilanPatientRecord,
  protocolePatientRecord: state.PatientReducer.protocolePatientRecord,
  isLoadingProtocolePatientRecord: state.PatientReducer.isLoadingProtocolePatientRecord,
  paramsBilan: state.PatientReducer.paramsBilan,
  isLoadingLast10Patients: state.DashboardReducer.isLoadingLast10Patients,
  last10Patient: state.DashboardReducer.last10Patient,
  loadingConsultationPatientRecord:
    state.ConsultationReducer.loadingConsultationPatientRecord,
  patientConsultationList: state.ConsultationReducer.patientConsultationList,
  paramsConsultation: state.ConsultationReducer.params,
  updateBill: state.FactureReducer.updateBill,
  addPatient: state.PatientReducer.addPatient,
  addSessions: state.ConsultationReducer.addSessions,
  isLoadingBillPatientRecord: state.PatientReducer.isLoadingBillPatientRecord,
  isLoadingPatientStatistical: state.PatientReducer.isLoadingPatientStatistical,
  updateConsultation: state.ConsultationReducer.updateConsultation,
});

const mapDispatchToProps = (dispatch) => ({
  getPatientStatistical: (id) => dispatch(actions.getPatientStatistical(id)),
  getPatientRecordById: (id) => dispatch(actions.getPatientRecordById(id)),
  getMedecins: () => dispatch(actionsJs.getMedecins()),
  changeActiveItemSideBarCompta: (data) =>
    dispatch(actionsJs.changeActiveItemSideBarCompta(data)),
  initialState: () => dispatch(actions.initialState()),
  initialStateConsultation: () => dispatch(consultationActions.initialState()),
  submitPatientForm: async (values, id) => {
    await dispatch(actions.submitPatientForm(values));
    dispatch(actions.getPatientRecordsByDoctor());
  },
  changeSearchedPhone: async (params) => {
    await dispatch(actions.changeSearchedString(params));
    dispatch(actions.getPatientRecordsByDoctor(params));
  },
  changeSearched: async (params) => {
    await dispatch(actions.changeSearchedString(params));
    dispatch(actions.getPatientRecordsByDoctor(params));
  },
  changeSearchedCity: async (params) => {
    await dispatch(actions.changeSearchedString(params));
    dispatch(actions.getPatientRecordsByDoctor(params));
  },
  changeSearchedBirthday: async (params) => {
    await dispatch(actions.changeSearchedString(params));
    dispatch(actions.getPatientRecordsByDoctor(params));
  },
  submitAddPatientRecordForm: async (params, values) => {
    await dispatch(actions.submitAddPatientRecordForm(values));
    dispatch(actions.getPatientRecordsByDoctor(params));
  },
  changeSearchedStringLimit: async (params) => {
    await dispatch(actions.changeSearchedString(params));
    dispatch(actions.getPatientRecordsByDoctor(params));
  },
  getPatientRecordsByDoctor: (params) =>
    dispatch(actions.getPatientRecordsByDoctor(params)),
  getPatients: (params) => dispatch(actions.getPatients(params)),
  getLast10PatientByDoctor: () => dispatch(actionsDashboard.getLast10PatientByDoctor()),
  getBilanByPatientRecordId: (params, id) =>
    dispatch(actions.getBilanByPatientRecordId(params, id)),
  getProtocoleByPatientRecordId: (params, id) =>
    dispatch(actions.getProtocoleByPatientRecordId(params, id)),
  getDoctorConsultations: (params) =>
    dispatch(consultationActions.getDoctorConsultations(params)),
  changeSearchedStringConsultation: (params, id) => {
    dispatch(consultationActions.changeSearchedString(params));
    dispatch(consultationActions.getPatientConsultations(params, id));
  },
  changeSearchedStringBilan: (params, id) => {
    dispatch(actions.changeSearchedStringBilan(params));
    dispatch(actions.getBilanByPatientRecordId(params, id));
  },
  changeActiveItemSideBarSettings: (item, data, create) =>
    dispatch(actionsJs.changeActiveItemSideBarSettings(item, data, create)),
  changeSearchedStringBill: (params, id) => {
    dispatch(actions.changeSearchedStringBill(params));
    dispatch(actions.getBillByPatientRecordId(params, id));
  },
  changeSearchedStringReceipt: (params, id) => {
    dispatch(actions.changeSearchedStringReceipt(params));
    dispatch(actions.getReceiptByPatientRecordId(params, id));
  },
});

class Patients extends PureComponent {
  constructor(props) {
    super(props);
    props.initialState();
    props.changeActiveItemSideBarCompta(null);
    props.initialStateConsultation();
    // props.getLast10PatientByDoctor();
    props.getMedecins();
    props.changeNamePage('Patients');
    props.changeActiveItemSideBarSettings(111, false, true);
    props.changeActiveItemSideBar(3);
    document.title = 'Patients';
    this.state = {
      defaultFilter: true,
      showPatient: true,
      showBilans: false,
      showProtocole: false,
      showConsultations: false,
      showFormPatient: false,
      errorPhone: false,
      errorName: false,
      errorDate: false,
      firstClick: false,
      patient: null,
      patient_selected:
        this.props.location.state && this.props.location.state.patient_id
          ? this.props.location.state.patient_id
          : null,
      note: '',
      last_name: props.params.last_name,
      phone: props.params.phone,
      search: '',
    };
    this.onChangePhoneParams = this.onChangePhoneParams.bind(this);
    this.onChangeNameParams = this.onChangeNameParams.bind(this);
    this.makeHttpRequestWithPage = this.makeHttpRequestWithPage.bind(this);
    this.gotToAgendaPrescription = this.gotToAgendaPrescription.bind(this);
    this.debouncedSearch = debounce(this.props.changeSearched, 300);
  }

  makeHttpRequestWithPage = (event, page) => {
    this.props.changeSearchedStringLimit({
      ...this.props.params,
      currentPage: page ? page : this.props.params.currentPage - 1,
      limit: this.props.params.limit,
    });
  };

  onChangePhoneParams(value) {
    const { params } = this.props;
    if (!isNumeric(value) || !firstNumberPhone(value)) {
      this.setState({
        errorPhone: true,
        phone: value,
      });
      if (!value)
        this.props.changeSearchedPhone({
          ...params,
          phone: value,
        });
    } else {
      this.setState({
        errorPhone: false,
        phone: value,
      });
      this.props.changeSearchedPhone({
        ...params,
        phone: value ? value : '',
      });
    }
  }

  onChangeNameParams(value) {
    const { params } = this.props;
    if (!validate(value)) {
      this.setState({
        errorName: true,
        first_name: value,
      });
      if (!value)
        this.props.changeSearchedLastName({
          ...params,
          first_name: '',
        });
    } else {
      this.setState({
        errorName: false,
        first_name: value,
      });
      this.props.changeSearchedLastName({
        ...params,
        first_name: value,
      });
    }
  }

  componentDidMount() {
    SelectorContactPatientsBodyItem();
    this.props.changeSearched({
      search:
        this.props.location.state && this.props.location.state.name
          ? this.props.location.state.name
          : '',
      first_name: '',
      city: '',
      birthday: '',
      cnam: '',
      phone:
        this.props.location.state && this.props.location.state.phone
          ? this.props.location.state.phone
          : '',
      currentPage: 1,
      limit: 10,
    });
  }

  componentWillReceiveProps(nextProps, nextContext) {
    const {
      patients,
      patientRecords,
      updateConsultation,
      addSessions,
      newPatient,
      updateBill,
      addPatient,
    } = nextProps;
    if (updateConsultation) {
      this.props.history.push({
        pathname: '/consultations',
        state: { consultation_id: updateConsultation.id },
      });
    }
    const { showFormPatient } = this.state;
    if (updateBill) {
      nextProps.history.push(`/factures`);
    }

    if (addPatient && showFormPatient) {
      this.showPatient(newPatient.id);
      this.setState({
        showFormPatient: false,
      });
    }
    if (patients.total === 0 && patientRecords.total === 0) {
      this.props.history.push(`/form-patient`);
    }
    if (addSessions) {
      this.props.history.push(`/consultations`);
    }
  }

  gotToAgendaPrescription(getDispoDate, initialValues) {
    this.props.history.push({
      pathname: '/agenda-prescription',
      state: { getDispoDate: getDispoDate, initialValues: initialValues },
    });
  }

  componentWillUnmount() {
    this.debouncedSearch.cancel();
  }

  handleSearchPatient = (event) => {
    const { value } = event.target;
    this.setState({ search: value });
    this.debouncedSearch({
      ...this.props.params,
      search: value,
    });
  };

  render() {
    const {
      params,
      patientRecord,
      isLoadingPatientRecords,
      patients,
      patientRecords,
      last10Patient,
      paramsConsultation,
      paramsBilan,
      user,
    } = this.props;

    const { showBilans, showFormPatient, errorPhone, firstClick, search } = this.state;
    const data =
      patientRecords.data && patientRecords.data.length === 0
        ? patients.data
        : (patientRecords.data && patientRecords.data.length > 0) ||
          (patientRecords.data && patients.data.length === 0)
        ? patientRecords.data
        : last10Patient;
    return (
      <div className="main_content">
        <div className="contact_patients_list">
          <div className="contact_patients_header" style={{ marginLeft: 45 }}>
            <p>Rechercher un patient</p>
            <div style={{ gap: 10 }} className="search_consultation_section">
              <div
                style={{
                  marginRight: 20,
                  width: '70%',
                  border: '1px solid #f1f1f1',
                  borderRadius: 5,
                }}
              >
                <input
                  style={{ width: '100%' }}
                  onChange={this.handleSearchPatient}
                  value={search}
                  type="text"
                  placeholder="Recherche multiple .. Taper"
                />
              </div>
              <div
                style={{
                  marginRight: 20,
                  width: '50%',
                  border: '1px solid #f1f1f1',
                  borderRadius: 5,
                }}
              >
                <input
                  maxLength={8}
                  style={{
                    width: '100%',
                    borderBottom: errorPhone && params.phone ? '1px solid #ff808b' : '',
                  }}
                  onChange={(event) => {
                    this.onChangePhoneParams(event.target.value);
                  }}
                  type="text"
                  value={params.phone}
                  placeholder="Num Tél"
                />
              </div>
              <div
                style={{
                  marginRight: 20,
                  width: '30%',
                  border: '1px solid #f1f1f1',
                  borderRadius: 5,
                }}
              >
                <input
                  style={{ width: '100%' }}
                  id="date"
                  type="date"
                  value={formatDate(params.birthday, FORMAT_DATE_SERVER)}
                  onChange={(e) => {
                    this.props.changeSearchedBirthday({
                      ...params,
                      birthday: formatDate(e.target.value, FORMAT_DATE_SERVER),
                    });
                  }}
                />
              </div>
              <Autocomplete
                style={{ width: '25%', marginTop: '10px' }}
                id="size-small-standard"
                size="small"
                options={sortCities}
                onChange={(event, option) => {
                  this.props.changeSearchedCity({
                    ...params,
                    city: option != null ? option.title : '',
                  });
                }}
                defaultValue={{
                  title: params.city,
                }}
                getOptionLabel={(option) => option.title}
                renderInput={(cities) => (
                  <TextField
                    style={{ width: '100%' }}
                    placeholder="Ville"
                    {...cities}
                    variant="standard"
                    //value={initialValues.address_practitioner && initialValues.address_practitioner.city}
                    //label={<span>Ville <span style={{color: 'red'}}>*</span> </span>}
                  />
                )}
              />
            </div>
          </div>
          {patientRecords.total === 0 && patients.total > 0 ? (
            <div
              className="select_container_item toast_info"
              style={{
                height: '12%',
                marginLeft: '3%',
                width: '95%',
              }}
            >
              <div className="left_section">
                <svg
                  width="20"
                  height="20"
                  viewBox="0 0 20 20"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M10.9023 7.25V5.40234H9.09766V7.25H10.9023ZM10.9023 14.5977V9.09766H9.09766V14.5977H10.9023ZM3.51172 3.55469C5.31641 1.75 7.47917 0.847656 10 0.847656C12.5208 0.847656 14.6693 1.75 16.4453 3.55469C18.25 5.33073 19.1523 7.47917 19.1523 10C19.1523 12.5208 18.25 14.6836 16.4453 16.4883C14.6693 18.2643 12.5208 19.1523 10 19.1523C7.47917 19.1523 5.31641 18.2643 3.51172 16.4883C1.73568 14.6836 0.847656 12.5208 0.847656 10C0.847656 7.47917 1.73568 5.33073 3.51172 3.55469Z"
                    fill="white"
                  />
                </svg>
              </div>
              <div className="right_section">
                <span className="title">Information</span>
                <span className="sub_desc">Nous vous proposons ce(s) patient(s).</span>
              </div>
            </div>
          ) : (
            ''
          )}
          {isLoadingPatientRecords ? (
            <CircularProgress
              disableShrink
              style={{ marginLeft: '45%', marginTop: '30%' }}
            />
          ) : (
            <div
              style={{
                height:
                  patientRecords.total === 0 && patients.total > 0
                    ? 'calc(77% - 160px)'
                    : patientRecords.total === 0 && patients.length === 0
                    ? 'calc(93% - 160px)'
                    : 'calc(99% - 160px)',
              }}
              className="contact_patients_body"
            >
              <div
                style={{ background: '#f8f9fc', cursor: 'default' }}
                className="contact_patients_body_item"
              >
                <div
                  style={{ marginLeft: 63, width: '100%' }}
                  className="patient_contact_info_container"
                >
                  <div style={{ width: '15%' }} className="patient_contact_info">
                    <span id="patient_contact_name">Nom & Prénom</span>
                  </div>
                  <div style={{ width: '15%' }} className="consultation_schedule_text">
                    <span id="patient_contact_name">Téléphone</span>
                  </div>
                  <div style={{ width: '15%' }} className="consultation_schedule_text">
                    <span id="patient_contact_name">Email</span>
                  </div>
                  <div style={{ width: '15%' }} className="consultation_schedule_text">
                    <span id="patient_contact_name">Cnam</span>
                  </div>
                  <div style={{ width: '15%' }} className="consultation_schedule_text">
                    <span id="patient_contact_name">Regime</span>
                  </div>
                </div>
              </div>
              {data && data.length > 0
                ? data.map((item) => {
                    if (item.first_name && item.last_name) {
                      return (
                        <Item_patient
                          key={item.id}
                          showPatient={this.showPatient}
                          firstClick={firstClick}
                          changeSearchedStringConsultation={
                            this.props.changeSearchedStringConsultation
                          }
                          submitAddPatientRecordForm={
                            this.props.submitAddPatientRecordForm
                          }
                          id={item && item.patient_record && item.patient_record.id}
                          //id={data && data.length > 0 && data[0].id}
                          getPatientStatistical={this.props.getPatientStatistical}
                          getPatientRecordById={this.props.getPatientRecordById}
                          getBilanByPatientRecordId={this.props.getBilanByPatientRecordId}
                          getProtocoleByPatientRecordId={
                            this.props.getProtocoleByPatientRecordId
                          }
                          item={item}
                          patientRecord={patientRecord}
                          paramsConsultation={paramsConsultation}
                          paramsBilan={paramsBilan}
                          user={user}
                          params={params}
                          showBilans={showBilans}
                          showFormPatient={showFormPatient}
                        />
                      );
                    }
                  })
                : ''}
            </div>
          )}
        </div>
      </div>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Patients);
