import React, { Component } from 'react';
import FormPec from './containers/form';
import actions from '../consultations/store/actions';
import { connect } from 'react-redux';
import CircularProgress from '@material-ui/core/CircularProgress';
import { isCodeCNAM, isNumeric } from '../../utils/helpres';
import update from 'immutability-helper';
import moment from 'moment';
import SimpleReactValidator from 'simple-react-validator';
import consultationActions from '../consultations/store/actions';
import actionsJs from '../../js/actions';
import { toast } from 'react-toastify';
import ToastAction from '../../components/ToastAction';

const mapStateToProps = (state) => ({
  user: state.AppReducer.user,
  loadingConsultationById: state.ConsultationReducer.loadingConsultationById,
  consultation: state.ConsultationReducer.consultation,
  error: state.ConsultationReducer.error,
  loadingGetDispoDoctor: state.ConsultationReducer.loadingGetDispoDoctor,
  getDispoDate: state.ConsultationReducer.getDispoDate,
  loadingAddSessions: state.ConsultationReducer.loadingAddSessions,
  addSessions: state.ConsultationReducer.addSessions,
  employeeManagement: state.AppReducer.employeeManagement,
  isLoadingHolidayEmployeeManagement: state.AppReducer.isLoadingHolidayEmployeeManagement,
});

const mapDispatchToProps = (dispatch) => ({
  getConsultationById: (id) => dispatch(actions.getConsultationById(id)),
  getDispoDoctor: (values) => dispatch(actions.getDispoDoctor(values)),
  initialState: () => dispatch(actions.initialState()),
  submitFormSession: (values) => {
    dispatch(consultationActions.submitFormSession(values));
  },
  getEmployeeManagement: (type) => dispatch(actionsJs.getEmployeeManagement(type)),
});

class PEC extends Component {
  constructor(props) {
    super(props);
    props.initialState();
    props.getConsultationById(props.match.params.id);
    props.changeActiveItemSideBar(2);
    props.getEmployeeManagement(true);
    props.changeNamePage('Ajouter une prescription médicale');
    document.title = 'Ajouter une prescription médicale';
    this.state = {
      initialValues: {
        days: [
          { day: 'Lundi', checked: false, hour_start: '', hour_end: '' },
          { day: 'Mardi', checked: false, hour_start: '', hour_end: '' },
          { day: 'Mercredi', checked: false, hour_start: '', hour_end: '' },
          { day: 'Jeudi', checked: false, hour_start: '', hour_end: '' },
          { day: 'Vendredi', checked: false, hour_start: '', hour_end: '' },
          { day: 'Samedi', checked: false, hour_start: '', hour_end: '' },
        ],
        getDispo: [],
        practitioner_id:
          props.user.login_access.offices.length === 0 ? props.user.id : null,
        patient_id: null,
        num_order: '',
        year: null,
        code_bureau: null,
        start_date: '',
        end_date: '',
        unit_price: '11.5',
        quality: '',
        hourStart: '',
        regime: '',
        duration: null,
        sessions_per_week: null,
        consultation_id: props.match.params.id,
        sessions_count: null,
        pec: 0,
      },
      errorDate: false,
      hasError: false,
    };
    this.handleCheckedPec = this.handleCheckedPec.bind(this);
    this.onChangeCheckBox = this.onChangeCheckBox.bind(this);
    this.handleChangeInput = this.handleChangeInput.bind(this);
    this.submitFormSession = this.submitFormSession.bind(this);
    this.submitPEC = this.submitPEC.bind(this);
    this.handleNexItem = this.handleNexItem.bind(this);
    this.goBack = this.goBack.bind(this);
    this.onChangeHours = this.onChangeHours.bind(this);
    this.validator = new SimpleReactValidator({
      messages: {
        required: 'Champ requis.',
        integer: 'Champ ne doit contenir que des chiffres',
        string: 'Champ ne doit contenir que des lettres',
        // OR
        default: 'Cette information est requise.', // will override all messages
      },
    });
  }

  onChangeCheckBox(value) {
    const { initialValues } = this.state;
    let days = [...initialValues.days];
    if (initialValues.sessions_per_week) {
      let countDays = initialValues.days.find((item) => item.checked === true);
      if (initialValues.sessions_per_week <= countDays.length) {
        if (initialValues.days.find((item) => item.checked === value)) {
          days = days.filter((item) => item.day !== value);
        } else
          toast(
            ({ closeToast }) => (
              <ToastAction
                type="info"
                message="Attention ! Le nbr des séances pas semaine saisi est inferieur au nbr jours selectionnés."
              />
            ),
            { hideProgressBar: true },
          );
      } else {
        if (days.length > 0) {
          if (days.find((item) => item.day === value))
            days = days.filter((item) => item.day !== value);
          else days.push({ day: value });
        } else days.push({ day: value });
      }
      this.setState({
        initialValues: {
          ...initialValues,
          days,
        },
      });
    } else {
      toast(
        ({ closeToast }) => (
          <ToastAction
            type="info"
            message="Attention ! Vous n'avez pas saisi le nbr des séances pas semaine."
          />
        ),
        { hideProgressBar: true },
      );
    }
  }

  onChangeHours(day, value, name) {
    const { initialValues } = this.state;
    if (initialValues.sessions_per_week) {
      this.setState((prevState) => {
        const data = [...prevState.initialValues.days];
        let countDays = data.filter((item) => item.checked === true);
        var commentIndex = data.findIndex(function (c) {
          return c.day === day;
        });
        let updatedComment = data[commentIndex];
        if (
          countDays &&
          countDays.length > 0 &&
          initialValues.sessions_per_week <= countDays.length
        ) {
          if (data[commentIndex].checked === true)
            updatedComment = update(data[commentIndex], {
              checked: { $set: false },
            });
          else
            toast(
              ({ closeToast }) => (
                <ToastAction
                  type="info"
                  message="Attention ! Le nbr des séances pas semaine saisi est inferieur au nbr jours selectionnés."
                />
              ),
              { hideProgressBar: true },
            );
        } else {
          updatedComment = update(data[commentIndex], {
            checked: { $set: !data[commentIndex].checked },
          });
        }
        if (value && name)
          updatedComment = update(data[commentIndex], {
            [name]: { $set: value },
          });
        data[commentIndex] = updatedComment;
        this.setState({
          initialValues: {
            ...initialValues,
            days: data,
          },
        });
      });
    } else {
      toast(
        ({ closeToast }) => (
          <ToastAction
            type="info"
            message="Attention ! Vous n'avez pas saisi le nbr des séances pas semaine."
          />
        ),
        { hideProgressBar: true },
      );
    }
  }
  async submitFormSession(initialValues, patient) {
    initialValues.end_date =
      initialValues.getDispo[initialValues.getDispo.length - 1].date;
    initialValues.patient_id = patient.id;
    if (!initialValues.pec) initialValues.quality = 'Assuré lui même';
    await this.props.submitFormSession(initialValues);
  }
  submitPEC(initialValues) {
    const { consultation } = this.props;
    if (
      !initialValues.sessions_count ||
      !initialValues.regime ||
      !initialValues.practitioner_id ||
      (initialValues.pec && !initialValues.quality) ||
      (initialValues.pec && !initialValues.num_order) ||
      (initialValues.pec && !initialValues.year) ||
      (initialValues.pec && !initialValues.code_bureau) ||
      /*(initialValues.pec &&
        initialValues.num_order &&
        !isNumeric(initialValues.num_order)) ||*/
      (initialValues.pec &&
        initialValues.code_bureau &&
        !isCodeCNAM(initialValues.code_bureau)) ||
      // (initialValues.pec &&
      //   initialValues.num_order &&
      //   //isNumeric(initialValues.num_order) &&
      //   initialValues.num_order <= 4) ||
      (initialValues.pec && initialValues.year && !isNumeric(initialValues.year)) ||
      (initialValues.pec &&
        initialValues.code_bureau &&
        !isNumeric(initialValues.code_bureau)) ||
      (initialValues.pec &&
        initialValues.code_bureau &&
        isNumeric(initialValues.code_bureau) &&
        initialValues.code_bureau.length !== 2) ||
      /* (initialValues.pec &&
        initialValues.num_order &&
        !isNumeric(initialValues.num_order)) ||*/
      (initialValues.pec &&
        initialValues.year &&
        isNumeric(initialValues.year) &&
        initialValues.year.length !== 4) ||
      (initialValues.sessions_per_week &&
        isNumeric(initialValues.sessions_per_week) &&
        initialValues.sessions_per_week > 6) ||
      (initialValues.duration &&
        isNumeric(initialValues.duration) &&
        initialValues.duration > 300) ||
      (initialValues.sessions_per_week && !isNumeric(initialValues.sessions_per_week)) ||
      (initialValues.sessions_count &&
        isNumeric(initialValues.sessions_count) &&
        initialValues.sessions_count > 36) ||
      (initialValues.sessions_count && !isNumeric(initialValues.sessions_count)) ||
      (initialValues.pec &&
        initialValues.year &&
        isNumeric(initialValues.year) &&
        initialValues.year > moment().format('YYYY'))
    ) {
      this.setState({
        ...initialValues,
        hasError: true,
      });
    } else if (this.validator.allValid()) {
      this.setState({
        ...initialValues,
        hasError: false,
      });
      if (
        initialValues.start_date &&
        moment().isAfter(moment(initialValues.start_date))
      ) {
        toast(
          ({ closeToast }) => (
            <ToastAction
              type="info"
              message="Attention ! Vous avez saisi une date de début dans le passé."
            />
          ),
          { hideProgressBar: true },
        );
      }
      initialValues.patient_id = consultation.patient_record.patient.id;
      this.props.getDispoDoctor(initialValues);
      // this.props.submitFormPEC(initialValues);
    } else {
      this.validator.showMessages();
      this.setState({
        ...initialValues,
        hasError: true,
      });
      // rerender to show messages for the first time
      // you can use the autoForceUpdate option to do this automatically`
      this.forceUpdate();
    }
  }
  handleCheckedPec(pec) {
    const { initialValues } = this.state;
    this.setState({
      initialValues: {
        ...initialValues,
        pec,
      },
    });
  }
  handleChangeInput(event) {
    const { initialValues } = this.state;
    const target = event.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;
    /* if (name === 'regime') {
      if (value === 'act') {
        this.setState({
          initialValues: {
            ...initialValues,
            pec: 1,
            [name]: value,
          },
        });
      } else {
        this.setState({
          initialValues: {
            ...initialValues,
            pec: 0,
            [name]: value,
          },
        });
      }
    } else*/
    this.setState({
      initialValues: {
        ...initialValues,
        [name]: value,
      },
    });
  }
  goBack() {
    this.props.history.goBack();
  }
  componentWillReceiveProps(nextProps, nextContext) {
    const { initialValues } = this.state;
    const { getDispoDate, consultation, addSessions } = nextProps;
    if (getDispoDate && getDispoDate.length > 0) {
      const { patient_record } = consultation;
      const { patient } = patient_record;
      initialValues.patient = patient;
      initialValues.patient_id = patient.id;
      this.props.history.push({
        pathname: '/agenda-prescription',
        state: { getDispoDate: getDispoDate, initialValues: initialValues },
      });
    }
    if (getDispoDate && getDispoDate.length > 0) {
      this.setState({
        initialValues: {
          ...initialValues,
          getDispo: getDispoDate,
          phone:
            consultation.patient_record && consultation.patient_record.patient
              ? consultation.patient_record.patient.phone
              : '',
          end_date: moment(getDispoDate[getDispoDate.length - 1].date).format(
            'YYYY-MM-DD',
          ),
        },
      });
    }
  }
  handlePrevItem(event, id, item) {
    const { initialValues } = this.state;
    this.setState((prevState) => {
      const data = [...prevState.initialValues.getDispo];
      var commentIndex = data.findIndex(function (c) {
        return c.week === id;
      });
      const date =
        initialValues.getDispo[commentIndex].values[
          (initialValues[item] ? initialValues[item] - 1 : 1) %
            initialValues.getDispo[commentIndex].values.length
        ].date;
      const updatedComment = update(data[commentIndex], { date: { $set: date } });
      data[commentIndex] = updatedComment;

      this.setState({
        initialValues: {
          ...initialValues,
          [item]: initialValues[item] ? initialValues[item] - 1 : 1,
          getDispo: data,
        },
      });
    });
  }
  handleNexItem(event, id, item) {
    const { initialValues } = this.state;
    this.setState((prevState) => {
      const data = [...prevState.initialValues.getDispo];
      var commentIndex = data.findIndex(function (c) {
        return c.week === id;
      });
      const date =
        initialValues.getDispo[commentIndex].values[
          (initialValues[item] ? initialValues[item] + 1 : 1) %
            initialValues.getDispo[commentIndex].values.length
        ].date;
      const updatedComment = update(data[commentIndex], { date: { $set: date } });
      data[commentIndex] = updatedComment;

      this.setState({
        initialValues: {
          ...initialValues,
          [item]: initialValues[item] ? initialValues[item] + 1 : 1,
          getDispo: data,
        },
      });
    });
  }

  render() {
    const {
      consultation,
      employeeManagement,
      loadingGetDispoDoctor,
      loadingAddSessions,
      admin,
      user,
    } = this.props;
    const { initialValues, hasError, errorDate } = this.state;
    if (!consultation.id)
      return (
        <CircularProgress disableShrink style={{ marginLeft: '33%', marginTop: '20%' }} />
      );
    const { patient_record } = consultation;
    const { patient } = patient_record;
    let i = 0;
    return (
      <div className="main_content">
        <div className="facture_info_section_form">
          <div className="container_changeable_content">
            <div className="add_facture_section">
              <div className="selected_facture_details">
                <div className="selected_facture_info_container">
                  <div className="selected_facture_img_container">
                    <svg
                      width="14"
                      height="14"
                      viewBox="0 0 14 14"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M3.02734 9.35547C4.41016 8.79297 5.73438 8.51172 7 8.51172C8.26562 8.51172 9.57812 8.79297 10.9375 9.35547C12.3203 9.89453 13.0117 10.6094 13.0117 11.5V13.0117H0.988281V11.5C0.988281 10.6094 1.66797 9.89453 3.02734 9.35547ZM9.10938 6.12109C8.52344 6.70703 7.82031 7 7 7C6.17969 7 5.47656 6.70703 4.89062 6.12109C4.30469 5.53516 4.01172 4.83203 4.01172 4.01172C4.01172 3.19141 4.30469 2.48828 4.89062 1.90234C5.47656 1.29297 6.17969 0.988281 7 0.988281C7.82031 0.988281 8.52344 1.29297 9.10938 1.90234C9.69531 2.48828 9.98828 3.19141 9.98828 4.01172C9.98828 4.83203 9.69531 5.53516 9.10938 6.12109Z"
                        fill="#8083A3"
                      />
                    </svg>
                  </div>
                  <div className="selected_facture_patient_info">
                    <span id="selected_facture_patient_name">
                      {patient ? `${patient.last_name} ${patient.first_name}` : ''}
                    </span>
                    <span id="selected_facture_patient_city">
                      {patient ? patient.phone : ''}
                    </span>
                  </div>
                </div>
              </div>
              <FormPec
                getDispo={initialValues.getDispo}
                initialValues={initialValues}
                patient={patient}
                loadingGetDispoDoctor={loadingGetDispoDoctor}
                errorDate={errorDate}
                admin={admin}
                user={user}
                hasError={hasError}
                employeeManagement={employeeManagement}
                getDispoDoctor={this.props.getDispoDoctor}
                handleChangeInput={this.handleChangeInput}
                handleCheckedPec={this.handleCheckedPec}
                onChangeCheckBox={this.onChangeCheckBox}
                onChangeHours={this.onChangeHours}
                goBack={this.goBack}
                submitPEC={this.submitPEC}
              />
            </div>
          </div>
        </div>
      </div>
    );
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(PEC);
