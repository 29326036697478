import React, { Component } from 'react';
import { codeBureauCNAM } from '../../../utils/constants';
import Modals from '../../../components/modal';
import { Link } from 'react-router-dom';
import InputField from '../../../components/inputField';
import SimpleReactValidator from 'simple-react-validator';
import { formatAmount, formatDate } from '../../../utils/helpres';
import moment from 'moment';

class Facture extends Component {
  constructor(props) {
    super(props);
    this.state = {
      setIsOpen: false,
      setIsOpenSendMail: false,
      hasError: false,
      initialValues: {
        id: props.bill.id,
        email:
          props.bill.patient_record && props.bill.patient_record.patient
            ? props.bill.patient_record.patient.email
            : props.bill &&
              props.bill.consultation &&
              props.bill.consultation.patient_record &&
              props.bill.consultation.patient_record.patient
            ? props.bill.consultation.patient_record.patient.email
            : '',
        file_name: `F°${props.bill.number}.pdf`,
        last_name:
          props.bill.patient_record && props.bill.patient_record.patient
            ? props.bill.patient_record.patient.first_name
            : props.bill &&
              props.bill.consultation &&
              props.bill.consultation.patient_record &&
              props.bill.consultation.patient_record.patient
            ? props.bill.consultation.patient_record.patient.first_name
            : '-',
        number: `F°${props.bill.number}`,
      },
    };
    this.openModal = this.openModal.bind(this);
    this.openModalSendMail = this.openModalSendMail.bind(this);
    this.validator = new SimpleReactValidator({
      messages: {
        email: 'Adresse e-mail invalide',
        required: 'Champ requis.',
        // OR
        default: 'Cette information est requise.', // will override all messages
      },
    });
  }

  openModal() {
    this.setState({
      setIsOpen: !this.state.setIsOpen,
    });
  }
  openModalSendMail() {
    this.setState({
      setIsOpenSendMail: !this.state.setIsOpenSendMail,
    });
  }
  async submitForm(initialValues) {
    if (!initialValues.email)
      this.setState({
        ...initialValues,
        hasError: true,
      });
    else if (this.validator.allValid()) {
      await this.props.submitSendBill(initialValues);
      this.setState({
        setIsOpenSendMail: !this.state.setIsOpenSendMail,
      });
    } else {
      this.validator.showMessages();
      // rerender to show messages for the first time
      // you can use the autoForceUpdate option to do this automatically`
      this.forceUpdate();
    }
  }

  render() {
    const { bill, tag, office, params, type } = this.props;
    const { setIsOpen, setIsOpenSendMail, initialValues, hasError } = this.state;
    return (
      <div className="container_changeable_content">
        <div className="fatcure_detailed_info_section">
          <div className="patient_detailed_info_section_header">
            <div className="patient_info">
              <div className="facture_general_info">
                <span id="facture_general_info_name">{`F°${bill.number}`}</span>
                <span id="facture_general_info_city">
                  {moment(bill.date).format('DD/MM/YYYY')}
                </span>
              </div>
              <div className="facture_general_info_btns">
                <Link
                  // target="_blank"
                  to={{
                    pathname: '/facture-pdf',
                    state: {
                      office: office,
                      facture: bill,
                      tag: tag,
                    },
                  }}
                >
                  <div className="np_btn">
                    <svg
                      width="16"
                      height="14"
                      viewBox="0 0 16 14"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M12.5 0.336914V3.3252H3.5V0.336914H12.5ZM12.7109 6.87598C12.8516 7.0166 13.0273 7.08691 13.2383 7.08691C13.4492 7.08691 13.625 7.0166 13.7656 6.87598C13.9297 6.73535 14.0117 6.55957 14.0117 6.34863C14.0117 6.1377 13.9297 5.96191 13.7656 5.82129C13.625 5.65723 13.4492 5.5752 13.2383 5.5752C13.0273 5.5752 12.8516 5.65723 12.7109 5.82129C12.5703 5.96191 12.5 6.1377 12.5 6.34863C12.5 6.55957 12.5703 6.73535 12.7109 6.87598ZM10.9883 12.3252V8.59863H5.01172V12.3252H10.9883ZM13.2383 4.09863C13.8477 4.09863 14.375 4.32129 14.8203 4.7666C15.2656 5.21191 15.4883 5.73926 15.4883 6.34863V10.8486H12.5V13.8369H3.5V10.8486H0.511719V6.34863C0.511719 5.73926 0.734375 5.21191 1.17969 4.7666C1.625 4.32129 2.15234 4.09863 2.76172 4.09863H13.2383Z"
                        fill="#FAFBFF"
                      />
                    </svg>

                    <span>Imprimer</span>
                  </div>
                </Link>
                {!bill.bordereau_bill &&
                  bill.consultation &&
                  !!bill.consultation.patient_record && (
                    <Link
                      to={{
                        pathname: `/edit-facture/${bill.consultation.id}`,
                        state: {
                          office: office,
                          bill: bill,
                          patient: bill.consultation.patient_record.patient,
                          consultation: bill.consultation,
                          tag: tag,
                        },
                      }}
                      className="more_icon_container"
                    >
                      <i
                        style={{
                          padding: '0',
                          border: '',
                          color: '',
                          borderRadius: '',
                          backgroundColor: 'white',
                        }}
                        className="fas fa-edit"
                        aria-hidden="true"
                      ></i>
                    </Link>
                  )}
                {!bill.bordereau_bill && (
                  <div
                    onClick={() => {
                      this.openModal();
                    }}
                    className="more_icon_container"
                  >
                    <i
                      style={{
                        padding: '0',
                        border: '',
                        color: '',
                        borderRadius: '',
                        backgroundColor: 'white',
                      }}
                      className="fas fa-trash"
                      aria-hidden="true"
                    ></i>
                  </div>
                )}
              </div>
            </div>
          </div>
          <div className="patient_detailed_info_section_body">
            <div className="facture_payment_details">
              <div className="facture_payment_details_container">
                {bill.consultation &&
                bill.consultation.prise_en_charges &&
                bill.regime !== 'autre' &&
                bill.consultation.prise_en_charges.length > 0 ? (
                  <div className="patient_detailed_info_section_body_item patient_info_email">
                    <div className="patient_detail_props">
                      <div className="email_label_container" id="patient_email">
                        <span id="patient_detail_props_label">Doit à</span>
                      </div>

                      <span id="patient_detail_props_text">
                        {(bill.consultation &&
                          bill.consultation.prise_en_charges &&
                          bill.consultation.prise_en_charges.length > 0 &&
                          codeBureauCNAM[
                            bill.consultation.prise_en_charges[0].code_bureau
                          ]) ||
                          '-'}
                      </span>
                    </div>
                  </div>
                ) : (
                  ''
                )}

                <div className="patient_detailed_info_section_body_item patient_info_birthday">
                  <div className="patient_detail_props">
                    <div className="patient_detail_props">
                      <div className="birthday_label_container" id="patient_birthday">
                        <span id="patient_detail_props_label">Patient</span>
                      </div>

                      <span id="patient_detail_props_text">
                        {bill.patient_record && bill.patient_record.patient ? (
                          `${bill.patient_record.patient.first_name} ${bill.patient_record.patient.last_name}`
                        ) : bill.consultation &&
                          bill.consultation.patient_record &&
                          bill.consultation.patient_record.patient ? (
                          `${bill.consultation.patient_record.patient.first_name} ${bill.consultation.patient_record.patient.last_name}`
                        ) : (
                          <strong style={{ color: '#8080803d' }}>
                            Patient non renseignée
                          </strong>
                        )}
                      </span>
                    </div>
                  </div>
                </div>

                {bill.consultation &&
                bill.consultation.prise_en_charges &&
                bill.consultation.prise_en_charges.length > 0 &&
                bill.consultation.prise_en_charges[0].code_bureau ? (
                  <div className="patient_detailed_info_section_body_item patient_info_cnam">
                    <div className="patient_detail_props">
                      <div className="patient_detail_props">
                        <div className="cnam_label_container" id="patient_cnam">
                          <span id="patient_detail_props_label">N° PEC</span>
                        </div>

                        <span id="patient_detail_props_text">
                          {bill.consultation &&
                          bill.consultation.prise_en_charges &&
                          bill.consultation.prise_en_charges.length > 0 &&
                          bill.consultation.prise_en_charges[0].code_bureau ? (
                            `${bill.consultation.prise_en_charges[0].code_bureau}/${bill.consultation.prise_en_charges[0].year}/${bill.consultation.prise_en_charges[0].num_order}`
                          ) : (
                            <strong style={{ color: '#8080803d' }}>
                              PEC non renseignée
                            </strong>
                          )}
                        </span>
                      </div>
                    </div>
                  </div>
                ) : (
                  ''
                )}
                {bill.consultation &&
                bill.consultation.prise_en_charges &&
                bill.consultation.prise_en_charges.length > 0 &&
                bill.consultation.prise_en_charges[0].quality ? (
                  <div className="patient_detailed_info_section_body_item patient_info_cnam">
                    <div className="patient_detail_props">
                      <div className="patient_detail_props">
                        <div className="cnam_label_container" id="patient_cnam">
                          <span id="patient_detail_props_label">Qualité</span>
                        </div>

                        <span id="patient_detail_props_text">
                          {bill.consultation &&
                          bill.consultation.prise_en_charges &&
                          bill.consultation.prise_en_charges.length > 0 &&
                          bill.consultation.prise_en_charges[0].quality ? (
                            bill.consultation.prise_en_charges[0].quality
                          ) : (
                            <strong style={{ color: '#8080803d' }}>
                              Qualité non renseignée
                            </strong>
                          )}
                        </span>
                      </div>
                    </div>
                  </div>
                ) : (
                  ''
                )}
              </div>
              <div style={{ marginRight: '5%' }} className="payment_tag_container">
                <div className="payment_tag">
                  {
                    //7ordereau
                    bill.bordereau_bill ? (
                      <button
                        style={{ width: '150px' }}
                        className={
                          bill.bordereau_bill.bordereau.status === 'invoiced'
                            ? 'payed'
                            : ''
                        }
                      >
                        {bill.bordereau_bill.bordereau.status === 'invoiced'
                          ? `B ${bill.bordereau_bill.bordereau.number} - Facturée`
                          : `B ${bill.bordereau_bill.bordereau.number} - Payée`}
                      </button>
                    ) : (
                      <button style={{ width: '100px' }}>Non attachée</button>
                    )
                  }
                </div>
              </div>
            </div>

            <hr
              style={{
                marginLeft: '150px',
                marginRight: '50px',
                marginTop: '20px',
                marginBottom: '20px',
                width: '73%',
              }}
            />

            <div className="payment_checkout">
              {/*  <div className="code_facture">
                <span>{bill.line_bill.matricule || '-'}</span>
              </div>*/}
              <div className="event">
                <span>{bill.line_bill.act || '-'}</span>
              </div>
              <div className="date">
                <span>
                  {bill.start_date ? (
                    ` Du ${formatDate(bill.start_date)}`
                  ) : (
                    <strong style={{ color: 'rgba(0,0,0,0.24)' }}>
                      Date début non renseignée
                    </strong>
                  )}
                </span>
                <span>
                  {bill.end_date ? (
                    ` Au ${formatDate(bill.end_date)}`
                  ) : (
                    <strong style={{ color: 'rgba(0,0,0,0.24)' }}>
                      Date fin non renseignée
                    </strong>
                  )}
                </span>
              </div>
              <div className="seance">
                <span>{bill.line_bill.sessions_nbr || '-'} séances</span>
              </div>
              <div className="prix" style={{ marginLeft: '13%' }}>
                <span>{formatAmount(bill.price_ttc) || '-'} DT</span>
              </div>
            </div>
          </div>
        </div>
        <Modals
          setIsOpen={setIsOpen}
          openModal={this.openModal}
          data={
            <>
              <div className="modal-header" style={{ marginTop: '-30px' }}>
                <button
                  type="button"
                  className="close"
                  data-dismiss="modal"
                  aria-label="Close"
                  onClick={() => this.openModal()}
                  style={{ marginTop: '1%' }}
                >
                  <span
                    aria-hidden="true"
                    style={{ marginRight: '5px', color: '#1A1C1D' }}
                  >
                    &times;
                  </span>
                </button>
              </div>
              <div className="modal-body">
                <h2>Facture F° {bill.number}!</h2>
                <span>Voulez-vous vraiment supprimer cette Facture ?</span>
              </div>
              <div className="modal-footer">
                <button
                  onClick={() => this.openModal()}
                  type="button"
                  className="modal_close_btn"
                  // data-dismiss="modal"
                  style={{ backgroundColor: '#f6f6f6' }}
                >
                  Non
                </button>
                <button
                  onClick={() => {
                    this.props.deleteFacture(bill.id, params, tag, type);
                    this.openModal();
                    window.location.href = '/module-cnam';
                  }}
                  type="button"
                  className="modal_disconnect_btn"
                >
                  Oui
                </button>
              </div>
            </>
          }
        />
        <Modals
          setIsOpen={setIsOpenSendMail}
          openModal={this.openModalSendMail}
          data={
            <>
              <div
                className="modal-header"
                style={{
                  marginTop: '-15px',
                  marginBottom: '-5px',
                }}
              >
                <h5 className="modal-title" id="exampleModalLabel">
                  Envoyer e-mail
                </h5>

                <button
                  type="button"
                  className="close"
                  data-dismiss="modal"
                  aria-label="Close"
                  onClick={() => this.openModalSendMail()}
                  style={{ marginTop: '0' }}
                >
                  <span
                    aria-hidden="true"
                    style={{ marginRight: '14px', color: '#1A1C1D' }}
                  >
                    &times;
                  </span>
                </button>
              </div>
              <div className="modal-body">
                <form
                  style={{
                    width: '100%',
                  }}
                >
                  <div
                    className="form-row"
                    style={{
                      display: 'flex',
                      gap: '30px',
                      margin: '20px 0px',
                    }}
                  >
                    <InputField
                      width="46.5%"
                      type="text"
                      placeholder="Adresse e-mail"
                      className=""
                      validInput="string"
                      label="Adresse e-mail"
                      hasError={hasError}
                      value={initialValues.email}
                      name="first_name"
                      icon={<i className="fas fa-envelope"></i>}
                      required={this.validator.message(
                        'email',
                        initialValues.email,
                        'required|email',
                      )}
                      onChange={(e) => {
                        this.setState({
                          initialValues: {
                            ...initialValues,
                            email: e.target.value,
                          },
                        });
                      }}
                    />
                  </div>
                </form>
              </div>
              <div
                className="modal-footer"
                style={{
                  display: 'flex',
                  justifyContent: 'flex-end',
                }}
              >
                <button
                  onClick={async () => {
                    this.submitForm(initialValues);
                  }}
                  type="button"
                  className="modal_disconnect_btn"
                >
                  Envoyer
                </button>
              </div>
            </>
          }
        />
      </div>
    );
  }
}

export default Facture;
