import React, { Component } from 'react';
import actionsRecette from '../recette/store/actions';
import actionsDepense from '../depense/store/actions';
import { connect } from 'react-redux';
import CircularProgress from '@material-ui/core/CircularProgress';
import { SwipeableDrawer } from '@material-ui/core';
import moment from 'moment';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import AppBar from '@material-ui/core/AppBar';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import { Link } from 'react-router-dom';
import { formatDate } from '../../utils/helpres';

const mapStateToProps = (state) => ({
  recettes: state.RecetteReducer.recettes,
  isLoadingRecettes: state.RecetteReducer.isLoadingRecettes,
  depenses: state.DepenseReducer.depenses,
  isLoadingDepenses: state.DepenseReducer.isLoadingDepenses,
  paramsDepense: state.DepenseReducer.params,
  paramsRecette: state.RecetteReducer.params,
});

const mapDispatchToProps = (dispatch) => ({
  getAllRecettes: (params) => dispatch(actionsRecette.getAllRecettes(params)),
  getAllDepenses: (params) => dispatch(actionsDepense.getAllDepenses(params)),
  changeDepenseSearchedEndDate: async (params) => {
    await dispatch(actionsDepense.changeSearchedStringDepenses(params));
    dispatch(actionsDepense.getAllDepenses(params));
  },
  changeDepenseSearchedStartDate: async (params) => {
    await dispatch(actionsDepense.changeSearchedStringDepenses(params));
    dispatch(actionsDepense.getAllDepenses(params));
  },
  changeRecetteSearchedEndDate: async (params) => {
    await dispatch(actionsRecette.changeSearchedStringRecettes(params));
    dispatch(actionsRecette.getAllRecettes(params));
  },
  changeRecetteSearchedStartDate: async (params) => {
    await dispatch(actionsRecette.changeSearchedStringRecettes(params));
    dispatch(actionsRecette.getAllRecettes(params));
  },
});
class DrawerGetRecetteDepense extends Component {
  constructor(props) {
    super(props);
    this.state = {
      value: 0,
      errorDate: false,
    };
  }
  a11yProps(index) {
    return {
      id: `simple-tab-${index}`,
      'aria-controls': `simple-tabpanel-${index}`,
    };
  }

  handleChange = (event, value) => {
    this.setState({
      value,
    });
  };
  componentDidMount() {
    this.props.changeRecetteSearchedEndDate({
      ...this.props.paramsRecette,
      start_date: formatDate(moment()),
      end_date: formatDate(moment()),
      search: '',
      status: '',
    });
    this.props.changeDepenseSearchedStartDate({
      ...this.props.paramsDepense,
      start_date: formatDate(moment()),
      end_date: formatDate(moment()),
      search: '',
      status: '',
    });
  }
  render() {
    const {
      recettes,
      office,
      isLoadingDepenses,
      depenses,
      paramsRecette,
      isLoadingRecettes,
      onCancel,
      activeDrawer,
      admin,
    } = this.props;
    const { value, errorDate } = this.state;
    return (
      <SwipeableDrawer
        style={{ width: '50%' }}
        anchor="right"
        open={activeDrawer}
        onClose={this.props.toggleDrawer('right', false)}
        onOpen={this.props.toggleDrawer('right', true)}
      >
        {isLoadingRecettes || isLoadingDepenses ? (
          <CircularProgress
            disableShrink
            style={{ marginLeft: '50%', marginTop: '22%' }}
          />
        ) : (
          <div className="right_side_bar">
            <div className="right_side_bar_header">
              <span id="add_app_sidebar_title">Recettes et Dépenses </span>
              <svg
                onClick={onCancel}
                style={{ cursor: 'pointer' }}
                id="close-right-sidebar"
                width="40"
                height="40"
                viewBox="0 0 40 40"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <rect
                  x="0.5"
                  y="0.5"
                  width="39"
                  height="39"
                  rx="9.5"
                  fill="white"
                  stroke="#ECEEF5"
                />
                <path
                  d="M25.2383 15.8164L21.0547 20L25.2383 24.1836L24.1836 25.2383L20 21.0547L15.8164 25.2383L14.7617 24.1836L18.9453 20L14.7617 15.8164L15.8164 14.7617L20 18.9453L24.1836 14.7617L25.2383 15.8164Z"
                  fill="#8083A3"
                />
              </svg>
            </div>
            <div className="right_side_bar_body">
              <div className="facture_patient_header" style={{ display: 'inline' }}>
                <p>Rechercher une recette</p>
                {admin ? (
                  <input
                    style={{
                      width: '20%',
                      margin: '0px 20px',
                      borderBottom: errorDate ? '1px solid #ff808b' : '',
                    }}
                    id="date"
                    type="date"
                    value={formatDate(paramsRecette.start_date, 'YYYY-MM-DD')}
                    onChange={(e) => {
                      this.props.changeDepenseSearchedStartDate({
                        ...paramsRecette,
                        start_date: formatDate(e.target.value, 'YYYY-MM-DD'),
                      });
                      this.props.changeRecetteSearchedStartDate({
                        ...paramsRecette,
                        start_date: formatDate(e.target.value, 'YYYY-MM-DD'),
                      });
                    }}
                  />
                ) : (
                  ''
                )}
                {admin ? (
                  <input
                    style={{
                      width: '20%',
                      margin: '0px 20px',
                      borderBottom: errorDate ? '1px solid #ff808b' : '',
                    }}
                    id="date"
                    type="date"
                    value={formatDate(paramsRecette.end_date)}
                    onChange={(e) => {
                      this.props.changeDepenseSearchedStartDate({
                        ...paramsRecette,
                        end_date: formatDate(e.target.value, 'YYYY-MM-DD'),
                      });
                      this.props.changeRecetteSearchedStartDate({
                        ...paramsRecette,
                        end_date: formatDate(e.target.value, 'YYYY-MM-DD'),
                      });
                    }}
                  />
                ) : (
                  ''
                )}
                {(recettes && recettes.length > 0) ||
                (depenses && depenses.length > 0) ? (
                  <Link
                    to={{
                      pathname: '/recette-depense-pdf',
                      state: { paramsRecette, office, recettes, depenses },
                    }}
                  >
                    <i className="fas fa-download" aria-hidden="true"></i>
                  </Link>
                ) : (
                  ''
                )}
              </div>
              <AppBar position="static">
                <Tabs
                  value={value}
                  onChange={this.handleChange}
                  aria-label="simple tabs example"
                >
                  <Tab label="Recette " {...this.a11yProps(0)} />
                  <Tab label="Depense " {...this.a11yProps(1)} />
                </Tabs>
              </AppBar>
              <TabPanel value={value} index={0}>
                <div className="modal_body_recap_details">
                  {recettes && recettes.length > 0 ? (
                    recettes.map((item) => {
                      return (
                        <div
                          key={item.id}
                          className="rendez_vous_list_body_item_container"
                          style={{
                            border: '1px solid grey',
                            marginBottom: '5px',
                          }}
                        >
                          <div className="rendez_vous_list_body_item">
                            <div
                              className="rv_list_body_sub_item"
                              id="time_container_indis"
                            >
                              <span id="start_time">{formatDate(item.payment_date)}</span>
                            </div>
                            <div className="rv_list_body_sub_item rv_details">
                              <div className="rv_text_details" style={{ width: 'auto' }}>
                                <div
                                  className="patient_consultation_text"
                                  style={{ width: 'inherit' }}
                                >
                                  <span id="type_consultation">{item.label}</span>
                                  <span id="patient_name">{item.note}</span>
                                </div>
                              </div>
                            </div>
                            <div
                              className="rv_list_body_sub_item rv_details"
                              style={{ width: '30%' }}
                            >
                              <div className="rv_text_details">
                                <div
                                  className="patient_consultation_text"
                                  style={{ width: 'inherit' }}
                                >
                                  <span id="type_consultation">{item.price} Dt</span>
                                  <span id="patient_name">{item.payment_mode}</span>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      );
                    })
                  ) : (
                    <div className="consultation_detailed_info_section">
                      <div className="empty_consultation_result_container">
                        <div className="empty_consultation_result">
                          <img
                            src={require('../../assets/img/undraw_design_team_af2y.svg')}
                          />
                          <span id="no_consultation">Liste vide !</span>
                          <span id="no_consultation_desc">
                            Aucune recette n'est enregistrée. Déclarez les recettes dès
                            maintenant.
                          </span>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              </TabPanel>
              <TabPanel value={value} index={1}>
                <div className="modal_body_recap_details">
                  {depenses && depenses.length > 0 ? (
                    depenses.map((item) => {
                      return (
                        <div
                          key={item.id}
                          className="rendez_vous_list_body_item_container"
                          style={{
                            border: '1px solid grey',
                            marginBottom: '5px',
                          }}
                        >
                          <div className="rendez_vous_list_body_item">
                            <div
                              className="rv_list_body_sub_item"
                              id="time_container_indis"
                            >
                              <span id="start_time">{formatDate(item.payment_date)}</span>
                            </div>
                            <div className="rv_list_body_sub_item rv_details">
                              <div className="rv_text_details">
                                <div
                                  className="patient_consultation_text"
                                  style={{ width: 'inherit' }}
                                >
                                  <span id="type_consultation">{item.label}</span>
                                  <span id="patient_name">{item.note}</span>
                                </div>
                              </div>
                            </div>
                            <div
                              className="rv_list_body_sub_item rv_details"
                              style={{ width: '30%' }}
                            >
                              <div className="rv_text_details">
                                <div
                                  className="patient_consultation_text"
                                  style={{ width: 'inherit' }}
                                >
                                  <span id="type_consultation">{item.price} Dt</span>
                                  <span id="patient_name">{item.payment_mode}</span>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      );
                    })
                  ) : (
                    <div className="consultation_detailed_info_section">
                      <div className="empty_consultation_result_container">
                        <div className="empty_consultation_result">
                          <img
                            src={require('../../assets/img/undraw_design_team_af2y.svg')}
                          />
                          <span id="no_consultation">Liste vide !</span>
                          <span id="no_consultation_desc">
                            Aucune dépense n'a été enregistrée aujourd'hui. Essayer d'en
                            ajouter une.
                          </span>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              </TabPanel>
            </div>
          </div>
        )}
      </SwipeableDrawer>
    );
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(DrawerGetRecetteDepense);
function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}
