import React, { useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { connect } from 'react-redux';
import Grid from '@material-ui/core/Grid';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Checkbox from '@material-ui/core/Checkbox';
import Button from '@material-ui/core/Button';
import Paper from '@material-ui/core/Paper';
import moment from 'moment';
import Modals from '../../../components/modal';
import actions from './../store/actions';
import actionsM from '../../messenger/store/actions';
import actionsJs from '../../../js/actions';
import { Link } from 'react-router-dom';
import { formatDate } from '../../../utils/helpres';
import { FORMAT_DATE } from '../../../utils/constants';

const useStyles = makeStyles((theme) => ({
  root: {
    margin: 'auto',
  },
  paper: {
    width: 200,
    height: 230,
    overflow: 'auto',
  },
  button: {
    margin: theme.spacing(0.5, 0),
  },
}));

function not(a, b) {
  return a.filter((value) => b.indexOf(value) === -1);
}

function intersection(a, b) {
  return a.filter((value) => b.indexOf(value) !== -1);
}
function selectedId(array1) {
  let array2 = [];
  array1.map((item) => {
    array2.push(item);
  });
  return array2;
}

const mapStateToProps = (state) => ({
  getAllRDV: state.AgendaReducer.getAllRDV,
  isLoadingGetIsDispo: state.AgendaReducer.getAllRDV,
  getIsDispo: state.AgendaReducer.getIsDispo,
  params: state.AgendaReducer.params,
  isLoadingAddRdv: state.AgendaReducer.isLoadingAddRdv,
  paramsDoctor: state.MessengerReducer.params,
  employeeManagement: state.AppReducer.employeeManagement,
  isLoadingHolidayEmployeeManagement: state.AppReducer.isLoadingHolidayEmployeeManagement,
});

const mapDispatchToProps = (dispatch) => ({
  AffectRdv: (values) => dispatch(actions.AffectRdv(values)),
  getAllRdv: (params, id) => dispatch(actions.getAllRdvByDay(params, id)),
  getAllDoctors: (params) => dispatch(actionsM.getAllDoctors(params)),
  getDoctorIsDispo: (values) => dispatch(actions.getDoctorIsDispo(values)),
  getEmployeeManagement: (type) => dispatch(actionsJs.getEmployeeManagement(type)),
  //submitRDVForm: (values) => dispatch(actions.submitRDVForm(values)),
  submitRDVForm: async (params, values, id) => {
    await dispatch(actions.submitRDVForm(values));
    setTimeout(() => {
      dispatch(actions.getAllRdvByDay(params, id));
    }, 1000);
  },
});

const TransferList = (props) => {
  const {
    employeeManagement,
    getAllRDV,
    AffectRdv,
    getIsDispo,
    getDoctorIsDispo,
    submitRDVForm,
  } = props;
  const classes = useStyles();
  const [checked, setChecked] = React.useState([]);
  const [rdvsLeft, setRdvsLeft] = React.useState([]);
  const [rdvsRight, setRdvsRight] = React.useState([]);
  const [isOpen, setIsOpen] = React.useState(false);
  const [isOpenVerify, setIsOpenVerify] = React.useState(false);
  const [typeTransfert, setTypeTransfert] = React.useState('all');
  const [dateLeft, setDateLeft] = React.useState(null);
  const [dateRight, setDateRight] = React.useState(null);
  const [doctorRight, setDoctorRight] = React.useState(null);
  const [doctorLeft, setDoctorLeft] = React.useState(null);
  const [rdvsSelected, setRdvsSelected] = React.useState([]);
  const leftChecked = intersection(checked, rdvsLeft);
  const rightChecked = intersection(checked, rdvsRight);
  const [actionRight, setActionRight] = React.useState(false);
  const [isDispo, setIsDispo] = React.useState(getIsDispo || false);
  const [actionLeft, setActionLeft] = React.useState(false);
  const [isVerified, setIsVerified] = React.useState(true);
  const [isChecked, setIsChecked] = React.useState(false);
  const [isAll, setIsAll] = React.useState(false);
  const [isOpenUpdateRdv, setIsOpenUpdateRdv] = React.useState(false);
  const [initialValues, setInitialValues] = React.useState({});
  const [hasError, setHasError] = React.useState(false);

  const openModal = (type) => {
    setIsOpen(!isOpen);
    setTypeTransfert(type);
  };
  const openModalVerify = () => {
    setIsOpenVerify(!isOpenVerify);
  };
  const handleToggle = (value) => () => {
    const currentIndex = checked.indexOf(value);
    const newChecked = [...checked];
    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }
    setChecked(newChecked);
  };

  const handleAllRight = () => {
    setActionLeft(false);
    setActionRight(false);
    getDoctorIsDispo({
      id: doctorRight,
      rdvs: rdvsLeft,
    });
    setIsAll(true);
  };

  const handleCheckedRight = () => {
    setActionLeft(false);
    setActionRight(false);
    getDoctorIsDispo({
      id: doctorRight,
      rdvs: leftChecked,
    });
    setIsChecked(true);
  };

  const handleCheckedLeft = () => {
    setActionLeft(false);
    setActionRight(false);
    setRdvsSelected(not(rightChecked, rdvsSelected));
    setRdvsLeft(rdvsLeft.concat(rightChecked));
    setRdvsRight(not(rdvsRight, rightChecked));
    setChecked(not(checked, rightChecked));
  };

  const handleAllLeft = () => {
    setActionLeft(false);
    setActionRight(false);
    setRdvsSelected(not(rdvsRight, rdvsSelected));
    setRdvsLeft(rdvsLeft.concat(rdvsRight));
    setRdvsRight([]);
  };
  useEffect(() => {
    setIsDispo(getIsDispo);
    if (actionLeft) {
      setRdvsLeft(getAllRDV);
    }
    if (actionRight) {
      setRdvsRight(getAllRDV);
    }
    if (isDispo === true && isAll) {
      setRdvsSelected(selectedId(rdvsLeft));
      setRdvsRight(rdvsRight.concat(rdvsLeft));
      setRdvsLeft([]);
      setIsVerified(false);
      setIsDispo(false);
      setIsAll(false);
    }
    if (isDispo && isChecked) {
      setRdvsSelected(selectedId(leftChecked));
      setRdvsRight(rdvsRight.concat(leftChecked));
      setRdvsLeft(not(rdvsLeft, leftChecked));
      setChecked(not(checked, leftChecked));
      setIsVerified(false);
      setIsDispo(false);
      setIsChecked(false);
    }
  }, [rdvsSelected, rdvsLeft, rdvsRight, leftChecked, isVerified]);

  const openModalUpdateModal = () => {
    setIsOpenUpdateRdv(!isOpenUpdateRdv);
  };
  const customList = (items, pos) => (
    <Paper className={classes.paper}>
      <List dense component="div" role="list">
        {items.map((value) => {
          const labelId = `transfer-list-item-${value.id}-label`;
          if (value.status === 'actif')
            return (
              <div style={{ display: 'flex' }}>
                <ListItem
                  key={value.id}
                  role="listitem"
                  button
                  onClick={handleToggle(value)}
                >
                  <ListItemIcon>
                    <Checkbox
                      checked={checked.indexOf(value) !== -1}
                      tabIndex={-1}
                      disableRipple
                      inputProps={{ 'aria-labelledby': labelId }}
                    />
                  </ListItemIcon>
                  <ListItemText
                    id={labelId}
                    primary={
                      <div
                        style={{ marginBottom: '10px' }}
                        key={value.id}
                        className="rendez_vous_list_body_item_container"
                      >
                        <div className="rendez_vous_list_body_item">
                          <div style={{ display: 'contents' }}>
                            <div
                              className="rv_list_body_sub_item"
                              id="time_container_indis"
                            >
                              <i className="far fa-clock"></i>
                              <span id="start_time">
                                {' '}
                                {moment(value.date_from).format('HH:mm')}
                              </span>
                              <span id="end_time">
                                {' '}
                                {moment(value.date_to).format('HH:mm')}
                              </span>
                            </div>
                            <div
                              style={{ marginLeft: '20%' }}
                              className="rv_list_body_sub_item rv_details"
                            >
                              <div className="rv_text_details">
                                <div className="patient_consultation_text">
                                  <span id="type_consultation">{value.display_name}</span>
                                  <span
                                    style={{ color: value.motif.color }}
                                    id="patient_name"
                                  >
                                    {value.motif && value.motif.label === 'Autre'
                                      ? value.diagnostic
                                      : value.motif.label}
                                  </span>
                                </div>
                              </div>
                            </div>
                          </div>
                          {/*{pos === 'right' ? (
                          
                        ) : (
                          ''
                        )}*/}
                        </div>
                      </div>
                    }
                  />
                </ListItem>
                {pos === 'left' && doctorRight && dateRight ? (
                  <div
                    onClick={() => {
                      setIsOpenUpdateRdv(true);
                      setInitialValues({
                        id: value.id,
                        date: formatDate(dateLeft, 'YYYY-MM-DD'),
                        date_from: formatDate(value.date_from, 'YYYY-MM-DD'),
                        date_to: formatDate(value.date_to, 'YYYY-MM-DD'),
                        startHours: moment(value.date_from).format('HH:mm'),
                        endHours: moment(value.date_to).format('HH:mm'),
                      });
                    }}
                    className="rv_more_details"
                  >
                    <div className="expanded_more_details">
                      <i
                        title="Modifier rdv"
                        id="expanded_more_details_delete"
                        className="fas fa-edit"
                      ></i>
                    </div>
                  </div>
                ) : (
                  ''
                )}
              </div>
            );
        })}
        <ListItem />
      </List>
    </Paper>
  );
  return (
    <div
      className="main_content"
      style={{
        overflow: 'hidden',
        width: '101.5%',
      }}
    >
      <Modals
        setIsOpen={isOpenUpdateRdv}
        openModal={openModalUpdateModal}
        data={
          <>
            <div
              className="modal-header"
              style={{ height: '100%', marginTop: '-22px', marginBottom: '1%' }}
            >
              <span style={{ marginLeft: '1%' }}>Détails rendez-vous</span>
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
                onClick={() => openModalUpdateModal()}
                style={{ margin: '0' }}
              >
                <span aria-hidden="true" style={{ color: '#1A1C1D' }}>
                  &times;
                </span>
              </button>
            </div>
            <div className="modal-body">
              <div className="modal_body_section_1">
                <div className="modal_body_section_1_left">
                  <div
                    style={{
                      width: '65px',
                      height: '65px',
                    }}
                    className="patient_contact_img_container"
                  >
                    <svg
                      width="14"
                      height="14"
                      viewBox="0 0 14 14"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M3.02734 9.35547C4.41016 8.79297 5.73438 8.51172 7 8.51172C8.26562 8.51172 9.57812 8.79297 10.9375 9.35547C12.3203 9.89453 13.0117 10.6094 13.0117 11.5V13.0117H0.988281V11.5C0.988281 10.6094 1.66797 9.89453 3.02734 9.35547ZM9.10938 6.12109C8.52344 6.70703 7.82031 7 7 7C6.17969 7 5.47656 6.70703 4.89062 6.12109C4.30469 5.53516 4.01172 4.83203 4.01172 4.01172C4.01172 3.19141 4.30469 2.48828 4.89062 1.90234C5.47656 1.29297 6.17969 0.988281 7 0.988281C7.82031 0.988281 8.52344 1.29297 9.10938 1.90234C9.69531 2.48828 9.98828 3.19141 9.98828 4.01172C9.98828 4.83203 9.69531 5.53516 9.10938 6.12109Z"
                        fill="#8083A3"
                      />
                    </svg>
                  </div>
                </div>
              </div>
              <br />
              <div className="modal_body_recap_details">
                <div
                  className="modal_body_recap_details_item"
                  style={{ height: '145px' }}
                >
                  <div className="modal_body_recap_details_item_left">
                    <span>Heure</span>
                  </div>
                  <div className="modal_body_recap_details_item_right">
                    <div
                      className="creneau_container"
                      style={{ width: '80%', maxHeight: '125px', overflow: 'auto' }}
                    >
                      <div className="right_sidebar_body_item">
                        <span className="creneau_container_title">Heure début</span>
                        <div
                          className={
                            hasError &&
                            (!initialValues.startHours ||
                              moment(
                                moment(
                                  `${initialValues.date} ${initialValues.startHours}`,
                                ).format(`YYYY-MM-DD HH:mm:ss`),
                              ).isAfter(
                                moment(
                                  `${initialValues.date} ${initialValues.endHours}`,
                                ).format(`YYYY-MM-DD HH:mm:ss`),
                              ))
                              ? 'creneau_container type3'
                              : 'creneau_container'
                          }
                          style={{ maxHeight: '300px', overflow: 'auto' }}
                        >
                          <div className="input_and_icon" style={{ width: '100%' }}>
                            <input
                              style={{ width: '100%' }}
                              value={initialValues.startHours}
                              name="startHours"
                              onChange={(e) =>
                                setInitialValues({
                                  ...initialValues,
                                  startHours: e.target.value,
                                })
                              }
                              className="form-control"
                              type="time"
                              placeholder="John Doe"
                            />
                          </div>
                        </div>
                      </div>
                      <div className="right_sidebar_body_item">
                        <span className="creneau_container_title">Heure fin</span>
                        <div
                          className={
                            hasError &&
                            (!initialValues.endHours ||
                              moment(
                                moment(
                                  `${initialValues.date} ${initialValues.startHours}`,
                                ).format(`YYYY-MM-DD HH:mm:ss`),
                              ).isAfter(
                                moment(
                                  `${initialValues.date} ${initialValues.endHours}`,
                                ).format(`YYYY-MM-DD HH:mm:ss`),
                              ))
                              ? 'creneau_container type3'
                              : 'creneau_container'
                          }
                          style={{ maxHeight: '300px', overflow: 'auto' }}
                        >
                          <div style={{ width: '100%' }} className="input_and_icon">
                            <input
                              style={{ width: '100%' }}
                              value={initialValues.endHours}
                              name="endHours"
                              onChange={(e) =>
                                setInitialValues({
                                  ...initialValues,
                                  endHours: e.target.value,
                                })
                              }
                              className="form-control"
                              type="time"
                              placeholder="John Doe"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="footer_buttons">
                <button
                  onClick={async () => {
                    if (
                      !initialValues.endHours ||
                      moment(
                        moment(
                          `${initialValues.date_from} ${initialValues.startHours}`,
                        ).format('YYYY-MM-DD HH:mm:ss'),
                      ).isAfter(
                        moment(
                          `${initialValues.date_to} ${initialValues.endHours}`,
                        ).format('YYYY-MM-DD HH:mm:ss'),
                      )
                    ) {
                      setHasError(true);
                    } else {
                      setActionRight(false);
                      setActionLeft(true);
                      submitRDVForm(
                        {
                          status: null,
                          date: formatDate(dateLeft, 'YYYY-MM-DD'),
                        },
                        initialValues,
                        doctorLeft,
                      );
                      setIsOpenUpdateRdv(false);
                    }
                  }}
                  className="btn_valid"
                >
                  Valider
                </button>
              </div>
            </div>
          </>
        }
      />
      <Modals
        setIsOpen={isOpenVerify}
        openModal={openModalVerify}
        data={
          <>
            <div
              className="modal-header"
              style={{
                borderBottom: '1px solid #dee2e6',
                padding: '1.5rem 0rem',
                margin: '0px 20px',
                alignItems: 'baseline',
                marginLeft: '3%',
                marginTop: '-30px',
                width: '100%',
              }}
            >
              <span>liste des rendez-vous transferés</span>
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
                onClick={() => openModalVerify()}
              >
                <span
                  aria-hidden="true"
                  style={{ marginRight: '30px', color: '#1A1C1D' }}
                >
                  &times;
                </span>
              </button>
            </div>
            <hr style={{ width: '85%' }} />
            <div
              style={{
                height: '400px',
                overflowY: 'auto',
                overflowX: 'hidden',
              }}
              className="modal-body"
            >
              {rdvsRight.map((value) => {
                if (value.status)
                  return (
                    <div
                      style={{ marginBottom: '10px' }}
                      key={value.id}
                      className="rendez_vous_list_body_item_container"
                    >
                      <div className="rendez_vous_list_body_item">
                        <div style={{ display: 'contents' }}>
                          <div
                            className="rv_list_body_sub_item"
                            id="time_container_indis"
                          >
                            <i className="far fa-clock"></i>
                            <span id="start_time">
                              {' '}
                              {moment(value.date_from).format('HH:mm')}
                            </span>
                            <span id="end_time">
                              {' '}
                              {moment(value.date_to).format('HH:mm')}
                            </span>
                          </div>
                          <div className="rv_list_body_sub_item rv_details">
                            <div className="rv_text_details">
                              <div className="patient_consultation_text">
                                <span id="type_consultation">{value.display_name}</span>
                                <span
                                  style={{ color: value.motif.color }}
                                  id="patient_name"
                                >
                                  {value.motif && value.motif.label === 'Autre'
                                    ? value.diagnostic
                                    : value.motif.label}
                                </span>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="rv_more_details">
                          <div className="expanded_more_details">
                            {value.actif ? (
                              <i
                                style={{ color: 'green' }}
                                title="Modifier rdv"
                                id="expanded_more_details_delete"
                                className="fas fa-check"
                              ></i>
                            ) : (
                              'X'
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  );
              })}
            </div>
            <hr style={{ width: '95%' }} />
            <div className="modal-footer">
              <button
                onClick={() => openModalVerify()}
                type="button"
                className="modal_close_btn"
                style={{ backgroundColor: '#f6f6f6' }}
              >
                Modifier
              </button>
              <button
                onClick={() => {
                  if (typeTransfert === 'all') handleAllRight();
                  else handleCheckedRight();
                  openModalVerify();
                }}
                type="button"
                className="modal_disconnect_btn"
              >
                Valider
              </button>
            </div>
          </>
        }
      />
      <Modals
        setIsOpen={isOpen}
        openModal={openModal}
        data={
          <>
            <div
              className="modal-header"
              style={{
                borderBottom: '1px solid #dee2e6',
                padding: '1.5rem 0rem',
                margin: '0px 20px',
                alignItems: 'baseline',
                marginLeft: '3%',
                marginTop: '-30px',
                width: '100%',
              }}
            >
              <span>Transfert de planning</span>
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
                onClick={() => openModal()}
              >
                <span
                  aria-hidden="true"
                  style={{ marginRight: '30px', color: '#1A1C1D' }}
                >
                  &times;
                </span>
              </button>
            </div>
            <hr style={{ width: '85%' }} />
            <div
              style={{
                height: '200px',
                width: '470px',
              }}
              className="modal-body"
            >
              <p>
                Voulez-vous vraiment confirmer les modifications faites suite à ce
                transfert de planning ?
              </p>
            </div>
            <hr style={{ width: '95%' }} />
            <div className="modal-footer">
              <button
                onClick={() => openModal()}
                type="button"
                className="modal_close_btn"
                // data-dismiss="modal"
                style={{ backgroundColor: '#f6f6f6' }}
              >
                Annuler
              </button>
              <button
                onClick={() => {
                  if (typeTransfert === 'all') handleAllRight();
                  else handleCheckedRight();
                  openModal();
                }}
                type="button"
                className="modal_disconnect_btn"
              >
                Transferer
              </button>
            </div>
          </>
        }
      />
      {employeeManagement.length > 0 ? (
        <div className="user_settings">
          <div className="container_changeable_content">
            <Link to="/agenda">
              <div className="bilan_details_header">
                <div className="back_icon">
                  <img src={require('../../../assets/img/arrow1.png')} alt="" />
                </div>
                <span>Retour</span>
              </div>
            </Link>

            <div className="add_patient_info" style={{ marginTop: '2%' }}>
              <Grid
                container
                spacing={2}
                justifyContent="center"
                alignItems="center"
                className={classes.root}
              >
                <div className="contact_patients_list_rdv">
                  <div className="contact_patients_header_rdv">
                    <div className="search_contact_patients_name">
                      <div className="add_patient_form_item">
                        <label>Collaborateurs</label>
                        <select
                          style={{ marginBottom: '6%', width: '100%' }}
                          value={doctorLeft}
                          onChange={(e) => {
                            if (dateLeft) {
                              props.getAllRdv(props.params, e.target.value);
                            }
                            setDoctorLeft(e.target.value);
                            setActionLeft(true);
                            setActionRight(false);
                          }}
                          name="doctor_id"
                        >
                          <option value={null}>Collaborateurs</option>
                          {employeeManagement.map((item) => {
                            return (
                              <option
                                value={item.id}
                              >{`${item.first_name} ${item.last_name}`}</option>
                            );
                          })}
                        </select>
                      </div>
                      <input
                        value={dateLeft}
                        onChange={(e) => {
                          if (doctorLeft) {
                            props.getAllRdv(
                              {
                                ...props.params,
                                date: formatDate(e.target.value, 'YYYY-MM-DD'),
                              },
                              doctorLeft,
                            );
                          }
                          setDateLeft(e.target.value);
                          setActionLeft(true);
                          setActionRight(false);
                        }}
                        type="date"
                        placeholder="Num Tél"
                      />
                    </div>
                  </div>
                  <Grid item>{customList(dateLeft ? rdvsLeft : [], 'left')}</Grid>
                </div>
                <div className="contact_patients_list_rdv">
                  <Grid item>
                    <Grid container direction="column" alignItems="center">
                      <Button
                        variant="outlined"
                        size="small"
                        className={classes.button}
                        onClick={() => openModal('all')}
                        disabled={rdvsLeft.length === 0 || !dateRight}
                        aria-label="move all right"
                      >
                        ≫
                      </Button>
                      <Button
                        variant="outlined"
                        size="small"
                        className={classes.button}
                        //onClick={handleCheckedRight}
                        onClick={() => openModal('single')}
                        disabled={leftChecked.length === 0 || !dateRight}
                        aria-label="move selected right"
                      >
                        &gt;
                      </Button>
                      <Button
                        variant="outlined"
                        size="small"
                        className={classes.button}
                        onClick={handleCheckedLeft}
                        disabled={rightChecked.length === 0}
                        aria-label="move selected left"
                      >
                        &lt;
                      </Button>
                      <Button
                        variant="outlined"
                        size="small"
                        className={classes.button}
                        onClick={handleAllLeft}
                        disabled={rdvsRight.length === 0}
                        aria-label="move all left"
                      >
                        ≪
                      </Button>
                    </Grid>
                  </Grid>
                </div>
                <div className="contact_patients_list_rdv">
                  <div className="contact_patients_header_rdv">
                    <div className="search_contact_patients_name">
                      <div style={{ width: '50%' }} className="add_patient_form_item">
                        <label>Collaborateurs</label>
                        <select
                          style={{ marginBottom: '6%', width: '100%' }}
                          value={doctorRight}
                          onChange={(e) => {
                            if (dateRight) {
                              props.getAllRdv(props.params, e.target.value);
                            }
                            setDoctorRight(e.target.value);
                            setActionLeft(false);
                            setActionRight(true);
                          }}
                          name="doctor_id"
                        >
                          <option value={null}>Collaborateurs</option>
                          {employeeManagement.map((item) => {
                            return (
                              <option
                                value={item.id}
                              >{`${item.first_name} ${item.last_name}`}</option>
                            );
                          })}
                        </select>
                      </div>
                      <input
                        type="date"
                        onChange={(e) => {
                          if (doctorRight) {
                            props.getAllRdv(
                              {
                                ...props.params,
                                date: formatDate(e.target.value, 'YYYY-MM-DD'),
                              },
                              doctorRight,
                            );
                          }
                          setDateRight(e.target.value);
                          setActionLeft(false);
                          setActionRight(true);
                        }}
                        value={dateRight}
                        placeholder="Num Tél"
                      />
                    </div>
                  </div>
                  <Grid item>{customList(dateRight ? rdvsRight : [], 'right')}</Grid>
                </div>
              </Grid>
              <div style={{ marginTop: '15px' }} className="footer_buttons">
                <button
                  disabled={isVerified}
                  onClick={() => {
                    if (rdvsSelected && doctorRight) {
                      setActionLeft(true);
                      AffectRdv({
                        id: doctorRight,
                        appointments: rdvsSelected,
                        date: dateRight,
                      });
                    }
                  }}
                  style={{ marginRight: '7%' }}
                  className="footer_btn"
                  id="update"
                >
                  Valider
                </button>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div className="not_app_container">
          <img src={require('../../../assets/img/pas_de_disponibilite.svg')} />
          <span id="appointment_details_indispo">Pas de rendez-vous !</span>
          <span id="appointment_details_sub">
            Essayez de créer des nouveaux rendez-vous ou synchroniser votre service de
            calendrier
          </span>
        </div>
      )}
    </div>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(TransferList);
